import styles from "../workspace.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { IWorkspace } from "redux/actions";
import { getRelativeTime } from "utils/constants";
import { useWindowSize } from "hooks/useWindowSize";
interface WorkspaceCardProps {
    space?: IWorkspace;
    handleConversation?: (id: number) => void;
    chatThreadInfo?: boolean;

}

export const WorkspaceCard: React.FC<WorkspaceCardProps> = ({ space, handleConversation, chatThreadInfo }) => {

    const { currentConversation } = useSelector((state) => state.workSpaceReducer);
    const { theme } = useSelector((state) => state.authReducer);
    const { width } = useWindowSize();

    return (
        <div
            className={`${styles.createContainer} ${classNames(
                styles.solidBorder,
                {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                }
            )}`}
            onClick={() => handleConversation?.(space?.id ?? 0)}
        >
            <div className={`flex justify-between ${chatThreadInfo && width < 1240 && width > 1024
                    ? "flex-col-reverse gap-[10px] items-start"
                    : "items-center"
                }`}>
                {chatThreadInfo && currentConversation ? currentConversation && currentConversation?.emoji : space?.emoji}
                <div className="font-normal leading-[14.52px] text-[#737373] dark:text-[rgba(255,255,255,0.8)]">
                    Updated {chatThreadInfo
                        ? getRelativeTime(currentConversation?.updated_at ?? "")
                        : getRelativeTime(space?.updated_at ?? "")}
                </div>
            </div>
            <div
                className={classNames(styles.statement, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}
            >
                {chatThreadInfo && currentConversation ? currentConversation && currentConversation?.name : space?.name}
            </div>

            {/* <div className="flex justify-between items-center">
                <div className={styles.indicatorContainer}>
                    <div
                        className={classNames(styles.circleWhite, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}
                    >
                        <div
                            className={classNames(styles.circlegrey, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                        ></div>
                    </div>
                    <div
                        className={classNames(
                            "-ml-[8px]",
                            styles.circleWhite,
                            {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            }
                        )}
                    >
                        <div
                            className={classNames(styles.circlegrey, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                        ></div>
                    </div>
                    <div
                        className={classNames(
                            "-ml-[8px]",
                            styles.circleWhite,
                            {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            }
                        )}
                    >
                        <div
                            className={classNames(styles.circleBlue, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                        >
                            <span>3+</span>
                        </div>
                    </div>
                </div>
                <div className="font-normal leading-[14.52px] text-[#737373] dark:text-[rgba(255,255,255,0.8)]">
                    <FormattedMessage id="workspace.update.today" />
                </div>
            </div> */}
        </div>
    );
}