import { IconContainer } from "pages/SettingsPage/pages/CurrentPlan/components/components";
import { PrivateChatMode } from "../PrivateChat/privateChatMode";
import styles from "./SidebarDropdown.module.scss";
import { Credit, Star } from "pages/SettingsPage/pages/CurrentPlan/components/icons";
import { useSelector } from "redux/hooks";
import { FormattedMessage } from "react-intl";
import CustomButton from "components/Button";
import classNames from "classnames";
import { EThemeType } from "redux/reducers";
import styled from "styled-components";
import { useSidebar } from "hooks/services/ReSizeSidebar";
import useRouter from "hooks/useRouter";
import { RoutePaths } from 'pages/routePaths';

interface IProp {
    onStartNewChat?: ({ toChat, PrivateChat }: { toChat: boolean, PrivateChat?: boolean }) => void;
    sendMessageLoading?: boolean;
    isAnswerComplete?: boolean;
}

const DurationButton = styled(CustomButton) <{ theme: EThemeType, sidebarWidth: number }>`
  background-color: ${({ theme }) => (theme === "dark" ? "#23212C" : "#fff")};
  color: ${({ theme }) => (theme === "dark" ? "#fff" : "#5B71F5")};
  flex-shrink: 0;
  font-size: 8px;
  line-height: 12px;
  padding: ${({ sidebarWidth }) => (sidebarWidth <= 270 ? "9px 6px" : "9px 12px")};
  &:hover {
    color: ${({ theme }) => (theme === "dark" ? "#fff" : "#5B71F5")};
  }
`;

const Icon = styled(IconContainer) <{ sidebarWidth: number }>`
  padding: ${({ sidebarWidth }) => (sidebarWidth <= 270 ? "5px" : "10px")};
  border:  1px solid rgba(255, 255, 255, 0.20);
  border-radius: 8px;
`;

export const SidebarDropdown = ({
    onStartNewChat,
    sendMessageLoading,
    isAnswerComplete,
}: IProp) => {

    const { push } = useRouter();
    const { theme, userDetail } = useSelector((state) => state.authReducer);
    const { PrivateChat } = useSelector((state) => state.chatReducer);
    const { sidebarWidth } = useSidebar();
    return (
        <div className={`absolute mt-[2px] ${classNames(styles.Container, {
            [styles.dark]: theme === "dark",
            [styles.dropDownAdjustment]: sidebarWidth > 520,
        })}`}>

            <div className={classNames(styles.innerContainer, {
                [styles.dark]: theme === "dark",
            })}>
                {userDetail?.user.team.role === "admin" && (
                    <>
                        <div className={`flex items-center py-[7px] px-[7px] ${classNames({
                            [styles.isbackdrop]: PrivateChat,
                        })}`}>
                            <Icon sidebarWidth={sidebarWidth}>
                                <Star className={styles.icon} />
                            </Icon>
                            <div className={styles.textContainer}>
                                <div className={styles.title}>
                                    <FormattedMessage id="sidebar.chat.newChat.currentPlan" />
                                </div>
                                <div className={styles.feature}>
                                    {userDetail?.user?.activeSubscription?.name}
                                </div>
                            </div>
                            {userDetail?.user?.activeSubscription?.name !== "Business" && (
                                <DurationButton theme={theme} sidebarWidth={sidebarWidth} onClick={() => push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)}>
                                    <span className={styles.monthly}>
                                        <FormattedMessage id="Upgrade.plan" />
                                    </span>
                                </DurationButton>
                            )}
                        </div>

                        <div className={`flex items-center py-[7px] px-[7px] ${classNames({
                            [styles.isbackdrop]: PrivateChat,
                        })}`}>
                            <Icon sidebarWidth={sidebarWidth}>
                                <Credit className={styles.icon} />
                            </Icon>
                            <div className={styles.textContainer}>
                                <div className={styles.title}>
                                    <FormattedMessage id="settings.plan.feature.addCredits" />
                                </div>
                                <div className={styles.feature}>
                                    <FormattedMessage
                                        id="settings.plan.feature.creditsFor"
                                        values={{ number: "100000", price: "500" }}
                                    />
                                </div>
                            </div>
                            <DurationButton theme={theme} sidebarWidth={sidebarWidth} onClick={() => push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)}>
                                <span className={styles.monthly}>
                                    <FormattedMessage id="settings.plan.feature.buyCredits" />
                                </span>
                            </DurationButton>
                        </div>
                        <div className={classNames(styles.svg, {
                            [styles.dark]: theme === "dark"
                        })} />
                    </>
                )}
                <div className="py-[5px]">
                    <PrivateChatMode onStartNewChat={onStartNewChat}
                        sendMessageLoading={sendMessageLoading}
                        isAnswerComplete={isAnswerComplete}
                        sidebarDropDown={true}
                    />
                </div>
            </div>
        </div>
        )
}