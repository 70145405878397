import { useMemo } from "react";
interface DefaultIconProps {
  isProcessing?: boolean;
  isError?:boolean;
}
export const LinkIcon = () => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
      >
        <g clipPath="url(#clip0_11595_829)">
          <path
            d="M15.2215 6.79707C16.6561 5.3625 16.6561 3.03926 15.2215 1.60469C13.952 0.335159 11.9512 0.17012 10.4913 1.21368L10.4506 1.2416C10.085 1.50313 10.0012 2.01094 10.2627 2.37403C10.5243 2.73711 11.0321 2.82344 11.3952 2.56192L11.4358 2.53399C12.2508 1.95254 13.3655 2.04395 14.0713 2.75235C14.8711 3.55215 14.8711 4.84707 14.0713 5.64688L11.2225 8.50078C10.4227 9.30059 9.12778 9.30059 8.32798 8.50078C7.61958 7.79239 7.52817 6.67774 8.10962 5.86524L8.13755 5.82461C8.39907 5.45899 8.31274 4.95118 7.94966 4.69219C7.58657 4.43321 7.07622 4.517 6.81724 4.88008L6.78931 4.92071C5.74321 6.37813 5.90825 8.37891 7.17778 9.64844C8.61235 11.083 10.9356 11.083 12.3702 9.64844L15.2215 6.79707ZM2.02856 6.20293C0.593994 7.6375 0.593994 9.96074 2.02856 11.3953C3.2981 12.6648 5.29888 12.8299 6.75884 11.7863L6.79946 11.7584C7.16509 11.4969 7.24888 10.9891 6.98735 10.626C6.72583 10.2629 6.21802 10.1766 5.85493 10.4381L5.81431 10.466C4.99927 11.0475 3.88462 10.9561 3.17876 10.2477C2.37896 9.44532 2.37896 8.15039 3.17876 7.35059L6.02759 4.49922C6.82739 3.69942 8.12231 3.69942 8.92212 4.49922C9.63052 5.20762 9.72192 6.32227 9.14048 7.13731L9.11255 7.17793C8.85103 7.54356 8.93735 8.05137 9.30044 8.31035C9.66353 8.56934 10.1739 8.48555 10.4329 8.12246L10.4608 8.08184C11.5069 6.62188 11.3418 4.6211 10.0723 3.35157C8.63774 1.917 6.3145 1.917 4.87993 3.35157L2.02856 6.20293Z"
            fill="#94A3B8"
          />
        </g>
        <defs>
          <clipPath id="clip0_11595_829">
            <rect
              width="16.25"
              height="13"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
  );
};

export const DefaultIcon: React.FC<DefaultIconProps> = ({ isProcessing = false, isError=false }) => {
  const defaultIconContainerStyle = useMemo(
    () => ({
      width: "30px",
      height: "30px",
      backgroundColor: isProcessing ? "#DBEAFE" : isError ? "#FEE2E2" : "#E2E8F0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
    }),
    [isProcessing, isError ]
  );
  return (
    <div style={defaultIconContainerStyle} >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <g clipPath="url(#clip0_415_614)">
        <path
          d="M18.1188 8.36559C19.8845 6.59997 19.8845 3.74059 18.1188 1.97497C16.5563 0.412466 14.0938 0.209341 12.297 1.49372L12.247 1.52809C11.797 1.84997 11.6938 2.47497 12.0157 2.92184C12.3376 3.36872 12.9626 3.47497 13.4095 3.15309L13.4595 3.11872C14.4626 2.40309 15.8345 2.51559 16.7032 3.38747C17.6876 4.37184 17.6876 5.96559 16.7032 6.94997L13.197 10.4625C12.2126 11.4468 10.6188 11.4468 9.63447 10.4625C8.7626 9.59059 8.6501 8.21872 9.36572 7.21872L9.4001 7.16872C9.72197 6.71872 9.61572 6.09372 9.16885 5.77497C8.72197 5.45622 8.09385 5.55934 7.7751 6.00622L7.74072 6.05622C6.45322 7.84997 6.65635 10.3125 8.21885 11.875C9.98447 13.6406 12.8438 13.6406 14.6095 11.875L18.1188 8.36559ZM1.88135 7.63434C0.115723 9.39997 0.115723 12.2593 1.88135 14.025C3.44385 15.5875 5.90635 15.7906 7.70322 14.5062L7.75322 14.4718C8.20322 14.15 8.30635 13.525 7.98447 13.0781C7.6626 12.6312 7.0376 12.525 6.59072 12.8468L6.54072 12.8812C5.5376 13.5968 4.16572 13.4843 3.29697 12.6125C2.3126 11.625 2.3126 10.0312 3.29697 9.04684L6.80322 5.53747C7.7876 4.55309 9.38135 4.55309 10.3657 5.53747C11.2376 6.40934 11.3501 7.78122 10.6345 8.78434L10.6001 8.83434C10.2782 9.28434 10.3845 9.90934 10.8313 10.2281C11.2782 10.5468 11.9063 10.4437 12.2251 9.99684L12.2595 9.94684C13.547 8.14997 13.3438 5.68747 11.7813 4.12497C10.0157 2.35934 7.15635 2.35934 5.39072 4.12497L1.88135 7.63434Z"
          fill={isProcessing ? "#2563EB" : isError? "#DC2626" : "#94A3B8"}
        />
      </g>
      <defs>
        <clipPath id="clip0_415_614">
          <rect width="20" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
    </div>
  );
};
