import { useS3FileUpload } from "hooks/services/AmazonServices";
import { useAppNotification } from "hooks/services/AppNotification";
import { AddChatContentFile, EmptyContentFile, generatePresignedURL, UpdateFileContent } from "redux/actions";
import { Dispatch, SetStateAction } from "react";
import { IUploadFile } from "pages/ChatPage";
import { useNetwork } from "hooks/services/NetworkProvider";
import { useRef, useEffect } from "react";

interface IProp {
    fileArray: File[],
    setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>> | undefined;
    fileType: "image" | "document" | "video" | "audio";
    setIsFileUploading?: Dispatch<SetStateAction<boolean>> | undefined;
    setFileS3Link?: Dispatch<SetStateAction<string[]>>;
    // wsNewChat?: boolean;
}

export const useFileUploader = () => {
    const { uploadFile } = useS3FileUpload();
    const { triggerNotification } = useAppNotification();
    const { isOnline } = useNetwork();
    const abortControllers = useRef<{ [key: string]: AbortController }>({});
    
 const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
};
useEffect(() => { 
    if (!isOnline) { 
        Object.values(abortControllers.current).forEach(controller => { controller.abort(); });
        abortControllers.current = {};
    } 
    return () => {
        Object.values(abortControllers.current).forEach(controller => { controller.abort(); });
    };
  }, [isOnline]);

    const uploadToS3 = async ({
        fileArray,
        setUploadingFiles,
        fileType,
        setIsFileUploading,
        setFileS3Link,
        //  wsNewChat
    }: IProp) => {
        fileArray.forEach((file) => {
            
            const newFile = { id: generateUniqueId(), file, status: "validating" as const, fileType, fileSize: file.size / (1024 * 1024)};

            AddChatContentFile(newFile);
            setUploadingFiles?.((prev) => [
                {id: generateUniqueId(), file, status: "validating", fileType: fileType },
                ...prev,
            ]);
            
        });

        const preSignedURLPromises = fileArray.map((file) => {
            const updatedFileName = `website-${new Date().getTime()}-${file.name}`;
            setUploadingFiles?.((prev) =>
                prev.map((f) =>
                    f.file === file ? { ...f, status: "uploading" , customFileName: updatedFileName } : f
                )
            );
            UpdateFileContent(file, "uploading")
            return generatePresignedURL({
                name: updatedFileName,
            })
                .then((preSignedRes: any) => ({
                    file,
                    preSignedURL: new URL(preSignedRes.data.url),
                }))
                .catch((err) => {
                    if(err?.message === "Network Error" || err?.data?.message === "Network Error" || err?.message === "canceled") {
                        setUploadingFiles?.((prev) => 
                          prev.filter((f) => f.file !== file));
                        EmptyContentFile();
                      }
                      else {
                    triggerNotification({
                        message: err?.data?.message,
                        type: "error",
                    });
                    setUploadingFiles?.((prev) =>
                        prev.map((f) =>
                            f.file === file ? { ...f, status: "error" } : f
                        )
                    );

                    UpdateFileContent(file, "error")
                }
                    setIsFileUploading?.(false);
                    return null;
                });
        });
        const preSignedURLs = await Promise.all(preSignedURLPromises);
        if (preSignedURLs.some((item) => item === null)) {
            return;
        }
        const validPreSignedURLs = preSignedURLs as {
            file: File;
            preSignedURL: URL;
        }[];
        const uploadPromises = validPreSignedURLs.map(
            ({ file, preSignedURL }) => {
                setUploadingFiles?.((prev) =>
                    prev.map((f) =>
                        f.file === file ? { ...f, status: "uploading" } : f
                    )
                );

                UpdateFileContent(file, "uploading");
                const abortController = new AbortController();
                abortControllers.current[file.name] = abortController;
                    
                return uploadFile({
                    file: file,
                    preSignedUrl: preSignedURL.href,
                    signal: abortController.signal, // Pass the abort signal   
                })
                    .then(() => {
                        setUploadingFiles?.((prev) =>
                            prev.map((f) =>
                                f.file === file
                                    ? {
                                        ...f,
                                        status: "uploaded",
                                        S3Link: `${preSignedURL?.origin}${preSignedURL?.pathname}`,
                                    }
                                    : f
                            )
                        );

                          UpdateFileContent(file, "uploaded", `${preSignedURL?.origin}${preSignedURL?.pathname}` )
                          delete abortControllers.current[file.name];
                        return { preSignedURL };
                    })
                    .catch((err) => {
                        if(err?.message === "Network Error" || err?.message === "canceled") {
                          setUploadingFiles?.((prev) => 
                            prev.filter((f) => f.file !== file));
                          EmptyContentFile();
                        }
                        else {
                        triggerNotification({
                            message: err?.data?.message,
                            type: "error",
                        });
                        setUploadingFiles?.((prev) =>
                            prev.map((f) =>
                                f.file === file ? { ...f, status: "error" } : f
                            )
                        ); 
                             UpdateFileContent(file, "error" ) 
                    }
                    delete abortControllers.current[file.name];
                        setIsFileUploading?.(false);
                        return null;
                    });
            }
        );

        const uploadedFiles = (await Promise.all(uploadPromises)).filter((item) => item !== null);

        const imagePath = uploadedFiles.map((img) => {
            return `${img?.preSignedURL?.origin}${img?.preSignedURL?.pathname}`;
        });
        
        setIsFileUploading?.(false);
        setFileS3Link?.((prevLinks) => [...imagePath, ...prevLinks]);
    
    };

    return { uploadToS3 };
};