import { useIntl } from "react-intl";
import styles from "../RichContext.module.scss";
// import CustomButton from "components/Button";
import styled from "styled-components";
import { Field, Formik, FieldProps, FormikHelpers } from "formik";
import { FieldItem, Form } from "pages/AuthPages/components/FormComponents";
import { LabeledInput } from "components";
import { PlusIcon } from "../../icons/RightSectionIcon";
import { useSelector } from "redux/hooks";
import { ErrorHandler } from "../../ErrorHandler/ErrorHandler";
import { ChangeEvent } from "react";
import { useEffect } from "react";

import { RippleIconButton } from "components/RippleEffect/RippleEffects";

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldRowItem = styled(FieldItem)`
  border-radius: 4px;
  margin-bottom: 0;
`;

// const DurationButton = styled(CustomButton)`
//   background-color: #3730A3;
//   color: #fff;
//   flex-shrink: 0;
//   font-size: 12px;
//   line-height: 15px;
//   font-weight:600;
//   border-radius: 4px;
//   padding: 6px 11px";
//     @media (max-width:576px) {
//         font-size: 11px;
//          padding: 5px 16px;
//      } 
// `;

// Styled button content to match DurationButton
const ButtonWrapper = styled.div<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? "#3730A3" : "#3730A3")};
  color: #fff;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  border-radius: 4px;
  padding: 10px 11px;
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  @media (max-width: 576px) {
    font-size: 11px;
    padding: 10px 16px;
  }
`;

interface UrlUploadUIProps {
  onSubmit: (url: string, resetForm: () => void) => void;
  errorModal: {
    message: string;
    show: boolean;
  };
  setErrorModal: (modal: { message: string; show: boolean }) => void;
  messageId: string;
  onConfirm: () => void;
  onCancel: () => void;
  getFileURL?: (event: ChangeEvent<HTMLInputElement>) => void;
  chatContent?: boolean
  placeholderId?: string;
}

interface FormValues {
  name: string;
}

export const UrlUploadUI = ({
  onSubmit,
  errorModal,
  setErrorModal,
  messageId,
  onConfirm,
  onCancel,
  getFileURL,
  chatContent,

  placeholderId = "workspace.context.placeholder",
}: UrlUploadUIProps) => {
  const { formatMessage } = useIntl();

  const { theme, userDetail } = useSelector((state) => state.authReducer);
  const { workSpaceFiles } = useSelector((state) => state.workSpaceReducer);

  // Get plan name
  const PlanName = userDetail?.user.activeSubscription?.name;
  const handleSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    onSubmit(values.name, resetForm);
  };
  const isFileLimitError =
  (!chatContent &&
    (errorModal.message === formatMessage({ id: "workSpace.upload.validation" }) ||
    errorModal.message === formatMessage({ id: "workSpace.upload.validation.Free" })));

  const isButtonDisabled =  !chatContent && ((PlanName === "Free" && (workSpaceFiles?.length ?? 0) >= 1) ||
    (PlanName !== "Free" && (workSpaceFiles?.length ?? 0) >= 5));

  useEffect(() => {
    if (!isButtonDisabled && errorModal.show) {
      setErrorModal({ message: "", show: false });
    }
  }, [workSpaceFiles?.length, isButtonDisabled]);

  // Determine if input should be read-only
const isInputReadOnly = !chatContent && (isButtonDisabled || (isFileLimitError && errorModal.show));
  return (
    <>
      <div className={styles.urlWrapper}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ name: "" }}
        >
          {({ handleSubmit }) => (
            <>
              <Form data-testid="workspace-form" style={{ width: "100%" }}>
                <FieldsRow>
                  <FieldRowItem>
                    <Field name="name">
                      {({ field, meta }: FieldProps<string>) => (
                        <LabeledInput
                          {...field}
                          workspace={true}
                          placeholder={formatMessage({
                            id: placeholderId,
                          })}
                          type="text"
                          error={!!meta.error && meta.touched}
                          value={
                            isInputReadOnly
                              ? formatMessage({ id: errorModal.message && errorModal.show
                                  ? errorModal.message
                                  : (PlanName === "Free" ? "workSpace.upload.validation.Free" : "workSpace.upload.validation")
                                })
                              : field.value 
                          }
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (!isInputReadOnly) {
                              field.onChange(event); 
                            }
                            if (getFileURL && chatContent) getFileURL(event);
                          }}
                          readOnly={isInputReadOnly}
                          variant={theme}
                          data-testid="workspace-create-name"
                        />
                      )}
                    </Field>


                  </FieldRowItem>
                </FieldsRow>
              </Form>
              <RippleIconButton
              uploadfile={true}
              color={'rgba(255, 255, 255, 0.5)'} onClick={() => handleSubmit()}>
              <ButtonWrapper disabled={isButtonDisabled}>
                <span className={styles.btn}>
                  {" "}
                  <PlusIcon URL={true} />{" "}
                </span>
              </ButtonWrapper>
              </RippleIconButton>
            </>
          )}
        </Formik>
      </div>
      {((!isFileLimitError || !errorModal.show) || chatContent) && (
        <ErrorHandler
          errorModal={{
            message: isFileLimitError ? "" : errorModal.message,
            show: isFileLimitError ? false : errorModal.show
          }}
          setErrorModal={setErrorModal}
          messageId={messageId}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </>
  );
};
