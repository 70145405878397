import { Dispatch, SetStateAction } from "react";
import { validateAudioDuration, validateFileSize, validateFileType, validateVideoDurations } from "./functions";
import { 
  // IErrorMessage,
   IFileErrorState } from "pages/ChatPage/pages";
import { IMessage, IUserDetail, updateErrorMessage, UploadFile, IChatContentUploadFile, IFilePreview } from "redux/actions";
import { IUploadFile } from "pages/ChatPage";
import { AllowedFileTypes } from "./constants";

const AllowedFileSize = 100;
const FreePlanAllowedFileSize = 10;
const StandardPlanAllowedFileSize = 50;

export const validateImgFile = (
  files: File[],
  setErrorModal:  Dispatch<SetStateAction<IFileErrorState>>,
  claudAllowedFileSize: number,
  // formatMessage: any
): boolean => {
  let isValid = true;
  let allowedFileSize = claudAllowedFileSize;

  for (let file of files) {
    if (!validateFileType({ file: file, types: AllowedFileTypes })) {

    setErrorModal({ message: "documentChat.file.fileFormatImage" , show: true });
      // setErrorMessage?.([]);
      // const formattedErrorMessage = formatMessage(
      //   { id: "documentChat.file.fileFormatImage" },
      //   { name: file.name }
      // );
      // setErrorMessage &&
      //   setErrorMessage((prevErrors) => [
      //     ...prevErrors,
      //     { name: file.name, error: formattedErrorMessage },
      //   ]);
      isValid = false;
      break;
    }

    if (
      !validateFileSize({
        file,
        sizeOptions: { size: allowedFileSize, unit: "MB" },
      })
    ) {
    setErrorModal({ message: "documentChat.file.fileSizeImage" , show: true });
      // setErrorMessage?.([]);
      // const formattedErrorMessage = formatMessage(
      //   { id: "documentChat.file.fileSizeImage" },
      //   { name: file.name, size: allowedFileSize }
      // );
      // setErrorMessage &&
      //   setErrorMessage((prevErrors) => [
      //     ...prevErrors,
      //     { name: file.name, error: formattedErrorMessage },
      //   ]);
      isValid = false;
      break;
    }
  }

  return isValid;
};

export const validateFile = (
  files: File[],
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>,
  userDetail: IUserDetail | undefined,
  setMessageId?: Dispatch<SetStateAction<string>>,
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>> | undefined,
  // fileType?: "document" | "video",
): boolean => {
  let isValid = true;
  const planName = userDetail?.user.activeSubscription.name.toLowerCase();

  if (!files || files.length === 0) {
    setErrorModal({ message: "documentChat.file.required", show: true });
    setIsMainScreenOpen?.(true);
    return false;
  }

  for (let file of files) {
    if (!validateFileType({ file, types: AllowedFileTypes })) {
      setErrorModal({ message: "documentChat.file.fileFormat", show: true });
      setIsMainScreenOpen?.(true);
      isValid = false;
      break;
    }

    // if(fileType !== "video"){
    let allowedFileSize = AllowedFileSize;

    if (planName === "free") {
      allowedFileSize = FreePlanAllowedFileSize;
    } else if (planName === "standard") {
      allowedFileSize = StandardPlanAllowedFileSize;
    }

    const valid = validateFileSize({
      file,
      sizeOptions: { size: allowedFileSize, unit: "MB" },
    });

    if (!valid) {
      if (planName === "free" || planName === "standard") {
        setMessageId &&
          setMessageId(`documentChat.file.${planName}.plan.fileSize`);
      } else {
        setErrorModal({ message: "documentChat.file.fileSize", show: true });
        setIsMainScreenOpen?.(true);
      }
      isValid = false;
      break;
    }
    // }
  }
  return isValid;
};

// Helper function to classify files based on type
export const classifyFiles = (files: File[]) => {
  return {
    imageFiles: files.filter((file) => file.type.startsWith("image/")),
    docFiles: files.filter((file) => !file.type.startsWith("image/") && !file.type.startsWith("video/") && !file.type.startsWith("audio/")),
    videoFiles: files.filter((file) => file.type.startsWith("video/")),
    audioFiles: files.filter((file) => file.type.startsWith("audio/")),
    otherFiles: files.filter((file) => !file.type.startsWith("image/")),
  };
};

// Helper function to check if the current chat type matches a given type and if files are present
export const isChatTypeMatchAndFilesPresent = (
  chatType: string,
  files: File[],
  newMessages: IMessage[],
  messages: IMessage[],
  history: boolean
): boolean => {
  const isChatTypeMatch = newMessages.length > 0 && newMessages[0]?.chatType === chatType ||
    history && messages && messages[0]?.chatType === chatType;

  return isChatTypeMatch && files.length > 0;
};

// Helper function to handle validation for file types
export const validateFileTypes = (
  newFilesArray: File[],
  setValidationError: (messageKey: string) => void,
  newMessages: IMessage[],
  messages: IMessage[],
  history: boolean,
  uploadingFiles: IUploadFile[] | undefined | IChatContentUploadFile[] | IFilePreview[],
): boolean => {

  const { imageFiles, docFiles, videoFiles, audioFiles, otherFiles } = classifyFiles(newFilesArray);

  const nonEmptyFileCounts = [
    docFiles.length > 0,
    imageFiles.length > 0,
    videoFiles.length > 0,
    audioFiles.length > 0,
  ].filter(Boolean).length;

  if (isChatTypeMatchAndFilesPresent("image_chat", otherFiles, newMessages, messages, history)) {
    setValidationError("upload.file.validation.docURL");
    return true;
  } else if (isChatTypeMatchAndFilesPresent("document", imageFiles.concat(videoFiles, audioFiles), newMessages, messages, history)) {
    setValidationError("upload.file.validation.imgURL");
    return true;
  } else if (isChatTypeMatchAndFilesPresent("video", imageFiles.concat(docFiles, audioFiles), newMessages, messages, history)) {
    setValidationError("upload.file.validation.video");
    return true;
  } else if (isChatTypeMatchAndFilesPresent("audio", imageFiles.concat(docFiles, videoFiles), newMessages, messages, history)) {
    setValidationError("upload.file.validation.audio");
    return true;
  } else if (
    (nonEmptyFileCounts >= 2) ||
    (uploadingFiles && uploadingFiles.length > 0 && (
      (uploadingFiles[0]?.file.type.startsWith("image/") && otherFiles.length > 0) ||
      (uploadingFiles[0]?.file.type.startsWith("audio/") && (imageFiles.length > 0 || docFiles.length > 0 || videoFiles.length > 0)) ||
      (uploadingFiles[0]?.file.type.startsWith("video/") && (imageFiles.length > 0 || docFiles.length > 0 || audioFiles.length > 0)) ||
      (!uploadingFiles[0]?.file.type.startsWith("image/") &&
        !uploadingFiles[0]?.file.type.startsWith("audio/") &&
        !uploadingFiles[0]?.file.type.startsWith("video/") &&
        (imageFiles.length > 0 || videoFiles.length > 0 || audioFiles.length > 0))
    ))
  ) {
    setValidationError("upload.file.validation.combine");
    return true;
  }

  return false;
};

export const shouldPreventMultipleVideoUpload = (videoFiles: File[], uploadingFiles: IUploadFile[] | undefined | IChatContentUploadFile[] | IFilePreview[]) => {
  if (videoFiles.length > 1 || (uploadingFiles && uploadingFiles.length > 0 && uploadingFiles[0]?.file.type.startsWith("video/") && videoFiles.length > 0)) {
    return true;
  }
  return false;
};

export const hasInvalidVideoDuration = async (videoFiles: File[]) => {
  if (videoFiles.length > 0 && await isExceedingVideoDuration(videoFiles[0])) {
    return true;
  }
  return false;
};

export const hasInvalidAudioDuration = async (audioFiles: File[]) => {
  for (const audioFile of audioFiles) {
    if (await isExceedingAudioDuration(audioFile)) {
      return true;
    }
  } return false;
};

//HELPER FUNCTION FOR VIDEO DURATION
export const isExceedingVideoDuration = async (file: File) => {
  try {
    const duration = await validateVideoDurations(file);
    return typeof duration === "number" && duration > 1800;
  } catch (error) {
    console.error(error); return false;
  }
};

//HELPER FUNCTION FOR AUDIO DURATION
export const isExceedingAudioDuration = async (file: File) => {
  try {
    const duration = await validateAudioDuration(file);
    return typeof duration === "number" && duration > 3600;
  } catch (error) {
    console.error(error); return false;
  }
};

// Helper function to validate the maximum number of files
export const validateMaxFileCount = (files: File[], maxCount: number, uploadingFiles: IUploadFile[] | undefined | IChatContentUploadFile[] | IFilePreview[]): boolean => {
  const totalFilesCount = (uploadingFiles ? uploadingFiles.length : 0) + files.length;
  return totalFilesCount <= maxCount;
};


export const validateWSFile = (
  files: UploadFile[],
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>,
  userDetail: IUserDetail | undefined,
): boolean => {
  let isValid = true;
  const planName = userDetail?.user.activeSubscription.name.toLowerCase();

  if (!files || files.length === 0) {
    setErrorModal({ message: "documentChat.file.required", show: true });
    return false;
  }

  for (let updatedFile of files) {
    const { file, id } = updatedFile;
    if (!validateFileType({ file, types: AllowedFileTypes })) {
      setErrorModal({ message: "documentChat.file.fileFormat", show: true });
      isValid = false;
      break;
    }

    let allowedFileSize = AllowedFileSize;

    if (planName === "free") {
      allowedFileSize = FreePlanAllowedFileSize;
    } else if (planName === "standard") {
      allowedFileSize = StandardPlanAllowedFileSize;
    }

    const valid = validateFileSize({
      file,
      sizeOptions: { size: allowedFileSize, unit: "MB" },
    });

    if (!valid) {
      if (planName === "free" || planName === "standard") {
        updateErrorMessage(id, "error", `workSpace.file.size.limits`);
      } else {
        updateErrorMessage(id, "error", `workSpace.file.size.limit`);
      }
      isValid = false;
      break;
    }
  }
  return isValid;
};
