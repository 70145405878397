import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "redux/store";
import { EChatType } from "../chatInterface";
import { setShowUpscaleModal } from "../imageGeneration";
import { filterLanguages } from "../chat";

export interface IModelAttributes {
  sizes: ISize[];
  max_image_count?: number;
  default: boolean;
  loader: boolean;
}

export interface ISize {
  value: string;
  width: number;
  height: number;
  credits_per_message: number;
}

export interface IChatModel {
  id: number;
  name: string;
  model: string;
  description: string | null;
  credits_per_message: number;
  image_url: string;
  created_at: string | null;
  updated_at: string | null;
  attributes: IModelAttributes | null;
  type: EChatType[];
}

export type EModelAttributes =
  (typeof ModelAttributes)[keyof typeof ModelAttributes];

export const ModelAttributes = {
  count: "count",
  maxCount: "maxCount",
  size: "size",
} as const;

export interface ISetImageAttribute {
  key: EModelAttributes;
  value: any;
}

export const getModels = () => {
  const { chatModels } = store.getState().chatModelsReducer;
  return new Promise((resolve, reject) => {
    if (chatModels.length === 0) {
      api
        .get("/api/ai-models")
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const getChatModels = () => {
  return new Promise((resolve, reject) => {
    getModels()
      .then((res: any) => {
        const { showUpscaleModal } = store.getState().authReducer;

         // Get all stored models from localStorage
         const storedGptModel = localStorage.getItem("GptModel");
         const storedVideoModel = localStorage.getItem("videoChatGptModel");
         const storedImgModel = localStorage.getItem("imageGptModel");
         const storedImgChatModel = localStorage.getItem("imageChatGptModel");
         const storeddocModel = localStorage.getItem("documentChatGptModel");
         const storedAudMod = localStorage.getItem("audioChatGptModel");
 
         // Parse all stored models
         const parsedGptModel = parseStoredModel(storedGptModel);
         const parsedVideoModel = parseStoredModel(storedVideoModel);
         const parsedImgModel = parseStoredModel(storedImgModel);
         const parsedImgChatModel = parseStoredModel(storedImgChatModel);
         const parsedDocModel = parseStoredModel(storeddocModel);
         const parsedAudioModel = parseStoredModel(storedAudMod);

        let models: IChatModel[] = res.data.map((model: IChatModel) => {
          return {
            ...model,
            type: model?.type?.filter(
              (modelType: EChatType) =>
                modelType === "document" ||
                modelType === "text" ||
                modelType === "image" ||
                modelType === "image_chat" || modelType === "video" || modelType === "audio"
            ),
          };
        });

        // Check and update main text chat model
        let model = validateAndUpdateModel(parsedGptModel, models, "GptModel");
        
        // Check and update other model types
        validateAndUpdateModel(parsedVideoModel, models, "videoChatGptModel");
        validateAndUpdateModel(parsedImgModel, models, "imageGptModel");
        validateAndUpdateModel(parsedImgChatModel, models, "imageChatGptModel");
        validateAndUpdateModel(parsedDocModel, models, "documentChatGptModel");
        validateAndUpdateModel(parsedAudioModel, models, "audioChatGptModel");
        
        if (!showUpscaleModal) {
          setShowUpscaleModal({ showUpscaleModal: "show" });
        }
        store.dispatch({ type: TYPES.GET_CHAT_MODELS, payload: models });
        store.dispatch({
          type: TYPES.SET_CHAT_MODEL,
          payload: model as IChatModel,
        });
        resolve(models);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Helper function to safely parse stored model
const parseStoredModel = (storedModel: string | null) => {
  if (!storedModel) {
    return null;
  }

  try {
    if (storedModel && storedModel !== "undefined") {
      return JSON.parse(storedModel);
    }
  } catch (e) {
    console.error("Error parsing stored model:", e);
  }
  return null;
};

// Helper function to validate and update models in localStorage
const validateAndUpdateModel = (parsedModel:IChatModel , models: IChatModel[], storageKey: string) => {
  // If we have a parsed model, check if it exists in the models array
  if (parsedModel) {
    const modelExists = models.some(model => model.id === parsedModel.id);
    
    if (!modelExists) {
      // If model doesn't exist, find appropriate replacement based on type
      const modelType = getModelTypeFromKey(storageKey);
      const defaultModel = models.find(model => 
        model.type && model.type.includes(modelType as EChatType)
      ) || models[0];
      
      // Update localStorage with new model
      localStorage.setItem(storageKey, JSON.stringify(defaultModel));
      return defaultModel;
    } else {
      // If model exists but name changed, update it
      const matchingModel = models.find(m => m.id === parsedModel.id);
      if (matchingModel && matchingModel.name !== parsedModel.name) {
        localStorage.setItem(storageKey, JSON.stringify(matchingModel));
        return matchingModel;
      }
      return parsedModel;
    }
  } else {
    // If no stored model, set default for this type
    const modelType = getModelTypeFromKey(storageKey);
    const defaultModel = models.find(model => 
      model.type && model.type.includes(modelType as EChatType)
    ) || models[0];
    
    localStorage.setItem(storageKey, JSON.stringify(defaultModel));
    return defaultModel;
  }
};

// Helper to determine model type from localStorage key
const getModelTypeFromKey = (key: string): string => {
  switch (key) {
    case "videoChatGptModel": return "video";
    case "imageGptModel": return "image";
    case "imageChatGptModel": return "image_chat";
    case "documentChatGptModel": return "document";
    case "audioChatGptModel": return "audio";
    default: return "text";
  }
};

export const setChatModel = (chatModel: IChatModel) => {
  if (chatModel) {
    
    store.dispatch({ type: TYPES.SET_CHAT_MODEL, payload: chatModel });
    filterLanguages(chatModel?.model);
  }
  if (chatModel?.type?.includes("image")) {
    if (chatModel?.attributes?.sizes) {
      setImageAttribute({ key: "count", value: 1 });
      setImageAttribute({ key: "size", value: chatModel?.attributes?.sizes[0] });
      setImageAttribute({
        key: "maxCount",
        value: chatModel?.attributes?.max_image_count,
      });
    }
  }
};

export const resetChatModel = () => {
  const { chatModels } = store.getState().chatModelsReducer;
  if (chatModels.length > 0) {
    const GPTModal = localStorage.getItem('GptModel');
    if (GPTModal) {
      const gptModel = JSON.parse(GPTModal);
      store.dispatch({ type: TYPES.SET_CHAT_MODEL, payload: gptModel });
    }
    else
      store.dispatch({ type: TYPES.SET_CHAT_MODEL, payload: chatModels[0] });
  } else {
    getChatModels().catch();
  }
};

export const setImageAttribute = (attribute: ISetImageAttribute) => {
  store.dispatch({ type: TYPES.SET_IMAGE_ATTRIBUTE, payload: attribute });
};

export const setRegenerateImageAttribute = (attribute: ISetImageAttribute) => {
  store.dispatch({ type: TYPES.SET_REGENERATE_IMAGE_ATTRIBUTE, payload: attribute });
};