import { IAction, TYPES } from "../types";
import {
  IUserDetail,
  IActivePlan,
  IUser,
  IChatModel,
  ISize,
  ISetImageAttribute,
} from "../actions";
import { produce } from "immer";

export interface ICreditLimit {
  daily_limit: number;
  used_today: number;
}

export interface IAttributes {
  count: number;
  size?: ISize;
  maxCount?: number;
}

export interface subOption {
  label: string;
  value: string;
}

export interface DropdownOption {
  key: string,
  label: string;
  value: string;
  options: subOption[];
}

export interface SelectedSubOptions {
  outputFormats: string,
  tones: string,
  writingStyles: string,
  responseLengths: string,
  language: string,
}

export type EThemeType = (typeof ThemeType)[keyof typeof ThemeType];

export const ThemeType = {
  light: "light",
  dark: "dark",
} as const;

export interface IAuthReducerState {
  creditLimits: ICreditLimit;
  userDetail: IUserDetail | undefined;
  gptModel?: IChatModel;
  imageAttributes?: IAttributes;
  showUpscaleModal?: "show" | "hide";
  theme?: EThemeType;
  selectedSubOptions: SelectedSubOptions;
  regenerateAttribute?: IAttributes;
  planExpired: boolean;
}

interface ICreditLimitAction extends IAction {
  payload: {
    creditLimits: ICreditLimit;
  };
}

interface ISignupAction extends IAction {
  payload: {
    invitationToken: string;
  };
}

interface IVerifyEmailAction extends IAction {
  payload: IUserDetail;
}

interface IAcceptInvitationAction extends IAction {
  payload: IUser;
}

interface ISelectTeamAction extends IAction {
  payload: IUser;
}

interface IGetActivePlanAction extends IAction {
  payload: IActivePlan;
}

interface IUpdateCreditAction extends IAction {
  payload: number;
}

interface IUpdateLanguageAction extends IAction {
  payload: string;
}

interface ISetImageAttributeAction extends IAction {
  payload: ISetImageAttribute;
}

type Action = ICreditLimitAction &
  IVerifyEmailAction &
  ISignupAction &
  IAcceptInvitationAction &
  ISelectTeamAction &
  IGetActivePlanAction &
  IUpdateCreditAction &
  IUpdateLanguageAction &
  ISetImageAttributeAction ;

const state: IAuthReducerState = {
  creditLimits: {
    daily_limit: 0,
    used_today: 0,
  },
  userDetail: undefined,
  gptModel: undefined,
  imageAttributes: {
    count: 1,
    size: undefined,
    maxCount: 1,
  },
  showUpscaleModal: "hide",
  theme: "light",
  selectedSubOptions: {
    outputFormats: 'default',
    tones: 'default',
    writingStyles: 'default',
    responseLengths: 'default',
    language: 'default',
  },
  regenerateAttribute: {
    count: 1,
    size: undefined,
    maxCount: 1,
  },
  planExpired: false,

};

export const KEY = "DGPT_USER";

export const authReducer = (
  mState = { ...state },
  action: Action
): IAuthReducerState => {
  switch (action.type) {
    case TYPES.SET_CHAT_MODEL: {
      const nState = produce(mState, (dState) => {
        dState.gptModel = action.payload;
        if (!dState?.imageAttributes) {
          dState.imageAttributes = {
            count: 1,
            size: undefined,
            maxCount: 1,
          };
        }
        if (!dState?.regenerateAttribute) {
          dState.regenerateAttribute = {
            count: 1,
            size: undefined,
            maxCount: 1,
          };
        }
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SET_IMAGE_ATTRIBUTE: {
      const nState = produce(mState, (dState) => {
        (dState?.imageAttributes as IAttributes)[action.payload["key"]] =
          action.payload["value"];
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SET_REGENERATE_IMAGE_ATTRIBUTE: {
      const nState = produce(mState, (dState) => {
        (dState?.regenerateAttribute as IAttributes)[action.payload["key"]] =
          action.payload["value"];
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SET_SHOW_UPSCALE_MODAL: {
      const nState = produce(mState, (dState) => {
        dState.showUpscaleModal = action.payload["showUpscaleModal"];
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SET_SYSTEM_THEME: {
      const nState = produce(mState, (dState) => {
        dState.theme = action.payload["theme"];
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.GET_CREDIT_LIMITS: {
      const nState = produce(mState, (dState) => {
        dState.creditLimits = action.payload["creditLimits"];
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.VERIFY_EMAIL: { 
      const nState = produce(mState, (dState) => {
        dState.userDetail = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.CHANGE_EMAIL: {
      const nState = produce(mState, (dState) => {
        (dState.userDetail as IUserDetail).user.email = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SELECT_TEAM: {
      const nState = produce(mState, (dState) => {
        (dState.userDetail as IUserDetail).user = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SOCIAL_CALLBACK: {
      const nState = produce(mState, (dState) => {
        dState.userDetail = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.GET_ACTIVE_PLAN: {
      const nState = produce(mState, (dState) => {
        (dState.userDetail as IUserDetail).user.activeSubscription =
          action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.UPDATE_CREDITS: {
      const nState = produce(mState, (dState) => {
        (dState.userDetail as IUserDetail).user.activeSubscription.credits =
          action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SET_SELECTED_OPTIONS: {
      const nState = produce(mState, (dState) => {
        dState.selectedSubOptions = action.payload;
      });
      return nState;
    }

    case TYPES.UPDATE_LANGUAGE: {
      const nState = produce(mState, (dState) => {
        (dState.userDetail as IUserDetail).user.language = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.INIT_USER_DETAILS: {
      const nState = produce(mState, (dState) => {
        dState.userDetail = {
          token: "",
          user: {
            id: 0,
            activeSubscription: {
              id: 0,
              attributes: { max_document_chats: 0, max_video_chats: 0, max_audio_chats: 0 },
              team_id: 0,
              status: "",
              credits: 0,
              document_chat_count: 0,
              video_chat_count: 0,
              audio_chat_count: 0,
              is_cancelled: 0,
              end_date: "",
              created_at: "",
              updated_at: "",
              name: "Free",
              total_credits: 0,
              total_earned_credits: 0,
              cost: "",
              renewal_date: "",
              duration: "month",
            },
            avatar: false,
            two_factor_secret: "",
            two_factor_recovery_codes: "",
            created_at: "",
            updated_at: "",
            name: "",
            email: "",
            email_verified_at: "",
            stripe_id: "",
            referral_token: "",
            language: dState.userDetail?.user.language,
            team: {
              id: 0,
              name: "",
              role: "member",
              slug: "",
            },
          },
        };
        dState.gptModel = undefined;
        dState.creditLimits = { daily_limit: 0, used_today: 0 };
        dState.imageAttributes = { count: 1, size: undefined, maxCount: 1 };
        dState.regenerateAttribute = { count: 1, size: undefined, maxCount: 1 };
        dState.showUpscaleModal = "hide";
        dState.theme = "light";
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.UPDATE_USER_DETAILS: {
      const nState = produce(mState, (dState) => {
        dState.userDetail = {
          ...(mState.userDetail as IUserDetail),
          user: {
            ...(mState?.userDetail?.user as IUser),
            ...(action.payload as object),
          },
        };
      });
      persistReducer(KEY, nState);
      return nState;
    }

     case TYPES.PLAN_EXPIRE:
        return produce(mState,(dState) => {
          dState.planExpired = action.payload;
        })

    default: {
      if (localStorage.getItem(KEY)) {
        return JSON.parse(localStorage.getItem(KEY) as string);
      } else {
        return { ...mState };
      }
    }
  }
};

const persistReducer = (key: string, state: IAuthReducerState) => {
  localStorage.setItem(key, JSON.stringify(state));
};
