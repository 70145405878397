import { createContext, useContext, ReactNode, useRef } from 'react';
import useThreadsHistory from 'hooks/useThreadsHistory';

// Define a type for your context value based on what useThreadsHistory returns
type ThreadsHistoryContextType = ReturnType<typeof useThreadsHistory> | null;

// Create the context with proper typing
const ThreadsHistoryContext = createContext<ThreadsHistoryContextType>(null);

// Create a module-level Set to track API calls in progress
// This needs to be outside any component to be shared across instances
const API_CALLS_IN_PROGRESS = new Set<string>();
const SETTINGS_API_CALLED = { value: false };
const ACTIVE_PLAN_API_CALLED = { value: false };
// Interface to track API call status
interface ApiCallTracker {
  isInitialRender: boolean;
}

// Properly type the provider props
interface ThreadsHistoryProviderProps {
  children: ReactNode;
}

// Provider component with proper typing
export const ThreadsHistoryProvider = ({ children }: ThreadsHistoryProviderProps) => {
  // Track if this is the initial render
  const apiCallTracker = useRef<ApiCallTracker>({
    isInitialRender: true
  });
  
  // Call the hook once here, passing the API tracker ref
  const threadsHistoryData = useThreadsHistory(apiCallTracker);
  
  return (
    <ThreadsHistoryContext.Provider value={threadsHistoryData}>
      {children}
    </ThreadsHistoryContext.Provider>
  );
};

// Custom hook to use the context with proper error handling
export const useThreadsHistoryContext = () => {
  const context = useContext(ThreadsHistoryContext);
  if (context === null) {
    throw new Error('useThreadsHistoryContext must be used within a ThreadsHistoryProvider');
  }
  return context;
};

// Export the API_CALLS_IN_PROGRESS for use in useThreadsHistory.ts
export { API_CALLS_IN_PROGRESS, SETTINGS_API_CALLED, ACTIVE_PLAN_API_CALLED };