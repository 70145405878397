import { useState, useEffect, useRef } from "react";
import {
    setInProgressWS,
    RemoveWSFiles,
    NewChatThreadWS,
    EmptyThreadChat,
    startNewChat,
    setEmptyThreadsHistory,
    setWsUploadedFile,
} from "redux/actions";
import useRouter from "hooks/useRouter";
import { CrossIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute } from "pages/ChatPage";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import styles from "../workSpaceConversation.module.scss";
import { useWindowSize } from "hooks/useWindowSize";

import { RippleIconButton } from "components/RippleEffect/RippleEffects";
import { PlusIcon } from "pages/Workspaces/components/icons/PlusIcon";
import { UseWsIcon } from "pages/Workspaces/components/icons/UseWsIcon";
import { CheckIcon } from "pages/Workspaces/components/icons/TreadsIcons";
import { EmptyObj } from "utils/fileProcessing";

interface IProp {
    wsNewChat?: boolean | undefined;
}

export const ContextDropdown = ({ wsNewChat }: IProp) => {
    const { theme } = useSelector((state) => state.authReducer);
    const { workSpaces, currentConversation, newChatThread } = useSelector((state) => state.workSpaceReducer);
    const { push, pathname } = useRouter();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { PrivateChat } = useSelector(
        (state) => state.chatReducer
    );
    const width = useWindowSize().width;
    const workspaceIdFromURL = pathname.split("/")[2];
    const [hoveredWorkspace, setHoveredWorkspace] = useState<number | null>(null);
    // Added state to track if CrossIcon was clicked
    const [resetWorkspace, setResetWorkspace] = useState<boolean>(false);

    // Click outside handler
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(prev => {
            // Reset hoveredWorkspace state when closing the dropdown
            if (prev) {
                setHoveredWorkspace(null);
            }
            return !prev;
        });
    };

    const handleResetStates = () => {
        RemoveWSFiles();
        setInProgressWS("", "", "", "");
    };

    const handleConversation = (id: number) => {
        if (wsNewChat) {
            NewChatThreadWS(id);
        } else {
            setWsUploadedFile();
            setEmptyThreadsHistory();
            handleResetStates();
            startNewChat();
            push(`/${RoutePaths.WorkspaceHistory}/${id}`);
        }
        setHoveredWorkspace(null);
        setIsOpen(false);
        // Reset the resetWorkspace state when selecting a new workspace
        setResetWorkspace(false);
    };

    const handleCrossIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setResetWorkspace(true);
        EmptyThreadChat(EmptyObj);
        NewChatThreadWS(0);
        setIsOpen(false);
    };

    const handleSwitchChange = (checked: boolean) => {
        sessionStorage.setItem('privateChatActive', JSON.stringify(checked));
        localStorage.setItem('previousPrivateChat', JSON.stringify(checked));

        const chatUrl = `/${RoutePaths.Chat}/${ChatRoute.New}`;
        const newTab = window.open(chatUrl, '_blank');

        if (newTab) {
            localStorage.setItem('openInPrivateMode', 'true');
        }

        setIsOpen(false);
    };

    const rippleColor = theme === "dark" ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.3)';

    const triggerElement = (
        <RippleIconButton
        disabled={!wsNewChat}
            color={'rgba(0, 0, 0, 0.3)'}
            className={`font_inter flex items-center justify-center bg-[#EEF2FF] rounded-full px-2 py-1 ${!wsNewChat ? "pointer-events-none":"cursor-pointer"}`}
            style={{ border: "0.8px solid #4338CA" }}
            onClick={!wsNewChat ? undefined : toggleDropdown}
        >
            <span className="text-sm mr-1.5">
                {(resetWorkspace || (!workspaceIdFromURL && newChatThread?.emoji === "")) ? (
                    <UseWsIcon />
                ) : (
                    wsNewChat && newChatThread?.emoji !== "" ? newChatThread?.emoji : currentConversation?.emoji
                )}
            </span>
            <span className={`text-[10px] font-normal font-inter max-w-[95px] overflow-hidden text-ellipsis whitespace-nowrap inline-block ${(resetWorkspace || (!workspaceIdFromURL && newChatThread?.name === "")) ? "text-[#737373]" : "text-[#3730A3]"}`}>
                {resetWorkspace || (!workspaceIdFromURL && newChatThread?.name === "") ? (
                    "Use Workspace"
                ) : (
                    wsNewChat && newChatThread?.name !== "" ? newChatThread?.name : currentConversation?.name
                )}
            </span>

            <span className="ml-[10px] mt-[2px]">
                {(resetWorkspace || (!workspaceIdFromURL && (newChatThread?.name === "" || newChatThread?.emoji === ""))) && (
                    <PlusIcon />
                )}
            </span>

        </RippleIconButton>
    );

    return (
        <>
        {(currentConversation?.name || !workspaceIdFromURL )&& (
            <div ref={dropdownRef} className={`relative ${!wsNewChat ? "pointer-events-none":"cursor-pointer"}`}>
                {/* Custom dropdown trigger */}
                {triggerElement}

                {/* Custom dropdown menu */}
                {isOpen && (
                    <div
                        className={`absolute ${width <= 360 ? "left-0" : "left-[0px]"} top-full mt-1 z-10 w-[200px] rounded-[6px] shadow-[0px_2px_12px_0px_rgba(0,0,0,0.25)] ${theme === "dark" ? "bg-[#252526] text-dark-light" : "bg-white text-light-default"}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className={classNames("font_inter", styles.DropDownbody, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}>
                            <div className={`flex flex-col gap-[10px] ${styles.WorkSpaceList}`}>
                                {workSpaces.map((workspace) => (
                                    <RippleIconButton
                                        dropdown={true}
                                        color={rippleColor}
                                        key={workspace.id}
                                        className="flex items-center cursor-pointer  "
                                        onClick={() => {
                                            setTimeout(() => {
                                                handleConversation(workspace.id);
                                            }, 5);
                                        }}
                                        onMouseEnter={() => setHoveredWorkspace(workspace.id)}
                                        onMouseLeave={() => setHoveredWorkspace(null)}
                                    >
                                        <>
                                        <span className="text-sm mr-2 relative z-[2]">{workspace.emoji}</span>
                                        <div className="flex gap-1 justify-between items-center w-[100%]">
                                        <span className={`text-[12px] font-normal font-inter max-w-[125px] overflow-hidden text-ellipsis whitespace-nowrap inline-block ${(!resetWorkspace && workspace.id === (newChatThread?.id || currentConversation?.id)) ? "text-[#4338CA]" : "text-[#171717] dark:text-[#fff]"}`}>
                                            {workspace.name}
                                        </span>
                                        <span>
                                            {!resetWorkspace && workspace.id === (newChatThread?.id || currentConversation?.id) && (
                                                hoveredWorkspace === workspace.id ? 
                                                <div onClick={(e) => handleCrossIconClick(e)}>
                                                    <CrossIcon conversation={true} />
                                                </div> : 
                                                <CheckIcon />
                                            )}
                                        </span>
                                        </div>
                                        </>
                                    </RippleIconButton>
                                ))}
                            </div>
                            <div className="h-[0.6px] bg-[#94A3B8]"></div>
                            <div
                                className="flex flex-col py-[10px] px-[6px] gap-[14px]"
                                onClick={() => handleSwitchChange(!PrivateChat)}
                            >
                                <div className="flex items-center cursor-pointer">
                                    <span className="text-sm mr-2 relative z-[2]">
                                        <img
                                            src={require("pages/Workspaces/components/icons/incognito.png")}
                                            alt="Incognito Icon"
                                            className="w-5 h-5"
                                        />
                                    </span>
                                    <span className="text-[12px] font-normal text-[#171717] dark:text-[#fff] font-inter">
                                        Use Incognito Mode
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            )}
        </>
    );
};