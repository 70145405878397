import { FormattedMessage } from "react-intl";
import styles from "./createworkspace.module.scss";
import { CrossIcon } from "../icons/CrossIcon";
import { useState, useEffect, useRef } from "react";
import CustomButton from "components/Button";
import styled from "styled-components";
// import { WorkspaceIcon } from "views/layout/Sidebar/component/ChatSidebar/components/icons/WorkspaceIcon";
import { ContinueIcon} from "../icons/ContinueIcon"
import { RichContext } from "../RichContext/RichContext";
import { ConfigureAIBehaviour } from "../ConfigureAI/ConfigureAIBehaviour";
import { WorkspaceName } from "../WorkspaceName/WorkspaceName";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import {
    ICRWorkSpace,
    RemoveWSFiles, setInProgressWS,
     setShowCreateWorkspace,
    UpdateWorkspace,
    UploadFile,
    WorkspaceCR, WorkspaceCreation,
} from "redux/actions";
import classNames from "classnames";
import { useWindowSize } from "hooks/useWindowSize";
import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
// import clsx from "clsx"
interface Iprops {
  onClose?: () => void;
  showCreateWorkspace:boolean
}
const RightPanelContainer = styled.div<{ theme: EThemeType }>`
  padding: 24px;
    // gap: 24px;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #fff;
     background-color: ${({ theme }) => (theme === "dark" ? "#252526" : "#fff")};
    z-index: 9999;
    width: 100%;
    font-family: "Inter", sans-serif;
    //width: 43%;
    // @media (max-width:768px) {
    //    width: 60%;
    // }
    //    @media (max-width:576px) {
    //    width: 75%;
    // } 
    //     @media (max-width:410px) {
    //    width: 88%;
    // } 
    //        @media (max-width:335px) {
    //    width: 92%;
    // } 
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
`;
const SectionHeader = styled.div`
  padding: 0px 0 24px 0;
  flex-shrink: 0;
`;
const SectionContent = styled.div`
  overflow-y: auto;
  transition: max-height 0.3s ease-in-out;
`;
const FixedHeader = styled.div`
  flex-shrink: 0;
  margin-bottom: 24px;
`;
const FixedFooter = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 8px;
//   background: ${({ theme }) => theme.background || '#fff'};
`;
const DurationButton = styled(CustomButton)<{ back?: boolean }>`
  background-color: ${({ back }) => (back ? "#fff" : "#4338CA")};
  color: ${({ back }) => (back ? "#4338CA" : "#fff")};
  flex-shrink: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border: 1px solid #4338ca;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  transition: padding 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus,
  &:active {
    padding: 6px 10px;
    background-color: ${({ back }) => (back ? "#fff" : "#4338CA")};
    color: ${({ back }) => (back ? "#4338CA" : "#fff")};
    border-color: #4338ca;
  }

  @media (max-width: 576px) {
    font-size: 11px;
    padding: 5px 16px;
  }

  &:disabled {
    padding: 6px 12px;
    transition: none;
    &:hover,
    &:focus,
    &:active {
      padding: 6px 12px;
    }
    @media (max-width: 576px) {
      padding: 5px 16px;
      &:hover,
      &:focus,
      &:active {
        padding: 5px 16px;
      }
    }
  }
`;
export const CRWorkspace = ({ onClose, showCreateWorkspace }: Iprops) => {
  const { width } = useWindowSize();
  const { push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const { workSpaces, workSpaceFiles, inProgressWorkspace } = useSelector(
    (state) => state.workSpaceReducer
  );
  const theme = useSelector((state) => state.authReducer.theme);
  const [step, setStep] = useState(1);
  const [workSpaceName, setWorkSpaceName] = useState<string | undefined>(
    undefined
  );
  const [isloading, setIsloading] = useState<boolean>(false);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const formRef = useRef<any>(null);
  type StepNumber = 1 | 2 | 3;
  const sectionRefs: Record<StepNumber, React.RefObject<HTMLDivElement>> = {
    1: useRef<HTMLDivElement>(null),
    2: useRef<HTMLDivElement>(null),
    3: useRef<HTMLDivElement>(null),
  };

  useEffect(() => {
    if (sectionRefs[step as StepNumber]?.current) {
      sectionRefs[step as StepNumber]!.current!.scrollTop = 0;
    }
  }, [step]);

  useEffect(() => {
    if (mainContentRef.current) {
      const totalHeight = mainContentRef.current.clientHeight;
      const headerHeight = 43;
      const spacing = 24;
      const availableHeight = totalHeight - headerHeight * 3 - spacing * 2;
      setContentHeight(availableHeight);
    }
  }, [width]);

  const validateStep1 = () => {
    const currentValues = formRef.current?.values;
    let hasError = false;
    // Check if name exists
    if (!currentValues?.name) {
      formRef.current?.setFieldError("name", "workspace.name.required");
      formRef.current?.setFieldTouched("name", true, false);
      hasError = true;
    } else {
      // Check minimum length
      if (currentValues.name.length < 3) {
        formRef.current?.setFieldError("name", "workspace.name.min");
        formRef.current?.setFieldTouched("name", true, false);
        hasError = true;
      }
      // Check maximum length
      if (currentValues.name.length > 100) {
        formRef.current?.setFieldError("name", "workspace.name.max");
        formRef.current?.setFieldTouched("name", true, false);
        hasError = true;
      }
    }
    return !hasError;
  };
  const validateStep2 = () => {
    const currentValues = formRef.current?.values;
    let Error = false;

    // Only validate length if description exists
    if (currentValues?.description) {
      const length = currentValues.description.length;

      if (length < 12) {
        formRef.current?.setFieldError(
          "description",
          "workspace.description.min"
        );
        formRef.current?.setFieldTouched("description", true, false);
        Error = true;
      }
      // Check maximum length
      if (length > 4096) {
        formRef.current?.setFieldError(
          "description",
          "workspace.description.max"
        );
        formRef.current?.setFieldTouched("description", true, false);
        Error = true;
      }
    }

    return !Error;
  };

  const isUploading =
    workSpaceFiles &&
    workSpaceFiles.some((file: UploadFile) => file.status === "uploading");

  const handleFileUpload = () => {
    const latestWorkSpaceId = workSpaces.length ? workSpaces[0].id : 1;
    RemoveWSFiles();
    setInProgressWS("", "", "", "");
    push(`/${RoutePaths.WorkspaceHistory}/${latestWorkSpaceId}`);
    setShowCreateWorkspace(false);
  };

  const handleContinue = () => {
    if (step === 1) {
      const isValid = validateStep1();
      if (!isValid) return;

      const currentValues = formRef.current?.values;
      const workspaceData: WorkspaceCR = {
        name: currentValues?.name,
        emoji: currentValues?.emoji || "😀",
      };
      if (
        currentValues?.name === inProgressWorkspace?.name &&
        currentValues?.emoji === inProgressWorkspace?.emoji
      ) {
        setStep(step + 1);
      } else {
        const workspaceId = workSpaces.length ? workSpaces[0].id : 1;
        const workspacePromise = inProgressWorkspace?.name
          ? UpdateWorkspace(workspaceId, workspaceData)
          : WorkspaceCreation(workspaceData);
        setIsloading(true);

        workspacePromise
          .then((response) => {
            setIsloading(false);
            setStep(step + 1);
            if (inProgressWorkspace?.name) {
              const res = response as ICRWorkSpace;
              setInProgressWS(
                currentValues?.name,
                currentValues?.emoji || "😀",
                inProgressWorkspace?.description || "",
                inProgressWorkspace?.instructions || ""
              );
              triggerNotification({
                message: res.message as string,
                type: "info",
              });
            } else {
              setInProgressWS(
                currentValues?.name,
                currentValues?.emoji || "😀",
                "",
                ""
              );
              triggerNotification({
                message: response as string,
                type: "info",
              });
            }
          })
          .catch((error) => {
            setIsloading(false);
            triggerNotification({
              message: error?.data?.message,
              type: "error",
            });
          });
      }
    } else if (step === 2) {
      const isValid = validateStep2();
      if (!isValid) return;
      const currentValues = formRef.current?.values;
      const description = currentValues?.description || "";
      const instructions = currentValues?.instruction || "";

      const workspaceId = workSpaces.length ? workSpaces[0].id : 1;

      if (
        description === inProgressWorkspace?.description &&
        instructions === inProgressWorkspace?.instructions
      ) {
        setStep(step + 1);
        return;
      }

      setIsloading(true);
      UpdateWorkspace(workspaceId, { description, instructions })
        .then((response) => {
          const res = response as ICRWorkSpace;
          setInProgressWS(
            inProgressWorkspace?.name ?? "",
            inProgressWorkspace?.emoji ?? "",
            description,
            instructions
          );
          setIsloading(false);
          setStep(step + 1);
          triggerNotification({ message: res.message, type: "info" });
        })
        .catch((error) => {
          setIsloading(false);
          triggerNotification({ message: error?.data?.message, type: "error" });
        });
    } else if (step === 3) {
      handleFileUpload();
    }
  };

  const onChangeName = (value?: string) => {
    setWorkSpaceName(value);
  };

  useEffect(() => {
    return () => {
      if (formRef.current && !showCreateWorkspace) formRef.current.resetForm();
    };
  }, [showCreateWorkspace]);

  return (
    <RightPanelContainer theme={theme as EThemeType}>
      <FixedHeader>
        <div className={styles.headContainer}>
          <div
            className={classNames(styles.text, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="workspace.create.head" />
          </div>
          <div className="cursor-pointer" onClick={onClose}>
            <CrossIcon />
          </div>
        </div>
      </FixedHeader>
      <MainContent ref={mainContentRef}>
        <Section>
          <SectionHeader>
            <div
              className={classNames(styles.nameContainer, {
                [styles.active]: step >= 1,
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <div
                className={classNames(styles.pointNumber, {
                  [styles.active]: step >= 1,
                  [styles.completed]: step > 1,
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                1
              </div>
              <h3 className={styles.nameText}>
                <FormattedMessage id="workspace.create.name" />
              </h3>
            </div>
          </SectionHeader>
          <SectionContent
            style={{
              maxHeight: step === 1 ? `${contentHeight}px` : "0px",
              overflow: step === 1 ? "auto" : "hidden",
              marginBottom: step === 1 ? "24px" : "0px",
            }}
            ref={sectionRefs[1]}
          >
            {step === 1 && (
              <WorkspaceName formRef={formRef} onChangeName={onChangeName} />
            )}
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader>
            <div
              className={classNames(styles.nameContainer, {
                [styles.active]: step >= 2,
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <div
                className={classNames(styles.pointNumber, {
                  [styles.active]: step >= 2,
                  [styles.completed]: step > 2,
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                2
              </div>
              <h3 className={styles.nameText}>
                <FormattedMessage id="workspace.create.AIbehaviour" />
              </h3>
            </div>
          </SectionHeader>
          <SectionContent
            style={{
              maxHeight: step === 2 ? `${contentHeight + 32}px` : "0px",
              overflow: step === 2 ? "auto" : "hidden",
              marginBottom: step === 2 ? "24px" : "0px",
            }}
            ref={sectionRefs[2]}
          >
            {step === 2 && <ConfigureAIBehaviour formRef={formRef} />}
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader
          >
            <div
              className={classNames(styles.nameContainer, {
                [styles.active]: step >= 3,
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <div
                className={classNames(styles.pointNumber, {
                  [styles.active]: step >= 3,
                  [styles.completed]: step > 3,
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                3
              </div>
              <h3 className={styles.nameText}>
                <FormattedMessage id="Add rich context" />
              </h3>
            </div>
          </SectionHeader>
          <SectionContent
            style={{
              maxHeight: step === 3 ? `${contentHeight + 32}px` : "0px",
              overflow: step === 3 ? "auto" : "hidden",
              marginBottom: step === 3 ? "24px" : "0px",
            }}
            ref={sectionRefs[3]}
          >
            {step === 3 && <RichContext />}
          </SectionContent>
        </Section>
      </MainContent>
      <FixedFooter>
        {step > 1 && (
          <DurationButton
            onClick={() => setStep(step - 1)}
            disabled={isUploading}
            back
          >
            <span className={styles.btn}>
              <span className="rotate-180 flex flex-row items-center justify-center">
                <ContinueIcon />
              </span>
              <FormattedMessage id="workspace.create.back" />
            </span>
          </DurationButton>
        )}
        <DurationButton
          onClick={handleContinue}
          disabled={(step === 1 && !workSpaceName) || isloading}
          isloading={isloading}
        >
          <span className={styles.btn}>
            <>
              <FormattedMessage id="workspace.create.continue" />
              <span className={isloading ? "opacity-0" : "opacity-100"}>
                <ContinueIcon />
              </span>
            </>
          </span>
        </DurationButton>
      </FixedFooter>
    </RightPanelContainer>
  );
};
