import { IUploadedFile, UploadFile } from "redux/actions";
import { imgURL } from "./constants";

export const getProcessedFileName = (file: IUploadedFile | UploadFile) => {

  if (!file) return "";
  const getFileName = () => {
    if ('file' in file) {
      if (file.file && 'displayName' in file.file) {
        // It's an IWSFile
        return file.file.displayName;
      } else if (file.file instanceof File) {
        // It's a File object, use name property instead
        return file.file.name;
      }
    }
    return "";
  };

  const fileName = getFileName();
  if (!fileName) return "";

  try {
    const baseName = fileName.startsWith("website-")
      ? fileName.split("-").slice(2).join("-")
      : fileName;

    // Only try to decode if it appears to be encoded
    return baseName.includes("%") ? decodeURIComponent(baseName) : baseName;
  } catch (error) {
    console.error("URI decode failed:", error);
    // Fallback to original processed name
    return fileName.startsWith("website-")
      ? fileName.split("-").slice(2).join("-")
      : fileName;
  }
};

// Add this function at the top of your file or in a utils file
export const processS3Filename = (s3Link: string | undefined): string => {
  if (!s3Link) return "unknown";

  try {
    let cleanedLink = s3Link;
    while (cleanedLink.endsWith("/")) {
      cleanedLink = cleanedLink.slice(0, -1);
    }

    if (!cleanedLink) return "unknown";

    const rawFilename = cleanedLink.split("/").pop() || "unknown";

    // Process the filename
    let processedName = rawFilename;
    if (rawFilename.startsWith("website-")) {
      const parts = rawFilename.split("-");
      if (parts.length >= 3) {
        processedName = parts.slice(2).join("-");
      }
    }

    // Only try to decode if it appears to be encoded
    return processedName.includes("%") ? decodeURIComponent(processedName) : processedName;
  } catch (error) {
    console.error("Error processing S3 filename:", error);
    return "unknown";
  }
};

// Create a Set from imgURL for faster lookups
const imgURLSet = new Set(imgURL);

export const isImageFile = (fileType: string | undefined) => {
  if (!fileType) return false;
  return imgURLSet.has(fileType);
};

// Add this function at the top of your file, outside of the component
export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return "0 bytes";
  
  // Convert to MB
  const mb = bytes / (1024 * 1024);
  
  if (mb >= 1) {
    // If MB is 1 or larger, display in MB with 2 decimal places
    return `${mb.toFixed(2)} MB`;
  } 
  
  // Convert to KB
  const kb = bytes / 1024;
  
  if (kb >= 1) {
    // If KB is 1 or larger, display in KB with 2 decimal places
    return `${kb.toFixed(2)} KB`;
  }
  
  // Otherwise display in bytes
  return `${bytes} bytes`;
};

 export const EmptyObj = {
  id: 0,
  created_at: '',
  description: '',
  instructions: '',
  name: '',
  settings: '',
  team_id: 0,
  updated_at: '',
  emoji: '',
};

