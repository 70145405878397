import React, { useState, useRef, useEffect, useCallback } from "react";
import classNames from "classnames";
import styles from "./answerContainer.module.scss";
import { DocIcon, PDFIcon, CSVIcon, TextIcon, XLSXIcon, XLSIcon, EMLIcon, SRTIcon, PPTXIcon, PPTIcon } from "pages/ChatPage/components/icons/DocIcon";
import { useSelector } from "redux/hooks";
import { IFile, PreviewableFile } from "redux/actions";
import { Tooltip } from "components";
import { RenderVideo } from "./RenderVideo";
import { audioURL, textExtensions, videoURL } from "utils/constants";
import { EThemeType } from "redux/reducers";
import FilePreviewModal from "pages/Workspaces/components/FilePreviewModal/FilePreviewModal";
import { adaptIFileAsync } from "utils/functions";
import { AudioPreview } from "pages/ChatPage/components/searchField/AudioPreview";
import { ThreadsFileDisplay } from "./ThreadsFileDisplay";

interface IFileLinkProps {
    file: IFile[];
    contentFile?: boolean;
    messageId: number;
    threads?: boolean;
}
const renderFileIcon = (fileExtension: string, theme: EThemeType | undefined) => {
    if (!fileExtension) return <DocIcon />;

    const fileExt = fileExtension.split(".").pop()?.toLowerCase();

    if (fileExt && textExtensions.includes(fileExt)) {
        return (
            <div
                className={classNames(styles.loader, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}
            >
                <span className="text-[8px] font-medium text-[#A09FA2] uppercase">
                    {fileExt}
                </span>
            </div>
        );
    }
    switch (fileExtension) {
        case 'pdf':
            return <PDFIcon />;
        case 'csv':
            return <CSVIcon />;
        case 'txt':
            return <TextIcon />;
        case 'xlsx':
            return <XLSXIcon />;
        case 'xls':
            return <XLSIcon />
        case 'srt':
            return <SRTIcon />;
        case 'eml':
            return <EMLIcon />;
        case 'pptx':
            return <PPTXIcon />;
        case 'ppt':
            return <PPTIcon />;
        default:
            return <DocIcon />;
    }
};

const getFileTypeLabel = (fileExtension: string) => {

    const fileExt = fileExtension.split(".").pop()?.toLowerCase();
    if (fileExt && textExtensions.includes(fileExt)) return fileExt

    switch (fileExtension) {
        case 'pdf':
            return 'PDF';
        case 'csv':
            return 'CSV';
        case 'txt':
            return 'Text';
        case 'xlsx':
            return 'XSLX';
        case 'xls':
            return 'XLS'
        case 'srt':
            return 'SRT'
        case 'eml':
            return 'EML';
        case 'pptx':
            return 'PPTX';
        case 'ppt':
            return 'PPT';
        case 'wmv':
            return 'Video';
        case "mp4":
            return 'Video';
        case "mpeg":
            return 'Video';
        case "mpg":
            return 'Video';
        case "webm":
            return 'Video';
        case "3gpp":
            return 'Video';
        case "mp3":
            return 'Audio';
        case "flac":
            return 'Audio';
        case "wav":
            return 'Audio';
        default:
            return 'Document';
    }
};

const extractFileExtension = (fileName: string) => {
    if (!fileName) return '';
    const parts = fileName.split('.');
    return parts.length > 1 ? parts.pop()?.toLowerCase() : '';
};

const FileLink: React.FC<IFileLinkProps> = ({ file, contentFile, messageId, threads }) => {
    const { theme } = useSelector((state) => state.authReducer);

    const [videoPlayer, setVideoPlayer] = useState<boolean>(true);
    const [previewFile, setPreviewFile] = useState<PreviewableFile | null>(null);
    const [audioPreview, setAudioPreview] = useState<{ path: string; name: string } | null>(null);

    // Use ref to track if component is mounted
    const isMounted = useRef(true);

    // Clean up on component unmount
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const extractFileName = (path: string) => {
        const decodedPath = decodeURIComponent(path);
        const parts = decodedPath.split('/');
        const lastSegment = parts[parts.length - 1];
        const firstHyphenIndex = lastSegment.indexOf('-');
        const secondHyphenIndex = lastSegment.indexOf('-', firstHyphenIndex + 1);
        return secondHyphenIndex !== -1 ? lastSegment.substring(secondHyphenIndex + 1) : lastSegment;
    };

    // Check if any file is a video
    const hasVideoFile = file && file.length > 0 && file?.some((file) => {
        const fileName = extractFileName(file?.path as string);
        const fileExtension = extractFileExtension(fileName);
        return ["mp4", "mpeg", "mpg", "webm", "3gpp"].includes(fileExtension || '');
    });


    // Add function to handle file preview
    const handlePreviewFile = useCallback(async (file: IFile, e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const adaptedFile = await adaptIFileAsync(file);
            if (isMounted.current) {
                setPreviewFile(adaptedFile); // Store the adapted file once resolved
            }
        } catch (error) {
            console.error("Failed to adapt file:", error);
        }
    }, []);

    // Add function to close preview
    const closePreview = useCallback(() => {
        if (isMounted.current) {
            setPreviewFile(null);
        }
    }, []);

    const closeAudioPreview = useCallback(() => {
        if (isMounted.current) {
            setAudioPreview(null);
        }
    }, []);

    // Handle audio preview with safe state updates
    const handleAudioPreview = useCallback((filePath: string, fileName: string) => {
        if (isMounted.current) {
            setAudioPreview(() => ({
                path: filePath || '',
                name: fileName || 'Audio File'
            }));
        }
    }, []);

    return (
        <>
            {previewFile && (
                <FilePreviewModal
                    file={previewFile}
                    onClose={closePreview}
                />
            )}

            {/* Audio preview modal */}
            {audioPreview && (
                <AudioPreview
                    audio={audioPreview}
                    onClose={closeAudioPreview}
                />
            )}

            <div
                className={classNames({
                    [styles.fileContainer]: !hasVideoFile,
                    [styles.docPadding]: contentFile,
                })}
            >
                <div
                    className={classNames(styles.fileText, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                    })}
                    style={{ display: 'flex', gap: '20px', overflowX: 'auto', flexDirection: 'row-reverse' }}
                >
                    {file && file.map((file, index) => {


                        const fileName = extractFileName(file?.path as string);
                        const fileExtension = extractFileExtension(fileName);

                        // If file is a video, render the video player
                        if (["mp4", "mpeg", "mpg", "webm", "3gpp"].includes(fileExtension || '')) {
                            if (videoPlayer === true) {
                                return (
                                    <div key={index} className="pt-[17px] pr-[3px]">
                                        <RenderVideo filePath={file?.path as string} keyValue={index ?? 0} setVideoPlayer={setVideoPlayer} messageId={messageId} />
                                    </div>
                                );
                            }

                        }

                        return (
                            <Tooltip
                                regenrate={true}
                                key={fileName}
                                control={
                                    <span onClick={(e) => {
                                        if (audioURL.includes(fileExtension || '')) {
                                            handleAudioPreview(file.path ?? "", fileName);
                                        } else handlePreviewFile(file, e)
                                    }}
                                        className="mt-3 w-[20px] cursor-pointer">
                                        {!threads ? (<div className={classNames(styles.fileDisplay, {
                                            [styles.light]: theme === "light",
                                            [styles.dark]: theme === "dark",
                                        })}>
                                            {(videoURL.includes(fileExtension || '') || audioURL.includes(fileExtension || '')) ? (
                                                <div className={classNames(styles.vDisplay, {
                                                    [styles.light]: theme === 'light',
                                                    [styles.dark]: theme === 'dark',
                                                })}>
                                                    <span className="text-[8px] font-medium text-[#A09FA2] uppercase">
                                                        {/* WMV */}
                                                        {fileExtension}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className={styles.fileIcon}>
                                                    {renderFileIcon(fileExtension || '', theme)}
                                                </div>
                                            )}
                                            <div className={styles.fileInfo}>
                                                <div className={styles.fileName}>{fileName}</div>
                                                <div className={styles.fileType}>
                                                    {getFileTypeLabel(fileExtension || '')}
                                                </div>
                                            </div>
                                        </div>
                                        ) : (file && <ThreadsFileDisplay files={file} />)}
                                    </span>
                                }
                                placement="top"
                                theme="light"
                            >
                                {fileName}

                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default FileLink;