import React, { useState, Dispatch, SetStateAction } from "react";
import { DownArrow, RightArrow } from "../icons/DownArrow";
import { Folder } from "../icons/FoldersIcon";
import styles from "../../ChatSidebar.module.scss";
import folderStyle from "../ChatItem/ChatItem.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { getFolder } from "redux/actions";
import useRouter from "hooks/useRouter";
import { ChatRoute } from "pages/ChatPage";
import { ChatFolderActions } from "./ChatFolderActions";
import { DelFolder, EditFolder } from "redux/actions/chatFolder";
import { useAppNotification } from "hooks/services/AppNotification";
import { DeleteChatConfirm } from "../DeleteChatConfirm";
import { HistoryIcon } from "views/layout/Navbar/components/icons/HistoryIcon";
import { RoutePaths } from "pages/routePaths";

interface ChatFolderHeaderProps {
  folder: getFolder;
  folderName: string;
  accordionState: Record<number, boolean>;
  toggleAccordion: (folder: getFolder) => void;
  folderId: number;
  handleNewChat: (folder_id: number) => void;
  selectedFolderItem: getFolder | undefined;
  setSelectedFolderItem: Dispatch<SetStateAction<getFolder | undefined>>;
  delFolderLoad: boolean;
  setDelFolderLoad: Dispatch<SetStateAction<boolean>>;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
}

export const ChatFolderHeader: React.FC<ChatFolderHeaderProps> = ({
  folder,
  folderId,
  folderName,
  accordionState,
  toggleAccordion,
  handleNewChat,
  selectedFolderItem,
  setSelectedFolderItem,
  delFolderLoad,
  setDelFolderLoad,
  onStartNewChat,
}) => {

  const themeMode = useSelector((state) => state.authReducer.theme);
  const { PrivateChat, newMessages } = useSelector(
    (state) => state.chatReducer
  );
  const { Folders } = useSelector((state) => state.chatFolderReducer);
  const { includeRoute, push } = useRouter();
  const { triggerNotification } = useAppNotification();

  //Edit Folder
  const [name, setupdatedName] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(false);

  //DEL FOLDER
  const [chatDelFolderConfirm, setChatDelFolderConfirm] = useState<
    number | null
  >(null);

  const toggleEdit = (folderId: number) => {
    setIsEdit((prev) => !prev);
    if (!isEdit) {
      const folderToEdit = Folders.find((folder) => folder.id === folderId);
      if (folderToEdit) setupdatedName(folderToEdit?.name);
      setSelectedFolderItem(folderToEdit);
    } else {
      setSelectedFolderItem(undefined);
    }
  };

  const handleEditFolderName = () => {
    setIsloading(true);

    EditFolder(selectedFolderItem?.id as number, name)
      .then((resp: any) => {

        const updatedFolder = resp.data;
        let index = Folders.findIndex(
          (folder) => folder.id === updatedFolder.id
        );
        if (index >= 0) {
          setTimeout(() => {
            setIsloading(false);
            setupdatedName(""); // Reset the name input
            toggleEdit(selectedFolderItem?.id as number); // Exit edit mode
          }, 500);
        }
      })
      .catch((err: any) => {
        setIsloading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  const isButtonActive = (): boolean => {
    let isActive = false;

    if (includeRoute(ChatRoute.History) || newMessages.length > 0) {
      isActive = true;
    } else if (includeRoute(ChatRoute.New)) {
      isActive = true;
    }
    return isActive;
  };

  const checkForRedirect = (folder_id: number) => {
    const currentPath = window.location.pathname;
    const pathSegments = currentPath.split('/');
    const Chatfolderid = pathSegments[pathSegments.length - 2];

    if (folder_id === Number(Chatfolderid)) {
        push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
        onStartNewChat?.({ toChat: true });
    }
}

  const resetDelState = () => {
    setDelFolderLoad(false);
    setChatDelFolderConfirm(null);
  }

  const handleDelFolder = (folder_id: number) => {
    setDelFolderLoad(true);
    DelFolder(folder_id)
      .then((res: string) => {
        resetDelState();
        checkForRedirect(folder_id);
        triggerNotification({ message: res, type: "info" });
      })
      .catch((err) => {
        resetDelState();
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  return (
    <>
      <div
        className={classNames(
          styles.historyIconContainer,
          styles.chatFolderContainer,
          {
            [styles.isbackdrop]: PrivateChat,
            [styles.folderHeading]: folderId !== 0, 
          }
        )}
      >
       <span className="flex-shrink-0">{folderId === 0 ? <HistoryIcon /> : <Folder />} </span> 
        {isEdit && selectedFolderItem?.id === folderId ? (
          <input
            className={classNames(
              folderStyle.editItemTextfield,
              folderStyle.chatFolderField,
              {
                [folderStyle.light]: themeMode === "light",
                [folderStyle.dark]: themeMode === "dark",
              }
            )}
            type="text"
            autoFocus
            value={name}
            onChange={(e) => setupdatedName(e.target.value)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            data-testid="edit-input"
          />
        ) : (
          <span className={classNames(
            styles.historyHeading,
            {
              [styles.chatFolder]: folderId !== 0,
            }
          )}>
            {folderName}
          </span>
        )}
        <ChatFolderActions
          folderId={folderId}
          isEdit={isEdit}
          toggleEdit={() => toggleEdit(folderId)}
          handleEditFolderName={handleEditFolderName}
          isloading={isloading}
          handleNewChat={() => {
            if (isButtonActive()) handleNewChat(folderId);
          }}
          handleDelFolder={() => setChatDelFolderConfirm(folderId)}
          selectedFolderItem={selectedFolderItem}
        />
        <div
          className={classNames("mt-[2px]", styles.delChat, {
            [styles.closeHistory]: !accordionState[folderId]
          })}
        >
          <div
            onClick={() => {
              toggleAccordion(folder);
            }}
          >
            {accordionState[folderId] ? <DownArrow /> : <RightArrow />}
          </div>
        </div>
      </div>

      {/* DELETE CONFIRMATION MODAL */}

      {chatDelFolderConfirm && (
        <DeleteChatConfirm
          onCancel={() => {
            setChatDelFolderConfirm(null);
          }}
          onSubmit={() => {
            handleDelFolder(chatDelFolderConfirm);
          }}
          loading={delFolderLoad}
          delFolder={true}
        />
      )}
    </>
  );
};