import { FormattedMessage } from "react-intl";
import styles from "./infoChat.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import {
  ChatInfoIcon,
} from "pages/Workspaces/components/icons/TreadsIcons";
import { WorkspaceCard } from "pages/Workspaces/components/WorkspaceCards";
import { UploadedConversationFiles } from "pages/Workspaces/components/uploadedFiles/UploadedConversationFile";
import { useState, useEffect
 } from "react";
import { MiniChatInfo } from "./MiniChatInfo";
import { useMiniChatInfo } from "hooks/services/ThreadServices/useMiniChatInfo";
import { Spinner } from "components";
import { useSidebar } from "hooks/services/ReSizeSidebar";
export const InfoChat = () => {

  const { theme, userDetail } = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  // const dropdownRef = useRef<HTMLDivElement>(null);
  const { sidebarWidth } = useSidebar();
  const { showMiniChatInfo, setShowMiniChatInfo } = useMiniChatInfo();
  const { wsUploadedFiles } = useSelector((state) => state.workSpaceReducer);
  const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";
  const maxSources = isFreePlan ? 1 : 5;
  const { currentConversation } = useSelector((state) => state.workSpaceReducer);

  useEffect(() => {
    if (currentConversation?.name && currentConversation?.emoji) {
      setIsLoading(false);
    }
  }, [currentConversation]);

 
  return (
    <>
      {showMiniChatInfo ? (
        <MiniChatInfo loading={loading}/>
      ) : (
        <div
          className={classNames(styles.rightSide, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <div
            className={classNames("font-inter", styles.KnowledgeContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <div className="flex justify-between">
              <div
                className={classNames(styles.infotitle, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                <FormattedMessage id="threds.chatinfo.head" />
              </div>
              <div
                className="flex-shrink-0"
                onClick={() => setShowMiniChatInfo(true)}
              >
                <ChatInfoIcon />
              </div>
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className={classNames(styles.workspace, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })} >
                {" "}
                <FormattedMessage id="threds.chatinfo.workspace" />
              </div>
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <Spinner extraSmall />
                </div>
              ) : (
                <WorkspaceCard chatThreadInfo={true} />
              )}
            </div>
            <div className="flex flex-col gap-[10px]">
              <div
                className={classNames(styles.knowledgeWrapper, {
                  [styles.Adjustposition]: sidebarWidth > 600,
                  [styles.Adjustment]: sidebarWidth > 413,
                })}
              >
                <div
                  className={classNames(styles.attachSources, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.Adjustposition]: sidebarWidth > 600,
                    [styles.Adjustment]: sidebarWidth > 413,
                  })}
                >
                  <span>
                    <FormattedMessage id="workspace.uploadedfiles.head" />
                  </span>
                  <span className="font-normal">(<FormattedMessage
                    id="workspace.addsource.subhead"
                    values={{ used: loading? 0 : wsUploadedFiles.length, max: maxSources }}
                  />)</span>
                </div>
              </div>
              <UploadedConversationFiles chatInfo={true} loading={loading} setLoading={setLoading}/>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
