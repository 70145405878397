import { FormattedMessage } from "react-intl";
import styles from "../../FilePreviewModal.module.scss";

interface IProp {
    error: string;
    fileUrl: string 
}

const ErrorPreview = ({ error, fileUrl }: IProp) => (
  <div className={styles.errorContainer}>
    <p className={styles.errorMessage}>{error}</p>
    <a href={fileUrl} target="_blank" rel="noreferrer" className={styles.downloadButton}>
      <FormattedMessage id="preview.download" defaultMessage="Download File" />
    </a>
  </div>
);

export default ErrorPreview;