import { useState, Fragment, Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import styles from "./MDNav.module.scss";

import { PlusIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { IChat, ICRWorkSpace, IWorkspace, setUpdatedName, UpdateWorkspace, UpdateThTitle, startNewChat, clearhistoryMessages, SaveSelectedThread } from "redux/actions";
import { useSelector } from "redux/hooks";
import { LogoutIcon } from "../AccountNav/icons";
import { logout } from "redux/actions";
import { Setting } from "../ChatNav/icons/setting";
import { ChatModel } from "../ChatNav/components/ChatModel";
import { Link } from "react-router-dom";
import { Credits } from "../ChatNav/components/Credits";
import styled from "styled-components";
import { RemoveIcon, TickIcon } from "views/layout/Sidebar/component/ChatSidebar/components/icons";
import { EditIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import MixpanelAnalytics from "utils/mixpanel";
import { Spinner } from "components";
import { useWindowSize } from "hooks/useWindowSize";
import { Loader } from "components/Loader";
import { SaveThreadInfo } from "utils/constants";
interface IProps {
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  toggleChatModelsModel?: () => void;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}


const WorkspaceName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: max-content;
  width: fit-content;
  flex-shrink: 1;
`;

const WorkspaceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 37%;
  min-width: 0;
   @media (max-width: break-points.$sm) {
       max-width: 34%;
    }
`;
export const MDNav = ({
  onStartNewChat,
  toggleChatModelsModel,
  setChatSetting,
  chatItem,
}: IProps) => {
  const { pathname, includeRoute, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const themeMode = useSelector((state) => state.authReducer.theme);
  const isShareChat = window.location.pathname.includes("share-chat");
  const { width } = useWindowSize();

  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";
  const Id = pathname.split("/")[2];

  const { newMessages } = useSelector((state) => state.chatReducer);
  const { currentConversation, selectedThread, loadTopicInfo } = useSelector((state) => state.workSpaceReducer);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const pathParts = pathname.split("/").filter(Boolean);
  // Parse path to determine which component to render
  const isWorkspacePath = pathParts[0] === "workspaces";
  const hasThreadId = pathParts.includes("thread") && pathParts.length > pathParts.indexOf("threads") + 1;

  // Decide which content to render
  const renderThreadsContent = isWorkspacePath && hasThreadId;

  const [workspaceName, setWorkspaceName] = useState<string>(renderThreadsContent ? selectedThread.thread_name : currentConversation?.name ?? "");
  const workSpace = pathname.includes("/thread");
  //Logout Function
  const [isloading, setIsloading] = useState<boolean>(false);

  const onLogout = () => {
    setIsloading(true);
    MixpanelAnalytics.trackLogout();
    logout()
      .then(() => {
        localStorage.removeItem('email');
        window.postMessage({ logout: true });
        MixpanelAnalytics.reset();
        setIsloading(false);
      })
      .catch((err: any) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setIsloading(false);
      });
  };

  // useEffect(() => {
  //   if (currentConversation?.name) {
  //     setWorkspaceName(currentConversation?.name ?? "");
  //   }
  // }, [currentConversation?.name])

  const handleEditClick = () => {
    if (renderThreadsContent || newMessages?.length > 0) {
      setWorkspaceName(selectedThread.thread_name);
      setIsEditing(true);
    }
    else if (currentConversation) {
      setWorkspaceName(currentConversation.name);
      setIsEditing(true);
    }
  };

  const handleInputChange = (event: any) => {
    setWorkspaceName(event.target.value);
  };

  const handleEdit = () => {
    setIsloading(true);  // Set loading state to true
    if (renderThreadsContent || newMessages?.length > 0) {
      UpdateThTitle(selectedThread.thread_id, { topic: workspaceName })
        .then((response: any) => {
          setIsloading(false);
          setIsEditing(false);
          triggerNotification({ message: response.message, type: "info" });
        })
        .catch((err: any) => {
          setIsloading(false);
          const errorMessage = err?.data?.message || "An unexpected error occurred.";
          triggerNotification({ message: errorMessage, type: "error" });
        })

    }
    else if (currentConversation) {
      UpdateWorkspace(currentConversation.id, { name: workspaceName })  // API call with the updated name
        .then((response) => {
          const res = response as ICRWorkSpace;
          const updatedData = res.data as IWorkspace
          setUpdatedName(updatedData.name, updatedData.description, updatedData.instructions, updatedData.id);
          setIsloading(false);
          setIsEditing(false);
          triggerNotification({ message: res.message, type: "info" });
        })
        .catch((err) => {
          setIsloading(false);
          console.error(err); // Log the error for debugging
          const errorMessage = err?.data?.message || "An unexpected error occurred.";
          triggerNotification({ message: errorMessage, type: "error" });
        })
    }

  }

  const handleRedirectBack = () => {
    if(renderThreadsContent || (newMessages.length > 0 && workSpace)) {
      SaveSelectedThread(SaveThreadInfo);
      startNewChat();
      clearhistoryMessages();
    push(`/${RoutePaths.Workspaces}/${renderThreadsContent? selectedThread.ws_id : Id}`)
    }
    else push(`/${RoutePaths.Workspaces}`)
    setIsEditing(false);
  }

  const pathSegments = pathname?.split("/") || [];
  const pathSegment = pathSegments[2] || "";
  const displayName = pathSegment ? `My ${pathSegment.charAt(0).toUpperCase()}${pathSegment.slice(1)}` : "";

  if (includeRoute(RoutePaths.Chat) || includeRoute(RoutePaths.Workspaces)) {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
          })}
        >
          {isShareChat && (
            <Link
              to="/"
              className={styles.headerLogo}
              data-testid="share-header-logo"
            >
              <img src={require("../../../Sidebar/logo.png")} />
            </Link>
          )}
          {userDetail &&
            userDetail.token &&
            !includeRoute(RoutePaths.Workspaces) && (
              <ChatModel
                toggleChatModelsModel={toggleChatModelsModel}
                chatItem={chatItem}
              />
            )}
          {(includeRoute(RoutePaths.Workspaces) || (workSpace)) && (
            <div
              className={classNames(
                styles.LogoContainer,
                "flex items-center gap-2"
              )}
            >
              <div
                className={classNames(styles.WorkSpace, {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                  [styles.cursor]: Id,
                })}
                onClick={handleRedirectBack}
                data-testid="sidebar-chat-newchat-workspace">{renderThreadsContent ?
                  selectedThread.ws_name : (workSpace) && currentConversation?.name
                    ? currentConversation.name
                    : <FormattedMessage id="sidebar.chat.newChat.workspace" />}
              </div>
              {(Id && width > 576) && (
                <>
                  
                  {isEditing ? (
                    <>
                    <span className="text-[#171717] dark:text-[#fff]">/</span>
                    <span
                      className={classNames(
                        styles.inputWithIcons,
                        "flex items-center flex-1 gap-4"
                      )}
                    >
                      <input
                        className={classNames(
                          "flex-grow min-w-0",
                          styles.inputField,
                          {
                            [styles.light]: themeMode === "light",
                            [styles.dark]: themeMode === "dark",
                          }
                        )}
                        type="text"
                        value={workspaceName}
                        onChange={handleInputChange}
                        autoFocus
                      />
                      <span className="flex items-center gap-3">
                        <div
                          data-testid="confirm-edit-btn"
                          className="cursor-pointer flex flex-row items-center justify-center"
                          onClick={handleEdit}
                        >
                          {isloading ? (
                            <Spinner extraSmall />
                          ) : (
                            <TickIcon EWorkSpace={true} />
                          )}
                        </div>
                        <div
                          data-testid="cancel-edit-btn"
                          className="cursor-pointer flex flex-row items-center justify-center"
                          onClick={() => setIsEditing(false)}
                        >
                          <RemoveIcon EWorkSpace={true} />
                        </div>
                      </span>
                    </span>
                    </>
                  ) : (
                    <>
                      <WorkspaceWrapper>
                        {(renderThreadsContent || (newMessages.length > 0)) ? (
                          loadTopicInfo? (
                            <div className="flex items-center"><Loader /></div>
                          ) : (
                            <>

                      {selectedThread.thread_name!== "" && <span className="text-[#171717] dark:text-[#fff]">/</span>}
                              <WorkspaceName className={classNames(styles.WorkSpace, {
                                [styles.light]: themeMode === "light",
                                [styles.dark]: themeMode === "dark",
                              })}>{selectedThread.thread_name}</WorkspaceName>
                               {selectedThread.thread_name!== "" && 
                              <span className="flex items-center cursor-pointer" onClick={handleEditClick}>
                                <EditIcon EditWorkSpace={true} />
                              </span>
                                }
                            </>
                          )) : !currentConversation?.name ? ( // Show loader if name is not available
                            <div className="flex item-center"><Loader /></div>
                          ) : (
                          <>

                      {currentConversation?.name && <span className="text-[#171717] dark:text-[#fff]">/</span>}
                            <WorkspaceName className={classNames(styles.WorkSpace, {
                              [styles.light]: themeMode === "light",
                              [styles.dark]: themeMode === "dark",
                            })}>{workSpace ? "" : currentConversation?.name}</WorkspaceName>
                           {currentConversation?.name && <span className="flex items-center cursor-pointer" onClick={handleEditClick}>
                              <EditIcon EditWorkSpace={true} />
                            </span>
                          }
                          </>
                        )}
                      </WorkspaceWrapper>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <div
            className={
              !isShareChat ? styles.menuContainer : styles.menuShareContainer
            }
          >
            {!isShareChat && (
              <>
                {!includeRoute(RoutePaths.Workspaces) && (
                  <>
                    <div
                      data-testid="md-setting-new-chat"
                      className={styles.icon}
                      onClick={() => {
                        onStartNewChat?.({ toChat: true });
                      }}
                    >
                      <PlusIcon color="#71717a" />
                    </div>
                  </>
                )}
                
                 <div
                      data-testid="md-chat-setting-icon"
                      className={`${styles.icon} ${styles.settingIcon}`}
                      onClick={() => 
                        setChatSetting!(true)}
                    >
                      <Setting />
                  </div>
                <div>
                  <Credits mdNav={true} />
                </div>
              </>
            )}
            {isShareChat && (
              <div className={styles.shareChatbtn}>
                <button
                  data-testid="share-chat-try-deftgpt-free"
                  className={styles.shareChatTryBtn}
                  onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                >
                  <FormattedMessage id="share.chat.header" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
            [styles.teamMdNav]: includeRoute(RoutePaths.Team),
          })}
        >
          {(includeRoute(RoutePaths.Team) || includeRoute(RoutePaths.Account) || includeRoute(RoutePaths.CurrentPlan)) && (
            <div
              className={classNames(styles.pageTitle, {
                [styles.account]: includeRoute(RoutePaths.Account),
              })}
            >
             
              {includeRoute(RoutePaths.Team) 
                ? "My Organization" 
                : includeRoute(RoutePaths.CurrentPlan) 
                ? "Current Plan" 
                : displayName
              }
            </div>
          )}
          {!includeRoute(RoutePaths.Team) && <div className={styles.placeholder}></div>}
          {(includeRoute(RoutePaths.Account) || includeRoute(RoutePaths.Team) || includeRoute(RoutePaths.CurrentPlan)) && (
            <button
              className={classNames(styles.logoutButton, {
                [styles.light]: themeMode === "light",
                [styles.dark]: themeMode === "dark",
              })}
              onClick={onLogout}
              disabled={isloading}
              id="logout"
              data-testid='account-setting-logout'
            >
              <div
                className={classNames(styles.btnContent, {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                })}
              >
                <LogoutIcon theme={themeMode} />
              </div>
            </button>
          )}
        </div>

      </Fragment>
    );
  }
};