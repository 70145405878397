import { useState, useEffect, memo, SetStateAction, Dispatch } from "react";
import { ClosedIcon ,CloseIcon} from "components/icons/CloseIcon";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import styles from "./SearchField.module.scss";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SpinAnimation } from "components/base/Button/LoadingButton";

import { Spinner, Tooltip } from "components";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { DocIcon, PDFIcon, CSVIcon, PPTXIcon, TextIcon, PPTIcon, XLSIcon, XLSXIcon, EMLIcon, SRTIcon } from "../icons/DocIcon";
import { useAppNotification } from "hooks/services/AppNotification";
import { DeleteS3Link, PreviewableFile } from "redux/actions";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import { textExtensions } from "utils/constants";
import FilePreviewModal from "pages/Workspaces/components/FilePreviewModal/FilePreviewModal";
import { adaptIUploadAsync } from "utils/functions";
import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import VideoPreview from "./VideoPreview";
import { AudioPreview } from "./AudioPreview";
import { renderAudioName, renderVideoName } from "utils/constants";

interface IProps extends TextareaAutosizeProps {
  selectedFile?: File[] | null;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
}

const UploadSpinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 12px;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: #527AE6;

  @media (max-width: 576px) {
    font-size: 10px;
  }
`;

export const FileUploadPreview = memo(({
  uploadingFiles,
  setUploadingFiles,
  setFileS3Link,
}: IProps) => {

  const { triggerNotification } = useAppNotification();
  
  const [previewFile, setPreviewFile] = useState<PreviewableFile | null>(null);
  const [previews, setPreviews] = useState<(string | null)[]>([]);
  const [loading, setLoading] = useState<{ id: string; isLoading: boolean }[]>([]);
  const { theme } = useSelector((state) => state.authReducer);

  const [audioPreview, setAudioPreview] = useState<{ path: string; name: string } | null>(null);

  // Video preview states
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<{ path: string; name: string } | null>(null);

  // Setup z-index for PhotoView-Portal
  useEffect(() => {
    const adjustPhotoViewPortalZIndex = () => {
      const portals = document.getElementsByClassName('PhotoView-Portal');
      Array.from(portals).forEach(portal => {
        if (portal instanceof HTMLElement) {
          portal.style.zIndex = '90000';
          portal.style.position = 'absolute';
        }
      });
    };

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && 
            Array.from(mutation.addedNodes).some(node => 
              node instanceof HTMLElement && 
              (node.classList.contains('PhotoView-Portal') || 
               node.querySelector('.PhotoView-Portal')))) {
          adjustPhotoViewPortalZIndex();
          break; // Only need to adjust once per batch of mutations
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    adjustPhotoViewPortalZIndex();

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (uploadingFiles) {
      const filePreviews = uploadingFiles.map((uploadFile) => {
        const file = uploadFile.file;
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          return new Promise<string | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
          });
        }
        return Promise.resolve(null);
      });

      Promise.all(filePreviews).then(setPreviews);
    }
  }, [uploadingFiles]);

  const handleDelete = (fileId: string, S3Link: string) => {
    setLoading(prev => [...prev, { id: fileId, isLoading: true }]);
    DeleteS3Link(S3Link).then(() => {
      setLoading(prev => prev.filter(state => state.id !== fileId));
      setFileS3Link!(prev => prev.filter(link => link !== S3Link));
      setUploadingFiles!(prev => prev.filter(file => file.id !== fileId));
    }).catch((err) => {
      setLoading(prev => prev.filter(state => state.id !== fileId));
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  const renderFileIcon = (fileType: string, fileName: string) => {
    if (fileType.includes('pdf')) return <PDFIcon />;
    if (fileType.includes('text/plain')) return <TextIcon />;
    if (fileType.includes('presentation')) return <PPTXIcon />;
    if (fileType.includes('ms-powerpoint')) return <PPTIcon />;
    if (fileType.includes('csv')) return <CSVIcon />;
    if (fileType.includes('sheet')) return <XLSXIcon />;
    if (fileType.includes('ms-excel')) return <XLSIcon />;
    if (fileType.includes('application/x-subrip')) return <SRTIcon />;
    if (fileType.includes('message/rfc822')) return <EMLIcon />;

    const fileExt = fileName.split(".").pop()?.toLowerCase(); // Extract file extension

    if (fileExt && textExtensions.includes(fileExt)) {
      return (
        <div
          className={classNames(styles.loader, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <span className="text-[8px] font-medium text-[#A09FA2] uppercase">
            {fileExt}
          </span>
        </div>
      );
    }

    // Fallback: check file extension if MIME type is empty
    if (fileType === "" && fileName.endsWith('.srt')) return <SRTIcon />;
    return <DocIcon />;
  };

  const getFileTypeLabel = (fileType: string, fileName: string) => {
    if (fileType.includes('pdf')) return 'PDF';
    if (fileType.includes('csv')) return 'CSV';
    if (fileType.includes('text/plain')) return 'Text';
    if (fileType.includes('presentation')) return 'PPTX';
    if (fileType.includes('ms-powerpoint')) return 'PPT';
    if (fileType.includes('sheet')) return 'XLSX';
    if (fileType.includes('ms-excel')) return 'XLS';
    if (fileType.includes('application/x-subrip')) return 'SRT';
    if (fileType.includes('message/rfc822')) return 'EML';
    if (fileType.startsWith("image/")) return 'Image';
    if (fileType === "" && fileName.endsWith('.srt')) return 'SRT';

    const fileExt = fileName.split(".").pop()?.toLowerCase(); // Extract file extension
    if (fileExt && textExtensions.includes(fileExt)) return fileExt
        
    return 'Document';
  };

  const handleVideoClick = (file: IUploadFile, e: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (file.status === 'uploaded' && file.file.type.startsWith('video/')) {
      setSelectedVideo({
        path: file.S3Link || '',
        name: file.file.name
      });
      setShowVideoPreview(true);
      setIsVideoLoading(true);
    }
  };
  
  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  const closeVideoPreview = () => {
    setShowVideoPreview(false);
    setSelectedVideo(null);
  };
  // Add function to handle file preview
const handlePreviewFile = async (file: IUploadFile, e: React.MouseEvent) => {
  
  e.preventDefault();
  e.stopPropagation();

  if (file.status === 'uploaded') {
    if (file.file.type.startsWith('audio/')) {
      // For audio files
      setAudioPreview({
        path: file.S3Link || '',
        name: file.file.name
      });
    } 
   else if (file.file.type.startsWith("video/")) {
      handleVideoClick(file, e);
    } else if (!file.file.type.startsWith("image/")) {
      try {
        const adaptedFile = await adaptIUploadAsync(file);
        setPreviewFile(adaptedFile);
      } catch (error) {
        console.error("Failed to adapt file:", error);
      }
    }
  }
};

// Add function to close preview
const closePreview = () => {
  setPreviewFile(null);
};
  
const closeAudioPreview = () => {
  setAudioPreview(null);
};
  
  return (
    <>
      {previewFile && (
        <FilePreviewModal 
          file={previewFile} 
          onClose={closePreview} 
        />
      )}

      {showVideoPreview && selectedVideo && (
        <VideoPreview 
          videos={[selectedVideo]} 
          loading={isVideoLoading}
          handleVideoLoad={handleVideoLoad}
          onClose={closeVideoPreview}
        />
      )}

      {/* Audio preview modal */}
      {audioPreview && (
        <AudioPreview 
          audio={audioPreview}
          onClose={closeAudioPreview}
        />
      )}

      <PhotoProvider maskOpacity={0.91}>
      {uploadingFiles && uploadingFiles.length > 0 && (
        <div className={styles.fileContainer}>
          {(uploadingFiles as IUploadFile[]).map((fil, index) => {
              if (fil.status === 'error') return null;
              const isImage = fil.file.type.startsWith("image/");
              const isUploaded = fil.status === 'uploaded';
              const previewImage = isImage && previews[index] && isUploaded;
               // Render file item with appropriate wrappers based on file type
               const fileItem = (
                <div key={index} className={classNames(styles.fileDisplay, {
                  [styles.dark]: theme === 'dark',
                  [styles.light]: theme === 'light',
                })} onClick={(e) => {
                  if (!isImage && isUploaded) {
                    handlePreviewFile(fil, e);
                  }
                }}
                  >
                  <div className={styles.fileIcon}>
                    {fil.status === 'validating' || fil.status === 'uploading' ?
                      <div className={classNames(styles.loader, {
                        [styles.light]: theme === 'light',
                        [styles.dark]: theme === 'dark',
                      })}>
                        <UploadSpinner icon={faCircleNotch} />
                      </div> :
                    previewImage ? (
                      <img 
                        src={previews[index]?? ""} 
                        alt="Uploaded Preview" 
                        className={styles.image}
                      />
                      ) : fil.file.type.startsWith("video/") ? (
                        <div className={classNames(styles.loader, {
                          [styles.light]: theme === 'light',
                          [styles.dark]: theme === 'dark',
                        })}>
                          <span className="text-[8px] font-medium text-[#A09FA2]">
                            {renderVideoName(fil.file.type)}
                          </span>
                        </div>
                      ) : fil.file.type.startsWith("audio/") ? (
                        <div className={classNames(styles.loader, {
                          [styles.light]: theme === 'light',
                          [styles.dark]: theme === 'dark',
                        })}>
                          <span className="text-[8px] font-medium text-[#A09FA2]">
                            {renderAudioName(fil.file.type, fil.file.name)}
                          </span>
                        </div>
                      ): (
                        renderFileIcon(fil.file.type, fil.file.name)
                      )}
                  </div>
                  <div className={styles.fileInfo}>
                    <div className={styles.fileName}>{fil && fil.file ? fil.file.name : 'Unknown File'}</div>
                    <div className={styles.fileType}>{fil.file.type.startsWith("audio/")? "Audio" :fil.file.type.startsWith("video/")? "video" : getFileTypeLabel(fil.file.type, fil.file.name)}</div>
                  </div>
                  {fil.status === 'uploaded' && (
                    loading.find(state => state.id === fil.id)?.isLoading ?
                      <div className={styles.closeButtonLoader}>
                        <Spinner extraSmall delFile={true}/>
                      </div> :
                      <div className={classNames(styles.closeButton, {
                        [styles.light]: theme === 'light',
                        [styles.dark]: theme === 'dark',
                      })} onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();
                        handleDelete(fil?.id, fil?.S3Link ? fil?.S3Link : '') 
                        }}>
                      {/* <ClosedIcon /> */}
                      {theme === "light" ? <CloseIcon closeDoc={true} /> : <ClosedIcon />}
                      </div>
                  )}

                </div>
            )
            return (
              <Tooltip
                key={fil.id || index}
                regenrate={true}
                control={
                  isImage && isUploaded ? (
                    <PhotoView src={fil.S3Link}>
                      {fileItem}
                    </PhotoView>
                  ) : fileItem
                }
                placement="top"
                theme="light"
              >
                {fil?.customFileName? fil?.customFileName : fil.file.name}
              </Tooltip>
            );
          }
          )}
        </div>
      )}
      </PhotoProvider>
    </>
  );
});