import { useEffect } from "react";
import classNames from "classnames";
import styles from "./Credits.module.scss";
import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

// import { Wallet } from "../../icons";

import { useSelector } from "redux/hooks";
import { Loader } from "components/Loader";

interface IProp {
  settingMiniBar?: boolean;
  mdNav?: boolean;
  isHovered?: boolean;
}

export const Credits = ({ settingMiniBar, mdNav, isHovered }: IProp) => {
  const { userDetail, creditLimits, theme } = useSelector(
    (state) => state.authReducer
  );
  const { creditLoad } = useSelector(
    (state) => state.chatReducer
  );
  const { includeRoute } = useRouter();

  const getReaminingCredits = (): number => {
    const adminCredits = userDetail?.user.activeSubscription.credits ?? 0;
    if (userDetail?.user.team.role === "admin") {
      return adminCredits;
    } else {
      const dailyCredits = creditLimits.daily_limit - creditLimits.used_today;
      return dailyCredits > 0 ? dailyCredits : 0;
    }
  };
  
  const isChat = includeRoute(RoutePaths.Chat);
  const isSetting = includeRoute(RoutePaths.Settings);
  const isWorkspaces = includeRoute(RoutePaths.Workspaces);
  const isThreads = includeRoute(RoutePaths.ThreadsHistory);

  useEffect(() => {
    const newCredits = getReaminingCredits();
    window.postMessage({ credits: newCredits });
  }, [
    userDetail?.user.activeSubscription.credits,
    creditLimits.used_today,
    userDetail?.user.team.role,
    creditLimits.daily_limit,
  ]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
        [styles.chat]: isChat || isWorkspaces || isThreads,
        [styles.chatCredit]: theme === 'dark' && (isChat || isWorkspaces || isThreads),
        [styles.miniSidebar]: settingMiniBar,
        [styles.isSetting]: isSetting,
      })}
    >
      {/* <Wallet /> */}
      {!isHovered &&
      <img
        src="/wallet.png"
        className="w-full h-full max-w-[16px]"
        alt="wallet"
      />
      }
      {!settingMiniBar &&
        (creditLoad ? (
          <Loader />
        ) : (
          <div
            className={classNames(
              // "text-[#4338CA] dark:text-[#5B71F5]",
              { [styles.credits]: !isHovered }, 
              { [styles.mdNav]: mdNav },
              { "text-[10px] text-[#4338CA] font-normal font-inter": isHovered } 
              
            )}
            id="deft-credit"
          >
            {getReaminingCredits()}
          </div>
        ))}
    </div>
  );
};


// import { useEffect } from "react";
// import classNames from "classnames";
// import styles from "./Credits.module.scss";
// import useRouter from "hooks/useRouter";
// import { RoutePaths } from "pages/routePaths";

// import { Wallet } from "../../icons";

// import { useSelector } from "redux/hooks";
// import { Loader } from "components/Loader";

// interface IProp {
//   settingMiniBar?: boolean;
//   mdNav?: boolean;
//   isHovered?: boolean;
// }

// export const Credits = ({ settingMiniBar, mdNav, isHovered }: IProp) => {
//   const { userDetail, creditLimits, theme } = useSelector(
//     (state) => state.authReducer
//   );
//   const { creditLoad } = useSelector(
//     (state) => state.chatReducer
//   );
//   const { includeRoute } = useRouter();

//   const getReaminingCredits = (): number => {
//     const adminCredits = userDetail?.user.activeSubscription.credits ?? 0;
//     if (userDetail?.user.team.role === "admin") {
//       return adminCredits;
//     } else {
//       const dailyCredits = creditLimits.daily_limit - creditLimits.used_today;
//       return dailyCredits > 0 ? dailyCredits : 0;
//     }
//   };
//   const isChat = includeRoute(RoutePaths.Chat);
//   const isSetting = includeRoute(RoutePaths.Settings);
//   const isWorkspaces = includeRoute(RoutePaths.Workspaces);
//   const isThreads = includeRoute(RoutePaths.ThreadsHistory);

//   useEffect(() => {
//     const newCredits = getReaminingCredits();
//     window.postMessage({ credits: newCredits });
//   }, [
//     userDetail?.user.activeSubscription.credits,
//     creditLimits.used_today,
//     userDetail?.user.team.role,
//     creditLimits.daily_limit,
//   ]);

//   return (
//     <div
//       className={classNames(styles.container, {
//         [styles.light]: theme === "light",
//         [styles.dark]: theme === "dark",
//         [styles.chat]: isChat || isWorkspaces || isThreads,
//         [styles.chatCredit]: theme === 'dark' && (isChat || isWorkspaces || isThreads),
//         [styles.miniSidebar]: settingMiniBar,
//         [styles.isSetting]: isSetting,
//       })}
//     >
//       {!isHovered && <Wallet />}
//       {!settingMiniBar && (
//         creditLoad ? (
//           <Loader />
//         ) : (
//           <div className={classNames(
//               { [styles.credits]: !isHovered }, 
//               { [styles.mdNav]: mdNav },
//               { "text-[10px] text-[#4338CA] font-normal font-inter": isHovered } 
//             )}
//             id="deft-credit"
//           >
//             {getReaminingCredits()}
//           </div>
//         )
//       )}
//     </div>
//   );
// };