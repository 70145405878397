import { BotSettings, updateFileProgress, selectedThread } from "redux/actions";

export interface IOption {
  label: any;
  value: any;
}

interface subOption {
  label: string;
  value: string;
}

interface DropdownOption {
  key: string;
  label: string;
  value: string;
  options: subOption[];
}

export const ImageCountOptions: IOption[] = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];

export const getDropdownOptions = (RollBot: BotSettings): DropdownOption[] => [
  {
    key: "output_format",
    label: "Output Format",
    value: "outputFormats",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.outputFormats || []),
    ],
  },
  {
    key: "tone",
    label: "Tone",
    value: "tones",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.tones || []),
    ],
  },
  {
    key: "style_of_writing",
    label: "Writing Style",
    value: "writingStyles",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.writingStyles || []),
    ],
  },
  {
    key: "language",
    label: "Language",
    value: "language",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.languages || []),
    ],
  },
  {
    key: "length",
    label: "Length",
    value: "responseLengths",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.responseLengths || []),
    ],
  },
];

export const responseLoadingMessage = [
  "Evaluating possibilities",
  "Thinking",
  "Breaking down the puzzle",
  "Analyzing the statements",
  "Mapping possibilities",
  "Piercing the clues togather",
];

export const imgURL = ["png", "jpg", "jpeg", "webp", "gif"];

export const videoURL = ["mp4", "mpeg", "mpg", "webm", "wmv", "3gpp"];

export const audioURL = ["wav", "mp3", "flac"];

export const AllowedFileTypes = [
  "pdf",
  "txt",
  "ppt",
  "pptx",
  // "doc",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "eml",
  "srt",
  "png",
  "jpg",
  "jpeg",
  "webp",
  "gif",
  "mp4",
  "mpeg",
  "mpg",
  "webm",
  "wmv",
  "3gpp",
  // "3gp"
  "wav",
  "mp3",
  "flac",
  "msg",
  "xml",
  "md",
  "mdx",
  "htm",
  "log",
  "json",
  "epub"
];

export const DocAllowedTypes = [
  "pdf",
  "txt",
  "ppt",
  "pptx",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "eml",
  "srt",
  "msg",
  "xml",
  "md",
  "mdx",
  "htm",
  "log",
  "json",
  "epub"
];

export const generateUniqueId = () => {
  return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
};

export const extractFileExtension = (fileName: string) => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts.pop()?.toLowerCase() : '';
};

export const simulateSourceProgress = (
  fileId: string,
  startProgress: number,
  targetProgress: number,
  duration: number
) => {
  const steps = 20;
  const stepDuration = duration / steps;
  const progressIncrement = (targetProgress - startProgress) / steps;
  let currentStep = 0;

  const interval = setInterval(() => {
    currentStep++;
    const currentProgress = startProgress + progressIncrement * currentStep;

    if (currentStep >= steps) {
      clearInterval(interval);
    } else {
      updateFileProgress(fileId, Math.round(currentProgress));
    }
  }, stepDuration);

  return interval;
};

export const getRelativeTime = (timestamp: string) => {
  const now = new Date();
  const time = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - time.getTime()) / 1000);

  if (diffInSeconds < 60) return `${diffInSeconds} sec ago`;
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) return `${diffInMinutes} min ago`;
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours} hours ago`;
  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays} days ago`;
};

export const formatFullDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  const time = date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });

  const suffix = (day: number) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
          case 1: return "st";
          case 2: return "nd";
          case 3: return "rd";
          default: return "th";
      }
  };

  return `${day}${suffix(day)} ${month} ${year}, ${time}`;
};

export const textExtensions = [ "msg", "xml", "md", "mdx", "htm", "log", "json", "epub" ];

export const externalDomains = [ 'com', 'org', 'net', 'io', 'gov', 'edu', 'co', 'app' ];

export const renderVideoName = (fileType: string, 
) => {
  if (fileType.includes('mp4')) return 'MP4';
  if (fileType.includes('mpg')) return 'MPG';
  if (fileType.includes('mpeg')) return 'MPEG';
  if (fileType.includes('webm')) return 'WEBM';
  if (fileType.includes('wmv')) return 'WMV';
  if (fileType.includes('3gpp')) return '3GPP';
  return 'MP4';
};

export const renderAudioName = (fileType: string, 
  fileName: string
) => {
  if (fileType.includes("mp3") || fileName.endsWith(".mp3")) return "MP3";
  if (fileType.includes("wav") || fileName.endsWith(".wav")) return "WAV";
  if (fileType.includes("flac") || fileName.endsWith(".flac")) return "FLAC";
  return 'MP3';
};

export const FILE_COLORS : { [key: string]: string } = {
  "pdf": "#EF3F36",
  "text/plain": "#2A2831",
  "presentation": "#ED1C24",
  "ms-powerpoint": "#F46F01",
  "csv": "#56B751",
  "sheet": "#E1F9EC",
  "ms-excel": "#79AC00",
  "application/x-subrip": "#FFB300",
  "message/rfc822": "#0393FF",
};

export const SaveThreadInfo: selectedThread = {
  ws_name: "",
  emoji: "",
  thread_name: "",
  thread_id: 0,
  ws_id: 0,
};

export const RIPPLE_COLORS = {
  light: {
    default: 'rgba(0, 0, 0, 0.3)',
    primary: 'rgba(59, 130, 246, 0.4)',
    success: 'rgba(34, 197, 94, 0.4)',
    danger: 'rgba(239, 68, 68, 0.4)',
    warning: 'rgba(234, 179, 8, 0.4)',
  },
  dark: {
    default: 'rgba(255, 255, 255, 0.5)',
    primary: 'rgba(96, 165, 250, 0.5)',
    success: 'rgba(74, 222, 128, 0.5)',
    danger: 'rgba(252, 165, 165, 0.5)',
    warning: 'rgba(252, 211, 77, 0.5)',
  }
};
