import { memo, useState, useEffect, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  vscDarkPlus,
  prism,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./codeBlock.module.scss";

import { useWindowSize } from "hooks/useWindowSize";

import { useSelector } from "redux/hooks";
import { CodeBlockCopyIcon, ThCodeBlockCopyIcon } from "pages/ChatPage/pages/newChat/icons/CodeBlockCopyIcon";
import { Tooltip } from "components";

// Create a custom theme for threads mode with all text in white
const createWhiteTheme = () => {
  // Start with the prism theme as base
  const whiteTheme = { ...prism };
  
  // Override all token styles to be white
  const whiteStyle = { color: 'white' };
  
  // List of all possible token types in prism
  const tokenTypes = [
    'comment', 'prolog', 'doctype', 'cdata', 'punctuation', 'namespace',
    'property', 'tag', 'boolean', 'number', 'constant', 'symbol', 'deleted',
    'selector', 'attr-name', 'string', 'char', 'builtin', 'inserted', 'operator',
    'entity', 'url', 'string', 'atrule', 'attr-value', 'keyword', 'function',
    'class-name', 'regex', 'important', 'variable'
  ];
  
  // Set all token types to white
  tokenTypes.forEach(type => {
    whiteTheme[type] = whiteStyle;
  });
  
  // Ensure the base text is also white
  whiteTheme.plain = { color: 'white' };
  
  return whiteTheme;
};

// Create our white theme
const whiteCodeTheme = createWhiteTheme();

interface IProps {
  language?: string;
  content: string;
  searchWord?: string;
  isHighlighted?: boolean;
  highlighted?: boolean;
  threads?: boolean;
}

export const CodeBlock = memo(({ language, content, searchWord, isHighlighted, highlighted, threads }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();
  const preRef = useRef<HTMLDivElement | null>(null);

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<number>(16);

  useEffect(() => {
    const intervalID = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 500);

    return () => clearInterval(intervalID);
  }, [isCopied]);

  useEffect(() => {
    if (width <= 576) {
      setFontSize(13);
    } else {
      setFontSize(16);
    }
  }, [width]);

  const highlightText = (node: HTMLElement, text: string) => {
    const regex = new RegExp(text, "gi");
    node.innerHTML = node.innerHTML.replace(
      regex,
      '<span class="bg-primary text-white">$&</span>'
    );
  };

  useEffect(() => {
    const preNode = preRef.current;
    // Don't apply highlighting in threads mode
    if (preNode && searchWord && isHighlighted && highlighted && !threads) {
      highlightText(preNode, searchWord);
    }
  }, [preRef, searchWord, threads, isHighlighted, highlighted]);

  // Choose the appropriate style based on threads prop
  const codeStyle = threads 
    ? whiteCodeTheme 
    : (theme === "dark" ? vscDarkPlus : prism);

  return (
    <div className={classNames({ 'px-[15px]': !threads })}>
      <div 
        ref={preRef}
        className={classNames(styles.container, {
          [styles.containerLight]: theme === "light" && !threads,
          [styles.containerDark]: theme === "dark" && !threads,
          [styles.threadsLight]: threads && theme === "light",
          [styles.threadsDark]: threads && theme === "dark",
        })}
      >
        <div className={classNames(styles.header, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
          [styles.threads]: threads,
        })}>
          <div className={classNames(styles.language, {
            [styles.threads]: threads,
          })}>{language}</div>
          <Tooltip
            regenrate={true}
            tooltipAdjustement={true}
            control={
              <CopyToClipboard
                text={content}
                onCopy={() => setIsCopied(true)}
              >
                <div className='cursor-pointer'>
                  {threads ? 
                    <div className="flex gap-[8px] items-center"> 
                      <span><ThCodeBlockCopyIcon/></span> 
                      <span className="text-[12px] text-[#fff] font-normal">copy</span> 
                    </div> 
                    : <CodeBlockCopyIcon />
                  }
                </div>
              </CopyToClipboard>
            }
            placement="top"
            theme="light"
          >
            <div>
              <FormattedMessage
                id={isCopied ? "answer.tooltip.copied" : "answer.tooltip.copy"}
              />
            </div>
          </Tooltip>
        </div>
        <div className={styles.codeContainer}>
          <SyntaxHighlighter
            showInlineLineNumbers
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
            }}
            wrapLines={true}
            wrapLongLines={true}
            language={language}
            style={codeStyle}
            customStyle={{
              fontSize: `${fontSize}px`,
              color: threads ? 'white' : undefined,
            }}
            codeTagProps={{
              style: {
                fontSize: "inherit",
                color: threads ? 'white' : undefined,
              },
            }}
            PreTag={({ children }) => {
              return (
                <pre className={classNames(styles.preTag, {
                  [styles.threads]: threads,
                })}>
                  <code className={threads ? 'text-white' : ''}>{children}</code>
                </pre>
              );
            }}
          >
            {content}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
});