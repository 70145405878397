import { useState, ChangeEvent, Dispatch, SetStateAction, FormEvent, useEffect } from "react";
import { Modal, Spinner } from "components";
import classNames from "classnames";
import styles from './docUpload.module.scss';
import { useSelector } from "redux/hooks";
import { getFileSize, getImageSize, getVideoDuration, validateFileType } from "utils/functions";
import useRouter from "hooks/useRouter";

import { FormattedMessage, useIntl } from "react-intl";
import validUrl from "valid-url";
import { setChatModel, IChatModel, ChatType } from "redux/actions";
import { IFileErrorState } from "pages/ChatPage/pages/newChat/NewChat";
import { useWindowSize } from "hooks/useWindowSize";
import { documentMaxCountReached, videoMaxCountReached, audioMaxCountReached } from "utils/chat";
import { AllowedFileTypes, audioURL, imgURL, videoURL } from "utils/constants";

interface IProp {
    setISUploadURL?: Dispatch<SetStateAction<boolean>>;
    setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
    setUploadUrl?: Dispatch<SetStateAction<boolean>>;
    message?: string;
    resetMessage?: () => void;
    url?: string;
    setURL?: Dispatch<SetStateAction<string>>;
    onSendMessage?: (question: string, chatModels?: IChatModel, regenerate?: boolean, images?: string[], filePath?: string[]) => void;
    setMessageId?: Dispatch<SetStateAction<string>>;
    changeModel?: boolean;
    setChangeModel?: Dispatch<SetStateAction<boolean>>;
}

export const UploadURL = ({ setISUploadURL, url,
    setURL,
    setErrorModal, setUploadUrl, message,
    resetMessage,
    onSendMessage,
    setMessageId,
    changeModel,
    setChangeModel,
}: IProp) => {

    const AllowedFileSize = 100;
    const FreePlanAllowedFileSize = 10;
    const StandardPlanAllowedFileSize = 50;

    const { pathname } = useRouter();
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const { theme, gptModel, userDetail } = useSelector((state) => state.authReducer);
    const { newMessages, messages } = useSelector((state) => state.chatReducer);
    const chatModel = useSelector((state) => state.authReducer.gptModel);
    const { formatMessage } = useIntl();
    const currentPlan = useSelector(
        (state) => state.planSubscriptionReducer.activePlan
    );

    const planName = userDetail?.user.activeSubscription.name.toLowerCase();
    const { width } = useWindowSize();

    const chatId = pathname.split("/")[3];
    const chatHistory = pathname.includes('chat/new');

    const getConditionalAllowedFileTypes = () => {
        return gptModel?.name.includes("Claude") ? AllowedFileTypes : [...AllowedFileTypes, "jpg"];
    };

    const [isURLLoading, setIsURLLoading] = useState<boolean>(false);

    // Helper function to set the error modal
    const setValidationError = (messageKey: string) => {
        setErrorModal({
            message: formatMessage({ id: messageKey }),
            show: true,
        });
    };

    const getFileURL = (event: ChangeEvent<HTMLInputElement>) => {
        setURL?.(event.target.value);
    
        const urlExtension = event.target.value?.split('.').pop()?.toLowerCase() || '';
        const modelTypeMapping = {
            [ChatType.video]: 'videoChatGptModel',
            [ChatType.audio]: 'audioChatGptModel',
            [ChatType.image_chat]: 'imageChatGptModel',
        };
    
        const supportedModels = {
            video: chatModels.filter(model => model.type.includes(ChatType.video)).map(model => model.name),
            audio: chatModels.filter(model => model.type.includes(ChatType.audio)).map(model => model.name),
            image_chat: chatModels.filter(model => model.type.includes(ChatType.image_chat)).map(model => model.name),
        };
    
        const urlMappings = {
            video: videoURL,
            audio: audioURL,
            image_chat: imgURL,
        };
    
        const handleModelChange = (modelKey: keyof typeof modelTypeMapping, defaultIndex: number) => {
            const storageKey = modelTypeMapping[modelKey];
            const storedModel = localStorage.getItem(storageKey);
            const parsedModel = storedModel ? JSON.parse(storedModel) : chatModels[defaultIndex];
    
            if (storedModel) {
                localStorage.setItem('GptModel', storedModel);
            } else {
                localStorage.setItem(storageKey, JSON.stringify(parsedModel));
                localStorage.setItem('GptModel', JSON.stringify(parsedModel));
            }
    
            setChatModel(parsedModel);
            setChangeModel?.(false);
        };
    
        Object.entries(urlMappings).forEach(([type, urlArray]) => {
            const typeKey = type as keyof typeof supportedModels;
    
            if (urlArray.includes(urlExtension)) {
                if (changeModel) {
                    handleModelChange(typeKey, typeKey === 'video' ? 0 : typeKey === 'audio' ? 0 : 1);
                }
    
                const isModelSupported = supportedModels[typeKey].includes(gptModel?.name ?? '');
                if (!isModelSupported) {
                    handleModelChange(typeKey, typeKey === 'video' ? 0 : typeKey === 'audio' ? 0 : 1);
                }
            }
        });
    };    

    useEffect(() => {
        setURL?.('')
    }, [])

    const onSubmit = async (e: FormEvent<EventTarget | HTMLFormElement>) => {
        e.preventDefault();

        const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
        const docURL = ["pdf", "txt", "ppt", "pptx", "docx", "csv", "xls", "xlsx", "eml", "srt",  "msg", "xml", "md", "mdx", "htm", "log", "json", "epub"]

        if (docURL.includes(urlExtension)) {
            if (documentMaxCountReached(currentPlan, userDetail)) {
                setMessageId!("documentChat.plan.max_count");
                setISUploadURL!(false);
                setURL?.('');
                return;
            }
        }
        if (videoURL.includes(urlExtension)) {
            if (videoMaxCountReached(currentPlan, userDetail)) {
                setMessageId!("videoChat.plan.max_count");
                setISUploadURL!(false);
                setURL?.('');
                return;
            }
        }

        if (audioURL.includes(urlExtension)) {
            if (audioMaxCountReached(currentPlan, userDetail)) {
                setMessageId!("audioChat.plan.max_count");
                setISUploadURL!(false);
                setURL!('');
                return;
            }
        }
        if (((newMessages.length > 0 &&
            newMessages[0]?.chatType === "audio"
            && chatHistory) || (messages[0]?.chatType === 'audio' && chatId)) && (docURL.includes(urlExtension) || imgURL.includes(urlExtension) || videoURL.includes(urlExtension))) {
            setURL!('');
            setValidationError("upload.file.validation.audio");
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }
        else if (((newMessages.length > 0 &&
            newMessages[0]?.chatType === "video"
            && chatHistory) || (messages[0]?.chatType === 'video' && chatId)) && (docURL.includes(urlExtension) || imgURL.includes(urlExtension) || audioURL.includes(urlExtension))) {
            setURL!('');
            setValidationError("upload.file.validation.video");
            resetMessage!();
            setISUploadURL!(false);
            return false;
        } else if (((newMessages.length > 0 &&
            newMessages[0]?.chatType === "image_chat"
            && chatHistory) || (messages[0]?.chatType === 'image_chat' && chatId)) && (docURL.includes(urlExtension) || videoURL.includes(urlExtension) || audioURL.includes(urlExtension))) {
            setURL!('');
            setValidationError("upload.file.validation.docURL");
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }
        else if (((newMessages.length > 0 &&
            newMessages[0]?.chatType === "document"
            && chatHistory) || (messages[0]?.chatType === 'document' && chatId)) && (imgURL.includes(urlExtension) || videoURL.includes(urlExtension) || audioURL.includes(urlExtension))) {
            setURL!('');
            setValidationError("upload.file.validation.imgURL");
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }
        else if (await validateURL()) {
            setIsURLLoading(true);
            setUploadUrl!(true);
            const urlExtension = url?.split('.').pop()?.toLowerCase() || '';

            if (imgURL.includes(urlExtension)) {
                onSendMessage!(message ? message : "", chatModel, false, url ? [url] : [], [])
            }
            else {
                onSendMessage!(message ? message : "", chatModel, false, [], url ? [url] : [])
            }
            setTimeout(() => {
                setURL!('')
                setISUploadURL!(false)
                resetMessage!()
                setUploadUrl!(false);
            }, 50)
        }
    };

    const handleStateUpdate = () => {
        setURL!('');
        resetMessage!();
        setIsURLLoading(false);
        setISUploadURL!(false);
        return;
    }

    const validateURL = async (): Promise<boolean> => {
        if (validUrl.isHttpsUri(url ? url : '')) {
            setIsURLLoading(true);
            const conditionalAllowedFileTypes = getConditionalAllowedFileTypes();
            const urlExtension = url?.split('.').pop()?.toLowerCase() || '';

            // New validation for multiple URLs or text after a comma
            const commaIndex = url?.indexOf(',') ?? -1; // Default to -1 if url is undefined
            if (commaIndex !== -1) {
                const textAfterComma = url?.slice(commaIndex + 1).trim();
                if (textAfterComma) {
                    setValidationError("upload.url.validation");
                    handleStateUpdate();
                    return false;
                }
            }
            if ((validateFileType({ url: url, types: conditionalAllowedFileTypes }))) {

                if (imgURL.includes(urlExtension)) {
                    setIsURLLoading(true);
                    const imageSize = await getImageSize(url ? url : '');
                    const allowedImgSize = gptModel?.name.includes("GPT-4") ? 15 : 5;
                    const maxSizeInBytes = gptModel?.name.includes("GPT-4") ? 15 * 1024 * 1024 : 5 * 1024 * 1024; // 5 MB

                    if (imageSize > maxSizeInBytes) {
                        setErrorModal({
                            message: formatMessage(
                                { id: 'ImgChat.file.fileSizeImage' },
                                { size: allowedImgSize }
                            ),
                            show: true,
                        });
                        handleStateUpdate();
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    const FileSize = await getFileSize(url ? url : '');

                    if (planName === 'free' && FileSize > FreePlanAllowedFileSize) {
                        setMessageId!(`documentChat.file.${planName}.plan.fileSize`);
                        handleStateUpdate();
                        return false;
                    }
                    else if (planName === 'standard' && FileSize > StandardPlanAllowedFileSize) {
                        setMessageId!(`documentChat.file.${planName}.plan.fileSize`);
                        handleStateUpdate();
                        return false;
                    }
                    else if (FileSize > AllowedFileSize) {
                        // setMessageId!("documentChat.file.fileSize");
                        setValidationError("documentChat.file.fileSize");
                        handleStateUpdate();
                        return false;
                    }

                    if (videoURL.includes(urlExtension) || audioURL.includes(urlExtension)) {
                        try {
                            const videoDuration = await getVideoDuration(url ?? ""); // You'll need a function to get the duration
                            const maxDurationInSeconds = videoURL.includes(urlExtension) ?  30 * 60 :  60 * 60;

                            if (videoDuration > maxDurationInSeconds) {
                                setValidationError(videoURL.includes(urlExtension) ? 'validate.video.duration' : 'validate.audio.duration');
                                handleStateUpdate();
                                return false;
                            } else {
                                return true;
                            }
                        } catch (error) {
                            console.error("Error fetching video duration", error);
                            handleStateUpdate();
                            return true;
                        }
                    }
                    else {
                        return true;
                    }
                }
            } else {
                setValidationError("documentChat.url.fileFormat");
                handleStateUpdate();
                return false;
            }
        } else {
            setValidationError("documentChat.url.validation");
            handleStateUpdate();
            return false;
        }
    };

    return (
        <>
            <Modal size='md' modalPrompt={width <= 576} onClose={() => {
                if (!isURLLoading) {
                    setISUploadURL!(false);
                    setURL!('');
                }
            }}>
                <div className={`py-[34px] ${width <= 576 ? 'px-[14px]' : 'px-[30px]'}`}>
                    <div className={styles.urlHeading}>
                        <FormattedMessage id="doc.upload.enter.url" />
                    </div>
                    <form data-testid='document-form' className={styles.form}
                        onSubmit={onSubmit}
                    >
                        <input
                            className={classNames(styles.input, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                            autoFocus
                            placeholder={formatMessage({
                                id: "doc.url.palaceholder",
                            })}
                            value={url}
                            onChange={getFileURL}
                            data-testid='document-upload-input'
                        />
                        <button
                            data-testid='document-submit-btn'
                            className={classNames({ [styles.isLoading]: isURLLoading })}
                            type="submit"
                        >
                            {isURLLoading ? (
                                <Spinner extraSmall isWhite={true} />
                            ) : (
                                <FormattedMessage id="documentChat.url.submitBtn" />
                            )}
                        </button>
                    </form>
                    <div className={classNames(styles.supportedFilesContainer, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                    })}>
                        <span className={styles.SupportedHeader}> <FormattedMessage id="SupportedFiles.url" /></span>
                        <span className={styles.supportedFiles}>pdf, txt, ppt, pptx, docx, csv, xls, xlsx, eml, srt, png, jpg, jpeg, webp, gif, mp4, mpeg, mpg, webm, wmv, 3gpp, wav, mp3, flac, msg, xml, md, mdx, htm, log, json, epub</span>
                    </div>
                </div>
            </Modal>
        </>
    )
}