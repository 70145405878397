import { FormattedMessage } from "react-intl";
import styles from "./configureAIBehaviour.module.scss";
import { DropDown, Tooltip } from "components";
import { Field, Formik, FieldProps } from "formik";
import { Form } from "pages/AuthPages/components/FormComponents";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { CloseIcon } from "../icons/CloseIcon";
import { Notifications } from "../Notifications/Notifications";
import { useSelector } from "redux/hooks";
import { useState, useRef, useEffect } from "react";
import useRouter from "hooks/useRouter";

interface IProp {
    EditIntructionModal?: boolean;
    onClose?: () => void;
    formRef?: React.RefObject<any>;
}
export const ConfigureAIBehaviour = ({ EditIntructionModal, onClose, formRef }: IProp) => {
    const  workspaceIds: string[] = [
        "Research and Analysis (Deep analysis, data processing, insights generation)",
        "Content Creation & Documentation (Writing, editing, maintaining documentation)",
        "Technical Development & Engineering (Programming, technical problem solving)",
        "Design & Creative Work (Visual and creative content development)",
        "Custom Behaviour (Configure custom behaviour from scratch)",
    ]

    const { pathname } = useRouter();
    const { formatMessage } = useIntl();
    const { theme } = useSelector((state) => state.authReducer);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { inProgressWorkspace, currentConversation } = useSelector((state) => state.workSpaceReducer);
    const [selectedOption, setSelectedOption] = useState<string>((inProgressWorkspace && inProgressWorkspace.instructions) || "");
    const [customDescription, setCustomDescription] = useState<string>((inProgressWorkspace && inProgressWorkspace.description) || "");
    const [dropDownValue, setDropdown] = useState<boolean>(false);
    let Id = "";
    let pathParts = pathname && pathname.split("/");
    if (pathParts && pathParts.length > 2) {
    Id = pathParts[2];
    }

    // Descriptions mapped to workspace options
    const descriptions: Record<string, string> = {
        "Research and Analysis (Deep analysis, data processing, insights generation)": formatMessage({ id: "workspace.ai.research.description" }),
        "Content Creation & Documentation (Writing, editing, maintaining documentation)": formatMessage({ id: "workspace.ai.content.description" }),
        "Technical Development & Engineering (Programming, technical problem solving)": formatMessage({ id: "workspace.ai.technical.description" }),
        "Design & Creative Work (Visual and creative content development)": formatMessage({ id: "workspace.ai.design.description" }),
    };
// Preprocess first words once and store in a lookup map
    const firstWordLookup = workspaceIds.reduce((acc, workspace) => {
        const firstWord = workspace.substring(0, workspace.indexOf(" ")) || workspace; // More efficient than split
        acc[workspace] = firstWord;
        return acc;
    }, {} as Record<string, string>);
    
    const dropdownOptions = workspaceIds.map((workspace) => { 
        const fullLabelText = `${formatMessage({ id: `workspace.ai.${firstWordLookup[workspace]}` })} ${formatMessage({ id: `workspace.ai.${firstWordLookup[workspace]}.details` })}`;
        return {
          label: (
            <Tooltip
            control={
            <div className="flex gap-2 items-center leading-[19px] py-[10px] px-[15px]">
              <span>
                {formatMessage({
                  id: `workspace.ai.${firstWordLookup[workspace]}`,
                })}
              </span>
              <span className="text-[#737373] dark:text-[rgba(255,255,255,0.7)]">
                {formatMessage({
                  id: `workspace.ai.${firstWordLookup[workspace]}.details`,
                })}
              </span>
            </div>
             }
             placement="right"
             theme="light"
         >
            {fullLabelText}
         </Tooltip>
          ),
          value: workspace,
        };
    });

    useEffect(() => {
        if (inProgressWorkspace) {
            setSelectedOption(inProgressWorkspace.instructions || ""); 
        }
    }, [inProgressWorkspace]);
    
    // Update state when the dropdown value changes
    const handleDropdownChange = (option: { value: string }, setFieldValue: (field: string, value: any) => void) => {
        setSelectedOption(option.value);
        setDropdown(true);
        if (option.value === "workspace.ai.custom") {
            setCustomDescription("");
        } else {
            const newDescription = descriptions[option.value] || "";
            setFieldValue("description", newDescription);
           
        }
        // Reset scroll position when the description changes
        if (textareaRef && textareaRef.current) {
            textareaRef.current.scrollTop = 0;
        }
    };

    
   const getDescription = () => {
     if (Number(Id)) return currentConversation?.description;
     if (
       inProgressWorkspace?.name &&
       inProgressWorkspace?.instructions &&
       !dropDownValue
     ) {
       return inProgressWorkspace?.description;
     }
     return selectedOption === "workspace.ai.custom"
       ? customDescription
       : descriptions[selectedOption] || "";
   };

    const description = getDescription();

    return (
      <div
        className={classNames(styles.container, {
          [styles.wrraperContainer]: !EditIntructionModal,
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.headContainer}>
          {!EditIntructionModal ? (
            <div
              className={classNames(styles.heading, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="workspace.ai.head" />
            </div>
          ) : (
            <div className="flex w-full justify-between pb-[10px]">
              <div
                className={classNames(styles.modalHeader, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                <FormattedMessage id="workspace.context.edit.instruction" />
              </div>
              <span
                data-testid="prompt-close"
                className="flex items-center justify-end hover:cursor-pointer text-[#94A3B8] dark:text-white"
                onClick={onClose}
              >
                <CloseIcon />
              </span>
            </div>
          )}
        </div>

        {/* Editable Textarea */}
        <Formik
          innerRef={formRef}
          initialValues={{
            description: description,
            // Number(Id)? currentConversation?.description : (inProgressWorkspace?.name && inProgressWorkspace?.instructions && dropDownValue===false) ? inProgressWorkspace?.description : selectedOption === "workspace.ai.custom" ? customDescription : descriptions[selectedOption] || "",
            instruction: Number(Id)
              ? currentConversation?.instructions
              : selectedOption,
          }}
          onSubmit={(_, actions) => {
            try {
              actions.setSubmitting(false);
            } catch (error) {
              console.error("Error handling form:", error);
            } finally {
              actions.setSubmitting(false);
            }
          }}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form data-testid="add-prompt-form">
              <div className="flex flex-col gap-[18px]">
                {/* Dropdown */}
                <DropDown
                  $background={theme === "light" ? "#fff" : "transparent"}
                  variant={theme}
                  height={33}
                  className={classNames(styles.select, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                  workspace={true}
                  value={values.instruction}
                  options={dropdownOptions}
                  placeholder={formatMessage({
                    id: "workspace.ai.select.placeholder",
                  })}
                  onChange={(option) => {
                    const value = option.value;
                    setFieldValue("instruction", value);
                    // handleDropdownChange(option);
                    handleDropdownChange(option, setFieldValue); // ✅ Pass setFieldValue
                  }}
                />

                <Field name="description">
                  {({ field, meta }: FieldProps<string>) => (
                    <>
                      {meta.touched && meta.error && (
                        <div
                          id="error-text"
                          className="text-[#ff5454] pb-[5px] font-medium text-sm"
                        >
                          {formatMessage({ id: meta.error })}
                        </div>
                      )}
                      <TextareaAutosize
                        ref={textareaRef}
                        {...field}
                        className={classNames(styles.textarea, {
                          [styles.error]: meta.touched && meta.error,
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                        })}
                        minRows={6}
                        maxRows={7.5}
                        placeholder={formatMessage({
                          id: "workspace.ai.placeholder",
                        })}
                        value={
                          selectedOption === "workspace.ai.custom"
                            ? customDescription
                            : values.description
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("description", value);
                          if (selectedOption === "workspace.ai.custom") {
                            setCustomDescription(value);
                          }
                        }}
                        data-testid="prompt-textarea"
                      />
                    </>
                  )}
                </Field>
              </div>
            </Form>
          )}
        </Formik>

        {/* Notifications */}
        <Notifications
          notificationIds={[
            "workspace.ai.notification1",
            "workspace.ai.notification2",
            "workspace.ai.notification3",
            "workspace.ai.notification4",
          ]}
          headingId="workspace.ai.notificationhead"
        />
      </div>
    );
};