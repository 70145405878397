
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { FileUploadWrapper } from "pages/Workspaces/components/RichContext/Component/FileUploadWrapper";
import { useFileUpload } from "hooks/useFileUpload";

export const ChatContentUploadFile = () => {
   const chatContent= true;
  
    const {
        errorModal,
        setErrorModal,
        messageId,
        setMessageId,
        handleFileSelect,
        handleDrop,
        fileLimit
      } = useFileUpload(chatContent);

  const { push } = useRouter();
  
  return (
     <FileUploadWrapper
      fileLimit={fileLimit}
      handleFileSelect={handleFileSelect}
      handleDrop={handleDrop}
      errorModal={errorModal}
      setErrorModal={setErrorModal}
      messageId={messageId}
      onConfirm={() => push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)}
      onCancel={() => setMessageId("")}
      chatContent={chatContent}
    />
  );
};
