import Lottie from "react-lottie";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./Hero.module.scss";

// import {
//   IntoChat,
//   IntroImage,
//   IntroDocument,
//   IntroChatDark,
//   IntroImageDark,
//   IntroDocumentDark,
// } from "json";

import CutsomButton from "components/Button";
import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";
import { useState, useEffect } from "react";

const defaultOptions = {
  loop: true,
  autoplay: true,
};

export const Hero = () => {
  const { push } = useRouter();
  const theme = useSelector((state) => state.authReducer.theme);
  const { userDetail } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  // Animation Data Array
  // const lightAnimations = [IntoChat, IntroImage, IntroDocument];
  // const darkAnimations = [IntroChatDark, IntroImageDark, IntroDocumentDark];

  const lightAnimations = [
    "IntoChat.json",
    "IntroImage.json",
    "IntroDocument.json",
  ];
  const darkAnimations = [
    "IntroChatDark.json",
    "IntroImageDark.json",
    "IntroDocumentDark.json",
  ];

  // Determine the animations based on the theme
  // const animations = theme === "dark" ? darkAnimations : lightAnimations;

  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
  const [animations, setAnimations] = useState<string[]>([]);

  const jsonPaths = theme === "dark" ? darkAnimations : lightAnimations;

  const controller = new AbortController();
  const loadAll = async () => {
    try {
      const data = await Promise.all(
        jsonPaths.map((path) =>
          fetch(process.env.PUBLIC_URL + "/json/" + path, {
            signal: controller.signal,
          })
            .then((r) => r.json())
            .catch((e) => console.error(`${path} 加载失败`, e))
        )
      );
      setAnimations(data);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
      }
    }
  };

  useEffect(() => {
    loadAll();
    return () => {
      controller.abort(); // Clean up the fetch requests
    };
  }, [theme]);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAnimationIndex(
        (prevIndex) => (prevIndex + 1) % jsonPaths.length
      );
    }, 4000); // Adjust the interval time as needed

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <section
      className={classNames(styles.section, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      style={{
        backgroundImage: `url(/landing/${theme}/Introduction.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={styles.left}>
        <h1
          className={classNames(styles.h1, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <span className={styles.gradient}>
            <FormattedMessage id="landing.hero.introducing" />{" "}
            <FormattedMessage id="landing.hero.deftgpt" />
          </span>
          <br />
          <p className={styles.subtitle}>
            <FormattedMessage id="landing.hero.title" />
          </p>
        </h1>
        <p
          className={classNames(styles.text, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <FormattedMessage id="landing.hero.desc" />
        </p>
        <CutsomButton
          hero={true}
          variant="latest-primary"
          onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
          data-testid="landing-signup"
        >
          <FormattedMessage id="landing.nav.SignUp" />
        </CutsomButton>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.lg_image}>
          {animations.length > 0 && (
            <Lottie
              options={{
                ...defaultOptions,
                animationData:
                  animations[
                    currentAnimationIndex >= animations.length
                      ? 0
                      : currentAnimationIndex
                  ],
              }}
            />
          )}
          <div />
        </div>
      </div>
    </section>
  );
};
