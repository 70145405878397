import styles from "../workSpaceConversation.module.scss";
import { EditIcon, PlusIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import { ConfigureAIBehaviour } from "pages/Workspaces/components/ConfigureAI/ConfigureAIBehaviour";
import { Spinner } from "components";
import { useRef, useState } from "react";
import CustomButton from "components/Button";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { AddSource } from "./AddSource";
import { useSidebar } from "hooks/services/ReSizeSidebar";
import classNames from "classnames";
import { useWindowSize } from "hooks/useWindowSize";
import { UploadedConversationFiles } from "pages/Workspaces/components/uploadedFiles/UploadedConversationFile";
import { useSelector } from "redux/hooks";
import { useAppNotification } from "hooks/services/AppNotification";
import { ICRWorkSpace, IWorkspace, setUpdatedName, UpdateWorkspace } from "redux/actions";
import useRouter from "hooks/useRouter";
import { isICRWorkSpace, isValidWorkspace } from "utils/workSpaceS3";
import AnimatedModal from "components/AnimateModal/AnimateModal";

import { RippleDurationButton } from "components/RippleEffect/RippleDurationButton";

interface IProp {
    loadingData: boolean;
    addSource: boolean;
    setAddSource: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DurationButton = styled(CustomButton)`
background-color: #4338CA;
color: #fff;
flex-shrink: 0;
font-size: 12px;
line-height: 15px;
font-weight: 600;
border-radius: 4px;
padding: 6px 20px;
font-family:"Inter";
   @media (max-width:576px) {
        font-size: 11px;
         padding: 5px 16px;
     } 
`;

export const InfoComponent = ({loadingData, addSource,setAddSource}: IProp) => {

  const { pathname } = useRouter();
    const Id = pathname.split("/")[2];
    const formRef = useRef<HTMLFormElement>(null);
    const { wsUploadedFiles, currentConversation } = useSelector((state) => state.workSpaceReducer);
    const { width } = useWindowSize();
    const { sidebarWidth } = useSidebar();
    const [editAIBehaviour, setEditAIBehaviour] = useState<boolean>(false);
    const { theme, userDetail } = useSelector((state) => state.authReducer);
    const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";
    const [isloading, setIsloading] = useState<boolean>(false);
    const { triggerNotification } = useAppNotification();
    const [loading, setLoading] = useState<boolean>(false);
    const handleEditAIBehaviour = () => {
        setEditAIBehaviour((prev) => !prev);
    }
    const handleAddSource = () => {
        setAddSource((prev) => !prev);
    }

    const validateStep2 = () => {
        const currentValues = formRef.current?.values;
        let Error = false;

        // Only validate length if description exists
        if (currentValues?.description) {
            const length = currentValues.description.length;

            if (length < 12) {
                formRef.current?.setFieldError('description', 'workspace.description.min');
                formRef.current?.setFieldTouched('description', true, false);
                Error = true;
            }
            // Check maximum length
            if (length > 4096) {
                formRef.current?.setFieldError('description', 'workspace.description.max');
                formRef.current?.setFieldTouched('description', true, false);
                Error = true;
            }
        }

        return !Error;
    }

    const resetStates= () => {
        setIsloading(false);
        setEditAIBehaviour(false)
    }

    const handleUpdateDescription = () => {
        const isValid = validateStep2();
        if (!isValid) return;
        const currentValues = formRef.current?.values;
        const description = currentValues?.description || "";
        const instructions =currentValues?.instruction || "";

        const id = currentConversation?.id ?? Number(Id);
        if (!id) { 
            return;
        } else {
        setIsloading(true);
        UpdateWorkspace(id,  { description, instructions })
          .then((response) => {
       
            const res = response as ICRWorkSpace;
            const updatedData = res.data as IWorkspace
            if (!isICRWorkSpace(res)) {
                triggerNotification({ message: "Invalid response format from server", type: "error" });
                return;
            }

            if (!isValidWorkspace(updatedData)) {
                triggerNotification({ message: "IInvalid workspace data received", type: "error" });
                return;
            }
            setUpdatedName(updatedData.name, updatedData.description, updatedData.instructions, updatedData.id);
            resetStates();
            triggerNotification({ message: res.message, type: "info" });
          })
          .catch((error) => {
            resetStates();
            triggerNotification({ message: error?.data?.message, type: "error" });
          });
        }
    }

    const instructionValue = currentConversation?.instructions?.split(" ")?.[0] || "";
    const maxSources = isFreePlan ? 1 : 5;

    return (
      <>
        <div
          className={classNames(styles.rightSide, {
            [styles.rightSidePosition]:
              (sidebarWidth > 287 && width <= 1150) ||
              (sidebarWidth > 497 && width <= 1323) ||
              (sidebarWidth > 480 && width <= 1305),
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
            [styles.loadingStyle]: loadingData,
          })}
        >
          {loadingData ? (
            <div className="flex items-center justify-center width-[36%]">
              <Spinner small />
            </div>
          ) : (
            <div
              className={classNames("font-inter", styles.KnowledgeContainer, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <div
                className={classNames(styles.knowledgeWrapper, {
                  [styles.Adjustposition]: sidebarWidth > 600,
                  [styles.Adjustment]: sidebarWidth > 413,
                })}
              >
                <div
                  className={classNames(styles.researchContainer, {
                    [styles.Adjustposition]: sidebarWidth > 600,
                    [styles.Adjustment]: sidebarWidth > 413,
                    [styles.instruction]: sidebarWidth > 245,
                  })}
                >
                  <div
                    className={classNames(styles.title, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    {" "}
                    <FormattedMessage id="workSpace.Knowledge" />{" "}
                  </div>
                  {instructionValue && (
                    <span
                      className={classNames(styles.research, {
                        [styles.instructionAdjustment]: sidebarWidth > 413,
                        [styles.instructionAdjustposition]: sidebarWidth > 600,
                        [styles.instruction]: sidebarWidth > 245,
                      })}
                    >
                      <FormattedMessage
                        id={`workspace.ai.${instructionValue}`}
                      />
                    </span>
                  )}
                </div>
                <div
                  className={styles.addSourcesContainer}
                  onClick={handleEditAIBehaviour}
                >
                  <span
                    className={classNames(styles.text, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <FormattedMessage id="workspace.edit.title" />
                  </span>
                  <span className="flex-shrink-0">
                    <EditIcon theme={theme} />
                  </span>
                </div>
              </div>
              {currentConversation?.description && (
                <div
                  className={classNames(styles.optionsContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentConversation?.description.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                </div>
              )}

              <div className={styles.knowledgeWrapper}>
                <div
                  className={classNames(styles.headContainer, {
                    [styles.Adjustposition]: sidebarWidth > 600,
                    [styles.Adjustment]: sidebarWidth > 413,
                  })}
                >
                  <span
                    className={classNames(styles.heading, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <FormattedMessage id="workspace.uploadedfiles.head" />
                  </span>
                  <span
                    className={classNames(styles.countSources, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    {" "}
                    <FormattedMessage
                      id="workspace.addsource.subhead"
                      values={{ used: loading? 0 : wsUploadedFiles.length, max: maxSources }}
                    />
                  </span>
                </div>             
                <div
                  className={styles.addSourcesContainer}
                  onClick={handleAddSource}
                >
                  <span
                    className={classNames(styles.text, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <FormattedMessage id="workspace.addsources.title" />
                  </span>
                  <span className="flex-shrink-0">
                    {" "}
                    <PlusIcon theme={theme} />{" "}
                  </span>
                </div>
              </div>
              <UploadedConversationFiles loading={loading} setLoading={setLoading}/>
            </div>
          )}
        </div>

        <AnimatedModal
          isOpen={editAIBehaviour}
          onClose={() => setEditAIBehaviour(false)}
        >
          <ConfigureAIBehaviour
            formRef={formRef}
            EditIntructionModal={true}
            onClose={() => setEditAIBehaviour(false)}
          />
          <div className="flex justify-end pt-4">
          <RippleDurationButton
              onClick={handleUpdateDescription}
              isloading={isloading}
            >
              <span className={styles.btn}>
                <FormattedMessage id="workspace.context.edit.btn" />
              </span>
            </RippleDurationButton>
          </div>
        </AnimatedModal>

        <AnimatedModal isOpen={addSource} onClose={() => setAddSource(false)}>
          <AddSource onClose={() => setAddSource(false)} />
        </AnimatedModal>

        {/* {editAIBehaviour && (
                <Modal onClose={() => setEditAIBehaviour(false)} isPadding workSpace>
                    <ConfigureAIBehaviour formRef={formRef} EditIntructionModal={true} onClose={() => setEditAIBehaviour(false)} />
                    <div className="flex justify-end pt-4">
                        <DurationButton onClick={handleUpdateDescription} isloading={isloading}>
                            <span className={styles.btn}>
                                <FormattedMessage id="workspace.context.edit.btn" />
                            </span>
                        </DurationButton>
                    </div>
                </Modal>
            )} */}

        {/* {addSource && <AddSource setAddSource={setAddSource} />} */}
      </>
    );
}