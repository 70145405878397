import { memo, Fragment, useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import styles from "./answerContainer.module.scss";
import classNames from "classnames";
import { UserName } from "../userName";

import { IMessage, IShareChatMessage } from "redux/actions";
import { useSelector } from "redux/hooks";
import React from "react";

import useRouter from "hooks/useRouter";
import FileLink from "./FileLink";
import { ImagePreview } from "./ImagePreview";
import { QuestionContainer } from "./questionContainer";
import { Tooltip } from "components";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyIcon, ThCopyIcon } from "../icons";
import ChatEditIcon, { ThEditIcon } from "../icons/ChatEditIcon";
import { RIPPLE_COLORS } from "utils/constants";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";
interface IProps {
  message?: IMessage;
  messageRef?: React.LegacyRef<HTMLDivElement>;
  shareMessage?: IShareChatMessage;
  shareChat?: boolean;
  chatItem?: IMessage[];
  setHighlighted?: React.Dispatch<React.SetStateAction<boolean>>;
  highlighted?: boolean;
  handleSubmit?: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  setImage?: React.Dispatch<React.SetStateAction<string[]>>;
  setFile?: React.Dispatch<React.SetStateAction<string[]>>;
  assistantMessage?: boolean;
  threads?: boolean;
}

export const Question = memo(
  ({
    message,
    messageRef,
    shareMessage,
    shareChat,
    handleSubmit,
    setMessage,
    setImage,
    setFile,
    assistantMessage,
    threads,
  }: IProps) => {
    const {
      userDetail,
      theme
    } = useSelector((state) => state.authReducer);
    const { searchWord } = useSelector((state) => state.chatReducer);
    const { includeRoute } = useRouter();

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const isHighlighted = searchWord !== "";
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
      setLoading(false);
    };
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      textAreaRef.current?.focus();

      if (textAreaRef.current) {
        const { value } = textAreaRef.current;
        textAreaRef.current.setSelectionRange(value.length, value.length);
      }
      return () => {
        setMessage && setMessage("");
      };
    }, [isEditMode === true]);

    useEffect(() => {
      const intervalID = setTimeout(() => {
        if (isCopied) setIsCopied(false);
      }, 500);

      return () => clearInterval(intervalID);
    }, [isCopied]);

    const isShareChat = includeRoute("/share-chat/");

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit && handleSubmit();
        setIsEditMode(false);
      }
    };

    useEffect(() => {
      if (isEditMode === true) {
        if (message && message?.images && message?.images.length > 0) {
          const images = message?.images.map((img) => {
            return img.path
          })
          setImage!(images);
        }
        else if (message?.files && message.files.length > 0) {
          const files = message.files.map((file) => file.path).filter((path): path is string => path !== null);
          setFile!(files);
        }
        else {
          setFile!([]);
          setImage!([]);
        }
      }
    }, [isEditMode === true])

    const themeColors = theme === 'dark' ? RIPPLE_COLORS.dark : RIPPLE_COLORS.light;

    return (
      <Fragment>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{ display: "flex", alignItems: "end", flexDirection: "column" }}
        >
          <div ref={messageRef} className={classNames(styles.QuestionContainer, {
            [styles.threads]: threads,
            "font-inter": threads,
          })}>
            <div className={classNames(styles.questionleftContainer, {
              [styles.threads]: threads,
            })}>

              {((message?.files?.length ?? 0) > 0 || (shareMessage?.files?.length ?? 0) > 0) ? (
                <div className={classNames(styles.container, styles.additionalContainer, {
                  [styles.sharedContainer]: (shareMessage?.files?.length ?? 0) > 0 && shareMessage?.content
                })}>
                  <FileLink file={message?.files || shareMessage?.files || []} contentFile={true} messageId={message?.id ?? 1} threads={threads} />
                </div>
              ) : (
                (((message?.images?.length ?? 0) > 0 && !shareChat) || ((shareMessage?.images?.length ?? 0) > 0 && isShareChat)) ? (
                  <div className={classNames(styles.imagesContainer, styles.additionalimages, {
                    [styles.imageContent]: true,
                    [styles.sharedimageContainer]: (message?.images?.length ?? 0) > 0 && !shareChat,
                  })}>
                    <ImagePreview images={message?.images || shareMessage?.images || []} loading={loading} handleImageLoad={handleImageLoad} />
                  </div>
                ) :
                  <QuestionContainer isEditMode={isEditMode}
                    message={message}
                    searchQuery={searchWord}
                    setMessage={setMessage}
                    shareChat={shareChat}
                    shareMessage={shareMessage}
                    textAreaRef={textAreaRef}
                    setIsCopied={setIsCopied} isShareChat={isShareChat}
                    isHighlighted={isHighlighted}
                    setIsEditMode={setIsEditMode}
                    isCopied={isCopied} handleKeyDown={handleKeyDown} threads={threads}
                    setImage={setImage}
                    setFile={setFile}
                    assistantMessage={assistantMessage} />
              )}
              <span className="shrink-0">
                <UserName
                  name={userDetail?.user?.name ?? ""}
                  email={userDetail?.user?.email ?? ""}
                  threads={threads}
                />
              </span>
            </div>
          </div>
          {(message?.content || shareMessage?.content) && ((message?.files?.length ?? 0) > 0 || ((shareMessage?.files?.length ?? 0) > 0) || (((message?.images?.length ?? 0)) > 0) || ((shareMessage?.images?.length ?? 0) > 0)) && !assistantMessage && (
            <QuestionContainer isEditMode={isEditMode}
              message={message}
              searchQuery={searchWord}
              setMessage={setMessage}
              shareChat={shareChat}
              shareMessage={shareMessage}
              textAreaRef={textAreaRef}
              setIsCopied={setIsCopied}
              isShareChat={isShareChat}
              isHighlighted={isHighlighted}
              setIsEditMode={setIsEditMode}
              isCopied={isCopied} handleKeyDown={handleKeyDown} threads={threads} 
              setImage={setImage}
              setFile={setFile}
              assistantMessage={assistantMessage}/>
          )}

          {!threads && (
            <>
              {!isEditMode && (
                ((message?.content || ((message?.files?.length ?? 0) > 0 && message?.content)) || (((message?.images?.length ?? 0)) > 0 && message?.content) && !assistantMessage) && (
                  <div className={` flex gap-[10px] ${classNames(styles.questionIconContainer, {
                    [styles.threads]: threads
                  })}`}>
                    {!shareChat && (

                      <Tooltip
                        regenrate={true}
                        tooltipAdjustement={true}
                        AdjustregenrateTooltip={true}
                        control={
                          <CopyToClipboard
                            text={String(message?.content)}
                            onCopy={() => setIsCopied(true)}
                          >
                           <RippleIconButton
                              color={themeColors.default} className={classNames({
                              [styles.EditButton]: !threads,
                              [styles.light]: theme === "light",
                              [styles.dark]: theme === "dark",
                              [styles.CopyIconContainer]: !threads,
                              "cursor-pointer": threads,
                            })} >
                              <div className={`${styles.copyIcon} ${"cursor-pointer flex justify-center items-center"}`}
                              >
                                {threads ? <ThCopyIcon /> : <CopyIcon theme={theme} />}
                              </div>
                            </RippleIconButton>
                          </CopyToClipboard>
                        }
                        placement="top"
                        theme="light"
                      >
                        <div>
                          <FormattedMessage
                            id={
                              isCopied
                                ? "answer.tooltip.copied"
                                : "answer.tooltip.copy"
                            }
                          />
                        </div>
                      </Tooltip>
                    )}
                    {!shareMessage?.content && (
                      <Tooltip
                        regenrate={true}
                        tooltipAdjustement={true}
                        AdjustregenrateTooltip={true}
                        control={
                        <RippleIconButton
                          color={themeColors.default}  className={classNames({
                            [styles.EditButton]: !threads,
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                            "cursor-pointer": threads,
                          })} onClick={() => setIsEditMode(!isEditMode)} >
                            <div
                              className={`${styles.editIcon} ${" flex justify-center items-center"}`}
                            >
                              {threads ? <ThEditIcon /> : <ChatEditIcon theme={theme} />}
                            </div>
                          </RippleIconButton>
                        }
                        placement="top"
                        theme="light"
                      >
                        <div>
                          <FormattedMessage id={"answer.tooltip.edit"} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                ))}

             
            </>
          )}
           {isEditMode && (
                <div className={classNames(styles.actionContainer, {
                  // [styles.threads]: threads
                })}>
                  <div className="flex items-center justify-end gap-2 w-full max-w-[800px]">
                    <div className="flex items-center gap-2">
                    <RippleIconButton
                        color={themeColors.default}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit && handleSubmit();
                          setIsEditMode(false);
                        }}
                        style={{
                          background:
                            "linear-gradient(92.31deg, #8C49F7 0%, #6C53FF 100%)",
                        }}
                        className="px-[20px] py-[10px] text-[12px] md:text-[15px] font-[600] text-white rounded-[100px] cursor-pointer"
                      >
                        <FormattedMessage id={"answer.edit.save"} />
                      </RippleIconButton>
                      <RippleIconButton
                      color={themeColors.default}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsEditMode(false);
                        }}
                        style={{ border: "1px solid rgba(31, 31, 31, 0.2)" }}
                        className="px-[20px] py-[10px] bg-white text-[12px] md:text-[15px] font-[600] text-black rounded-[100px] cursor-pointer"
                      >
                        <FormattedMessage id={"answer.edit.cancel"} />
                      </RippleIconButton>
                    </div>
                  </div>
                </div>
              )}
        </form>
      </Fragment >
    );
  }
);