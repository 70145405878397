
import { ErrorModal } from "pages/ChatPage/components/ErrorModal";
import { FileSizeLimitModal } from "pages/ChatPage/components/fileSizeLimitModal";

interface IProp {
    errorModal: { message: string; show: boolean };
    setErrorModal: (error: { message: string; show: boolean }) => void;
    messageId: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ErrorHandler = ({
    errorModal,
    setErrorModal,
    messageId,
    onConfirm,
    onCancel,
}: IProp) => {
  return (
    <>
      {errorModal.show && (
          <ErrorModal
            message={errorModal.message}
            uploadURL={true}
            onClose={() => setErrorModal({ message: "", show: false })}
          />
      )}

      {messageId && (
        <FileSizeLimitModal
          messageId={messageId}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};
