import { MainView } from "views/layout/MainView";
import { FormattedMessage } from "react-intl";
import styles from "./workspace.module.scss";
import { ArrowIcon } from "./components/ArrowIcon";
import classNames from "classnames";
import {
  EmptyCurrentChat,
  EmptyThreadChat,
  NewChatThreadWS,
  RemoveWSFiles,
  setEmptyThreadsHistory,
  setInProgressWS,
  setShowCreateWorkspace,
  setWsUploadedFile,
  ShowWorkspace,
} from "redux/actions/workSpace";
import { useSelector } from "redux/hooks";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import { Spinner } from "components";
import { ChatType, IWorkspace, setChatModel, EChatType } from "redux/actions";
import { CRWorkspace } from "./components/CreateWorkspace/CRWorkspace";
import Drawer from "components/Drawer/Drawer";
import { WorkspaceCard } from "./components/WorkspaceCards";
import { useThreadsHistoryContext } from "hooks/ThreadHistoryContext";
import { ChatSettingModal } from "pages/ChatPage/components/chatSettingModal";
import _ from "lodash";
import { useFileUpload } from "hooks/useFileUpload";
import { ErrorHandler } from "pages/Workspaces/components/ErrorHandler/ErrorHandler";
import { DragDropModal } from "./pages/WorkspaceConversation.tsx/Component/Drag&DropModal";
import { EmptyObj } from "utils/fileProcessing";

export const Workspace = () => {
  const {
    chatSettings,
    setChatSetting,
    settings,
    setSettings,
    loadingSetting,
  } = useThreadsHistoryContext();
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const { showCreateWorkspace, workSpaces, isModalOpen, openUploadURLModel } = useSelector(
    (state) => state.workSpaceReducer
  );
  
    const { newMessages, messages } = useSelector((state) => state.chatReducer);
  
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const { gptModel } = useSelector((state) => state.authReducer);
  // Workspace limits based on subscription
  const [messageLimit, setMessageLimit] = useState<string>("");
  const [isloading, setIsloading] = useState<boolean>(true);

  const [isDrag, setIsDrag] = useState<boolean>(false);
  const { errorModal, setErrorModal, messageId, setMessageId, handleDrop } = useFileUpload(false);
  const { push } = useRouter();
  const plan = userDetail?.user.activeSubscription?.name;

  const ResetcurrentConversation = {
    id: 0,
    created_at: '',
    description: '',
    instructions: '',
    name: '',
    settings: '',
    team_id: 0,
    updated_at: '',
    emoji: '',
  }

  const getWorkspaceLimit = () => {
    switch (plan) {
      case "Free":
        return 1;
      case "Standard":
      case "Professional":
        return 3;
      case "Team":
        return 5;
      case "Business":
        return Infinity;
      default:
        return 0;
    }
  };

  const workspaceLimit = getWorkspaceLimit();

  const handleResetStates = () => {
    RemoveWSFiles();
    setInProgressWS("", "", "", "");
  };

  const handleLinkClick = () => {
    if (workSpaces.length >= workspaceLimit) {
      setMessageLimit("workspace.limit.reached"); // Show FileSizeLimitModal
    } else {
      setInProgressWS("", "", "", "");
      setShowCreateWorkspace(true); // Open CreateWorkspace modal
    }
    handleResetStates();
    setWsUploadedFile();
  };

  const handleConversation = (id: number) => {
    handleResetStates();
    setEmptyThreadsHistory();
    NewChatThreadWS(id);
    push(`/${RoutePaths.WorkspaceHistory}/${id}`);
  };

  const handleClose = () => {
    setShowCreateWorkspace(false);
  };

  const confettiBall = String.fromCodePoint(0x1f38a);

  useEffectOnce(() => {
    EmptyThreadChat(EmptyObj);
    EmptyCurrentChat(ResetcurrentConversation);
    ShowWorkspace().finally(() => setIsloading(false)); // Set loading false after API response
  });

  const isAllowUploadFile = useMemo(() => {
  
    if (gptModel?.type.includes(ChatType.image)) {
      return false;
    }
    if( openUploadURLModel) {
      return false;
    }
    if(isModalOpen) return true;
    return true;
  }, [gptModel, newMessages.length, messages]);
    
  const handleUpdateModel = (modelType: EChatType) => {
    let index = chatModels.findIndex(
      (model) => model.type.includes(modelType) && model.attributes?.default === true
    );
    if (index >= 0) {
      if ((_.isEqual(gptModel?.type, ["document", "text"]) || (gptModel?.type.includes("text") && gptModel?.type.includes("document")))) {
      } else {
        const imageGPTModel = localStorage.getItem("documentChatGptModel");
        const selectedModel = imageGPTModel
          ? JSON.parse(imageGPTModel)
          : chatModels[index];
        localStorage.setItem(
          "GptModel",
          imageGPTModel || JSON.stringify(chatModels[index])
        );
        setChatModel(selectedModel);
      }
    }
  };

  return (
    <>
      <MainView setIsDrag={setIsDrag}
        updateChatModel={handleUpdateModel} 
        isAllowUploadFile={isAllowUploadFile} setChatSetting={setChatSetting}>
        <div
          className={`mx-auto font-inter mt-4 mb-4 w-full flex-0 px-4 md:pl-8 lg:mt-6 md:pr-8 2xl:pr-14 ${isloading ? "h-[100%]" : ""}`}
        >
          {isloading ? (
            <div className="flex justify-center items-center h-[100%]">
              <Spinner />
            </div>
          ) : (
            <>
              <div className={styles.headContainer}>
                <div className="text-[18px] font-inter font-medium leading-[22px] dark:text-white text-[#171717] sm:text-[20px]">
                  <FormattedMessage id="workspaces.title" />
                </div>

                {workSpaces.length > 0 && (
                  <h5
                    className={classNames(styles.countWorkspaces, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    (
                    {plan === "Free" ? (
                      <FormattedMessage
                        id="workspace.limit"
                        values={{ count: workSpaces.length, limit: 1 }}
                      />
                    ) : (
                      <FormattedMessage
                        id="workspace.limits"
                        values={{
                          count: workSpaces.length,
                          limit:
                            workspaceLimit === Infinity ? "∞" : workspaceLimit,
                        }}
                      />
                    )}
                    )
                  </h5>
                )}
              </div>
              <div
                className={`grid grid-cols-[repeat(auto-fill,minmax(278px,1fr))] gap-[12px] mt-6 overflow-hidden`}
              >
                <div
                  className={classNames(styles.createContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                  onClick={handleLinkClick}
                >
                  {confettiBall}
                  <div
                    className={classNames(styles.statement, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    {" "}
                    <FormattedMessage id="workspace.create.text" />{" "}
                  </div>
                  <div className={styles.linkContainer}>
                    <div
                      className={classNames(styles.link, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      <FormattedMessage id="workspace.create.link" />{" "}
                    </div>
                    <ArrowIcon theme={theme} />
                  </div>
                </div>
                {workSpaces &&
                  workSpaces.map((space: IWorkspace) => (
                    <WorkspaceCard key={space.id} space={space} handleConversation={handleConversation} />
                  ))}
              </div>
            </>
          )}
        </div>
       
      </MainView>
      <Drawer
        open={showCreateWorkspace}
        onClose={() => setShowCreateWorkspace(false)}
      >
        <CRWorkspace
          onClose={handleClose}
          showCreateWorkspace={showCreateWorkspace}
        />
      </Drawer>
      {chatSettings && (
          <ChatSettingModal
            onClose={() => setChatSetting(false)}
            loadingSetting={loadingSetting}
            settings={settings}
            setSettings={setSettings}
          />
        )}
       {isDrag && (
              <DragDropModal
                handleClose={() => setIsDrag(false)}
                handleDrop={async (event: React.DragEvent<HTMLDivElement>) => 
                  await handleDrop(event, isModalOpen)
                 }
              />
            )}

            <ErrorHandler
              errorModal={errorModal}
              setErrorModal={setErrorModal}
              messageId={messageId ? messageId : messageLimit}
              onConfirm={() => push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)}
              onCancel={() => {
              setMessageId("")
              setMessageLimit("")}}
            />
    </>
  );
};
