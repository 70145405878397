export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
    >
      <path
        d="M10.7062 4.70627C11.0968 4.31565 11.0968 3.68127 10.7062 3.29065C10.3155 2.90002 9.68115 2.90002 9.29053 3.29065L5.9999 6.5844L2.70615 3.29377C2.31553 2.90315 1.68115 2.90315 1.29053 3.29377C0.899902 3.6844 0.899902 4.31877 1.29053 4.7094L4.58428 8.00002L1.29365 11.2938C0.903027 11.6844 0.903027 12.3188 1.29365 12.7094C1.68428 13.1 2.31865 13.1 2.70928 12.7094L5.9999 9.41565L9.29365 12.7063C9.68428 13.0969 10.3187 13.0969 10.7093 12.7063C11.0999 12.3156 11.0999 11.6813 10.7093 11.2906L7.41553 8.00002L10.7062 4.70627Z"
        fill="currentColor"
      />
    </svg>
  );
}