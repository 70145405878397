import React, { 
  useRef, 
  useCallback, 
  useEffect, 
  RefObject, 
  MouseEvent, 
  cloneElement, 
  isValidElement,
  forwardRef,
} from 'react';
import useRouter from "hooks/useRouter";

interface RippleOptions {
  color?: string;
  duration?: number;
  disabled?: boolean;
}

interface RippleHookResult<T extends HTMLElement> {
  elementRef: RefObject<T>;
  rippleProps: {
    ref: RefObject<T>;
    onMouseDown: (e: MouseEvent<T>) => void;
  };
}

export const useRippleEffect = <T extends HTMLDivElement>({
  color,
  duration = 600,
  disabled = false,
}: RippleOptions = {}): RippleHookResult<T> => {
  
  const elementRef = useRef<T>(null);
  const { pathname } = useRouter();
  const workSpace = pathname.includes("workspaces");

  const createRipple = useCallback((event: MouseEvent<T>) => {
    if (disabled || !workSpace) return;
    
    const element = elementRef.current;
    if (!element) return;
    
    const rect = element.getBoundingClientRect();
    
    const rippleColor = color || 
      getComputedStyle(element).getPropertyValue('--ripple-color') || 
      'rgba(0,0,0,0.3)';
    
    let rippleContainer = element.querySelector('.ripple-container') as HTMLElement;
    
    if (!rippleContainer) {
      rippleContainer = document.createElement('div');
      rippleContainer.className = 'ripple-container';
      rippleContainer.style.position = 'absolute';
      rippleContainer.style.top = '0';
      rippleContainer.style.left = '0';
      rippleContainer.style.right = '0';
      rippleContainer.style.bottom = '0';
      rippleContainer.style.overflow = 'hidden';
      rippleContainer.style.pointerEvents = 'none'; // Make sure it doesn't interfere with clicking
      element.appendChild(rippleContainer);
    }
    
    // Create ripple element
    const ripple = document.createElement('span');
    ripple.style.position = 'absolute';
    ripple.style.borderRadius = '50%';
    ripple.style.transform = 'scale(0)';
    ripple.style.backgroundColor = rippleColor;
    ripple.style.pointerEvents = 'none';
    
    // Calculate size (larger of width or height * 2)
    const size = Math.max(rect.width, rect.height) * 2;
    
    // Position the ripple
    ripple.style.width = `${size}px`;
    ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - rect.left - (size / 2)}px`;
    ripple.style.top = `${event.clientY - rect.top - (size / 2)}px`;
    
    // Add animation
    ripple.style.animation = `ripple-effect ${duration}ms linear`;
    
    // Clean up existing ripples
    const existingRipples = rippleContainer.getElementsByClassName('ripple');
    Array.from(existingRipples).forEach(el => el.remove());
    
    // Add classes and append to container
    ripple.classList.add('ripple');
    rippleContainer.appendChild(ripple);
    
    // Remove ripple after animation
    setTimeout(() => {
      if (ripple.parentNode === rippleContainer) {
        rippleContainer.removeChild(ripple);
      }
    }, duration);
  }, [color, duration, disabled]);
  
  // Ensure global styles for ripple
  useEffect(() => {
    // Add global style for ripple animation
    const styleId = 'ripple-global-style';
    if (!document.getElementById(styleId)) {
      const style = document.createElement('style');
      style.id = styleId;
      style.textContent = `
        @keyframes ripple-effect {
          to {
            transform: scale(4);
            opacity: 0;
          }
        }
        .ripple {
          opacity: 0.5;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);
  
  return {
    elementRef,
    rippleProps: {
      ref: elementRef,
      onMouseDown: disabled ? () => {} : createRipple, 
    }
  };
};

interface RippleIconButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  disabled?: boolean;
  regenerate?: boolean;
  audioController?: boolean;
  uploadfile?: boolean;
  dropdown?: boolean;
  noPaddingBorder?: boolean;
}

export const RippleIconButton = forwardRef<HTMLDivElement, RippleIconButtonProps>(({ 
  children, 
  className = '', 
  color, 
  disabled = false,
  regenerate,
  audioController,
  uploadfile,
  dropdown,
  noPaddingBorder,
  ...props 
}, ref) => {
  const { rippleProps, elementRef } = useRippleEffect<HTMLDivElement>({ 
    color, 
    disabled,
  });
  
  // Combine refs
  const combinedRef = React.useMemo(() => {
    return (node: HTMLDivElement | null) => {
      // Update internal ref using type assertion to bypass readonly
      (elementRef as React.MutableRefObject<HTMLDivElement | null>).current = node;
      
      // Update forwarded ref
      if (ref) {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref && 'current' in ref) {
          (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
        }
      }
    };
  }, [ref, elementRef]);

  // Ensure a single, cloneable child
  const childElement = React.Children.only(
    isValidElement(children) 
      ? children 
      : <div>{children}</div>
  );

  // Safely clone element with additional props
  const childWithRipple = React.useMemo(() => {
    const existingClassName = (childElement.props as any).className || '';
    return cloneElement(childElement, {
      ...rippleProps,
      className: `${existingClassName} ${regenerate ? "flex gap-[8px] items-center justify-center" : ""} relative overflow-hidden`.trim(),
    } as React.Attributes);
  }, [childElement, rippleProps]);
  
  return (
    <div
    ref={combinedRef}
    onMouseDown={rippleProps.onMouseDown}
    className={`relative overflow-hidden cursor-pointer flex items-center
      ${dropdown ? "py-3" : "justify-center"}
      ${noPaddingBorder ? "" : uploadfile ? "" : "rounded-full"}
      ${noPaddingBorder ? "" : audioController || uploadfile || regenerate ? "" : "p-2"}
      ${className}`.trim()}
    {...props}
  >
      {childWithRipple}
    </div>
  );
});
