import _ from "lodash";
import { TYPES } from "../types";
import { produce } from "immer";
import { IUploadedFile, IWorkspace, UploadFile, InprogressWs, IChatContentUploadFile, IFilePreview, TChatHistoryResponse, selectedThread } from "redux/actions";

export interface IWorkSpaceReducerState {
  showCreateWorkspace: boolean;
  workSpaceFiles: UploadFile[];
  workSpaces: IWorkspace[];
  wsUploadedFiles: IUploadedFile[];
  deletedFileId: string | null;
  inProgressWorkspace?: InprogressWs;
  currentConversation?: IWorkspace;
  chatContentUploadingFiles: IChatContentUploadFile[];
  FilePreview: IFilePreview[];
  ThreadsHistory: TChatHistoryResponse;
  msgGeneration: boolean;
  isGeneRep: boolean;
  controller: AbortController | null;
  selectedThread: selectedThread;
  getThreadHis: boolean;
  loadTopicInfo: boolean;
  newChatThread: IWorkspace;
  newChatContentUploadingFiles: IChatContentUploadFile[];
  isModalOpen: boolean;
  openUploadURLModel: boolean;
}

const state: IWorkSpaceReducerState = {
  showCreateWorkspace: false,
  workSpaceFiles: [],
  workSpaces: [],
  wsUploadedFiles: [],
  deletedFileId: null,
  inProgressWorkspace: {},
  currentConversation: {
    id: 0,
    created_at: '',
    description: '',
    instructions: '',
    name: '',
    settings: '',
    team_id: 0,
    updated_at: '',
    emoji: '',
  },
  chatContentUploadingFiles: [],
  FilePreview: [],
  ThreadsHistory: {
    data: {
      current_page: 1,
      last_page: 1,
      total: 0,
      data: [], // Empty initially
    },
  },
  msgGeneration: false,
  isGeneRep: false,
  controller: null,
  selectedThread: {
    ws_name: "",
    emoji: "",
    thread_name: "",
    thread_id: 0,
    ws_id: 0,
  },
  getThreadHis: false,
  loadTopicInfo: false,
  newChatThread: {
    id: 0,
    created_at: '',
    description: '',
    instructions: '',
    name: '',
    settings: '',
    team_id: 0,
    updated_at: '',
    emoji: '',
  },
  newChatContentUploadingFiles: [],
  isModalOpen: false,
  openUploadURLModel: false,
};

export const workSpaceReducer = (
  mState = { ...state },
  action: any
): IWorkSpaceReducerState => {
  switch (action.type) {
    case TYPES.CREATE_WORKSPACE:
      return produce(mState, (dState) => {
        dState.showCreateWorkspace = action.payload;
      });

    case TYPES.ADD_FILE:
      return produce(mState, (dState) => {
        if (!dState.workSpaceFiles) {
          dState.workSpaceFiles = [];
        }
        dState.workSpaceFiles.unshift(action.payload);
      });

    case TYPES.UPDATE_FILE_STATUS:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => { // This will pause execution when this line runs
            return f.id === action.payload.id;
          }
        );
        if (file) {
          file.status = action.payload.status;
        }
      });

    case TYPES.REMOVE_FILE:
      return produce(mState, (dState) => {
        if (!action.payload) return mState; // Ensure payload exists

        const payloadAsNumber = Number(action.payload);

        dState.workSpaceFiles = (dState.workSpaceFiles || []).filter((file) => {
          return file.id !== action.payload && file.uploaded_id !== payloadAsNumber;
        });

        dState.deletedFileId = action.payload;
      });

    case TYPES.EMPTY_FILE:
      return produce(mState, (dState) => {
        dState.deletedFileId = action.payload;
      })

    case TYPES.UPDATE_FILE_S3_LINK:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.status = action.payload.status;
          file.S3Link = action.payload.S3Link;
        }
      });

    case TYPES.UPDATE_FILE_ERR_MESSAGE:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.status = action.payload.status;
          file.errorMessage = action.payload.errorMessage;
        }
      });

    case TYPES.UPDATE_FILE_PROGRESS:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.progress = action.payload.progress;
        }
      });

    case TYPES.UPDATE_FILE_SIZE:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.fileSize = action.payload.fileSize;
        }
      });

    case TYPES.ADD_WORKSPACE:
      return produce(mState, (dState) => {
        dState.workSpaces = [action.payload, ...dState.workSpaces];
      })

    case TYPES.WS_UPLOADED_FILES:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles = [...action.payload];
      });


    case TYPES.WORKSPACE_UPLOADED_FILES:
      return produce(mState, (dState) => {
        dState.workSpaceFiles = [...action.payload];
      });

    case TYPES.REMOVE_WS_FILES:
      return produce(mState, (dState) => {
        dState.workSpaceFiles = action.pyload;
      });

    case TYPES.UPDATE_UPLOADED_FILE:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles = [...dState.wsUploadedFiles, action.payload];
      });

    case TYPES.WS_UPLOADED_FILES_REMOVE:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles = dState.wsUploadedFiles.filter(
          (file) => file.id !== action.payload.id
        );
      });

    case TYPES.ADD_FILE_UPLOADED:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles.unshift(action.payload);
      });

    case TYPES.EMPTY_WS_UPLOADED_FILES:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles = action.payload;
      })

    case TYPES.UPLOADED_FILE_ID:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.uploaded_id = action.payload.uploaded_id;
          file.updated_at = action.payload.updated_at;
          file.created_at = action.payload.created_at;
        }
      });

    case TYPES.WS_CREATED:
      return produce(mState, (dState) => {
        dState.workSpaces = [...action.payload];
      });

    case TYPES.WS_Updated:
      return produce(mState, (dState) => {
        const workspace = dState.workSpaces.find(
          (ws) => ws.id === action.payload.id
        );
        if (workspace) {
          workspace.description = action.payload.description;
          workspace.instructions = action.payload.instructions;
          workspace.name = action.payload.name;
          workspace.emoji = action.payload.emoji;
        }
      });
    case TYPES.WS_PROGESSVALUES:
      return produce(mState, (dState) => {
        dState.inProgressWorkspace = {
          name: action.payload.name,
          emoji: action.payload.emoji,
          description: action.payload.description,
          instructions: action.payload.instructions,
        };
      });

    case TYPES.CURRENT_CONVERSATION:
      return produce(mState, (dState) => {
        dState.currentConversation = dState.workSpaces?.find((conversation) => {
          return conversation.id === action.payload.id;
        });
      });

    case TYPES.WS_NAMEUPDATED:
      return produce(mState, (dState) => {
        if (dState.currentConversation && dState.currentConversation.id === action.payload.id) {
          dState.currentConversation.name = action.payload.name;
          dState.currentConversation.description = action.payload.description;
          dState.currentConversation.instructions = action.payload.instructions;
        }
      });

    case TYPES.CHAT_CONTENT_UPLOADING_FILES:
      return produce(mState, (dState) => {
        if (!dState.chatContentUploadingFiles) {
          dState.chatContentUploadingFiles = [];
        }
        dState.chatContentUploadingFiles.unshift(action.payload);
      })

      case TYPES.CHAT_CONTENT_NEW_UPLOADING_FILES:
        return produce(mState, (dState) => {
          if (!dState.newChatContentUploadingFiles) {
            dState.newChatContentUploadingFiles = [];
          }
          dState.newChatContentUploadingFiles.unshift(action.payload);
        })

    case TYPES.CHAT_CONTENT_UPDATE_FILE_STATUS:
      return produce(mState, (dState) => {
        const file = dState.chatContentUploadingFiles.find(
          (f) => f.file === action.payload.file
        );
        if (file) {
          file.status = action.payload.status;
          file.S3Link = action.payload.S3Link;
        }
      });

      case TYPES.CHAT_CONTENT_UPDATE_NEW_FILE_STATUS:
        return produce(mState, (dState) => {
          const file = dState.chatContentUploadingFiles.find(
            (f) => f.file === action.payload.file
          );
          if (file) {
            file.status = action.payload.status;
            file.S3Link = action.payload.S3Link;
          }
        })


    case TYPES.FILE_PREVIEW_UPLOADING_FILES:
      return produce(mState, (dState) => {
        dState.FilePreview = [action.payload, ...dState.FilePreview];
      });

    case TYPES.DEL_PREVIEW_UPLOADING_FILES:
      return produce(mState, (dState) => {
        dState.FilePreview = dState.FilePreview.filter(
          (file) => file.file !== action.payload.file
        );
      });

    case TYPES.EMPTY_PREVIEW_UPLOADING_FILES:
      return produce(mState, (dState) => {
        dState.FilePreview = action.payload;
      });

    case TYPES.SAVE_THREADS_HISTORY:
      return produce(mState, (dState) => {
        dState.ThreadsHistory = action.payload;
      });

    case TYPES.DEL_CHAT_UPLOAD_FILE:
      return produce(mState, (dState) => {
        dState.chatContentUploadingFiles = dState.chatContentUploadingFiles.filter(
          (file) => file.id !== action.payload
        );
      })

    case TYPES.DEL_CHAT_UPLOAD_FILES:
      return produce(mState, (dState) => {
        dState.chatContentUploadingFiles = action.payload;
      })

    case TYPES.EMPTY_THREADS_HISTORY:
      return produce(mState, (dState) => {
        dState.ThreadsHistory = action.payload;
      })

            case TYPES.MESSAGE_GENERATION:
              return produce(mState, (dState) => {
                dState.msgGeneration = action.payload;
              })
            case TYPES.IS_GENERATE_RES:
              return produce(mState, (dState) => {
                dState.isGeneRep = action.payload;
              })
            
              
    case TYPES.SET_CONTROLLER:
      return produce(mState, (dState) => {
        dState.controller = action.payload;
      });

    case TYPES.CLEAR_CONTROLLER:
      return produce(mState, (dState) => {
        dState.controller = null;
      });
      case TYPES.DELETE_THREADS:
        return produce(mState, (dState) => {
            dState.ThreadsHistory.data.data = dState.ThreadsHistory.data.data.filter((chat) => {
                return chat.id !== action.payload.id // Exclude chats with matching IDs
            });
        });
        
        case TYPES.TH_TITLE_Updated:
          return produce(mState, (dState) => {
            const thread = dState.ThreadsHistory.data.data.find(
              (th) => th.id === action.payload.id
            );
            if (thread) {
              thread.chat.topic = action.payload.topic;
            }
            if (dState.selectedThread && dState.selectedThread.thread_id === action.payload.id) {
               dState.selectedThread.thread_name = action.payload.topic;
            }
          });

          case TYPES.THREAD_CHAT_HISTORY:
            return produce(mState, (dState) => {
              dState.selectedThread = action.payload;
          });

          case TYPES.GET_THREAD_HISTORY:
            return produce(mState, (dState) => {
              dState.getThreadHis= action.payload;
            })

        case TYPES.UPDATE_FILE_NAME:
          return produce(mState, (dState) => {
            const file = dState.workSpaceFiles.find(
              (f) => f.id === action.payload.id
            );
            if (file) {
              file.customName = action.payload.displayName;
              file.fileSize = action.payload.fileSize;
            }
            return dState;
          });
    

          case TYPES.GET_THREAD_TOPIC_INFO: 
            return produce(mState, (dState) => {
              dState.loadTopicInfo = action.payload;
          })

          case TYPES.NEW_CHAT_THREAD_WS:
            return produce(mState, (dState) => {
              const workspace = dState.workSpaces?.find((conversation) => {
                return conversation.id === action.payload.id;
              });
              
              if (workspace) {
                dState.newChatThread = workspace;
              }
            });

            case TYPES.EMPTY_NEW_CHAT_THREAD:
              return produce(mState, (dState) => {
                dState.newChatThread = action.payload;
              });

              case TYPES.EMPTY_CURRENT_CHAT_THREAD:
                return produce(mState, (dState) => {
                  dState.currentConversation = action.payload;
                });

              case TYPES.SET_NEW_CHAT_MODAL:
                return produce(mState, (dState) => {
                  dState.isModalOpen = action.payload;
                });

              case TYPES.DELETE_CHAT_CONTENT_BY_FILE:
                return produce(mState, (dState) => {
                      dState.chatContentUploadingFiles = dState.chatContentUploadingFiles.filter(
                        (file) => file.status !== "uploading" && file.status !== "validating"
                      );
                });

                case TYPES.OPEN_UPLOAD_URL_MODAL:
                  return produce(mState,(dState) => {
                    dState.openUploadURLModel = action.payload;
                  })

    default:
      return { ...mState };
  }
};
