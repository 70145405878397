import { useSelector } from "redux/hooks";
import {
  PDFIcon,
  TextIcon,
  PPTXIcon,
  PPTIcon,
  CSVIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  EMLIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import styles from "../../../components/uploadedFiles/uploadedFiles.module.scss";
import { DelPreviewFile, IFilePreview, PreviewableFile } from "redux/actions";
import { CrossIcon } from "pages/Workspaces/components/icons/CrossIcon";
import { useEffect, useState, ReactNode } from "react";
import { renderVideoName, renderAudioName, textExtensions } from "utils/constants";
// import { DurationButton } from "../InfoComponent";
import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import { Tooltip } from "components";
import FilePreviewModal from "pages/Workspaces/components/FilePreviewModal/FilePreviewModal";
import { AudioPreview } from "pages/ChatPage/components/searchField/AudioPreview";
import VideoPreview from "pages/ChatPage/components/searchField/VideoPreview";
import { ButtonContent } from "components/RippleEffect/RippleDurationButton";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";

interface IProp {
    handleSave: () => void;
}

export const FilesPreview = ({handleSave}: IProp) => {
  const { FilePreview } = useSelector((state) => state.workSpaceReducer);
  const { theme } = useSelector((state) => state.authReducer);
  const [previews, setPreviews] = useState<(string | null)[]>([]);
  // Add state for different preview types
  const [previewFile, setPreviewFile] = useState<PreviewableFile | null>(null);
  const [audioPreview, setAudioPreview] = useState<{ path: string; name: string } | null>(null);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<{ path: string; name: string } | null>(null);
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true);
  const [localFileUrls, setLocalFileUrls] = useState<{[key: string]: string}>({});


  // Setup z-index for PhotoView-Portal
  useEffect(() => {
    const adjustPhotoViewPortalZIndex = () => {
      const portals = document.getElementsByClassName('PhotoView-Portal');
      Array.from(portals).forEach(portal => {
        if (portal instanceof HTMLElement) {
          portal.style.zIndex = '90000';
          portal.style.position = 'absolute';
        }
      });
    };

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && 
            Array.from(mutation.addedNodes).some(node => 
              node instanceof HTMLElement && 
              (node.classList.contains('PhotoView-Portal') || 
               node.querySelector('.PhotoView-Portal')))) {
          adjustPhotoViewPortalZIndex();
          break; // Only need to adjust once per batch of mutations
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    adjustPhotoViewPortalZIndex();

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (FilePreview) {
      const filePreviews = FilePreview.map((uploadFile) => {
        const file = uploadFile.file;
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          return new Promise<string | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
          });
        }
        return Promise.resolve(null);
      });

      Promise.all(filePreviews).then(setPreviews);
    }
  }, [FilePreview]);


  // Cleanup local URLs when component unmounts
  useEffect(() => {
    return () => {
      // Revoke all blob URLs when component unmounts
      Object.values(localFileUrls).forEach(url => {
        if (url.startsWith('blob:')) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [localFileUrls]);

    // Create and cache a local URL for a file
    const getLocalFileUrl = (file: File) => {
      const fileId = `${file.name}-${file.size}-${file.lastModified}`;
      
      if (!localFileUrls[fileId]) {
        const url = URL.createObjectURL(file);
        setLocalFileUrls(prev => ({...prev, [fileId]: url}));
        return url;
      }
      
      return localFileUrls[fileId];
    };

  const getFormattedFileName = (file?: IFilePreview): string => {
    const fileName = file?.file?.name;

    if (!fileName) return "";

    return fileName.startsWith("website-")
      ? fileName.split("-").slice(2).join("-")
      : fileName;
  };

  const FILE_ICONS: { [key: string]: React.ComponentType } = {
    pdf: PDFIcon,
    "text/plain": TextIcon,
    presentation: PPTXIcon,
    "ms-powerpoint": PPTIcon,
    csv: CSVIcon,
    sheet: XLSXIcon,
    "ms-excel": XLSIcon,
    "application/x-subrip": SRTIcon,
    "message/rfc822": EMLIcon,
  };

  const getFileIcon = (fileType: string, fileName: string): ReactNode => {
    for (const key in FILE_ICONS) {
      if (fileType.includes(key)) {
        const IconComponent = FILE_ICONS[key as keyof typeof FILE_ICONS];
        return <IconComponent />;
      }
    }
    if (fileType === "" && fileName.endsWith(".srt")) {
      return <SRTIcon />;
    }

    const fileExt = fileName.split(".").pop()?.toLowerCase(); // Extract file extension

    if (fileExt && textExtensions.includes(fileExt)) {
      return (
        <div className={classNames(styles.loader, {
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
        })}>
          <span className="text-[8px] font-medium text-[#A09FA2]">
            {fileExt}
          </span>
        </div>
      );
    }
    
    return <DocIcon />;
  };
// Handle video preview
const handleVideoClick = (file: IFilePreview, e: React.MouseEvent) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  
  if (file.file.type.startsWith('video/')) {
    // Create object URL for local file
    const videoUrl = getLocalFileUrl(file.file);
    setSelectedVideo({
      path: videoUrl,
      name: file.file.name
    });
    setShowVideoPreview(true);
    setIsVideoLoading(true);
  }
};

const handleVideoLoad = () => {
  setIsVideoLoading(false);
};

const closeVideoPreview = () => {
  setShowVideoPreview(false);
  setSelectedVideo(null);
};

// Handle audio preview
const handleAudioPreview = (file: IFilePreview, e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  
  if (file.file.type.startsWith('audio/')) {
    // Create object URL for local file
    const audioUrl = getLocalFileUrl(file.file);
    setAudioPreview({
      path: audioUrl,
      name: file.file.name
    });
  }
};

const closeAudioPreview = () => {
  setAudioPreview(null);
};

// Handle document preview
const handleDocumentPreview = async (file: IFilePreview, e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  
  try {
    // Create URL for the file
    const fileUrl = getLocalFileUrl(file.file);
    
    // Create a preview file object that matches the interface
    const previewableFile: PreviewableFile = {
      file: file.file,
      S3Link: fileUrl,
      fileSize: file.file.size
    };
    
    setPreviewFile(previewableFile);
  } catch (error) {
    console.error("Failed to create preview:", error);
  }
};

const closeDocumentPreview = () => {
  setPreviewFile(null);
};

// General file preview handler
const handlePreviewFile = (file: IFilePreview, e: React.MouseEvent) => {
  if (file.file.type.startsWith('image/')) {
    // Images are handled by PhotoView
    return;
  } else if (file.file.type.startsWith('video/')) {
    handleVideoClick(file, e);
  } else if (file.file.type.startsWith('audio/')) {
    handleAudioPreview(file, e);
  } else {
    handleDocumentPreview(file, e);
  }
};
  
  if (!FilePreview || FilePreview.length === 0) return null;

  return (
    <>
     {/* Document Preview Modal */}
     {previewFile && (
        <FilePreviewModal 
          file={previewFile} 
          onClose={closeDocumentPreview} 
        />
      )}

      {/* Video Preview Modal */}
      {showVideoPreview && selectedVideo && (
        <VideoPreview 
          videos={[selectedVideo]} 
          loading={isVideoLoading}
          handleVideoLoad={handleVideoLoad}
          onClose={closeVideoPreview}
        />
      )}

      {/* Audio Preview Modal */}
      {audioPreview && (
        <AudioPreview 
          audio={audioPreview}
          onClose={closeAudioPreview}
        />
      )}

      <PhotoProvider maskOpacity={0.91}>
      <div
        className={classNames(styles.addSources, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.headContainer}>
          <h5
            className={classNames(styles.heading, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="workspace.uploadedfiles.head" />
          </h5>
        </div>
        {FilePreview &&
          FilePreview.map((file: IFilePreview, index) => {

            const isImage = file.file.type.startsWith("image/");
            const fileItem = (
            // return (
              <div
                key={index}
                className={classNames(styles.filesContainer, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })} onClick={(e) => {if(!isImage) handlePreviewFile(file, e)}}
              >
                <div className={styles.fileWrapper}>
                  {file.file.type.startsWith("image/") && previews[index] ? (
                    <img
                      src={previews[index] as string}
                      alt="Uploaded Preview"
                      width= "30px" height= "30px"
                    />
                  ) : file.file.type.startsWith("video/") ? (
                    <div className={classNames(styles.loader, {
                        [styles.light]: theme === 'light',
                        [styles.dark]: theme === 'dark',
                      })}>
                        <span className="text-[8px] font-medium text-[#A09FA2]">
                          {renderVideoName(file.file.type)}
                        </span>
                      </div>
                  ) : file.file.type.startsWith("audio/") ? (
                    <div className={classNames(styles.loader, {
                        [styles.light]: theme === 'light',
                        [styles.dark]: theme === 'dark',
                      })}>
                        <span className="text-[8px] font-medium text-[#A09FA2]">
                         { renderAudioName(file.file.type, file.file.name)}
                        </span>
                    </div>
                  ) : (
                    getFileIcon(file.file.type, file.file.name)
                  )}
                  <div className={styles.fileNameWrapper}>
                    <div
                      className={classNames(styles.fileName, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      {" "}
                      {getFormattedFileName(file)}
                    </div>
                    {file.errorMessage && (
                      <span className="text-[12px] font-normal leading-[15px] text-[#DC2626]">
                        <FormattedMessage id={file.errorMessage} />
                      </span>
                    )}
                  </div>
                  <div className={styles.icons}>
                    <span onClick={(e) => {
                      e.stopPropagation();
                      DelPreviewFile(file.file);
                      }}>
                      <CrossIcon />
                    </span>
                  </div>
                </div>
              </div>
            );
              
            return isImage && previews[index] ? (
              <Tooltip
                key={index}
                regenrate={true}
                control={
                  <PhotoView src={previews[index] as string}>
                    {fileItem}
                  </PhotoView>
                }
                placement="top"
                theme="light"
              >
                {file.file.name}
              </Tooltip>
            ) : (
              <Tooltip
                key={index}
                regenrate={true}
                control={fileItem}
                placement="top"
                theme="light"
              >
                {file.file.name}
              </Tooltip>
            );
          })}
      </div>
      </PhotoProvider>
      <div className="flex justify-end pt-4">
      <RippleIconButton
        uploadfile={true}
        color={'rgba(255, 255, 255, 0.5)'} onClick={handleSave}>
      <div className="relative overflow-hidden w-full h-full">
        <ButtonContent>
            <FormattedMessage id="prompt.save"/>
        </ButtonContent>
      </div>
       </RippleIconButton>
      </div>
    </>
  );
};