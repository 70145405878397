import { FormattedMessage } from "react-intl";
import styles from "../../FilePreviewModal.module.scss";

const LoadingPreview = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.spinner}></div>
    <p><FormattedMessage id="preview.loading" defaultMessage="Loading preview..." /></p>
  </div>
);

export default LoadingPreview;