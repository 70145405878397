export const UseWsIcon = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
  <g clip-path="url(#clip0_1490_2474)">
    <path d="M3.59375 0.9375H6.40625C6.49219 0.9375 6.5625 1.00781 6.5625 1.09375V1.875H3.4375V1.09375C3.4375 1.00781 3.50781 0.9375 3.59375 0.9375ZM2.5 1.09375V1.875H1.25C0.560547 1.875 0 2.43555 0 3.125V5H3.75H6.25H10V3.125C10 2.43555 9.43945 1.875 8.75 1.875H7.5V1.09375C7.5 0.490234 7.00977 0 6.40625 0H3.59375C2.99023 0 2.5 0.490234 2.5 1.09375ZM10 5.625H6.25V6.25C6.25 6.5957 5.9707 6.875 5.625 6.875H4.375C4.0293 6.875 3.75 6.5957 3.75 6.25V5.625H0V8.125C0 8.81445 0.560547 9.375 1.25 9.375H8.75C9.43945 9.375 10 8.81445 10 8.125V5.625Z" fill="#737373"/>
  </g>
  <defs>
    <clipPath id="clip0_1490_2474">
      <rect width="10" height="10" fill="white"/>
    </clipPath>
  </defs>
</svg>
    )
}