import { useState } from "react";
import { useSelector } from "redux/hooks";
import { DragEvent } from "react";
import { useFileUploader } from "utils/fileUploadS3";
import useRouter from "hooks/useRouter";
import {
  classifyFiles,
  hasInvalidVideoDuration,
  shouldPreventMultipleVideoUpload,
  validateFileTypes,
  validateMaxFileCount,
  hasInvalidAudioDuration,
  validateFile,
  validateImgFile,
} from "utils/fileService";
import { audioMaxCountReached, documentMaxCountReached, videoMaxCountReached } from "utils/chat";
import { ChatType, EChatType, IFilePreview, setChatModel, setFilePreview } from "redux/actions";
import { convertFileNamesToLowercase } from "utils/functions";
// import { store } from "redux/store";

export interface FileUploadHookResult {
  errorModal: {
    message: string;
    show: boolean;
  };
  setErrorModal: React.Dispatch<React.SetStateAction<{
    message: string;
    show: boolean;
  }>>;
  messageId: string;
  setMessageId: React.Dispatch<React.SetStateAction<string>>;
  handleDrop: (event: DragEvent<HTMLElement> | undefined, isUpload?: boolean) => Promise<void>;
  resetFileUpload: () => void;
  fileLimit: number;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>, isUpload?: boolean) => void;
  handleFileUpload: (files: FileList | null, isUpload?: boolean ) => Promise<void>;
}

export const useFileUpload = (chatContent?: boolean): FileUploadHookResult => {
  const { pathname } = useRouter();
  const { uploadToS3 } = useFileUploader();

  const { chatContentUploadingFiles, FilePreview, isModalOpen} = useSelector((state) => state.workSpaceReducer);
  const { userDetail, gptModel } = useSelector((state) => state.authReducer);
  const currentPlan = useSelector((state) => state.planSubscriptionReducer.activePlan);
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { newMessages, messages } = useSelector((state) => state.chatReducer);

  const [changeModel, setChangeModel] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>("");
  const [errorModal, setErrorModal] = useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });

  // const [fileUploadAllow, setAllowFile] = useState<boolean>(false);

  const history = pathname.includes("/workspaces/thread");

  const fileLimit =
    userDetail?.user.activeSubscription?.name === "Free"
      ? 10
      : userDetail?.user.activeSubscription?.name === "Standard"
        ? 50
        : 100;

  const claudAllowedFileSize = gptModel?.name.includes("GPT-4") ? 15 : 5;

  const setValidationError = (messageKey: string) => {
    setErrorModal({ message: messageKey, show: true });
  };

  const resetFileUpload = () => {
    // Implement reset logic here if needed
  };

  const validateAndSetModel = (chatType: EChatType, modelKey: string) => {
    const supportedModels = chatModels
      .filter((model) => model.type.includes(chatType))
      .map((model) => model.name);

    if (!supportedModels.includes(gptModel?.name ?? "")) {
      const storedModel = localStorage.getItem(modelKey);
      const selectedModel = storedModel
        ? JSON.parse(storedModel)
        : chatType==="image_chat"? chatModels[1] : chatModels[0];
      localStorage.setItem(modelKey, JSON.stringify(selectedModel));
      localStorage.setItem("GptModel", JSON.stringify(selectedModel));
      setChatModel(selectedModel);
    }
  };

  const handleFileUpload = async (files: FileList | null, isUpload? : boolean) => {
    if (!files?.length) return;
    // setAllowFile(isUpload? isUpload : false);
    if (!gptModel?.type?.includes(ChatType.document)) {
      setChangeModel(true);
      validateAndSetModel(ChatType.document, "documentChatGptModel");
    }

    const newFilesArray = Array.from(files);
    const { imageFiles, videoFiles, audioFiles, otherFiles } =
      classifyFiles(newFilesArray);

      const UploadFiles=  isUpload? chatContentUploadingFiles :  chatContent? FilePreview : chatContentUploadingFiles;

      if(chatContentUploadingFiles.length > 0 && chatContent) {
        const File_TYPE = newFilesArray[0].type.startsWith("image/") ? "image" : newFilesArray[0].type.startsWith("video/")? "video" : 
        newFilesArray[0].type.startsWith("audio/") ? "audio" : "document"; 

        if(chatContentUploadingFiles[0]?.fileType !== File_TYPE) {
          setValidationError("upload.file.validation.combine");
          return ;
        }
        const Total_files= chatContentUploadingFiles.length + newFilesArray.length + FilePreview.length; 
        if(Total_files > 10 && File_TYPE === "audio") {
          setValidationError("audioChat.file.fileLimit");
          return;
        } else if(Total_files > 1 && File_TYPE === "video") {
          setValidationError("validate.video.upload");
          return;
        } else if(Total_files > 20 && File_TYPE === "image") {
          setValidationError("ImageChat.file.fileLimit");
          return;
        } else if(Total_files > 5 && File_TYPE === "document") {
          setValidationError("documentChat.file.fileLimit");
          return;
        } 

      }

    const validationError = validateFileTypes(
      newFilesArray,
      setValidationError,
      newMessages,
      messages,
      history,
      UploadFiles
    );
    if (validationError === false) {
      if (
        shouldPreventMultipleVideoUpload(videoFiles, UploadFiles) &&
        !validationError
      ) {
        setValidationError("validate.video.upload");
        return;
      }
      if (
        imageFiles.length === 0 &&
        !validateMaxFileCount(audioFiles, 10, UploadFiles)
      ) {
        setValidationError("audioChat.file.fileLimit");
        return;
      }
      if ((await hasInvalidVideoDuration(videoFiles)) && !validationError) {
        setValidationError("validate.video.duration");
        return;
      }
      if ((await hasInvalidAudioDuration(audioFiles)) && !validationError) {
        setValidationError("validate.audio.duration");
        return;
      }
      if (
        imageFiles.length === 0 &&
        audioFiles.length === 0 &&
        !validateMaxFileCount(otherFiles, 5, UploadFiles)
      ) {
        setValidationError("documentChat.file.fileLimit");
        return;
      }
      await processFiles({
        imageFiles,
        videoFiles,
        audioFiles,
        otherFiles,
        validationError,
        isUpload
      });
    }
  };

  const processFiles = async ({
    imageFiles,
    videoFiles,
    audioFiles,
    otherFiles,
    validationError,
    isUpload
  }: {
    imageFiles: File[];
    videoFiles: File[];
    audioFiles: File[];
    otherFiles: File[];
    validationError: boolean;
    isUpload: boolean | undefined;
  }) => {
    if (imageFiles.length > 0 && !validationError) {
      await handleImageFiles(imageFiles, isUpload);
    } else if (videoFiles.length > 0 && !validationError) {
      await handleVideoFiles(videoFiles, isUpload);
    } else if (audioFiles.length > 0 && !validationError) {
      await handleAudioFiles(audioFiles, isUpload);
    } else if (otherFiles.length > 0 && !validationError) {
      await handleDocumentFiles(otherFiles, isUpload);
    }
  };

  const handleImageFiles = async (files: File[], isUpload: boolean | undefined) => {
    updateModelIfNeeded("imageChatGptModel", files);

    // Handle file selection and validation
    let shouldStopExecution = false;

    // Fetch valid files first
    const validFiles = await handleFileLimit(files, 20, files);

    if (validFiles === null) {
      shouldStopExecution = true; 
      resetFileUpload();
      return;
    }

    if (shouldStopExecution) return;

    const imgFile = convertFileNamesToLowercase(files);
    await uploadFilesToS3(imgFile, isUpload);
  };

  const handleVideoFiles = async (files: File[], isUpload?: boolean | undefined) => {
    if (videoMaxCountReached(currentPlan, userDetail)) {
      setMessageId("videoChat.plan.max_count");
      resetFileUpload();
      return;
    }
    updateModelIfNeeded("videoChatGptModel", files);
    validateAndSetModel(ChatType.video, "videoChatGptModel");
    await uploadFilesToS3(files, isUpload);
  };

  const handleAudioFiles = async (files: File[], isUpload?: boolean | undefined) => {
    if (audioMaxCountReached(currentPlan, userDetail)) {
      setMessageId("audioChat.plan.max_count");
      resetFileUpload();
      return;
    }
    const isFree = checkForFreeLimit(files, "audioChat.plan.max_count", isUpload);
    if (isFree === false) return;
    updateModelIfNeeded("audioChatGptModel", files);
    validateAndSetModel(ChatType.audio, "audioChatGptModel");
    await uploadFilesToS3(files, isUpload);
  };

  const handleDocumentFiles = async (files: File[], isUpload: boolean | undefined) => {
    if (documentMaxCountReached(currentPlan, userDetail)) {
      setMessageId("documentChat.plan.max_count");
      resetFileUpload();
      return;
    }
    const isFree = checkForFreeLimit(files, "documentChat.plan.max_count", isUpload);
    if (isFree === false) return;
    updateModelIfNeeded("documentChatGptModel", files);
    validateAndSetModel(ChatType.document, "documentChatGptModel");
  
    await uploadFilesToS3(files, isUpload);
  };

  const checkForFreeLimit = (files: File[], messageId: string, isUpload?: boolean | undefined) => {
    const isFreeUser = userDetail?.user.activeSubscription?.name === "Free";
    const UploadFiles= isUpload? chatContentUploadingFiles :  chatContent? FilePreview : chatContentUploadingFiles;
    const totalFilesCount =
      (UploadFiles ? UploadFiles.length : 0) + files.length;
    if (isFreeUser && (files.length > 1 || totalFilesCount > 1)) {
      resetFileUpload();
      setMessageId(messageId);
      return false;
    }
    return true;
  };

  const updateModelIfNeeded = (modelKey: string, files: File[]) => {
    const supportedModels = chatModels
    .filter((model) => model.type.includes(ChatType.image_chat))
    .map((model) => model.name);

    if (newMessages.length === 0 && !history && changeModel === true) {
      const storedModel = localStorage.getItem(modelKey);
      const selectedModel = storedModel
        ? JSON.parse(storedModel)
        : chatModels[1];
      localStorage.setItem(modelKey, JSON.stringify(selectedModel));
      localStorage.setItem("GptModel", JSON.stringify(selectedModel));
      setChatModel(selectedModel);
      setChangeModel(false);
    }
    if (
      files[0].type.startsWith("image/") &&
      !supportedModels.includes(gptModel?.name ?? "")
    ) {
      updateModelForFileType("imageChatGptModel");
    }
  };

  const updateModelForFileType = (modelKey: string) => {
  
    if (newMessages.length === 0 && !history) {
      const supportedModels = chatModels
      .filter((model) => model.type.includes(ChatType.image_chat))
      .map((model) => model.name);
      
      const storedModel = localStorage.getItem(modelKey);
      const parsedStoredModel = storedModel ? JSON.parse(storedModel) : null;
    
    // Check if the model from localStorage supports image_chat
    const isModelSupported = parsedStoredModel && supportedModels.includes(parsedStoredModel.name);
    
    // Use parsedStoredModel only if supported, otherwise use chatModels[1]
    const selectedModel = isModelSupported ? parsedStoredModel : chatModels[1];
      localStorage.setItem(modelKey, JSON.stringify(selectedModel));
      localStorage.setItem("GptModel", JSON.stringify(selectedModel));
      setChatModel(selectedModel);
    }
  };

  const handleFileLimit = (
    files: File[],
    limit: number,
    imageFiles: File[],
    isUpload?: boolean | undefined
  ): File[] | null => {
    const UploadFiles= isUpload? chatContentUploadingFiles : chatContent? FilePreview : chatContentUploadingFiles;
    const total =
    UploadFiles && UploadFiles.length + imageFiles.length;
    if (
      (UploadFiles && UploadFiles?.length > 20) ||
      (total && total > 20)
    ) {
      setErrorModal({ message: "ImageChat.file.fileLimit", show: true });
      return null;
    } else if (
      files.length > limit &&
      UploadFiles &&
      UploadFiles.length === 0
    ) {
      setErrorModal({ message: "ImageChat.file.fileLimit", show: true });
      return null;
    } else return files;
  };

  const uploadFilesToS3 = async (files: File[], isUpload?: boolean | undefined) => {
    if (
      !files[0].type.startsWith("image/") &&
      !files[0]?.type.startsWith("video/") &&
      !files[0]?.type.startsWith("audio/")
    ) {
      if (documentMaxCountReached(currentPlan, userDetail)) {
        setMessageId("documentChat.plan.max_count");
        return;
      }
    }

 // Convert files to IChatContentUploadFile format
  const fileList: IFilePreview[] = files.map((file) => ({
    file,
    errorMessage: "",
  }));
  fileList.forEach((file) => setFilePreview(file));

    const fileArray = Array.from(files);
    const fileType = files[0]?.type.startsWith("image/")
      ? "image"
      : files[0]?.type.startsWith("video/")
        ? "video"
        : "document";
    const validFiles =
      fileType === "image"
        ? validateImgFile(
            fileArray,
            setErrorModal,
            claudAllowedFileSize,
          )
        : validateFile(
            fileArray,
            setErrorModal,
            userDetail,
            setMessageId,
          );

          if(isModalOpen ){
            if(isUpload){
              if (validFiles) {
               await uploadToS3({
                fileArray,
                fileType,
             });
            }
          }} else if(chatContent !== true){
            if (validFiles) {
              await uploadToS3({
              fileArray,
              fileType,
          });

    }
  }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, isUpload?: boolean) => {
    if (!event.target.files?.length) return;
    handleFileUpload(event.target.files, isUpload);
    event.target.value = "";
  };

  const handleDrop = async (event: DragEvent<HTMLElement> | undefined, isUpload?: boolean) => {
    event?.preventDefault();
    const files = event?.dataTransfer?.files ?? null;
    handleFileUpload(files, isUpload);
  };

  return {
    errorModal,
    setErrorModal,
    messageId,
    setMessageId,
    handleDrop,
    resetFileUpload,
    fileLimit,
    handleFileSelect,
    handleFileUpload,
  };
};