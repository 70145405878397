import styles from "../../chatFooter.module.scss";
import { useState, useEffect, useRef,
   } from "react";
import { IBot, RoleBot, setSelectedSubOptions } from "redux/actions";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import { Spinner } from "components";
import React from "react";
import SubOption from "./SubOptions";
import RememberBotSetting from "./RememberBotSetting";
import MainOption from "./MainOption";
interface IProps {
  onClose: () => void;
  loadingSetting: boolean;
  conversation?:boolean;
  chat?:boolean;
  chatPage?:boolean;
}

const BotSetting: React.FC<IProps> = ({
  onClose,
  loadingSetting,
  conversation,
  chat,
  chatPage,
}) => {
  const { PrivateChat } = useSelector(
    (state) => state.chatReducer
  );
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { theme } = useSelector((state) => state.authReducer);
  const { dropdownOptions, selectedSubOptions } = useSelector ((state) => state.chatReducer);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const mainOptionsRef = useRef<HTMLDivElement>(null);
  const [maxHeightNeeded, setMaxHeightNeeded] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
  };

  const handleSubOptionClick = (
    mainOptionValue: string,
    key: string,
    subOptionValue: string
  ) => {
    
    // Get current options from Redux
    const currentOptions = { ...selectedSubOptions };
    
    // Create new options object
    const updatedOptions = {
      ...currentOptions,
      [mainOptionValue]: subOptionValue,
    };
    
    // Dispatch with the new object directly
    setSelectedSubOptions(updatedOptions);
    
    setSelectedOption(null);
  
    const botSettingsFromOptions = {
      language: currentOptions.language,
      length: currentOptions.responseLengths,
      output_format: currentOptions.outputFormats,
      style_of_writing: currentOptions.writingStyles,
      tone: currentOptions.tones
    };

    const updatedBot = {
      ...botSettingsFromOptions,
      key: "bot_settings",
      [key]: subOptionValue,
    };
  
    const filteredBot = Object.fromEntries(
      Object.entries(updatedBot).filter(
        ([_, value]) => value !== "" && value !== "default"
      )
    ) as unknown as IBot;
  
    performOperation(filteredBot);
  };

  const performOperation = (data: IBot) => {
    window.postMessage({ settings: data });
    RoleBot(data);
  };

  // Calculate and apply max height based on entire page height
  useEffect(() => {
    if (!mainOptionsRef.current || !dropdownRef.current || !dropdownOptions) return;

    const calculateMaxHeight = () => {
      // Get the total page height (document height)
      const pageHeight = document.documentElement.scrollHeight;
      // Get dropdown position
      const dropdownRect = dropdownRef.current?.getBoundingClientRect();
      const dropdownTop = dropdownRect?.top || 0;
      // Adjust for any scrolling that's occurred
      const absoluteDropdownTop = dropdownTop + window.scrollY;
      
      const availableSpace = pageHeight - absoluteDropdownTop - 62;
      
      const totalOptionsHeight = mainOptionsRef.current?.scrollHeight || 0;
      
      if (totalOptionsHeight > availableSpace) {
        setMaxHeight(availableSpace);
        setMaxHeightNeeded(true);
      } else {
        setMaxHeight(null);
        setMaxHeightNeeded(false);
      }
    };

    calculateMaxHeight();
    window.addEventListener('resize', calculateMaxHeight);
    window.addEventListener('scroll', calculateMaxHeight);
    
    return () => {
      window.removeEventListener('resize', calculateMaxHeight);
      window.removeEventListener('scroll', calculateMaxHeight);
    };
  }, [dropdownOptions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.dropdown, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
        [styles.conversation]: conversation,
        [styles.chat]:chat,
        [styles.chatPage]:chatPage,
      })}
    >
      {loadingSetting ? (
        <div className="flex items-center justify-center py-3">
          {" "}
          <Spinner extraSmall />{" "}
        </div>
      ) : (
        <>
          {!PrivateChat && <RememberBotSetting />}

          <div 
            ref={mainOptionsRef}
            className="overflow-x-hidden relative"
            style={{
              ...(maxHeightNeeded ? { overflowY: "auto", maxHeight: maxHeight ? `${maxHeight}px` : undefined } : {}),
              ...(chat ? { maxHeight: "222px", overflowY: "auto" } : {}) 
            }}
          >
            {dropdownOptions!== null && dropdownOptions?.map((option) => {
                if (!selectedSubOptions[option.value]) {
                  selectedSubOptions[option.value] = "default";
                }
              return (
                <div key={option.value}>
                  <MainOption
                    option={option}
                    selectedOption={selectedOption}
                    onClick={() => handleOptionClick(option.value)}
                    selectedSubOptions={selectedSubOptions}
                  />
                </div>
              );
            })}
          </div>
          
          {dropdownOptions!== null && dropdownOptions?.map((option) => (
            <React.Fragment key={`sub-${option.value}`}>
              {selectedOption !== null &&
                selectedOption === option.value &&
                option.options && (
                  <SubOption
                    subOptions={option.options}
                    optionkey={option.key}
                    selectedOption={selectedOption}
                    selectedSubOptions={selectedSubOptions}
                    onClick={handleSubOptionClick}
                    dropdownOptions={dropdownOptions}
                    chat={chat}
                  />
                )}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};
export default BotSetting;