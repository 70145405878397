import {
  useEffect,
  useState,
  useRef,
  memo,
  lazy,
  Suspense,
  Dispatch,
  SetStateAction,
} from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./ChatSidebar.module.scss";
import { NavLink } from "react-router-dom";

import {
  PlanIcon,
  SettingIcon,
  FreeCreditsIcon,
  PlusIcon,
} from "./components/icons";

import { Separator } from "components";
import { SearchField } from "./components/SearchField";
import { ThemeSwitch } from "components/Header/ThemeSwitch/ThemeSwitch";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { EmptyThreadChat, EmptyUploadedFiles, IChat, setIsModalOpen, setSearchWord } from "redux/actions";
import { useSelector } from "redux/hooks";
import links from "core/links";
import { ChatRoute } from "pages/ChatPage";
import { ChatSideBarFooter } from "./components/ChatSideBarFooter";
// import { FolderIcon } from "./components/icons/FoldersIcon";
import { CreateFolder } from "./components/CreateFolder/CreateFolder";
import { ChatHistoryList } from "./components/ChatHistoryList/ChatHistoryList";
import { WorkspaceIcon } from "./components/icons/WorkspaceIcon";
import { WorkspaceSearchField } from "pages/Workspaces/pages/WorkspaceConversation.tsx/InfoComponent/WorkspaceSearchField";
import AnimatedModal from "components/AnimateModal/AnimateModal";
import { UploadedFilePreview } from "pages/Workspaces/pages/WorkspaceConversation.tsx/Threads/Components/UploadedFilePreview";
import { EmptyObj } from "utils/fileProcessing";
const FreeCreditsModal = lazy(() =>
  import("pages/ChatPage/components/freeCreditsModal").then((module) => ({
    default: module.FreeCreditsModal,
  }))
);
interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined, folder_id: number) => void;
  onStartNewChat?: ({
    toChat,
    PrivateChat,
    newChatbtn,
  }: {
    toChat: boolean;
    PrivateChat?: boolean;
    newChatbtn?: boolean;
  }) => void;
  isMainScreenOpen?: boolean;
  toggleSidebar?: any;
  itemStyles?: any;
  searchQuery?: string;
  setSearchQuery?: Dispatch<SetStateAction<string>>;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  isAnswerComplete?: boolean;
}

export const ChatSidebar = memo(
  ({
    onSelectChatItem,
    onStartNewChat,
    toggleSidebar,
    itemStyles,
    searchQuery,
    setSearchQuery,
    setOpenHistory,
    isAnswerComplete,
  }: IProps) => {
    const {
      pathname,
      includeRoute,
       push
       } = useRouter();
    const chatId = pathname.split("/")[3];
    const { showCreateWorkspace, isModalOpen } = useSelector(
      (state) => state.workSpaceReducer
    );
    const { Folders } = useSelector((state) => state.chatFolderReducer);
    const chatHistory = useSelector((state) => state.chatReducer.chats);
    const { userDetail, theme } = useSelector((state) => state.authReducer);
    const { newMessages } = useSelector((state) => state.chatReducer);
    const { PrivateChat } = useSelector((state) => state.chatReducer);
    const [showReferralModal, setShowReferralModal] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IChat | undefined>(
      undefined
    );
    const [createFolder, setCreateFolder] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const workSpaceChat = pathname.includes("/workspaces");

    const onClearSearchField = () => {
      setSearchQuery && setSearchQuery("");
      setSearchWord("");
      if (inputRef.current) inputRef.current.focus();
    };

    useEffect(() => {
      if (chatId) {
        const index = chatHistory.findIndex(
          (item) => item.id === JSON.parse(chatId)
        );
        if (index >= 0) {
          setSelectedItem(chatHistory[index]);
        }
      } else {
        setSelectedItem(undefined);
      }
    }, [chatId, chatHistory]);

    const isButtonActive = (): boolean => {
      let isActive = false;
      if (includeRoute(ChatRoute.History) || newMessages.length > 0) {
        isActive = true;
      } else if (includeRoute(ChatRoute.New)) {
        isActive = true;
      } 
      else if (includeRoute(RoutePaths.Workspaces)) {
        isActive = true;
      }
      return isActive;
    };

    const handleSerchFocus = () => {
      if (inputRef.current) inputRef.current.focus();
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchQuery && setSearchQuery(value);
      setSearchWord("");
    };

    // const handleCreateFolder = () => {
    //   setCreateFolder((prev) => !prev);
    // };

    return (
      <>
        {(PrivateChat || showCreateWorkspace) && (
          <div className={styles.backdrop}></div>
        )}
        <div className={styles.container}>
          <div className="flex gap-2">
            <div className={`${styles.themeallow} `}>
              <button
                className={classNames(styles.newChatBtn, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                  [styles.isActive]: isButtonActive(),
                  [styles.isbackdrop]: showCreateWorkspace,
                })}
                onClick={() => {
                  if( workSpaceChat
                  ) {
                    if(isAnswerComplete || isAnswerComplete === undefined ) {
                      setIsModalOpen(true);
                      EmptyUploadedFiles();
                    }
                  } else if (isButtonActive()) {
                    onStartNewChat?.({ toChat: true, newChatbtn: true });
                  }

                }}

                data-testid="new-chat-btn"
              >
                <div
                  className={classNames(styles.btnContent, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.isActive]: isButtonActive(),
                    [styles.isbackdrop]: showCreateWorkspace,
                  })}
                >
                  <PlusIcon />
                  <div className={styles.text}>
                    <FormattedMessage id="sidebar.chat.newChat.btn.text" />
                  </div>
                </div>
              </button>
            </div>
            {/* <span
              className={classNames(styles.folderIcon, {
                [styles.dark]: theme === "dark",
                [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
              })}
              onClick={handleCreateFolder}
            >
              <FolderIcon />
            </span> */}
          </div>

          <div className="mb-[14px] mt-1">
            <SearchField
              inputRef={inputRef}
              value={searchQuery}
              onChange={(e) => {
                handleSearchChange(e);
              }}
              onClear={onClearSearchField}
              chatsidebar={true}
              handleSerchFocus={handleSerchFocus}
            />
          </div>
          {userDetail?.user.team.role === "admin" && (
            <div className="border-t border-solid border-[#fff] dark:border-[rgba(255,255,255,0.9)]">
              <div
                className={classNames(
                  "flex gap-[11px] px-[17px] cursor-pointer mt-2.5 py-1.5 hover:bg-[#92A5ED] dark:hover:bg-[#343541] rounded-[4px]",
                  {
                    [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                  }
                )}
                onClick={() => push(`/${RoutePaths.Workspaces}`)}
                data-testid="sidebar-chat-newchat-workspace"
              >
                <WorkspaceIcon />
                <div className={styles.historyHeading}>
                  <FormattedMessage id="sidebar.chat.newChat.workspace" />
                </div>
              </div>
            </div>
          )}
          {createFolder && <CreateFolder setCreateFolder={setCreateFolder} />}
          <Separator height="16px" />
          <div
            className={classNames(styles.scrollBar, {
              [styles.emptyFold]: Folders.length === 0 && searchQuery === "",
              [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
            })}
          >
            <ChatHistoryList
              searchQuery={searchQuery || ""}
              onSelectChatItem={onSelectChatItem}
              setSelectedItem={setSelectedItem}
              setOpenHistory={setOpenHistory}
              selectedItem={selectedItem}
              onStartNewChat={onStartNewChat}
            />
          </div>
          <div className={styles.footer}>
            <div className={styles.Hidefootersetting}>
              <ChatSideBarFooter
                itemStyles={itemStyles}
                toggleSidebar={toggleSidebar}
                userDetail={userDetail}
                theme={theme}
              />
            </div>
            <div className={styles.themeallow}>
              <div
                className={classNames(styles.itemContainer, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                  [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                })}
                onClick={() => setShowReferralModal(true)}
                data-testid="sidebar-chat-newchat-earnCredit"
              >
                <FreeCreditsIcon />
                <div className={styles.itemText}>
                  <FormattedMessage id="sidebar.chat.newChat.earnCredits" />
                </div>
              </div>
              <Separator height="18px" />
              {userDetail?.user.team.role === "admin" && (
                <>
                  <NavLink
                    className={classNames(styles.itemContainer, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                      [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                    })}
                    to={`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`}
                    data-testid="chat-sidebar-currentplan"
                  >
                    <PlanIcon />
                    <div className={styles.itemText}>
                      <FormattedMessage id="sidebar.chat.newChat.currentPlan" />
                    </div>
                    <div
                      className={classNames(styles.usageTag, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      {userDetail.user.activeSubscription.name}
                    </div>
                  </NavLink>
                  <Separator height="18px" />
                </>
              )}

              <a
                href={links.ChromeStorePluginUrl}
                target="_blank"
                rel="noreferrer"
                className={classNames(styles.itemContainer, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                  [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                })}
                data-testid="chrome-store-plugin"
              >
                <img
                  src="/ChromeIcon.svg"
                  height={"22px"}
                  width={"22px"}
                  style={{ backgroundColor: "transparent" }}
                />
                <div className={styles.itemText}>
                  <FormattedMessage id="sidebar.chat.newChat.addToChrome" />
                </div>
              </a>
              <Separator height="18px" />
              <div className={styles.themeContainer}>
                <NavLink
                  className={classNames(styles.itemContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                  })}
                  to={`/${RoutePaths.Settings}`}
                  data-testid="sidebar-setting"
                >
                  <SettingIcon />
                  <div className={styles.itemText}>
                    <FormattedMessage id="sidebar.chat.newChat.settings" />
                  </div>
                </NavLink>
                <ThemeSwitch ThemeDark={true} chatsidebar={true} />
              </div>
            </div>
          </div>
        </div >
        {isModalOpen && (
          <AnimatedModal isOpen={isModalOpen} onClose={() => {
            setIsModalOpen(false)
            EmptyUploadedFiles();
            EmptyThreadChat(EmptyObj);
          }} threads={true}>
            <UploadedFilePreview /> 
            <WorkspaceSearchField  threads={true} chat={true} wsNewChat={true} 
            />
          </AnimatedModal>
        )}
        <Suspense fallback={<></>}>
          {showReferralModal && (
            <FreeCreditsModal onClose={() => setShowReferralModal(false)} />
          )}
        </Suspense>
      </>
    );
  }
);
