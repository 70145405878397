import {
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
} from "react";
import classNames from "classnames";
import styles from "./workSpaceConversation.module.scss";
import { useSelector } from "redux/hooks";
import { InfoComponent } from "./InfoComponent";
import { useSidebar } from "hooks/services/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";
import { useEffectOnce } from "react-use";
import {
  setWsUploadedFile,
  startNewChat,
  clearhistoryMessages,
  SaveSelectedThread,
  selectedThread,
  EmptyUploadedFiles,
} from "redux/actions";
import useRouter from "hooks/useRouter";
import { WorkspaceSearchField } from "./InfoComponent/WorkspaceSearchField";
import { useThreadsHistoryContext } from "hooks/ThreadHistoryContext";
import { UploadedFilePreview } from "./Threads/Components/UploadedFilePreview";
import { Threads } from "./Threads/Threads";
import { MiniConversationHeader } from "./InfoComponent/MiniConversationHeader";
import { ContinueIcon } from "pages/Workspaces/components/icons/ContinueIcon";
import { ThreadsConversation } from "../ThreadsConversation/ThreadsConversation";

interface WorkspaceContentProps {
  isAnswerComplete: boolean;
  isFileUploading: boolean;
  openHistory: boolean;
  addSource: boolean;
  setAddSource: Dispatch<SetStateAction<boolean>>;
  loadingData: boolean;
  isAllowUploadFile: boolean;
}

export const WorkspaceConversation = ({
  isAnswerComplete,
  isFileUploading,
  openHistory,
  addSource,
  setAddSource,
  loadingData,
  isAllowUploadFile,
}: WorkspaceContentProps) => {
  const { onEnter } = useThreadsHistoryContext();

  const { width } = useWindowSize();
  const { sidebarWidth } = useSidebar();
  const { isModalOpen } = useSelector((state) => state.workSpaceReducer);
  const { newMessages } = useSelector((state) => state.chatReducer);
  const { pathname } = useRouter();
  const thread = pathname.includes("thread");
  const workspaceId = pathname.split("/")[2];
  const [showInfo, setShowInfo] = useState(false);

  useEffectOnce(() => {
    const SaveThreadInfo: selectedThread = {
      ws_name: "",
      emoji: "",
      thread_name: "",
      thread_id: 0,
      ws_id: 0,
    };
    SaveSelectedThread(SaveThreadInfo);
    EmptyUploadedFiles();
    setWsUploadedFile();
    if (!thread) startNewChat();
    clearhistoryMessages();
  });

  useEffect(() => {
    if (newMessages.length === 0 && thread) {
      window.history.replaceState(null, "", `/workspaces/${workspaceId}`);
    }
  }, []);

  const hasMessages = useMemo(
    () => newMessages && newMessages.length > 0,
    [newMessages]
  );

  return (
    <>
      {hasMessages ? (
        <ThreadsConversation
          isAllowUploadFile={isAllowUploadFile}
        />
      ) : (
        <div className="flex flex-col gap-[10px]">
          {width < 576 && !showInfo && (
            <MiniConversationHeader setShowInfo={setShowInfo} />
          )}
          {width < 576 && showInfo ? (
            <div className="py-[14px] pl-[15px] pr-[13px] flex flex-col gap-3">
              <div
                className="text-[#4338CA] text-[12px] font-semibold flex gap-2 items-center"
                onClick={() => setShowInfo(false)}
              >
                <span className="rotate-180 flex flex-row items-center justify-center">
                  <ContinueIcon />
                </span>
                <span>Back</span>
              </div>
              <InfoComponent
                loadingData={loadingData}
                addSource={addSource}
                setAddSource={setAddSource}
              />
            </div>
          ) : (
            <div
              className={classNames(
                "flex flex-col lg:flex-row mx-auto mt-4 w-full flex-0 px-4 md:pl-8 lg:mt-6 md:pr-8 2xl:pr-14 gap-[20px]",
                {
                  [styles.ConversationContainer]:
                    (sidebarWidth > 287 && width <= 1150) ||
                    (sidebarWidth > 497 && width <= 1323) ||
                    (sidebarWidth > 480 && width <= 1305),
                  "pr-[19px] pl-[15px]": width < 576,
                }
              )}
            >
              <div
                className={classNames("flex flex-col", styles.chatContainer, {
                  [styles.Container]:
                    (sidebarWidth > 287 && width <= 1150) ||
                    (sidebarWidth > 497 && width <= 1323) ||
                    (sidebarWidth > 480 && width <= 1305),
                })}
              >
                <div
                  className={classNames(
                    "flex flex-col",
                    styles.text_Main_Container,
                    {
                      [styles.textContainer]:
                        (sidebarWidth > 287 && width <= 1150) ||
                        (sidebarWidth > 497 && width <= 1323) ||
                        (sidebarWidth > 480 && width <= 1305),
                    }
                  )}
                >
                  {!isModalOpen && <UploadedFilePreview />}
                  <WorkspaceSearchField
                    openHistory={openHistory}
                    isAnswerComplete={isAnswerComplete}
                    isFileUploading={isFileUploading}
                    onEnter={onEnter}
                    addSource={addSource}
                    conversation={true}
                  />
                </div>
                <Threads />
              </div>
              {width > 576 && (
                <InfoComponent
                  loadingData={loadingData}
                  addSource={addSource}
                  setAddSource={setAddSource}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
