import styles from "./uploadedFiles.module.scss";
import { 
  // RefetchIcon,
   TrashIcon } from "../icons/RightSectionIcon";
import { useSelector } from "redux/hooks";
import { DeleteUploadedFile, IUploadedFile
  // , RefetchFile
 } from "redux/actions";
import {
  CSVIcon,
  EMLIcon,
  PDFIcon,
  TextIcon,
  PPTIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  PPTXIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
import { Spinner } from "components";
import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import useRouter from "hooks/useRouter";
// import { useEffectOnce } from "react-use";
import { ShowUploadSource } from "redux/actions";
import { useAppNotification } from "hooks/services/AppNotification";
import classNames from "classnames";
import { getRelativeTime, textExtensions, audioURL, videoURL } from "utils/constants";
import { DefaultIcon } from "../icons/LinkIcon";
import { EThemeType } from "redux/reducers";
import FilePreviewModal from "../FilePreviewModal/FilePreviewModal";
import { adaptUploadedFile, debounce } from "utils/functions";
import { formatFileSize, getProcessedFileName, isImageFile } from "utils/fileProcessing";
import { AudioPreview } from "pages/ChatPage/components/searchField/AudioPreview";
import VideoPreview from "pages/ChatPage/components/searchField/VideoPreview";
import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import React from "react";

const renderFileIcon = (fileExtension: string, theme: EThemeType | undefined) => {

    const fileExt = fileExtension.split(".").pop()?.toLowerCase(); // Extract file extension

    if (fileExt && textExtensions.includes(fileExt)) {
      return (
        <div
          className={classNames(styles.loader, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <span className="text-[8px] font-medium text-[#A09FA2] uppercase">
            {fileExt}
          </span>
        </div>
      );
    }

  switch (fileExtension) {
    case "pdf":
      return <PDFIcon />;
    case "csv":
      return <CSVIcon />;
    case "txt":
      return <TextIcon />;
    case "xlsx":
      return <XLSXIcon />;
    case "xls":
      return <XLSIcon />;
    case "srt":
      return <SRTIcon />;
    case "eml":
      return <EMLIcon />;
    case "pptx":
      return <PPTXIcon />;
    case "ppt":
      return <PPTIcon />;
    case "docx":
      return <DocIcon />;
    default:
        if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
          console.warn(`Unknown file extension encountered: "${fileExtension}"`);
        }
      return <DefaultIcon />;
  }
};

// Moved outside component to prevent recreation on each render
const getFormattedFileSize = (file: IUploadedFile): string => {
  
  if (file?.file?.size === undefined || file?.file?.size === null) {
    return "Unknown size";
  }

  const fileSize = Number(file.file.size);
  const size= formatFileSize(fileSize);
  
  return size;
  // !isNaN(fileSize)
  //   ? `${Number.isInteger(fileSize) ? fileSize : fileSize.toFixed(2)} ${(fileSize === 0 || fileSize === 1 )? "token" : "tokens"}` 
  //   : "Unknown size";
};
interface IProps{
  chatInfo?:boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export const UploadedConversationFiles = ({chatInfo, loading, setLoading}:IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { triggerNotification } = useAppNotification();
  const { wsUploadedFiles } = useSelector((state) => state.workSpaceReducer);
  const [deletingFileIds, setDeletingFileIds] = useState<Set<number>>(
    new Set()
  );
  // const [loading, setLoading] = useState<boolean>(false);
  // const [refetchingFileIds, setRefetchingFileIds] = useState<Set<number>>(new Set());
  const [previewFile, setPreviewFile] = useState<IUploadedFile | null>(null);

  const { pathname } = useRouter();
  const workSpaceId = pathname.split("/")[2];

  const [audioPreview, setAudioPreview] = useState<{ path: string; name: string } | null>(null);

  // Video preview states
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<{ path: string; name: string } | null>(null);

  useEffect(() => {
    let observer: MutationObserver | null = null;
    
    const adjustPhotoViewPortalZIndex = () => {
      const portals = document.getElementsByClassName('PhotoView-Portal');
      Array.from(portals).forEach(portal => {
        if (portal instanceof HTMLElement && portal.style.zIndex !== '9000000') {
          portal.style.zIndex = '9000000';
          portal.style.position = 'absolute';
        }
      });
    };
    
    const debouncedAdjustPhotoViewPortalZIndex = debounce(adjustPhotoViewPortalZIndex, 100);
    
    observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && 
            Array.from(mutation.addedNodes).some(node => 
              node instanceof HTMLElement && 
              (node.classList.contains('PhotoView-Portal') || 
               node.querySelector('.PhotoView-Portal')))) {
          debouncedAdjustPhotoViewPortalZIndex();
          break; // Only need to adjust once per batch of mutations
        }
      }
    });
    
    adjustPhotoViewPortalZIndex();
    observer.observe(document.body, { childList: true, subtree: true });
    
    return () => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    };
  }, []);

  useEffect(() => {
    
    if(wsUploadedFiles.length === 0) {
    setLoading(true);
 
    setTimeout(() => {

    const path= window.location.pathname;
    const Id =  path.split("/")[2];

    ShowUploadSource(Number(Id))
      .then(() => {
           setLoading(false);
      })
      .catch((err) => {
          setLoading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
      });
    },15)
    }
  },[workSpaceId, wsUploadedFiles.length]);

    // Process files only once when wsUploadedFiles changes
    const processedFiles = useMemo(() => {
      if (!wsUploadedFiles || wsUploadedFiles.length === 0) return [];
      
      return wsUploadedFiles.map((file: IUploadedFile) => {
        // Pre-compute all derived data once
        return {
          ...file,
          processedFileName: getProcessedFileName(file),
          formattedFileSize: getFormattedFileSize(file),
          fileIcon: file && file.file ? renderFileIcon(file.file.mime_type?.toLowerCase() || '', theme) : <DefaultIcon />,
          timeInfo: file.created_at === file.updated_at
            ? ` Added ${getRelativeTime(file.created_at)}`
            : ` Updated ${getRelativeTime(file.updated_at)}`
        };
      });
    }, [wsUploadedFiles]);

  const handleDeleteFile = async (id: number) => {
    if (deletingFileIds.has(id)) {
      return;
    }
    setDeletingFileIds((prev) => new Set([...prev, id]));
    try {
      await DeleteUploadedFile(id);
    } finally {
      setDeletingFileIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  // Add function to handle file preview
const handlePreviewFile = useCallback((file: typeof processedFiles[0], e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  if (!file || !file.file) return; 
  
  if (audioURL.includes(file.file.mime_type?? "")) {
    setAudioPreview({
      path: file.file.path || '',
      name: file.processedFileName
    });
  } else if (videoURL.includes(file.file.mime_type?? "")) {
  setSelectedVideo({
    path: file.file.path || '',
    name: file.processedFileName
  });
  setShowVideoPreview(true);
  setIsVideoLoading(true);
  }  else {
    // Create a new object with the correct fileType property
    const previewFileWithType = {
      ...file,
      fileType: "document" as const // Explicitly set fileType to "document"
    };
    
    setPreviewFile(previewFileWithType);
  }
 
}, []);

 // Add function to close preview
 const closePreview = useCallback(() => {
  setPreviewFile(null);
}, []);

const handleVideoLoad = useCallback(() => {
  setIsVideoLoading(false);
}, []);

const closeVideoPreview = useCallback(() => {
  setShowVideoPreview(false);
  setSelectedVideo(null);
}, []);

const closeAudioPreview = useCallback(() => {
  setAudioPreview(null);
}, []);

  // const handleRefetch = async(id: number) => {

  //   if (refetchingFileIds.has(id)) {
  //     return;
  //   }
  //     setRefetchingFileIds((prev) => new Set([...prev, id]));
    
  //     try {
  //       await RefetchFile(id);
  //       triggerNotification({ message: "Source refetched successfully", type: "info" });
  //     } catch (error) {
  //       triggerNotification({ message: error?.data?.message, type: "error" });
  //     } finally {
  //       setRefetchingFileIds((prev) => {
  //         const newSet = new Set(prev);
  //         newSet.delete(id);
  //         return newSet;
  //       });
  //     }
  // };
  const [maxHeight, setMaxHeight] = useState("auto");
  const containerRef = useRef<HTMLDivElement | null>(null); // ✅ Explicit type
  
  useEffect(() => {
    const updateMaxHeight = () => {
      if (containerRef.current) {
        const parent = containerRef.current.parentElement;
        if (parent) {
          const availableHeight =
            window.innerHeight - containerRef.current.getBoundingClientRect().top - 43; // Adjust for padding
          setMaxHeight(`${Math.max(availableHeight, 150)}px`); // Ensure minimum height of 150px
        }
      }
    };
  
    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);
    return () => window.removeEventListener("resize", updateMaxHeight);
  }, [wsUploadedFiles]);


  return (
    <>
      {previewFile && (
        <FilePreviewModal 
          file={adaptUploadedFile(previewFile, true)} 
          onClose={closePreview} 
          getsize={true}
        />
      )}

{showVideoPreview && selectedVideo && (
        <VideoPreview 
          videos={[selectedVideo]} 
          loading={isVideoLoading}
          handleVideoLoad={handleVideoLoad}
          onClose={closeVideoPreview}
        />
      )}

      {/* Audio preview modal */}
      {audioPreview && (
        <AudioPreview 
          audio={audioPreview}
          onClose={closeAudioPreview}
        />
      )}
            {/* Wrap entire file list with PhotoProvider for image previews */}
    <PhotoProvider maskOpacity={0.91}>
    <div ref={containerRef}
    className={classNames(styles.addSources, {
      [styles.chatInfo]:chatInfo,
    })}
    style={{
    maxHeight,
    overflowY: loading ? "hidden" : "auto", // Prevent scrolling when loading
    position:"relative",
    overflowX: "hidden",
  }}>
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner extraSmall />
        </div>
      ) : (
        processedFiles.map((file) => {
      const isImage = isImageFile(file.file.mime_type);
          // Create the file item component
       const fileItem = (
              <div
                className={classNames(styles.filesContainer, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                onClick={(e) => {if(!isImage) handlePreviewFile(file, e)}}
                style={{ cursor: 'pointer' }}
              >
                <div className={styles.fileWrapper}>
                  <div>{file.fileIcon}</div>
                  <div className={styles.fileNameWrapper}>
                    <div
                      className={classNames(styles.fileName, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                        [styles.chatInfo]:chatInfo,
                      })}
                    >
                      {file.processedFileName}
                    </div>

                    <span className="text-[12px] font-normal leading-[15px] text-[#737373] dark: text-[rgba(255,255,255,0.7)">
                      {/* {(() => {
                        const fileSize = Number(file?.file?.size);
                        return !isNaN(fileSize) && fileSize > 0
                          ? `${fileSize.toFixed(2)} MB`
                          : "No size";
                      })()} */}

                      {/* {file.created_at === file.updated_at
                        ? ` Added ${getRelativeTime(file.created_at)}`
                        : ` Updated ${getRelativeTime(file.updated_at)}`} */}
                      {file.formattedFileSize}
                      <span className="mx-[4px]">•</span>
                      {file.timeInfo}
                    </span>
                  </div>
                  {!chatInfo && (
                  <div className="flex gap-[12px] justify-center cursor-pointer absolute right-[45px] md:right-[55px] lg:right-[26px]">
                    {/* <span
                      className={classNames({
                        "animate-spin": refetchingFileIds.has(file?.id),
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleRefetch(file?.id);
                      }}
                    >
                      <RefetchIcon />
                    </span> */}
                    {deletingFileIds.has(file?.id) ? (
                      <span>
                        {" "}
                        <Spinner extraSmall />{" "}
                      </span>
                    ) : (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleDeleteFile(file?.id);
                        }}
                      >
                        <TrashIcon />
                      </span>
                    )}
                  </div>
                  )}
                </div>
              </div>
          );

          if (isImage) {
            return (
              <PhotoView key={file.id} src={file.file.path}>
                {fileItem}
              </PhotoView>
            );
          }

          return (
            <React.Fragment key={file.id}>
              {fileItem}
            </React.Fragment>
          );
        })
      )}
    </div>
    </PhotoProvider>
    </>
  );
};
