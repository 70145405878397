// import React, { useState } from "react";

interface DrawerProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const Drawer: React.FC<DrawerProps> = ({ children, open, onClose }) => {
  return (
    <>
      <div
        className={`fixed inset-0 bg-black/30 transition-opacity z-[1000000] ${
          open ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={onClose}
      />
      <div
        className={`fixed bottom-0 right-0 h-full transition-transform duration-500 ease-out drawer-responsive-width`}
        style={{
          zIndex: 1000001,
          willChange: "transform",
          transform: open ? "none" : "translateX(100%)",
        }}
      >
        <div className="h-full p-4">{children}</div>
      </div>
    </>
  );
};
export default Drawer;
