
// FilePreviewModal/components/previewTypes/DownloadPreview.tsx
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "../../FilePreviewModal.module.scss";
// import { renderFileIcon } from "utils/fileUtils";
import { EThemeType } from "redux/reducers";
import { renderFileIcon } from "utils/fileUtils";

interface DownloadPreviewProps {
  fileType: string;
  url: string;
  theme: EThemeType | undefined;
}

const DownloadPreview: React.FC<DownloadPreviewProps> = ({ fileType, url, theme }) => (
  <div className={styles.styledDownloadContainer}>
    <div className={styles.fileIconLarge}>
      {renderFileIcon(fileType, theme)}
    </div>
    <p className={`font-inter ${styles.downloadMessage}`}>
      <FormattedMessage 
        id="preview.notAvailable" 
        defaultMessage="Preview not available for this file type." 
      />
    </p>
    <a href={url} target="_blank" rel="noreferrer" className={styles.downloadButton}>
      <FormattedMessage id="preview.download" defaultMessage="Download File" />
    </a>
  </div>
);

export default DownloadPreview;