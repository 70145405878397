import classNames from "classnames";
import styles from "./drag&dropModal.module.scss";
import { Modal } from "components";
import { useSelector } from "redux/hooks";
import { NewUploadIcon } from "pages/ChatPage/pages/newChat/icons/NewUploadIcon";
import { FormattedMessage } from "react-intl";

interface IProp {
  handleClose: () => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => Promise<void>;
}

export const DragDropModal = ({ handleClose, handleDrop }: IProp) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <div onClick={handleClose}>
      <Modal
        size="xl"
        drag={true}
        uploadDoc={true}
        onDragOver={(event) => event?.preventDefault()}
        onDragLeave={handleClose}
        onDrop={(event) => {
            if (!event) return; 
            const dragEvent = event as React.DragEvent<HTMLDivElement>;
            handleDrop(dragEvent);
            handleClose();
          }}  
        onClose={handleClose}
      >
        <label
          htmlFor="file"
          className={classNames(styles.dropzone, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <NewUploadIcon />
          <div className={styles.message}>
            <FormattedMessage id="documentChat.dragDrop.new.message" />
          </div>
          <div
            className={classNames(styles.validationText, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="doc.drag.drop.start" />
          </div>
        </label>
      </Modal>
    </div>
  );
};
