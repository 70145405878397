import { CreditsIcon } from "pages/Workspaces/components/icons/CreditsIcon";
import { Credits } from "views/layout/Navbar/components/ChatNav/components/Credits";
import { useState, useRef, useEffect } from "react";
import { useWindowSize } from "hooks/useWindowSize";

interface IProp {
  credit: number;
}

export const WorkspaceCredits = ({ credit }: IProp) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [hoveredWidth, setHoveredWidth] = useState<number>(0);
  const { width } = useWindowSize();
  
  const hoveredContentRef = useRef<HTMLDivElement>(null);
  
  // Measure both content widths on mount and when credit changes
  useEffect(() => {
    if (hoveredContentRef.current) {
      setHoveredWidth(hoveredContentRef.current.offsetWidth);
    }
  }, [credit]);

  // Handle hover with transition state
  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsTransitioning(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsTransitioning(true);
    
    // Reset transitioning state after animation completes
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300); 
  };

  return (
    <div 
      className={`${width < 576 ? "right-[12px]" : "right-0"} flex items-center gap-1.5 bg-[#C7D2FE] rounded-full px-2 py-1 cursor-pointer absolute transition-all duration-300 ease-out overflow-hidden`}
      style={{ 
        width: isHovered 
          ? `${hoveredWidth + 36}px` 
          : `${80 + 36}px`
      }}
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      <span className="flex-shrink-0"><CreditsIcon /></span>
      
      {/* Hidden measurement divs */}
      <div 
        className="absolute invisible whitespace-nowrap" 
        aria-hidden="true"
      >
        <span className="text-[10px] text-[#4338CA] font-normal font-inter mb-[3px]">
          {credit} credits / query
        </span>
      </div>
      
      <div 
        ref={hoveredContentRef} 
        className="absolute invisible whitespace-nowrap" 
        aria-hidden="true"
      >
        <div className="flex gap-[2px] items-center">
          <Credits isHovered={true} />
          <span className="text-[10px] text-[#4338CA] font-normal font-inter">credits left</span>
        </div>
      </div>
      
      {/* The actual visible content with cross-fade effect */}
      <div 
        className={`absolute left-7 transition-opacity duration-300 items-center ${isHovered ? 'opacity-0' : 'opacity-100 mb-[3px]'}`}
      >
        <span className="text-[10px] text-[#4338CA] font-normal font-inter">
          {credit} credits / query
        </span>
      </div>
      
      <div 
        className={`absolute left-7 transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}
      >
        <div className="flex gap-[2px] items-center">
          <Credits isHovered={isHovered || isTransitioning} />
          <span className="text-[10px] text-[#4338CA] font-normal font-inter">credits left</span>
        </div>
      </div>
    </div>
  );
};
