import React from "react";
import PDFPreview from "./previewTypes/PDFPreview";
import CSVPreview from "./previewTypes/CSVPreview";
import CodePreview from "./previewTypes/CodePreview";
import DownloadPreview from "./previewTypes/DownloadPreview";
import LoadingPreview from "./previewTypes/LoadingPreview";
import ErrorPreview from "./previewTypes/ErrorPreview";
import HTMLPreview from "./previewTypes/HTMLPreview.tsx";
import { Preview } from "redux/actions";
import { EThemeType } from "redux/reducers";

interface PreviewContentProps {
  loading: boolean;
  error: string | null;
  preview: Preview;
  fileUrl: string;
  theme: EThemeType | undefined;
}

const PreviewContent: React.FC<PreviewContentProps> = ({ 
  loading, 
  error, 
  preview, 
  fileUrl, 
  theme 
}) => {
  if (loading) {
    return <LoadingPreview />;
  }

  if (error) {
    return <ErrorPreview error={error} fileUrl={fileUrl} />;
  }

  if (!preview || !preview.type) return null;

  switch (preview.type) {
    case "pdf":
      return <PDFPreview url={preview.url} />;
    case "csv":
      return <CSVPreview rows={preview.rows} />;
    case "json":
      return <CodePreview content={preview.content} language="json" />;
    case "xml":
      return <CodePreview content={preview.content} language="xml" />;
    case "markdown":
      return <CodePreview content={preview.content} language="markdown" />;
    case "html":
      return <HTMLPreview content={preview.content} />;
    case "text":
      return <CodePreview content={preview.content} language="text" />;
    case "download":
      return <DownloadPreview fileType={preview.fileType} url={preview.url} theme={theme} />;
    default:
      return null;
  }
};

export default PreviewContent;