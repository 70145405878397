// FilePreviewModal/utils/fileUtils.tsx
import React from "react";
import {
  PDFIcon,
  TextIcon,
  PPTXIcon,
  PPTIcon,
  CSVIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  EMLIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
import { textExtensions } from "utils/constants";
import classNames from "classnames";
import { EThemeType } from "redux/reducers";
import styles from "pages/Workspaces/components/uploadedFiles/uploadedFiles.module.scss";
// import styles from "pages/WorkSpaces/components/FilePreviewModal/FilePreviewModal.module.scss";
import { PreviewableFile } from "redux/actions";

export const getFileType = (file: PreviewableFile): string => {
  const isEmptyFile = file.file.name === "EMpty.txt";
  if (isEmptyFile && file.S3Link) {
    const parts = file.S3Link.split(".");
    return parts.length > 1 ? parts.pop()?.toLowerCase() || "" : "";
  }
  
  const fileType = file.file.type || "";
  
  if (fileType.includes("pdf")) return "pdf";
  if (["text/plain", "txt"].some((type) => fileType.includes(type))) return "txt";
  if (["presentation", "pptx"].some((type) => fileType.includes(type))) return "pptx";
  if (["ms-powerpoint", "ppt"].some((type) => fileType.includes(type))) return "ppt";
  if (fileType.includes("csv")) return "csv";
  if (["sheet", "xlsx"].some((type) => fileType.includes(type))) return "xlsx";
  if (["ms-excel", "xls"].some((type) => fileType.includes(type))) return "xls";
  if (["application/x-subrip", "srt"].some((type) => fileType.includes(type))) return "srt";
  if (["message/rfc822", "eml"].some((type) => fileType.includes(type))) return "eml";
  if (["application/vnd.openxmlformats-officedocument.wordprocessingml.document"].some((type) => fileType.includes(type))) return "docx";
  if (["application/xml", "text/xml"].some((type) => fileType.includes(type))) return "xml";
  if (["application/json", "text/json"].some((type) => fileType.includes(type))) return "json";
  if (["text/markdown", "text/md"].some((type) => fileType.includes(type))) return "md";
  if (["text/mdx"].some((type) => fileType.includes(type))) return "mdx";
  if (["text/html", "application/xhtml+xml"].some((type) => fileType.includes(type))) return "htm";
  if (["text/plain", "log"].some((type) => fileType.includes(type))) return "log";
  if (["application/epub+zip"].some((type) => fileType.includes(type))) return "epub";
  if (["application/vnd.ms-outlook"].some((type) => fileType.includes(type))) return "msg";
  
  const fileName = getFileName(file);
  const extension = fileName.split('.').pop()?.toLowerCase() || "";
  
  // Also check filename extensions for common types
  if (["xml", "json", "md", "mdx", "htm", "html", "log", "epub", "msg"].includes(extension)) {
    return extension;
  }
  
  return extension || "unknown";
};

export const getFileName = (file: PreviewableFile): string => {
  const fileName = file.file.name;
  
  if (!fileName || fileName === "EMpty.txt") {
    return decodeURIComponent(file.S3Link?.split("/").pop() || "unknown");
  }

  return fileName.startsWith("website-") 
    ? fileName.split("-").slice(2).join("-") 
    : fileName;
};

export const renderFileIcon = (fileType: string, theme: EThemeType | undefined): React.ReactNode => {
  if (textExtensions.includes(fileType)) {
    return (
      <div
      className={classNames(styles.loader, styles.uploadedFilePreview, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",

      })}
      >
        <span className="text-[16px] font-medium text-[#A09FA2] uppercase">
          {fileType}
        </span>
      </div>
    );
  }
  
  switch (fileType) {
    case "pdf": return <PDFIcon />;
    case "txt": return <TextIcon />;
    case "pptx": return <PPTXIcon />;
    case "ppt": return <PPTIcon />;
    case "csv": return <CSVIcon />;
    case "xlsx": return <XLSXIcon />;
    case "xls": return <XLSIcon />;
    case "srt": return <SRTIcon />;
    case "eml": return <EMLIcon />;
    case "docx": return <DocIcon />;
    default: return <DocIcon />;
  }
};