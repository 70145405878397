import { useState, useRef } from 'react';
import validUrl from "valid-url";
import { useSelector } from "redux/hooks";
import { useIntl } from 'react-intl';
import { useAppNotification } from 'hooks/services/AppNotification';
import useRouter from 'hooks/useRouter';
import {
  addFile,
  AddUploadedFile,
  AddUploadedId,
  IUploadedFile,
  removeFile,
  updateErrorMessage,
  updateFileProgress,
  updateFileSize,
  updateFileStatus,
  UploadedFileRes,
  UploadFile,
  UploadSource,
  WorkspaceUploadSrc,
} from "redux/actions";
import { RoutePaths } from 'pages/routePaths';
import { DocAllowedTypes,  generateUniqueId, simulateSourceProgress, } from './constants';
import { getFileSize } from "utils/functions";
import { documentMaxCountReached } from './chat';

export const useFileUpload = () => {
  const { triggerNotification } = useAppNotification();
  const { push, pathname } = useRouter();
  const { formatMessage } = useIntl();
  const { userDetail } = useSelector((state) => state.authReducer);
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );
  const { workSpaceFiles, workSpaces, wsUploadedFiles } = useSelector(
    (state) => state.workSpaceReducer
  );

  const AllowedFileSize = 100;
  const FreePlanAllowedFileSize = 10;
  const StandardPlanAllowedFileSize = 50;
  
  const PlanName = userDetail?.user.activeSubscription?.name ?? "";
  const workSpaceId = pathname ? pathname.split("/")[2] : null;
  const [messageId, setMessageId] = useState("");
  const [errorModal, setErrorModal] = useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });
  const isMounted = useRef(true);

  const setValidationError = (messageKey: string) => {
    if (isMounted.current) {
      setErrorModal({
        message: formatMessage({ id: messageKey }),
        show: true,
      });
    }
  };

  const retryUpload = async (existingFileId: string, inputUrl: string) => {
    if (!inputUrl) return;
  
    // Update the file status to show it's retrying
    updateFileStatus(existingFileId, "uploading");
    
    // Get file size again (in case it changed)
    const FileSize = await getFileSize(inputUrl);
    
    // Validate the URL but using the existing file ID
    if (await validateURL(inputUrl, null, FileSize, existingFileId, 
      // true
    )) {
      const latestWorkSpaceId = (workSpaces && workSpaces.length > 0) ? workSpaces[0].id : 1;
  
      const data: WorkspaceUploadSrc = {
        source_type: "url",
        file_path: [inputUrl],
        workspace_id: (workSpaceId !== undefined && workSpaceId !== null) ? Number(workSpaceId) : latestWorkSpaceId,
      };
  
      // Start progress simulation
      const progressInterval = simulateSourceProgress(existingFileId, 0, 99, 2000);
  
      UploadSource(data)
        .then((response) => {
          clearInterval(progressInterval);
          updateFileProgress(existingFileId, 100);
          updateFileStatus(existingFileId, "uploaded");
  
          const res = response as UploadedFileRes;
          const uploadedFile = res.data as IUploadedFile;
          AddUploadedFile(uploadedFile);
          AddUploadedId(existingFileId, uploadedFile.id, uploadedFile.updated_at, uploadedFile.created_at);
          triggerNotification({ message: res.message, type: "info" });
        })
        .catch((error) => {
          clearInterval(progressInterval);
          console.error(error);
          updateFileStatus(existingFileId, "error"); // Keep the file but mark as error
          updateErrorMessage(existingFileId, "error", error?.data?.message || "Upload failed");
          
          // Notify the user of the error
          triggerNotification({
            message: error?.data?.message || "Upload failed",
            type: "error",
          });
        });
    }
  };

  const onSubmit = async (inputUrl: string, resetForm: (() => void) | null) => {
    if (!inputUrl) return;

    const urlExtension = inputUrl?.split(".").pop()?.toLowerCase() || "";

    const uniqueFileIds = new Set([
      ...(workSpaceFiles
        ?.filter((file: UploadFile) => file.status !== "error")
        .map((file: UploadFile) => file.uploaded_id) || []),
      ...(wsUploadedFiles?.map((file: IUploadedFile) => file.id) || []),
    ]);

    const TotalFiles = uniqueFileIds.size;

    if (
      (TotalFiles > 4 && PlanName !== "Free") ||
      (PlanName === "Free" && TotalFiles > 0)
    ) {
      if (PlanName !== "Free"){
        setValidationError("workSpace.upload.validation");}
      else setValidationError("workSpace.upload.validation.Free");
      return;
    }
    const workSpaceFilesLength = Array.isArray(workSpaceFiles) ? workSpaceFiles.length : 0;
    if (workSpaceFilesLength > 4) {
      setValidationError("workSpace.upload.validation");
      return;
    }

    if (PlanName === "Free" && workSpaceFiles.length > 0) {
      if (isMounted.current) {
        setMessageId!("documentChat.plan.max_count");
        resetForm!==null && resetForm();
      }
      return;
    }

    if (DocAllowedTypes.includes(urlExtension)) {
      if (documentMaxCountReached(currentPlan, userDetail)) {
        setMessageId!("documentChat.plan.max_count");
        if (isMounted.current) resetForm!==null && resetForm();
        return;
      }
    }

    const FileSize = await getFileSize(inputUrl ? inputUrl : "");
    if (!isMounted.current) return;
    const file_id = generateUniqueId();
    if (await validateURL(inputUrl, resetForm, FileSize, file_id)) {
      const latestWorkSpaceId = (workSpaces && workSpaces.length > 0) ? workSpaces[0].id : 1;

      const data: WorkspaceUploadSrc = {
        source_type: "url",
        file_path: [inputUrl],
        workspace_id: (workSpaceId !== undefined && workSpaceId !== null) ? Number(workSpaceId) : latestWorkSpaceId,
      };

      updateFileStatus(file_id, "uploading");
      // Start progress simulation before UploadSource
      const progressInterval = simulateSourceProgress(file_id, 0, 99, 2000);

      UploadSource(data, file_id)
        .then((response) => {
          clearInterval(progressInterval);
          updateFileProgress(file_id, 100);
          updateFileStatus(file_id, "uploaded");

          const res = response as UploadedFileRes; // Type assertion
          const uploadedFile = res.data as IUploadedFile;
          AddUploadedFile(uploadedFile);
          AddUploadedId(file_id, uploadedFile.id, uploadedFile.updated_at, uploadedFile.created_at);
          triggerNotification({ message: res.message, type: "info" });
        })
        .catch((error) => {
          clearInterval(progressInterval);
          console.error(error);
          removeFile(file_id);
          // Notify the user of the error or retry the request
          triggerNotification({
            message: error?.data?.message,
            type: "error",
          });
        });
    }
  };

  const validateURL = async (
    inputUrl: string,
    resetForm: (() => void) | null,
    FileSize: number,
    file_id: string,
    // retry?: boolean,
  ): Promise<boolean> => {
    if (validUrl.isHttpsUri(inputUrl ? inputUrl : "")) {
      const commaIndex = inputUrl?.indexOf(",") ?? -1;
      if (commaIndex !== -1) {
        const textAfterComma = inputUrl?.slice(commaIndex + 1).trim();
        if (textAfterComma) {
          setValidationError("upload.url.validation");
          resetForm!==null && resetForm();
          return false;
        }
      }
      if (isMounted.current) resetForm!==null && resetForm();
      const emptyFile = new File([""], "EMpty.txt", { type: "text/plain" });
      const updatedFile: UploadFile = {
        id: file_id,
        file: emptyFile,
        status: "uploaded",
        fileType: "document",
        S3Link: inputUrl,
        errorMessage: "",
        progress: 0,
        fileSize: 0,
      };

  //  if(!retry)  
    addFile(updatedFile);
  // Check if the URL is reachable
  // if (retry) {
  //   await new Promise((resolve) => setTimeout(resolve, 500));
  // }
  
  // try {
  //   const response = await fetch(inputUrl, {
  //     method: 'HEAD',
  //     cache: 'no-cache',
  //   });
  
  //   // Now we can reliably check the status
  //   if (!response.ok) {
  //     updateErrorMessage(file_id, "error", `URL not accessible`);
  //     if (isMounted.current) resetForm !== null && resetForm();
  //     return false;
  //   }
  // } catch (error) {
  //   updateErrorMessage(file_id, "error", `URL not accessible`);
  //   if (isMounted.current) resetForm !== null && resetForm();
  //   return false;
  // }
      if (PlanName === "Free" && FileSize > FreePlanAllowedFileSize) {
        updateErrorMessage(
          updatedFile.id,
          "error",
          `workSpace.file.size.limits`
        );
        resetForm!==null && resetForm();
        return false;
      } else if (
        PlanName === "Standard" &&
        FileSize > StandardPlanAllowedFileSize
      ) {
        updateErrorMessage(
          updatedFile.id,
          "error",
          `workSpace.file.size.limits`
        );
        resetForm!==null && resetForm();
        return false;
      } else if (FileSize > AllowedFileSize) {
        resetForm!==null && resetForm();
        updateErrorMessage(
          updatedFile.id,
          "error",
          `workSpace.file.size.limit`
        );
        return false;
      }

      updateFileSize(updatedFile.id, FileSize);
    } else {
      setValidationError("documentChat.url.validation");
      if (isMounted.current) resetForm!==null && resetForm();
      return false;
    }

    return true;
  };

  const onConfirm = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);

  const onCancel = () => setMessageId("");

  return {
    retryUpload,
    onSubmit,
    validateURL,
    onConfirm,
    onCancel,
    messageId,
    errorModal,
    setErrorModal,
    setMessageId
  };
};