import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { deleteAllChats, getAllChats, IChat, IMessageData, setcontainerHeight } from "redux/actions";
import { useSelector } from "redux/hooks";
import { useAppNotification } from "hooks/services/AppNotification";
import { EmptyFolder, setChatInFolder, ShowFolders } from "redux/actions/chatFolder";
import { LoadingPage } from "components";
import React from "react";
import { ChatSearchItem } from "../ChatItem/ChatSearchItem";
import { useWindowSize } from "hooks/useWindowSize";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { ChatItem } from "../ChatItem";
import { useChatActions } from "hooks/services/ChatServices/useChatAction";
import styles from "../../ChatSidebar.module.scss";
import classNames from "classnames";
import { DeleteChatConfirm } from "../DeleteChatConfirm";
import { SelectChat } from "../SelectChat/SelectChat";
import { ChatFolders } from "../ChatFolders/ChatFolders";
import useRouter from "hooks/useRouter";
import { FormattedMessage } from "react-intl";

interface IProps {
    onSelectChatItem?: (chatItem: IChat | undefined, folder_id: number) => void;
    searchQuery: string;
    setSelectedItem: (chatItem: IChat | undefined) => void;
    selectedItem?: IChat | undefined;
    setOpenHistory?: Dispatch<SetStateAction<boolean>>;
    onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
}

export const ChatHistoryList = ({ searchQuery, onSelectChatItem, setOpenHistory, setSelectedItem, selectedItem, onStartNewChat }: IProps) => {

    const { pathname } = useRouter();
    const chat_folder_Id = pathname.split("/")[4];
    const { width } = useWindowSize();
    const { toggleSidebar } = useToggleSidebar();
    const { triggerNotification } = useAppNotification();
    const contentRef = useRef<HTMLDivElement>(null);
    const [perPage, setPerPage] = useState<number>(0);
    const [loadFolder, setLoadFolder] = useState<boolean>(false);
    const [loadHistory, setLoadHistory] = useState<boolean>(false);
    const [chatDelConfirm, setChatDelConfirm] = useState<Record<number | string, boolean>>({});
    const workSpace = pathname.includes("workspaces");

    const { Folders } = useSelector((state) => state.chatFolderReducer);
    const { containerHeight, pagination, chathistory, PrivateChat } = useSelector((state) => state.chatReducer);
    const { loading, handleSubmit, handleSelectId, handleCancel, selectAllChat, setSelectAllChats, selectedChatIds, setSelectedChatIds, setManuallySelectedChatIds } = useChatActions(setChatDelConfirm, onStartNewChat, selectedItem);
    const showDelete = selectedChatIds["search"]?.length >= 1 && !loadHistory;

    useEffect(() => {
        if (contentRef && contentRef.current) {
            const contentHeight = contentRef?.current?.clientHeight || 0;
            if (contentHeight > containerHeight + 40 || pagination.page === 0) {
                setPerPage(0);
                setcontainerHeight(contentHeight);
            }

            const newPerPage = Math.floor(contentHeight / 28);
            setPerPage(newPerPage);
        }
    }, [contentRef?.current?.clientHeight]);

    useEffect(() => {

        if (searchQuery === "" && Folders.length === 0) {
            setLoadHistory(true);
            setLoadFolder(true);

            const folder_id = !workSpace? Number(chat_folder_Id) || null : null;

            Promise.all([
                getAllChats({ search: searchQuery, page: 1, perPage, folder_id }),
                ShowFolders(),
            ])
                .then(([chatsResponse]) => {

                    const resp = chatsResponse as IMessageData;
                    setLoadFolder(false);
                    setLoadHistory(false);

                    const pagination = {
                        page: resp.current_page,
                        lastPage: resp.last_page,
                    }

                    setChatInFolder(resp.data, pagination, folder_id);
                })
                .catch((err) => {
                    setLoadFolder(false);
                    setLoadHistory(false);
                    triggerNotification({ message: err?.data?.message, type: "error" });
                });
        }
    }, [searchQuery]);

    useEffect(() => {
        let isMounted = true;
        if (searchQuery) {
            setLoadHistory(true);
            const delayDebounce = setTimeout(() => {
                setLoadHistory(true);
                deleteAllChats();
                EmptyFolder();
                getAllChats({ search: searchQuery })
                    .then(() => {
                        if (isMounted)
                            setLoadHistory(false);
                    })
                    .catch((err: any) => {
                        if (isMounted)
                            setLoadHistory(false);
                        triggerNotification({ message: err?.data?.message, type: "error" });
                    });
            }, 1500);
            return () => {
                isMounted = false;
                clearTimeout(delayDebounce);
            }
        }
    }, [searchQuery]);

    const onSelectChat = (chatItem: IChat | undefined, folder_id?: number) => {
        setOpenHistory!(true);
        setSelectedItem(chatItem);
        onSelectChatItem?.(chatItem, folder_id ?? 0);
        if (width && width <= 768) {
            setTimeout(() => toggleSidebar(), 500);
        }
    };

    // Memoized function to handle "Select All" logic
    const handleSelectAllChats = useCallback((isChecked: boolean, folder_id: number | string) => {
        const allChatIds = chathistory?.map(chat => chat.id) || [];
        setSelectedChatIds((prev) => ({
            ...prev,
            [folder_id]: isChecked ? allChatIds : [],
        }));

        setSelectAllChats((prev) => ({ ...prev, [folder_id]: isChecked ? true : false }));
    }, [chathistory]);

    return (
        <>
            {(loadFolder || loadHistory) &&
                <div className={classNames(styles.content)}>
                    <LoadingPage smallLoader isWhite />
                </div>
            }

            <div className={classNames({
                [styles.content]: !loadFolder && !loadHistory,
                [styles.isbackdrop]: PrivateChat,
                [styles.contentScroll]: true,
            })} ref={contentRef}>
                {(!searchQuery && (!loadFolder || !loadHistory)) && (
                    <div>
                        <ChatFolders
                            searchQuery={searchQuery || ''}
                            selectedItem={selectedItem}
                            onSelectChatItem={onSelectChatItem}
                            setSelectedItem={setSelectedItem}
                            onStartNewChat={onStartNewChat}
                            setOpenHistory={setOpenHistory}
                            perPage={perPage}
                        />

                    </div>
                )}

                {showDelete && (
                    <SelectChat
                        setChatDelConfirm={setChatDelConfirm}
                        loading={loading}
                        handleSelectAllChats={handleSelectAllChats}
                        selectAllChat={selectAllChat}
                        handleCancel={handleCancel}
                        folder_id="search"
                        selectedChatIds={selectedChatIds}
                    />
                )}

                {searchQuery && (
                    !loadHistory &&
                    chathistory &&
                    chathistory.length > 0 &&
                    chathistory.map((chatItem, key) => (
                        <React.Fragment>
                            <ChatItem
                                key={`topic-${key}`}
                                chatItem={chatItem}
                                selectedItem={selectedItem}
                                onSelectChat={onSelectChat}
                                searchQuery={searchQuery}
                                handleSelectId={handleSelectId}
                                selectedChatIds={selectedChatIds}
                                setSelectedChatIds={setSelectedChatIds}
                                onStartNewChat={onStartNewChat}
                                setSelectedItem={setSelectedItem}
                                folder_id="search"
                                setManuallySelectedChatIds={setManuallySelectedChatIds}
                            />
                            {(searchQuery && searchQuery.length > 0) && (
                                <ChatSearchItem
                                    chatItem={chatItem}
                                    onSelectChat={onSelectChat}
                                    searchQuery={searchQuery}
                                />
                            )}
                        </React.Fragment>
                    )
                    )
                )}
                {searchQuery && !loadHistory && chathistory?.length === 0 && (
                    <div className={styles.emptySearchMessage} >
                        <FormattedMessage id="sidebar.chat.noChat.message" />
                    </div>
                )}
            </div>
            {/* DELETE CONFIRMATION MODAL */}

            {(chatDelConfirm["search"]) && (
                <DeleteChatConfirm onCancel={() => {
                    handleCancel(true, "search")
                }} onSubmit={() => {
                    handleSubmit({ searchQuery, folder_id: "search" });
                }} loading={loading} />
            )
            }
        </>
    );
};