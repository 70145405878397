import styles from "../../FilePreviewModal.module.scss";

interface IProp {
    url: string;
}

const PDFPreview = ({ url }: IProp) => (
  <div className={styles.styledPdfContainer}>
    <div className={styles.pdfPreviewFrame}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <iframe 
          src={`${url}#toolbar=0&view=Fit`}
          className={styles.pdfIframe}
          title="PDF Preview"
          allowFullScreen={true}
          style={{ width: "100%", height: "100%", border: "none", background: "transparent" }}
        />
      </div>
    </div>
  </div>
);

export default PDFPreview;