import { useState , Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useSelector } from "redux/hooks";
import useRouter from "hooks/useRouter";
import { MainView } from "views/layout/MainView";
import { useThreadsHistoryContext } from "hooks/ThreadHistoryContext";
import { ErrorHandler } from "pages/Workspaces/components/ErrorHandler/ErrorHandler";
import { RoutePaths } from "pages/routePaths";
import { useFileUpload } from "hooks/useFileUpload";
import { setChatModel, EChatType, setCurrentConversation, ShowWorkspace,  ChatType, NewChatThreadWS, } from "redux/actions";
import _ from "lodash";
import { WorkspaceConversation } from "./WorkspaceConversation.tsx/WorkspaceConversation";
import { ThreadsConversation } from "./ThreadsConversation/ThreadsConversation";
import { DragDropModal } from "./WorkspaceConversation.tsx/Component/Drag&DropModal";
import { ChatSettingModal } from "pages/ChatPage/components/chatSettingModal";

export const ConversationRouter = () => {
  const { pathname } = useRouter();
  const pathParts = pathname.split("/").filter(Boolean);
  
  const isWorkspacePath = pathParts[0] === "workspaces";
  const hasThreadId = pathParts.includes("thread") && pathParts.length > pathParts.indexOf("thread") + 1;
  
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const renderThreadsContent = isWorkspacePath && hasThreadId;
  
  return (
      <ConversationRouterContent 
        renderThreadsContent={renderThreadsContent} 
        isDrag={isDrag}
        setIsDrag={setIsDrag}
      />
  );
};

const ConversationRouterContent = ({ 
  renderThreadsContent, 
  isDrag, 
  setIsDrag 
}: { 
  renderThreadsContent: boolean;
  isDrag: boolean;
  setIsDrag: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    isAnswerComplete,
    isFileUploading,
    openHistory,
    sizeMessage,
    chatSettings,
    setChatSetting,
    settings,
    setSettings,
    loadingSetting,
  } = useThreadsHistoryContext();
  const { push, pathname } = useRouter();
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { gptModel } = useSelector((state) => state.authReducer);
  const [addSource, setAddSource] = useState<boolean>(false);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const { newMessages, messages } = useSelector((state) => state.chatReducer);
  const { workSpaces,  isModalOpen, openUploadURLModel } = useSelector((state) => state.workSpaceReducer);
  const Id = pathname.split("/")[2];

  const { errorModal, setErrorModal, messageId, setMessageId, handleDrop } = useFileUpload(false);
  
  const handleUpdateModel = (modelType: EChatType) => {
    let index = chatModels.findIndex(
      (model) => model.type.includes(modelType) && model.attributes?.default === true
    );
    if (index >= 0) {
      if ((_.isEqual(gptModel?.type, ["document", "text"]) || (gptModel?.type.includes("text") && gptModel?.type.includes("document")))) {
      } else {
        const imageGPTModel = localStorage.getItem("documentChatGptModel");
        const selectedModel = imageGPTModel
          ? JSON.parse(imageGPTModel)
          : chatModels[index];
        localStorage.setItem(
          "GptModel",
          imageGPTModel || JSON.stringify(chatModels[index])
        );
        setChatModel(selectedModel);
      }
    }
  };

const handleWorkSpace = async () => {
  setLoadingData(true);
  try {
    await ShowWorkspace();
  } catch (error) {
    console.error(error);
  } finally {
    setLoadingData(false);
  }
};

useEffect(() => {
  if (workSpaces.length === 0 && !loadingData) {
    handleWorkSpace();
  }
},[]);

useEffect(() => {
if ( Id && workSpaces.length !== 0) {
    setCurrentConversation(Number(Id));
    NewChatThreadWS(Number(Id));
  }
}, [workSpaces, Id]);

const isAllowUploadFile = useMemo(() => {

  if (gptModel?.type.includes(ChatType.image)) {
    return false;
  }

  if(addSource || openUploadURLModel) {
    return false;
  }

  if(isModalOpen) return true;

  if ( renderThreadsContent && messages[0]?.chatType === "text" ) {
      return false;
  }
  if (newMessages.length > 0) {
    const firstMessage = newMessages[0];
    if ( firstMessage?.chatType === "text" ) {
      return false;
    }
  }
  return true;
}, [gptModel, newMessages.length, messages]);
  
  return (
    <div>
      <MainView 
        setIsDrag={setIsDrag}
        isAnswerComplete={isAnswerComplete} 
        updateChatModel={handleUpdateModel} 
        isAllowUploadFile={isAllowUploadFile}
        setChatSetting={setChatSetting}
      >
        {renderThreadsContent 
         ? (
          <ThreadsConversation
            isAllowUploadFile={isAllowUploadFile}
          />
        ) : (
          <WorkspaceConversation 
            isAnswerComplete={isAnswerComplete}
            isFileUploading={isFileUploading}
            openHistory={openHistory}
            addSource={addSource}
            setAddSource={setAddSource}
            loadingData={loadingData}
            isAllowUploadFile={isAllowUploadFile}
          />
        )}
      
      </MainView>

      {isDrag && (
        <DragDropModal
          handleClose={() => setIsDrag(false)}
          handleDrop={async (event: React.DragEvent<HTMLDivElement>) => {
            await handleDrop(event, openUploadURLModel? false : isModalOpen)
          }}
        />
      )}

       {chatSettings && (
                <ChatSettingModal
                  onClose={() => setChatSetting(false)}
                  loadingSetting={loadingSetting}
                  settings={settings}
                  setSettings={setSettings}
                />
              )}
      <ErrorHandler
        errorModal={errorModal}
        setErrorModal={setErrorModal}
        messageId={messageId ? messageId : sizeMessage}
        onConfirm={() => push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)}
        onCancel={() => setMessageId("")}
      />
    </div>
  );
};

export default ConversationRouter;