import { Tooltip } from "components";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import styles from "./answerContainer.module.scss";
import {
  AudioPlayer,
} from "redux/actions";

import {
  AmplifierIcon,
  StopIcon,
  LoadingIcon,
} from "../icons";
import { FormattedMessage } from "react-intl";
import { useAudio } from "hooks/services/AudioContext";
import { ThAmplifierIcon, ThStopIcon } from "../icons/ThreadsAnsIcons";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";
import { RIPPLE_COLORS } from "utils/constants";
interface AudioPlayerProps {
  message: string;
  messageId: number;
  threads?: boolean;
}

export const AudioController: React.FC<AudioPlayerProps> = ({ message, messageId, threads }) => {


  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const { theme } = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);

  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const audioURLRef = useRef<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const responseStartedRef = useRef<boolean>(false);

  const { playAudio, isPlaying, setIsPlaying, currentMessageId, stopAudio, setCurrentMessageId, isPlayingVideo, stopVideo, setIsAudioLoading, isAudioLoading } = useAudio(); // Use audio context

  const stopAudioPlayback = () => {
    if (audioElementRef.current) {
      setIsLoading(false);
      setIsAudioLoading(false);
      stopAudio();
      setCurrentMessageId(null);
      audioElementRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    return () => {
      sourceRef.current?.stop();
      stopAudioPlayback();
      audioContextRef.current?.close();
      if (!responseStartedRef.current) {
        abortControllerRef.current?.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (isAudioLoading && isLoading) {
      if (abortControllerRef.current)
        abortControllerRef.current.abort();
      setIsAudioLoading(false);
      setIsLoading(false);
    }
  }, [isAudioLoading]);


  const playAudioInstance = (url: string) => {
    if (isPlayingVideo) {
      stopVideo(); // Pause video if playing
    }
    // Create a new audio element if it doesn't exist
    if (!audioElementRef.current) {
      audioElementRef.current = new Audio(url);
      audioElementRef.current.addEventListener("ended", () => {
        setIsPlaying(false);
      });
    }

    // If the audio element already exists and the URL is different, stop current audio
    if (audioElementRef.current.src !== url) {
      stopAudioPlayback();
      audioElementRef.current.src = url;
    }

    playAudio(audioElementRef.current, messageId);
    setIsPlaying(true);
  };

  const onGenerateSpeech = async () => {

    if (audioURLRef.current) {
      playAudioInstance(audioURLRef.current);
      return;
    }

    if (isLoading) return;

    if (!audioElementRef.current) {
      audioElementRef.current = new Audio();
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    responseStartedRef.current = false;

    try {
      setIsLoading(true);
      const data = {
        model: "tts-1",
        voice: "alloy",
        text: message,
      };
      const response = await AudioPlayer(
        data, userDetail?.token, abortControllerRef.current.signal
      );

      if (response) { // Check if the response started
        responseStartedRef.current = true;
      }

      const mediaSource = new MediaSource();

      const url = URL.createObjectURL(mediaSource);

      audioURLRef.current = url;
      if (audioElementRef.current) {
        audioElementRef.current.src = url;
      }

      mediaSource.addEventListener("sourceopen", () => {
        const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");

        const processData = async () => {
          const { done, value } = await response!.read();

          if (done) {
            if (!sourceBuffer.updating) {
              mediaSource.endOfStream();
            } else {
              sourceBuffer.addEventListener("updateend", () => {
                if (!sourceBuffer.updating) {
                  mediaSource.endOfStream();
                }
              });
            }
            setIsLoading(false);
            return;
          }

          if (value) {
            sourceBuffer.appendBuffer(value);
          }
          if (!sourceBuffer.updating) {
            processData();
          } else {
            sourceBuffer.addEventListener("updateend", processData, {
              once: true,
            });
          }
        };

        processData();
      });

      playAudioInstance(url);
      setTimeout(() => setIsLoading(false), 500);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (isPlaying) {
        setIsPlaying(false);
      }
    }
  };

  const themeColors = theme === 'dark' ? RIPPLE_COLORS.dark : RIPPLE_COLORS.light;
  return (
    <>
    <>
      {/* <div className={`cursor-pointer`}> */}
        {isLoading && (currentMessageId !== messageId) && (
          <div className={`flex items-center justify-center ${classNames({ [styles.iconContainer]: !threads }, {
            [styles.light]: theme === 'light',
            [styles.dark]: theme === 'dark',
          })}`}>
            <LoadingIcon className={styles.animatespin} theme={theme} />
          </div>
        )}

        {(currentMessageId === messageId) && (
         
         <RippleIconButton
         color={themeColors.default}
         audioController={true}
         className={classNames(
            { [styles.iconContainer]: !threads }, // Exclude when threads is true
            {
              [styles.light]: theme === 'light',
              [styles.dark]: theme === 'dark',
            },
             "cursor-pointer",
            )} onClick={stopAudioPlayback}>
            {threads ? <ThStopIcon /> : <StopIcon theme={theme} />}
          </RippleIconButton>
        )}
      {/* </div> */}
      </>
      {(!isLoading && ((currentMessageId !== messageId || currentMessageId === null))) && (
        <Tooltip
          regenrate={true}
          tooltipAdjustement={true}
          control={
            <RippleIconButton
            audioController={true}
            color={themeColors.default}
              className={classNames("pt-[2px]",styles.icon, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
                [styles.iconContainer]: !threads,
              })} onClick={onGenerateSpeech}
              >
            {threads ? <ThAmplifierIcon /> : <AmplifierIcon theme={theme} />}
             
            </RippleIconButton>
          }
          placement="top"
          theme="light"
        >
          <div>
            <FormattedMessage id="answer.tooltip.audio" />
          </div>
        </Tooltip>
      )}
    </>
  );
};