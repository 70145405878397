import React, { Dispatch, FormEvent, SetStateAction,useState } from "react";
import styles from "../../chatFooter.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { FormattedMessage } from "react-intl";
import { ChatType, EChatType, IChat, IChatModel } from "redux/actions";
import { SearchField } from "pages/ChatPage/components/searchField";
import { Tooltip } from "components";
import { PromptIcon } from "pages/ChatPage/components/icons";
import { RoleBotIcon } from "pages/ChatPage/components/icons/RoleBotIcon";
import { ImageGeneration } from "../Icons";
import { IUploadFile, 
} from "pages/ChatPage/ChatPage";
import { IFileErrorState } from "pages/ChatPage/pages";
import BotSetting from "../BotSetting/BotSetting";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { useWindowSize } from "hooks/useWindowSize";


interface FilePreviewProps {
  onSubmit?: ({
    event,
    message,
  }: {
    event: FormEvent<EventTarget | HTMLFormElement>;
    message: string;
  }) => void;
  onEnter?: ({
    event,
    message,
  }: {
    event: React.KeyboardEvent<HTMLTextAreaElement>;
    message: string;
  }) => void;
  isAnswerComplete?: boolean;
  isFileUploading: boolean;
  settings: {
    real_time_results: boolean;
    related_questions: boolean;
    send_message_with_enter: boolean;
  };
  updateChatModel?: (modalType: EChatType) => void;
  setSelectedFile: Dispatch<SetStateAction<File[] | null>>;
  selectedFile: File[] | null;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  onSendMessage: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  openHistory?: boolean;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  fileS3Link?: string[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
  loadingSetting: boolean;
  showPromptModal: boolean;
  setShowPromptModal: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<string>>;
  message: string;
  onTextareaFocus: () => void;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  AllowCustomizeResponse: boolean;
  url: string;
  setURL: Dispatch<SetStateAction<string>>;
  resetMessage: () => void;
  setUploadUrl: Dispatch<SetStateAction<boolean>>;
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
  handleSubmit: (event: FormEvent) => void;
  handleStartNewImageChat: () => void;
  getMessagesLoading: boolean | undefined;
  credit: number;
}

export const ChatFooterForm: React.FC<FilePreviewProps> = ({
  onSubmit,
  onEnter,
  isAnswerComplete,
  isFileUploading,
  settings,
  updateChatModel,
  setSelectedFile,
  selectedFile,
  setIsMainScreenOpen,
  setIsFileUploading,
  onSendMessage,
  openHistory,
  setOpenHistory,
  uploadingFiles,
  fileS3Link,
  setUploadingFiles,
  setFileS3Link,
  isAllowUploadFile,
  chatItem,
  loadingSetting,
  setShowPromptModal,
  setMessage,
  message,
  onTextareaFocus,
  textareaRef,
  AllowCustomizeResponse,
  url,
  setURL,
  resetMessage,
  setUploadUrl,
  setErrorModal,
  handleSubmit,
  handleStartNewImageChat,
  getMessagesLoading,
  credit,
}) => {

  const { theme, gptModel } = useSelector((state) => state.authReducer);
  const chatModel = useSelector((state) => state.authReducer.gptModel);
  const { RememberType} = useSelector((state) => state.chatReducer);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isTextareaFocused, setIsTextareaFocused] = useState<boolean>(false);
  const onOpenPromptLibrary = () => setShowPromptModal(true);
  const { isOpen } = useToggleSidebar();
  const { width } = useWindowSize();

  const getMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(e.target.value);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (
      fileS3Link &&
      fileS3Link.length > 0 &&
      openHistory === false &&
      isAnswerComplete === true &&
      !isFileUploading && (fileS3Link.length === (uploadingFiles && uploadingFiles.length))
    ) {
      const s3Links: string[] = (uploadingFiles ?? [])
        .map((file) => file.S3Link)
        .filter((link): link is string => link !== undefined);
      if (uploadingFiles && uploadingFiles[0]?.fileType === "image")
        onSendMessage!(message ? message : "", chatModel, false, s3Links ?? []);
      else if (uploadingFiles && (uploadingFiles[0]?.fileType === "document" ||  uploadingFiles[0]?.fileType === "video" || uploadingFiles[0]?.fileType === "audio"))
        onSendMessage!(
          message ? message : "",
          chatModel,
          false,
          [],
          s3Links ?? []
        );
      setUploadingFiles!([]);
    } else if (!isFileUploading && !url && (uploadingFiles && uploadingFiles.length === 0)) onSubmit?.({ event: event, message });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    {
      const s3Links: string[] = (uploadingFiles ?? [])
      .map((file) => file.S3Link)
      .filter((link): link is string => link !== undefined);
      if (!settings?.send_message_with_enter) {
        if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
          if (
            fileS3Link &&
            fileS3Link.length > 0 &&
            openHistory === false &&
            isAnswerComplete === true &&
            !isFileUploading && (fileS3Link.length === (uploadingFiles && uploadingFiles.length))
          ) {
            event.preventDefault();
            if (uploadingFiles && uploadingFiles[0]?.fileType === "image")
              onSendMessage!(
                message ? message : "",
                chatModel,
                false,
                s3Links ?? []
              );
            else if (
              uploadingFiles &&
              (uploadingFiles[0]?.fileType === "document" || uploadingFiles[0]?.fileType === "video" || uploadingFiles[0]?.fileType === "audio" )
            )
              onSendMessage!(
                message ? message : "",
                chatModel,
                false,
                [],
                s3Links ?? []
              );
            setUploadingFiles!([]);
          } else if (!isFileUploading && !url && (uploadingFiles && uploadingFiles.length === 0))
            onEnter?.({ event: event, message });
        }
      } else {
        if (!event.shiftKey && event.key === "Enter") {
          if (
            fileS3Link &&
            fileS3Link.length > 0 &&
            openHistory === false &&
            isAnswerComplete === true &&
            !isFileUploading && (fileS3Link.length === (uploadingFiles && uploadingFiles.length))
          ) {
            event.preventDefault();
            if (uploadingFiles && uploadingFiles[0]?.fileType === "image")
              onSendMessage!(
                message ? message : "",
                chatModel,
                false,
                s3Links ?? []
              );
            else if (
              uploadingFiles &&
              (uploadingFiles[0]?.fileType === "document" || uploadingFiles[0]?.fileType === "video" || uploadingFiles[0]?.fileType === "audio") 
            )
              onSendMessage!(
                message ? message : "",
                chatModel,
                false,
                [],
                s3Links ?? []
              );
            setUploadingFiles!([]);
          } else if (!isFileUploading && !url && (uploadingFiles && uploadingFiles.length === 0))
            onEnter?.({ event: event, message });
        }
      }
    }
  };

  return (
    <>
      <form
        className={`${styles.form} ${getMessagesLoading? styles.isbackdrop: ""}`}
        onSubmit={handleFormSubmit}
        data-testid="chat-footer"
      >
        {(gptModel?.type?.includes(ChatType.text) ||
          gptModel?.type?.includes(ChatType.document)) && (
          <Tooltip
            placement="top"
            theme="light"
            prompt={true}
            AdjustTooltip={true}
            control={
              <div
                data-testid="prompt-library"
                className={`hover:cursor-pointer text-light dark:text-dark ${styles.chatfooterPromptbtn}`}
                onClick={onOpenPromptLibrary}
              >
                <PromptIcon 
                />
              </div>
            }
          >
            <FormattedMessage id="prompt.library" />
          </Tooltip>
        )}
        {((gptModel?.type?.includes(ChatType.text) && AllowCustomizeResponse && getMessagesLoading === false) || (getMessagesLoading === true && (RememberType ==='text' 
        || RememberType ==='document'
       )) ) && (
          <>
            <Tooltip
              placement="top"
              theme="light"
              prompt={true}
              customize={true}
              AdjustTooltip={true}
              control={
                <div
                  className={styles.footerIconWrapper}
                  onClick={toggleDropdown}
                >
                  <RoleBotIcon 
                  />
                </div>
              }
            >
              <FormattedMessage id="customize.response" />
            </Tooltip>
          </>
        )}
        {isDropdownVisible && (
          <BotSetting
            onClose={closeDropdown}
            loadingSetting={loadingSetting}
          />
        )}
        <SearchField
          value={message}
          onChange={getMessage}
          onKeyDown={(event) => handleKeyDown(event)}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
          autoFocus={!(width <= 786 && isOpen)}
          isloading={!isAnswerComplete}
          textareaRef={textareaRef}
          isFieldFocused={isTextareaFocused}
          onTextareaFocus={onTextareaFocus}
          handleSubmit={handleSubmit}
          updateChatModel={updateChatModel}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setIsMainScreenOpen={setIsMainScreenOpen}
          setUploadUrl={setUploadUrl}
          resetMessage={resetMessage}
          message={message}
          url={url}
          setURL={setURL}
          onSendMessage={onSendMessage}
          setOpenHistory={setOpenHistory}
          uploadingFiles={uploadingFiles}
          setUploadingFiles={setUploadingFiles}
          setIsFileUploading={setIsFileUploading}
          setFileS3Link={setFileS3Link}
          isAllowUploadFile={isAllowUploadFile}
          chatItem={chatItem}
          setErrorModal={setErrorModal}
          getMessagesLoading={getMessagesLoading?? false}
          credit={ credit}
          fileS3Link={fileS3Link}
        />
        <Tooltip
          placement="top"
          theme={theme}
          prompt={true}
          imageGeneration={true}
          AdjustTooltip={true}
          control={
            <div
              className={classNames(styles.chatfooterbtn, {
                [styles.imagegeneration]:
                  gptModel?.type?.includes(ChatType.image) &&
                  !gptModel?.type?.includes(ChatType.image_chat),
                  [styles.light]:theme==="light",
                  [styles.dark]:theme==="dark",
              })}
              onClick={handleStartNewImageChat}
            >
              <div
                className={`flex justify-center items-center h-[50px]
               ${classNames(styles.imagegenerationIcon, {
                  [styles.light]:theme==="light",
                  [styles.dark]:theme==="dark",
               })}
                `}
              >
                <ImageGeneration 
                  imageGeneration={
                    gptModel?.type?.includes(ChatType.image) &&
                    !gptModel?.type?.includes(ChatType.image_chat)
                  }
                  disable={!isFileUploading && isAnswerComplete}
                />
              </div>
            </div>
          }
        >
          <FormattedMessage id="doc.generate.image" />
        </Tooltip>
      </form>
    </>
  );
};
