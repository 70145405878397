import {
  useEffect, useState, memo,
  Dispatch, SetStateAction
} from "react";
import classNames from "classnames";
import styles from "./ChatItem.module.scss";

import { Spinner, Tooltip } from "components";
import { WordHighlighter } from "./WordHighlighter";

import {
  TickIcon,
  RemoveIcon,
  NewImageIcon,
  NewChatIcon,
  ImageGenerationIcon,
  VideoIcon,
  AudioIcon
} from "../icons";

import {
  IChat, updateChatHistory,
  removeMultipleChat,
  deleteSingleChatHistory,
  EChatType
} from "redux/actions";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import { NewDocumentIcon } from "../icons/NewDocumentIcon";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { ChatFolderDel, ChatFolderEdit } from "../icons/ChatFolder";
// import { useChatActions } from "hooks/services/ChatServices/useChatAction";

interface IProps {
  chatItem: IChat;
  selectedItem?: IChat;
  onSelectChat?: (itemId: IChat | undefined, folder_id: number) => void;
  searchQuery: string;
  handleSelectId: (folder_id: number | string, id: number) => void;
  selectedChatIds: Record<string | number, number[]>;
  setSelectedChatIds: Dispatch<SetStateAction<Record<string | number, number[]>>>;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  setSelectedItem: (chatItem: IChat | undefined) => void;
  folder_id: number | string;
  setManuallySelectedChatIds: Dispatch<SetStateAction<Record<string | number, number[]>>>;
}

export const ChatItem = memo(
  ({ chatItem, selectedItem, setSelectedItem, onSelectChat, searchQuery,
    handleSelectId, selectedChatIds, setSelectedChatIds, onStartNewChat, folder_id, setManuallySelectedChatIds }: IProps) => {
    const themeMode = useSelector((state) => state.authReducer.theme);
    const { chathistory } = useSelector((state) => state.chatReducer);
    const { Folders } = useSelector((state) => state.chatFolderReducer);
    const { triggerNotification } = useAppNotification();
    const { push } = useRouter();
    const { sidebarWidth } = useSidebar();

    const isActive: boolean = selectedItem?.id === chatItem.id;

    const [message, setMessage] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [isloading, setIsloading] = useState<boolean>(false);
    const [rightClickedId, setRightClickedId] = useState<number | null>(null);

    useEffect(() => {
      const currentPath = window.location.pathname;
      const pathSegments = currentPath.split('/');
      let chatId;
      if (pathSegments.length === 5)
        chatId = pathSegments[pathSegments.length - 2];
      else
        chatId = pathSegments[pathSegments.length - 1];

      if (chatItem.id === Number(chatId)) {
        setSelectedItem(chatItem);
      }
      else if (chatId === 'new') { setSelectedItem(undefined) }
    }, [window.location.pathname, chathistory, Folders]);

    useEffect(() => {
      if (!isEdit) {
        setMessage(selectedItem?.topic as string)
      }
    }, [selectedItem]);

    useEffect(() => {
      if (!message && !isEdit) {
        setMessage(selectedItem?.topic as string);
      }
    }, [selectedItem, message, isEdit]);

    const toggleEdit = () => {
      setIsEdit((prev) => {
        const newEditState = !prev;
        if (!newEditState) {
          setMessage("");
        }
        return newEditState;
      });
    }

    const resetState = () => {
      setTimeout(() => {
        setIsloading(false);
        setMessage("");
        toggleEdit();
      }, 500);
    };

    const onEdit = () => {
      setIsloading(true);

      updateChatHistory(selectedItem?.id as number, message, folder_id)
        .then((resp: any) => {

          const chatIndex = chathistory.findIndex((chat) => chat.id === resp.id);
          if (folder_id !== null && folder_id !== undefined && folder_id !== "search") {
            let folder = Folders.find((f) => f.id === folder_id);
            if (folder) {
              const folderChatIndex = folder.chats.findIndex((chat) => chat.id === resp.id);
              if (folderChatIndex >= 0) {
                resetState()
                return;
              };
            }
          }
          if (chatIndex >= 0) {
            resetState();
          }
        })
        .catch((err) => {
          setIsloading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    };

    const toggleDelete = () =>
      setIsDelete((prev) => {
        return !prev;
      });

    const onDelete = () => {
      setIsloading(true);

      const currentPath = window.location.pathname;

      const pathSegments = currentPath.split('/');
      const chatIdFromUrl = pathSegments[pathSegments.length - 2];
      if ((chatItem.id === selectedItem?.id)) {
        push(`/${RoutePaths.Chat}`);
      }
      if (chatItem.id === Number(chatIdFromUrl)) {
        onStartNewChat?.({ toChat: true });
      }
      toggleDelete();
      deleteSingleChatHistory(chatItem.id, folder_id);

      setSelectedChatIds((prevSelectedChatIds) => {
        const folderChatIds = prevSelectedChatIds[folder_id] || [];
        if (folderChatIds.includes(chatItem.id)) {
          return {
            ...prevSelectedChatIds,
            [folder_id]: folderChatIds.filter((id) => id !== chatItem.id),
          };
        } else {
          return prevSelectedChatIds;
        }
      });

      setManuallySelectedChatIds((prevSelectedChatIds) => {
        const folderChatIds = prevSelectedChatIds[folder_id] || [];
        if (folderChatIds.includes(chatItem.id)) {
          return {
            ...prevSelectedChatIds,
            [folder_id]: folderChatIds.filter((id) => id !== chatItem.id),
          };
        } else {
          return prevSelectedChatIds;
        }
      });

      removeMultipleChat([chatItem.id])
        .then((resp: any) => {
          if (resp?.message === "Chats deleted successfully") {
          }
          setIsloading(false);
        })
        .catch((err) => {
          setIsloading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    };

    const onConfirm = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (isDelete) onDelete();
      else onEdit();
    };

    const onCancel = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (isDelete) toggleDelete();
      else toggleEdit();
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      e.stopPropagation();
      handleSelectId?.(folder_id, id);
    };

    const handleRightClick = () => {
      setRightClickedId(chatItem.id); // Set the right-clicked chat ID
    };

    const getChatTypeIcon = (chatType: EChatType) => {
      const icons: Record<EChatType, JSX.Element> = {
        text: <NewChatIcon />,
        document: <NewDocumentIcon />,
        image_chat: <NewImageIcon />,
        image: <ImageGenerationIcon />,
        video: <VideoIcon />,
        audio: <AudioIcon />,
      };

      return icons[chatType] || null;
    };

    return (
      <Tooltip
        control={
          <a href={`/chat/history/${rightClickedId}/${folder_id}`} onClick={(e) => e.preventDefault()} data-testid="chat-link">
            <div
              className={classNames(styles.itemContainer, {
                [styles.itemContainerDark]: themeMode === "dark",
                [styles.itemContainerLight]: themeMode === "light",
                [styles.active]: isActive,
              })}
              onClick={() => {
                if (typeof folder_id === 'number' && !isNaN(folder_id)) {
                  onSelectChat?.(chatItem, folder_id);
                } else {
                  onSelectChat?.(chatItem, chatItem?.folder?.id ?? 0); // Call without folder_id if it's not a number
                }
              }
                //  onSelectChat?.(chatItem, folder_id)
              }

              onContextMenu={handleRightClick}
              data-testid='select-chat'
            >
              <div className={styles.check}>
                <input type="checkbox"
                  checked={selectedChatIds?.[folder_id]?.includes(chatItem.id)}
                  onChange={(e) => handleCheckboxChange(e, chatItem.id)}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  className={selectedChatIds?.[folder_id]?.includes(chatItem.id) ? styles.visibleCheckbox : styles.hiddenCheckbox}
                />
                <div className={styles.actionIcon} style={{ marginTop: chatItem.chat_type === "text" ? "3px" : "", marginLeft: chatItem.chat_type === "document" ? "1.5px" : "" }}>
                  {getChatTypeIcon(chatItem.chat_type)}
                </div>

              </div>
              <div
                className={classNames(styles.itemMsgActionContainer, {
                  [styles.itemMsgActionContainerLight]: themeMode === "light",
                  [styles.itemMsgActionContainerDark]: themeMode === "dark",
                  [styles.active]: isActive,
                  [styles.isDeleted]: isDelete
                })}
              >
                <div className={classNames(styles.itemMessageContainer)}>
                  {isEdit ? (
                    <input
                      className={classNames(styles.editItemTextfield, {
                        [styles.light]: themeMode === "light",
                        [styles.dark]: themeMode === "dark",
                        [styles.editField]: sidebarWidth <= 360,
                      })}
                      type="text"
                      autoFocus
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                      }}
                      data-testid='edit-input'
                    />
                  ) : (
                    <div
                      className={classNames(styles.itemText, {
                        [styles.isActive]: isActive,
                        [styles.isDeleted]: isDelete,
                        [styles.itemField]: sidebarWidth <= 325,
                        [styles.itemFieldAdjust]: sidebarWidth <= 350 && sidebarWidth > 325,
                      })}
                    >
                      <WordHighlighter
                        searchQuery={searchQuery}
                        text={chatItem.topic || "New chat"}
                        excerptLength={160}
                      />
                    </div>
                  )}
                </div>
                {!isActive && !isEdit && !isDelete && (
                  <div className={classNames(styles.deleteContainer, {
                    [styles.itemField]: sidebarWidth <= 415,
                  })}>
                    <div data-testid='del-btn' className={styles.actionIcon} onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleDelete();
                    }}>
                      <ChatFolderDel />
                    </div>
                  </div>
                )}
                {isActive && !isEdit && !isDelete && (
                  <div className={classNames(styles.actContainer, {
                    [styles.editOnly]: isActive && !isDelete,
                    [styles.IconAdjust]: isActive && !isDelete && sidebarWidth <= 325,
                    [styles.IconAdjustLg]: isActive && !isDelete && sidebarWidth <= 527 && sidebarWidth > 325,
                  })}>
                    <div data-testid='edit-btn' className={styles.actionIcon} onClick={(e) => {
                      e.stopPropagation(); // Prevent anchor's default behavior when clicking edit
                      e.preventDefault();
                      toggleEdit();
                    }}>
                      <ChatFolderEdit />
                    </div>
                    <div data-testid='del-btn' className={styles.deleteIcon} onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleDelete()
                    }}>
                      <ChatFolderDel />
                    </div>
                  </div>
                )}
                {isEdit && (
                  <div className={styles.actionsContainer}>
                    {isloading ? (
                      <Spinner extraSmall isWhite={themeMode === 'light'} />
                    ) : (
                      <div data-testid='confirm-edit-btn' className={styles.actionIcon} onClick={(e) => onConfirm(e)}>
                        <TickIcon />
                      </div>
                    )}
                    <div data-testid='cancel-edit-btn' className={styles.actionIcon} onClick={(e) => onCancel(e)}>
                      <RemoveIcon />
                    </div>
                  </div>
                )}
                {isDelete && (
                  <div className={styles.actionsContainer}>
                    <div data-testid='cancel-delbtn' className={styles.actionIcon} onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onCancel(e)
                    }}>
                      <RemoveIcon />
                    </div>
                    <div data-testid='confirm-del-btn' className={styles.actionIcon} onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onConfirm(e)
                    }}>
                      <TickIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </a>
        }
        placement="right"
        theme="light"
      >
        {chatItem.topic || "New Chat"}
      </Tooltip>
    );
  }
);
