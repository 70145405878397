export const ContinueIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
  >
    <path
      d="M9.79018 6.5045C10.0692 6.22548 10.0692 5.77236 9.79018 5.49334L6.21875 1.92191C5.93973 1.64289 5.48661 1.64289 5.20759 1.92191C4.92857 2.20093 4.92857 2.65405 5.20759 2.93307L7.5625 5.28575H0.714286C0.319196 5.28575 0 5.60495 0 6.00003C0 6.39512 0.319196 6.71432 0.714286 6.71432H7.56027L5.20982 9.067C4.9308 9.34602 4.9308 9.79914 5.20982 10.0782C5.48884 10.3572 5.94196 10.3572 6.22098 10.0782L9.79241 6.50673L9.79018 6.5045Z"
      fill="currentColor"
    />
  </svg>
);
