import { FormattedMessage } from "react-intl";
import { EditIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import styles from "../workSpaceConversation.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { useEffect, useState } from "react";
import { Loader } from "components/Loader";

interface IProp {
    setShowInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MiniConversationHeader = ({ setShowInfo }: IProp) => {
    const { theme } = useSelector((state) => state.authReducer);
    const { currentConversation } = useSelector((state) => state.workSpaceReducer);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (currentConversation?.name) {
            setIsLoading(false);
        }
    }, [currentConversation]);

    return (
        <>
            <div className="font-inter flex justify-between mt-[14px] pr-[19px] pl-[15px] ">
                <div className={classNames(styles.workspaceName, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}
                >  {isLoading ? (
                   <div className="flex items-center mt-1"> <Loader /></div>
                ) : (
                    currentConversation?.name
                )}</div>
                <div
                    className={styles.addSourcesContainer}
                    onClick={() => setShowInfo(true)}
                >
                    <span
                        className={classNames(styles.text, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}
                    >
                        <FormattedMessage id="workspace.edit.title" />
                    </span>
                    <span className="flex-shrink-0">
                        <EditIcon theme={theme} />
                    </span>
                </div>
            </div>
        </>
    )
}