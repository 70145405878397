import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useSelector } from "redux/hooks";
import { EThemeType } from 'redux/reducers';

interface AudioFile {
    path: string;
    name: string;
}

interface AudioPreviewProps {
    audio: AudioFile;
    onClose?: () => void;
}

const AudioPreviewOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999999; /* Extremely high z-index */
`;

const AudioContainer = styled.div<{theme: EThemeType}>`
    width: 80%;
    max-width: 600px;
    // background-color: #1e1e1e;
    background-color: ${({ theme }) => theme === 'dark' ? '#1e1e1e' : '#f9f9f9'};
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    color: white;
    opacity: 0.75;
    font-size: 24px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    
    &:hover {
        opacity: 1;
    }
`;

const AudioTitle = styled.div<{theme: EThemeType}>`
    // color: white;

    color: ${({ theme }) => theme === 'dark' ? 'white' : 'black'};
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
`;

const AudioIcon = styled.div<{theme: EThemeType}>`
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #333;

    background-color: ${({ theme }) => theme === 'dark' ? '#333' : '#ddd'};
    border-radius: 50%;
`;

const AudioPlayer = styled.audio<{theme: EThemeType}>`
    width: 100%;
    outline: none;
    
    /* Styling audio controls for better appearance */
    &::-webkit-media-controls-panel {
        // background-color: #333;
        background-color: ${({ theme }) => theme === 'dark' ? '#333' : '#ddd'};
    }
    
    &::-webkit-media-controls-play-button {
        // background-color: #555;
        background-color: ${({ theme }) => theme === 'dark' ? '#555' : '#777'};
        border-radius: 50%;
    }
    
    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
        // color: #fff;
        color: ${({ theme }) => theme === 'dark' ? '#fff' : '#000'};
    }
`;

const AudioExtension = styled.span<{theme: EThemeType}>`
    // color: white;
    color: ${({ theme }) => theme === 'dark' ? 'white' : 'black'};
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const AudioPreview = ({ audio, onClose }: AudioPreviewProps) => {

    const { theme } = useSelector((state) => state.authReducer);
 
      useEffect(() => {

        const originalOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        
        return () => {
            document.body.style.overflow = originalOverflow;
        };
    }, []);
    
    // Check for valid audio object with required properties
    if (!audio || !audio.path || !audio.name) return null;
    
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };
    
    // Get audio file extension
    const getAudioExtension = (fileName: string) => {
        const extension = fileName.split('.').pop()?.toLowerCase() || '';
        return extension === 'mp3' || extension === 'wav' || extension === 'flac' 
            ? extension 
            : 'audio';
    };
    
    // Create the content that will be rendered in the portal
    const content = (
        <AudioPreviewOverlay className="audio-preview-portal">
            <CloseButton onClick={handleClose}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </CloseButton>
            <AudioContainer theme={theme}>
                <AudioTitle theme={theme}>{audio.name}</AudioTitle>
                <AudioIcon theme={theme}>
                    <AudioExtension theme={theme}>{getAudioExtension(audio.name)}</AudioExtension>
                </AudioIcon>
                <AudioPlayer theme={theme}
                    src={audio.path}
                    controls
                    autoPlay
                />
            </AudioContainer>
        </AudioPreviewOverlay>
    );
    
    // Use ReactDOM.createPortal to render directly to body
    return ReactDOM.createPortal(
        content,
        document.body
    );
};