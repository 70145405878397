export const CreditsIcon = () =>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
  <g clip-path="url(#clip0_120_1154)">
    <path d="M0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0C6.32608 0 7.59785 0.526784 8.53553 1.46447C9.47322 2.40215 10 3.67392 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47322 6.32608 10 5 10C3.67392 10 2.40215 9.47322 1.46447 8.53553C0.526784 7.59785 0 6.32608 0 5ZM6.25 6.875C6.25 6.56445 6.13672 6.28125 5.95117 6.0625L7.44727 3.18359C7.56641 2.95312 7.47656 2.66992 7.24805 2.55078C7.01953 2.43164 6.73633 2.52148 6.61523 2.75L5.11914 5.63086C5.08008 5.62695 5.04102 5.625 5 5.625C4.31055 5.625 3.75 6.18555 3.75 6.875C3.75 7.56445 4.31055 8.125 5 8.125C5.68945 8.125 6.25 7.56445 6.25 6.875Z" fill="#4338CA"/>
  </g>
  <defs>
    <clipPath id="clip0_120_1154">
      <rect width="10" height="10" fill="white"/>
    </clipPath>
  </defs>
</svg>
    )}