import { useS3FileUpload } from "hooks/services/AmazonServices";
import { useAppNotification } from "hooks/services/AppNotification";
import {
  AddUploadedFile,
  generatePresignedURL,
  updateFileProgress,
  updateFileS3Link,
  updateFileStatus,
  UploadFile,
  UploadSource,
  WorkspaceUploadSrc,
  UploadedFileRes,
  IUploadedFile,
  AddUploadedId,
  removeFile,
  IWorkspace,
  ICRWorkSpace,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import useRouter from "hooks/useRouter";
import { simulateSourceProgress } from "./constants";
import { useEffect, useRef } from "react";

interface IProp {
  fileArray: UploadFile[];
}

export const useWSFileUploader = () => {
  const { pathname } = useRouter();
  const workSpaceId = pathname.split("/")[2];
  const { uploadFile } = useS3FileUpload();
  const { triggerNotification } = useAppNotification();
  const { workSpaceFiles, workSpaces, deletedFileId } = useSelector(
    (state) => state.workSpaceReducer
  );
  const abortControllerMap = useRef(new Map<string, AbortController>());
  const fileIndexMap = useRef(new Map<string, number>());

  // Update fileIndexMap whenever workSpaceFiles changes
  useEffect(() => {
    fileIndexMap.current.clear();
    if (Array.isArray(workSpaceFiles)) {
      workSpaceFiles.forEach((file, index) => {
        if (file && file.id) {
          fileIndexMap.current.set(file.id, index);
        }
      });
    }
  }, [workSpaceFiles]);

useEffect(() => {
  if (deletedFileId) {
    const abortController = abortControllerMap.current.get(deletedFileId);
    if (abortController) {
      abortController.abort();
      abortControllerMap.current.delete(deletedFileId);
    }
  }
}, [deletedFileId]);

type FileStatus = 'validating' | 'uploading' | 'uploaded' | 'error';

const updateFileStatusById = (fileId: string, status: FileStatus) => {
  const fileIndex = fileIndexMap.current.get(fileId);
  if (typeof fileIndex === 'number') {
    updateFileStatus(fileId, status);
  }
};

  const fileUPToS3 = async ({ fileArray }: IProp) => {
      // Create a temporary map for the current upload batch
      const currentBatchMap = new Map(
        fileArray.map(file => [file.id, file])
      );

    const preSignedURLPromises = fileArray.map((file) => {
      updateFileStatus(file.id, "uploading");

      // Create an AbortController for this file
      const abortController = new AbortController();
      abortControllerMap.current.set(file.id, abortController);

      return generatePresignedURL({
        name: `website-${new Date().getTime()}-${file.file.name}`,
        type: "document",
        signal: abortController.signal,
      })
        .then((preSignedRes: any) => ({
          file,
          preSignedURL: new URL(preSignedRes.data.url),
        }))
        .catch((err) => {
          if (err.name === "AbortError") {
            console.error(`Presigned URL request aborted for file ID: ${file.id}`);
          } else {
          triggerNotification({
            message: err?.data?.message,
            type: "error",
          });

       const failedFile = currentBatchMap.get(file.id);
        if (failedFile) {
          updateFileStatusById(failedFile.id, "error");
        }          
          // workSpaceFiles.forEach((files: UploadFile) => {
          //   if (file.file === files.file) updateFileStatus(files.id, "error");
          // });
        }

          return null;
        });
    });
    
    const preSignedURLs = await Promise.all(preSignedURLPromises);
    if (preSignedURLs.some((item) => item === null)) {
      return;
    }
    const validPreSignedURLs = preSignedURLs as {
      file: UploadFile;
      preSignedURL: URL;
    }[];
    const uploadPromises = validPreSignedURLs.map(({ file, preSignedURL }) => {

      updateFileStatus(file.id, "uploading");
      return uploadFile({
        file: file.file,
        preSignedUrl: preSignedURL.href,
        id: file.id,
      })
        .then(() => {
          // Start progress simulation before UploadSource
          const progressInterval = simulateSourceProgress(
            file.id,
            90,
            100,
            2000
          );

          const latestWorkSpaceId = workSpaces.length
            ? workSpaces[0].id
            : 1;

          const data: WorkspaceUploadSrc = {
            source_type: "file",
            file_path: [`${preSignedURL?.origin}${preSignedURL?.pathname}`],
            workspace_id: workSpaceId? Number(workSpaceId) : latestWorkSpaceId,
          };
          if (!abortControllerMap.current) {
            throw new Error(`abortControllerMap is not initialized.`);
        }
          const abortController = abortControllerMap.current.get(file.id);
          if (!abortController) {
            throw new Error(`No AbortController found for file ID: ${file.id}`);
          }
          UploadSource(data, file.id, { 
            signal: abortController.signal,
            // signal: abortControllerMap.current.get(file.id)?.signal 
          })
            .then((response) => { 
              const res = response as UploadedFileRes;
              const uploadedFile = res.data as IUploadedFile; 
              abortControllerMap.current.delete(file.id);
          
              if(workSpaceId) {
                AddUploadedFile(uploadedFile);
              }
              clearInterval(progressInterval);
              updateFileProgress(file.id, 100);
              updateFileS3Link(
                file.id,
                "uploaded",
                `${preSignedURL?.origin}${preSignedURL?.pathname}`
              );
              AddUploadedId(file.id, uploadedFile.id, uploadedFile.updated_at, uploadedFile.created_at);
              triggerNotification({ message: res.message, type: "info"});
            })
            .catch((error) => {
              removeFile(file.id);
              abortControllerMap.current.delete(file.id);
              if (error.name === "AbortError") {
                console.error(`UploadSource request aborted for file ID: ${file.id}`);
              } else {
              triggerNotification({
                message: error?.data?.message,
                type: "error",
              });
            }
            });

          return { preSignedURL };
        })
        .catch((err) => {
          triggerNotification({
            message: err?.data?.message,
            type: "error",
          });
          updateFileStatus(file.id, "error");
          return null;
        });
    });

    const uploadedFiles = await Promise.all(uploadPromises);
    if (uploadedFiles.some((item) => item === null)) {
      return;
    }
  };

  return { fileUPToS3 };
};

    // Type guards
 export const isICRWorkSpace = (response: ICRWorkSpace) => {
      return response 
          && typeof response === 'object'
          && 'data' in response 
          && 'message' in response
          && typeof response.message === 'string';
  };

  export const isValidWorkspace = (workspace: IWorkspace) => {
    return (
      workspace &&
      typeof workspace === "object" &&
      "id" in workspace &&
      typeof workspace.id === "number" &&
      "name" in workspace &&
      typeof workspace.name === "string" &&
      "description" in workspace &&
      (typeof workspace.description === "string" || workspace.description === null) &&
      "instructions" in workspace &&
      (typeof workspace.instructions === "string" || workspace.instructions === null)
    );
  };
  