import { LeftIcon, RightIcon } from "pages/Workspaces/components/icons/TreadsIcons";
import React from "react";
import ReactPaginate from "react-paginate";

interface PaginationProps {
  pageCount: number; // Total number of pages
  //   onPageChange: (selectedItem: { selected: number }) => void;
  onPageChange?: (selectedItem: { selected: number }) => void;
  forcePage?: number; // Current page (0-indexed)
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  // onPageChange
  onPageChange,
  forcePage,
}) => {
  return (
    <ReactPaginate
      previousLabel={<LeftIcon />}
      nextLabel={<RightIcon />}
      breakLabel={null} // Remove "..."
      //   breakLabel="..."
      pageCount={pageCount}
      marginPagesDisplayed={0} // Hide pages at the start & end
      pageRangeDisplayed={2} // Show only 1 page before and after
      //   onPageChange={onPageChange}
      onPageChange={onPageChange}
      forcePage={forcePage}
      containerClassName="flex justify-center gap-[12px] items-center"
      activeClassName="text-[#4338CA] dark:text-[#4338CA] !underline"
      disabledClassName="text-[#737373] cursor-not-allowed opacity-50"
      pageClassName="cursor-pointer text-[#171717] dark:text-white" 
      previousClassName="cursor-pointer"
      nextClassName="cursor-pointer"
      //   breakClassName="px-3 py-1 border rounded-md hover:bg-gray-200"
    />
  );
};

export default Pagination;
