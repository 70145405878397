import { useWindowSize } from "hooks/useWindowSize";
import { useState } from "react";
import { MiniTConversationHeader } from "./MiniTConversationHeader";
import { InfoChat } from "../WorkspaceConversation.tsx/InfoComponent/InfoChat";
import { ContinueIcon } from "pages/Workspaces/components/icons/ContinueIcon";
import { useMiniChatInfo } from "hooks/services/ThreadServices/useMiniChatInfo";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";

import classNames from "classnames";
import { ThreadsHistory } from "./ThreadsHistory";

interface ThreadsContentProps {
    isAllowUploadFile: boolean;
}

export const ThreadsConversation = ({
    isAllowUploadFile,
}: ThreadsContentProps) => {

    const [showInfo, setShowInfo] = useState(false);
    const { width } = useWindowSize();
    const { showMiniChatInfo } = useMiniChatInfo();
    const { isOpen } = useToggleSidebar();

    return (
        <>
            {(width <= 576 || (width > 576 && width <= 1024 && ((isOpen && !showMiniChatInfo) || (!isOpen && !showMiniChatInfo) || (isOpen && showMiniChatInfo)))) && !showInfo && <MiniTConversationHeader setShowInfo={setShowInfo} />}
            {(width <= 576 || (width > 576 && width <= 1024 && ((isOpen && !showMiniChatInfo) || (!isOpen && !showMiniChatInfo) || (isOpen && showMiniChatInfo)))) && showInfo ? (
                <div className="py-[14px] pl-[15px] pr-[13px] flex flex-col gap-3">
                    <div className="text-[#4338CA] text-[12px] font-semibold flex gap-2 items-center" onClick={() => setShowInfo(false)}>
                        <span className="rotate-180 flex flex-row items-center justify-center">
                            <ContinueIcon />
                        </span>
                        <span>Back</span></div>
                    <  InfoChat />
                </div>
            ) : (
                < div
                    className={classNames(
                        "flex flex-row mx-auto mt-[27px] w-full h-[100%] gap-[30px]"
                    )} >
                    <div className={`flex flex-col gap-[30px] " ${(width <= 576) || (width > 576 && width <= 1024 && ((isOpen && !showMiniChatInfo) || (!isOpen && !showMiniChatInfo) || (isOpen && showMiniChatInfo))) ? "w-full" : showMiniChatInfo? "flex-1 min-w-0" : "w-[64%]"}`}>
                        <ThreadsHistory
                            isAllowUploadFile={isAllowUploadFile}/>
                    </div>
                    {((width > 1024) || (width > 576 && width <= 1024 && ((!isOpen && showMiniChatInfo)))) && (
                        <div className={`${showMiniChatInfo ? "flex-1 min-w-0 max-w-[6rem]" : "w-[34%]"}`}><InfoChat /></div>
                    )}
                </div>

            )}
        </>
    );
};
