// components/ContainerRelativeModal.tsx
import { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
// import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  closeOnOutsideClick?: boolean;
  mountNode?: HTMLElement | null;
  feedback?:boolean;
  threads?:boolean;
  filePreview?: boolean;
};

const ContainerRelativeModal = ({
  isOpen,
  onClose,
  children,
  closeOnOutsideClick = true,
  mountNode,
  feedback,
  threads,
  filePreview,
}: ModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // 同步状态处理
  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 10);
      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
      timeoutRef.current = setTimeout(() => setIsMounted(false), 500);
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isOpen]);

  // 安全检测浏览器环境
  useEffect(() => {
    setIsBrowser(typeof document !== "undefined");
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  // 键盘事件监听
  useEffect(() => {
    if (!isOpen) return;
    const handleKeyDown = (e: KeyboardEvent) => e.key === "Escape" && onClose();
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onClose, isOpen]);

  if (!isBrowser || !isMounted) return null;

  const targetNode = mountNode || document.body;
  //   const isCustomContainer = mountNode !== document.body;

  return createPortal(
    <div
      className={clsx(
        "inset-0 z-[999999] flex items-center justify-center transition-opacity duration-300",
        {
          fixed: mountNode === document.body, // 全屏模式
          absolute: mountNode !== document.body, // 容器内模式
          "bg-black/50 opacity-100": isVisible,
          "bg-transparent opacity-0": !isVisible,
        },
        "transition-opacity duration-300"
      )}
      onClick={closeOnOutsideClick ? onClose : undefined}
    >
      <div
        ref={modalRef}
        className={clsx("transform-gpu will-change-transform transition-all duration-500 ease-[cubic-bezier(0.68,-0.55,0.265,1.55)]",
          {
            "max-w-[575px] lg:max-w-[47%]" : threads,
           "max-w-[35rem]" :  feedback, 
           "max-w-lg ": !filePreview && !threads && !feedback, 
           "mx-4 w-full": !filePreview, // 明确声明过渡属性
            "opacity-0 translate-y-24 scale-90": !isVisible, // 初始状态：下移+缩小
            "opacity-100 translate-y-0 scale-100": isVisible, // 最终状态
          }
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={clsx(
          {
            "max-h-[90vh] overflow-y-auto rounded-lg bg-white dark:bg-[#252526] p-6 shadow-2xl": !filePreview && !threads,
            "max-h-[90vh] rounded-lg bg-white dark:bg-[#252526] shadow-2xl overflow-y-auto p-4 " : feedback,
            "max-h-[90vh] pt-4 pr-4 shadow-none bg-transparent !important" : threads,
          })}>
          {/* <button
            onClick={onClose}
            className={clsx(
              "absolute right-4 top-4 text-2xl transition-colors",
              "text-gray-500 hover:text-gray-700" // 固定样式
            )}
          >
            &times;
          </button> */}
          {children}
        </div>
      </div>
    </div>,
    targetNode
  );
};

export default ContainerRelativeModal;
