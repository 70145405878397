import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./FileSizeLimitModal.module.scss";

import { Separator } from "components"; //Modal
import Button from "components/Button";
import AnimatedModal from "components/AnimateModal/AnimateModal";

import { useSelector } from "redux/hooks";

interface IProps {
  onCancel?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
  messageId: string;
}

export const FileSizeLimitModal = ({
  messageId,
  onCancel,
  onConfirm,
  onClose,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { planExpired } = useSelector((state) => state.authReducer);

  return (
    <AnimatedModal
      isOpen={messageId ? true : false}
      onClose={() => onClose?.()}
    >
      {(messageId || planExpired) && (
        <>
          <div
            className={classNames(styles.headline, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
              [styles.isPlanExpired]: planExpired,
            })}
          >
            <FormattedMessage
              id={planExpired ? "current.plan.expired" : messageId}
            />
          </div>
          <Separator height="30px" />
          <div className={styles.modalFooter}>
            <Button
              data-testid="file-size-cancel-btn"
              variant="outlined-primary"
              onClick={onCancel}
            >
              <div
                className={classNames(styles.btnText, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                <FormattedMessage id="creditLimit.modal.cancelBtn.text" />
              </div>
            </Button>
            <Button
              data-testid="file-size-confirm-btn"
              variant="primary"
              onClick={onConfirm}
            >
              <FormattedMessage
                id={
                  planExpired
                    ? "current.plan.renewal"
                    : "creditLimit.modal.continueBtn.text"
                }
              />
            </Button>
          </div>
        </>
      )}
    </AnimatedModal>
  );
};
