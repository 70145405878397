import { useState, useEffect, useCallback, Fragment, memo,Dispatch,SetStateAction } from "react";
import classNames from "classnames";
import styles from "./Navbar.module.scss";

import { BurgerIcon } from "./components/icons";

import {
  AccountNav,
  ChatNav,
  CurrentPlanNav,
  TeamNav,
  MDNav,
  GPTModal,
} from "./components";

import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { useWindowSize } from "hooks/useWindowSize";
import { useAppNotification } from "hooks/services/AppNotification";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";

import { useSelector } from "redux/hooks";
import { IChat, getChatModels, setChatModel, ChatType } from "redux/actions";
import { IUploadFile } from "pages/ChatPage";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";

interface IProps {
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  isMainScreenOpen?: boolean;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?:IChat;
  isFileUploading?: boolean;
  uploadingFiles?: IUploadFile[];
}

export const Navbar = memo(
  ({ onStartNewChat, isMainScreenOpen, setChatSetting, chatItem, isFileUploading,
    uploadingFiles
  }: IProps) => {
    
    const {
       theme,
       gptModel } = useSelector((state) => state.authReducer);
    const { isOpen, toggleSidebar } = useToggleSidebar();
    const { includeRoute, pathname } = useRouter();
    const { triggerNotification } = useAppNotification();
    const { width } = useWindowSize();
    const userDetail = useSelector((state) => state.authReducer.userDetail);
    const history = pathname.includes('/chat/history');
    const { showCreateWorkspace } = useSelector((state)=> state.workSpaceReducer);

    // Chat Models Model
    const [chatModelsModel, setChatModelsModel] = useState<boolean>(false);
    const toggleChatModelsModel = () => setChatModelsModel((prev) => !prev);
    const[loadModel,setLoadModel] = useState<boolean>(false);

    const onGetChatModels = useCallback(() => {
      if (userDetail?.token) {
        setLoadModel(true);
        const storedChatModel = localStorage.getItem("GptModel");
        getChatModels()
          .then((res: any) => {
            setLoadModel(false);
            if (isMainScreenOpen  && !history) {
              if (gptModel?.type?.includes(ChatType.image) && !gptModel?.type?.includes(ChatType.image_chat)){
              if (storedChatModel) {              
                setChatModel(JSON.parse(storedChatModel));
              } else {
                setChatModel(res[0]);
                localStorage.setItem('GptModel', JSON.stringify(res[0]));

              }
              }
            }
          })
          .catch((err: any) => {
            setLoadModel(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }
    }, [isMainScreenOpen]);

    useEffect(() => onGetChatModels(), []);
    const isShareChat = window.location.pathname.includes("share-chat")

    const {sidebarWidth} = useSidebar();
    return (
      <Fragment>
        <div
          className={classNames(styles.navbar, {
            [styles.light]: theme === "light" ,
            [styles.dark]: theme === "dark",
            [styles.light]: theme === "light" ,
            [styles.dark]: theme === "dark",
            [styles.isSidebarOpen]: isOpen && !isShareChat,
            [styles.reSizeNavBar]: width > 768 && isOpen && includeRoute(RoutePaths.Team),
            [styles.teamlight]: theme === "light" && (includeRoute(RoutePaths.Team) || includeRoute(RoutePaths.CurrentPlan)),
            [styles.shareChatLight]: theme === "light" && isShareChat,
            [styles.workSpacesBorder]: (includeRoute(RoutePaths.Workspaces) || includeRoute(RoutePaths.ThreadsHistory)),
            [styles.navBarDisable]: showCreateWorkspace,
            [styles.workSpacelight]: theme === "light" && (includeRoute(RoutePaths.Workspaces) || includeRoute(RoutePaths.ThreadsHistory)) ,
            [styles.workSpacedark]: theme === "dark" && (includeRoute(RoutePaths.Workspaces) || includeRoute(RoutePaths.ThreadsHistory)),
          })}
          style={{width: width > 768 && isOpen && !includeRoute(RoutePaths.Team) && !isShareChat? `calc(100% - ${sidebarWidth}px)`: '100%'}}
        >
          {(!isOpen && width <= 768) && (
            <div className={classNames(styles.buttonsContainer,{
              [styles.teamButtonContainer]: !includeRoute(RoutePaths.Chat),
              [styles.isbackdrop]: showCreateWorkspace,
            })}>
               <button
                className={classNames(styles.openSidebarBtn, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                  [styles.teamButtonContainer]: includeRoute(RoutePaths.Team) && theme === 'dark'
                })}
                onClick={toggleSidebar}
                data-testid='toggle-nav-bar-burger-icon'
              >
                <BurgerIcon />
              </button> 
            </div>
          )}

          {width > 768 && (
            <div className={styles.lgNav} 
            >
              {(includeRoute(RoutePaths.Chat) || includeRoute(RoutePaths.Workspaces) || includeRoute(RoutePaths.ThreadsHistory)) &&  (
                <ChatNav
                  isMainScreenOpen={isMainScreenOpen}
                  toggleChatModelsModel={toggleChatModelsModel}
                  setChatSetting={setChatSetting}
                  chatItem={chatItem}
                />
              )}
              {includeRoute(RoutePaths.Account) && <AccountNav />}
              {includeRoute(RoutePaths.Team) && <TeamNav />}
              {includeRoute(RoutePaths.CurrentPlan) && <CurrentPlanNav />}
            </div>
          )}

          {width <= 768 && (
            <div className={styles.mdNav}>
              <MDNav
                onStartNewChat={onStartNewChat}
                toggleChatModelsModel={toggleChatModelsModel}
                setChatSetting={setChatSetting}
                chatItem={chatItem}
              />
            </div>
          )}
        </div>

        {chatModelsModel && (
          <GPTModal
          isMainScreenOpen={isMainScreenOpen}
            onClose={toggleChatModelsModel}
            onStartNewChat={onStartNewChat}
            isFileUploading={isFileUploading}
            loadModel={loadModel}
            uploadingFiles={uploadingFiles}
          />
        )}
      </Fragment>
    );
  }
);
