// FilePreviewModal/components/previewTypes/CSVPreview.tsx
import React from "react";
import styles from "../../FilePreviewModal.module.scss";

interface CSVPreviewProps {
  rows: string[][];
}

const CSVPreview: React.FC<CSVPreviewProps> = ({ rows }) => (
  <div className={styles.csvContainer}>
    <table className={styles.csvTable}>
      <tbody>
        {rows.length === 0 ? (
            <tr>
              <td style={{ textAlign: "center" }}>
                No data available
              </td>
            </tr>
          ) : rows.map((row, rowIndex) => (
          <tr key={`row-${rowIndex}`}>
            {row.map((cell, cellIndex) => (
              <td key={`cell-${rowIndex}-${cellIndex}`}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default CSVPreview;