import {
  FormEvent,
  memo,
  useState,
  useImperativeHandle,
  forwardRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useEffectOnce } from "react-use";
import { ChatRoute, IUploadFile, 
 } from "pages/ChatPage/ChatPage";
import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";

import {
  IChat,
  getAllPropts,
  getSavePrompts,
  EChatType,
  startNewChat,
  IChatModel,
  removeMultipleChat,
  DeleteS3Link,
  deleteSingleChatHistory, 
} from "redux/actions";
import { ShareFooter } from "./components/ShareFooter";
import { IFileErrorState } from "pages/ChatPage/pages/newChat/NewChat";
import { RoutePaths } from "pages/routePaths";
import ErrorModalSection from "./components/ErrorModalSection";
import { calculateCredit } from "utils/functions";
import { ChatFooterWrapper } from "./chatFooterWrapper";
import { MainComponent } from "./components/MainComponent";
import { imgURL } from "utils/constants";

interface IProps {
  onSubmit?: ({
    event,
    message,
  }: {
    event: FormEvent<EventTarget | HTMLFormElement>;
    message: string;
  }) => void;
  getMessagesLoading?: boolean;
  onEnter?: ({
    event,
    message,
  }: {
    event: React.KeyboardEvent<HTMLTextAreaElement>;
    message: string;
  }) => void;
  isAnswerComplete?: boolean;
  isGenerating?: boolean;
  onStopGeneratingResponse?: () => void;
  isFileUploading: boolean;
  showScrollToBottom?: boolean;
  share?: boolean;
  scrollToBottom?: ({ behavior }: ScrollOptions) => void;
  selectedMessages?: any;
  toggleShareChat?: () => void;
  handleSelectAllChats?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedChatId?: any;
  loadingShareChat?: boolean;
  setSelectedMessages?: Dispatch<SetStateAction<any[]>>;
  settings: {
    real_time_results: boolean;
    related_questions: boolean;
    send_message_with_enter: boolean;
  };
  updateChatModel?: (modalType: EChatType) => void;
  setSelectedFile: Dispatch<SetStateAction<File[] | null>>;
  selectedFile: File[] | null;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  IGLoading?: boolean;
  onSendMessage: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  openHistory?: boolean;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  credit: number;
  setCredit: Dispatch<SetStateAction<number>>;
  uploadingFiles?: IUploadFile[];
  fileS3Link?: string[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
  loadingSetting: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}

export interface ChatFooterMethods {
  resetMessage: () => void;
  onTextareaFocus: () => void;
}


export const ChatFooter = memo(
  forwardRef<ChatFooterMethods, IProps>(
    (
      {
        onSubmit,
        getMessagesLoading,
        onEnter,
        isAnswerComplete,
        isGenerating,
        onStopGeneratingResponse,
        isFileUploading,
        showScrollToBottom,
        scrollToBottom,
        share,
        selectedMessages,
        toggleShareChat,
        handleSelectAllChats,
        selectedChatId,
        loadingShareChat,
        setSelectedMessages,
        settings,
        updateChatModel,
        setSelectedFile,
        selectedFile,
        setIsMainScreenOpen,
        setIsFileUploading,
        IGLoading,
        onSendMessage,
        openHistory,
        setOpenHistory,
        credit,
        setCredit,
        uploadingFiles,
        fileS3Link,
        setUploadingFiles,
        setFileS3Link,
        isAllowUploadFile,
        chatItem,
        loadingSetting,
        textareaRef,
      },
      ref
    ) => {
      const { includeRoute, push, pathname } = useRouter();
      const { gptModel } = useSelector((state) => state.authReducer);
      const chatModel = useSelector((state) => state.authReducer.gptModel);
      const { messages, newMessages } = useSelector(
        (state) => state.chatReducer
      );
      const { PrivateChat } = useSelector((state) => state.chatReducer);
      const creditPerQuery = pathname.includes("chat/new");

      const [showPromptModal, setShowPromptModal] = useState<boolean>(false);

      const [message, setMessage] = useState<string>("");
      const resetMessage = () => {
        setMessage("");
      };
      const [promptLoading, setPromptLoading] = useState<boolean>(false);
      const [userPromptLoading, setUserPromptLoading] =
        useState<boolean>(false);
      const [uploadURl, setUploadUrl] = useState<boolean>(false);
      const [url, setURL] = useState<string>("");
      const [creditsPerQuery, setCreditsPerQuery] = useState<boolean>(false);
      const [selectIFile, SetSelectIFile] = useState<boolean>(false);
      const isShareChat = window.location.pathname.includes("share-chat");
      const [allowCustomRes, setAllowCustomRes] = useState<boolean>(false);
      const [errorModal, setErrorModal] = useState<IFileErrorState>({
        message: "",
        show: false,
      });

      useEffectOnce(() => {
        if (!isShareChat) {
          setPromptLoading(true);
          setUserPromptLoading(true);

          Promise.all([
            getSavePrompts().then(
              () => setUserPromptLoading(false),
              () => setUserPromptLoading(false)
            ),
            getAllPropts().then(
              () => setPromptLoading(false),
              () => setPromptLoading(false)
            ),
          ]).finally(() => {
            setPromptLoading(false);
            setUserPromptLoading(false);
          });
        }
      });

      useEffect(() => {
        if (newMessages[0]?.images && newMessages[0]?.images.length > 0)
          setCreditsPerQuery(true);
        else setCreditsPerQuery(false);
        if (((newMessages[0]?.images && newMessages[0]?.images.length > 0)))
          setAllowCustomRes(true)
        else setAllowCustomRes(false);
        if (selectedFile) {
          if (
            selectedFile &&
            selectedFile?.length > 0 &&
            selectedFile[0]?.type.startsWith("image/")
          )
            SetSelectIFile(true);
          else SetSelectIFile(false);
        }
      }, [newMessages, selectedFile]);

      useEffect(() => {
        if (!gptModel) return;
        if (isAnswerComplete === true) {
          const credit = calculateCredit(
            gptModel,
            settings,
            uploadingFiles,
            url,
            isAnswerComplete
          );
          setCredit(credit);
        }
      }, [
        message,
        uploadingFiles,
        newMessages,
        messages,
        settings,
        gptModel,
        url,
        isAnswerComplete,
        isFileUploading,
      ]);

      const onTextareaFocus = () => {
        if (textareaRef.current) textareaRef.current.focus();
      };

      useImperativeHandle(ref, () => ({
        resetMessage,
        onTextareaFocus,
      }));

      const isContentFound = (): boolean => {
        let isContent: boolean = false;
        if (
          includeRoute(ChatRoute.History) &&
          messages[messages.length - 1].content
        ) {
          isContent = true;
        }

        if (
          includeRoute(ChatRoute.New) &&
          newMessages[newMessages.length - 1].content
        ) {
          isContent = true;
        }

        return isContent;
      };

      const handleSubmit = (event: React.FormEvent) => {
        if (
          fileS3Link &&
          fileS3Link.length > 0 &&
          openHistory === false &&
          isAnswerComplete === true &&
          !isFileUploading && (fileS3Link.length === (uploadingFiles && uploadingFiles.length))
        ) {
          const s3Links: string[] = (uploadingFiles ?? [])
            .map((file) => file.S3Link)
            .filter((link): link is string => link !== undefined);
          if (uploadingFiles && uploadingFiles[0]?.fileType === "image")
            onSendMessage!(
              message ? message : "",
              chatModel,
              false,
              s3Links ?? []
            );
          else if (uploadingFiles && ( uploadingFiles[0]?.fileType === "document" || uploadingFiles[0]?.fileType === "video" || uploadingFiles[0]?.fileType === "audio" ))
            onSendMessage!(
              message ? message : "",
              chatModel,
              false,
              [],
              s3Links ?? []
            );
          setUploadingFiles!([]);
        } else if (!isFileUploading && (uploadingFiles && uploadingFiles.length === 0)) onSubmit?.({ event: event, message });
      };

      const handleStartNewImageChat = () => {
        if (!isFileUploading && isAnswerComplete && !IGLoading && !uploadURl) {
          setUploadingFiles!([]);
          setFileS3Link!([]);
          startNewChat();
          if (PrivateChat) {
            const currentPath = window.location.pathname;
            const pathSegments = currentPath.split('/');
            const chatIdFromUrl = pathSegments[pathSegments.length - 2];
            if (Number(chatIdFromUrl)) {
              deleteSingleChatHistory(Number(chatIdFromUrl))
              removeMultipleChat([Number(chatIdFromUrl)])
            }
          }
          push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
          updateChatModel?.("image");
          resetMessage();
          setSelectedFile!(null);
        }
      };

      const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
      const AllowCustomizeResponse = ((allowCustomRes === false && creditPerQuery) || (chatItem && (chatItem.chat_type === "text"|| chatItem.chat_type === "document")) || (!creditPerQuery && (!messages[0]?.images || messages[0]?.images.length === 0))) && (!uploadingFiles || (uploadingFiles?.length===0 ) || (uploadingFiles?.length > 0 && !uploadingFiles[0].file.type.startsWith("image/"))) && (!url || (url!=='' && !imgURL.includes(urlExtension)))

      const handleDeleteS3Link = () => {
        fileS3Link?.map((file) => {
          DeleteS3Link(file)
        })
        setFileS3Link!([]);
        setUploadingFiles!([]);
      }

      useEffect(() => {
        resetMessage!();
        handleDeleteS3Link();
    }, [PrivateChat]);

      return (
        <>
          <ChatFooterWrapper
            creditsPerQuery={creditsPerQuery}
            selectIFile={selectIFile}
            isFileUploading={isFileUploading}
            url={url}
            message={message}
            selectedFile={selectedFile}
            share={share}
            uploadingFiles={uploadingFiles}
          >
            {share || isShareChat ? (
              <ShareFooter
                selectedMessages={selectedMessages}
                toggleShareChat={toggleShareChat}
                handleSelectAllChats={handleSelectAllChats}
                selectedChatId={selectedChatId}
                loadingShareChat={loadingShareChat}
                setSelectedMessages={setSelectedMessages}
                scrollToBottom={scrollToBottom}
              />
            ) : (
              !isShareChat && (
                <MainComponent
                  onSubmit={onSubmit}
                  getMessagesLoading={getMessagesLoading}
                  onEnter={onEnter}
                  isAnswerComplete={isAnswerComplete}
                  isGenerating={isGenerating}
                  onStopGeneratingResponse={onStopGeneratingResponse}
                  isFileUploading={isFileUploading}
                  showScrollToBottom={showScrollToBottom}
                  scrollToBottom={scrollToBottom}
                  settings={settings}
                  updateChatModel={updateChatModel}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  setIsMainScreenOpen={setIsMainScreenOpen}
                  setIsFileUploading={setIsFileUploading}
                  IGLoading={IGLoading}
                  onSendMessage={onSendMessage}
                  openHistory={openHistory}
                  setOpenHistory={setOpenHistory}
                  credit={credit}
                  setCredit={setCredit}
                  uploadingFiles={uploadingFiles}
                  fileS3Link={fileS3Link}
                  setUploadingFiles={setUploadingFiles}
                  setFileS3Link={setFileS3Link}
                  isAllowUploadFile={isAllowUploadFile}
                  chatItem={chatItem}
                  loadingSetting={loadingSetting}
                  showPromptModal={showPromptModal}
                  setShowPromptModal={setShowPromptModal}
                  setMessage={setMessage}
                  message={message}
                  onTextareaFocus={onTextareaFocus}
                  promptLoading={promptLoading}
                  userPromptLoading={userPromptLoading}
                  textareaRef={textareaRef}
                  AllowCustomizeResponse={AllowCustomizeResponse}
                  isContentFound={isContentFound}
                  url={url}
                  setURL={setURL}
                  setUploadUrl={setUploadUrl}
                  resetMessage={resetMessage}
                  handleSubmit={handleSubmit}
                  setErrorModal={setErrorModal}
                  handleStartNewImageChat={handleStartNewImageChat}
                />
              )
            )}
          </ChatFooterWrapper>
          <ErrorModalSection
            errorModal={errorModal}
            setErrorModal={setErrorModal}
          />
        </>
      );
    }
  )
);