import { memo } from "react";
import { components, SingleValueProps } from "react-select";
import { theme } from "theme";
import styled from "styled-components";

import { IDataItem } from "./Option";
import Text from "./Text";
import { EThemeType } from "redux/reducers";
import { FormattedMessage } from "react-intl";

export type IProps<T> = {
  data?: IDataItem;
  variant?: EThemeType;
  small?: boolean;
  account?: boolean;
  inviteModel?: boolean;
  workspace?: boolean;
  feedback?: boolean;
} & SingleValueProps<T>;

export const ItemView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%
`;

export const Icon = styled.div<{
  small?: boolean;
}>`
  ${({ small }) => (small ? "max-width: 20px;" : "")}
  width: 20px;
  height:20px;
  margin: 0 6px;
  display: inline-block;
`;

const SingleValue = <T extends { data: { img: string } }>(
  props: React.PropsWithChildren<IProps<T>>
) => {

  const firstWord = typeof props.data?.value === "string" && props.data.value ? props.data.value.split(" ")[0] : "Research";


  return (
    <ItemView>
      {props.data.imgNode ? <Icon>{props.data.imgNode}</Icon> : null}
      {props.data.img ? (
        <Icon small={props.small}>
          <img src={props.data.img} alt="" />
        </Icon>
      ) : null}
      <Text>
        <components.SingleValue {...props}>
          <div
            style={{
              color: props.variant === "light" ? theme.black : theme.grey300,
            }}
          >

            {
              props.account || props.inviteModel ?
                props.children : props.feedback ? 
                <div className="flex gap-2 items-center">

                  <FormattedMessage id={`feedback.neg.${firstWord || "Research"}`} />
                  <span className={props.variant === "light" ? "text-[#737373]" : "text-[#fff] opacity-[0.6]"}>
                    <FormattedMessage id={`feedback.neg.${firstWord || "Research"}.details`} />
                  </span>
                </div>
                  : props.workspace ?
                    // <FormattedMessage id={props.data.value} />
                    <div className="flex gap-2 items-center">

                      <FormattedMessage id={`workspace.ai.${firstWord || "Research"}`} />
                      <span className={props.variant === "light" ? "text-[#737373]" : "text-[#fff] opacity-[0.6]"}>
                        <FormattedMessage id={`workspace.ai.${firstWord || "Research"}.details`} />
                      </span>
                    </div>
                    : props.data.text
            }
          </div>
        </components.SingleValue>
      </Text>
    </ItemView>
  );
};

export default memo(SingleValue);