import { FormattedMessage } from "react-intl";
import styles from "./threads.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { DeleteIcon } from "pages/Workspaces/components/icons/TreadsIcons";
import { useState, useEffect } from "react";
import Pagination from "./Components/Pagination";
import { DeleteThread, SaveSelectedThread, ShowMultipleThread, TdataResponse, selectedThread, EmptyUploadedFiles } from "redux/actions";
import useRouter from "hooks/useRouter";
import { Spinner } from "components";
import { useAppNotification } from "hooks/services/AppNotification";
import { RoutePaths } from "pages/routePaths";
import { getRelativeTime, formatFullDate } from "utils/constants";
const itemsPerPage = 10;

export const Threads = () => {

    const { theme } = useSelector((state) => state.authReducer);
    const [hoveredThreadId, setHoveredThreadId] = useState<number | null>(null);
    const { ThreadsHistory } = useSelector((state) => state.workSpaceReducer);
    const { pathname, push } = useRouter();
    const workspaceId = pathname.split("/")[2];
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { triggerNotification } = useAppNotification();
    const [loadingThreads, setLoadingThreads] = useState<{ [key: number]: boolean }>({});
    // Get pagination data from API response
    const currentPageFromAPI = ThreadsHistory?.data?.current_page || 1;
    const lastPage = ThreadsHistory?.data?.last_page || 1;

    useEffect(() => {
        const controller = new AbortController(); // Create an instance of AbortController
        const signal = controller.signal; // Extract the signal

        const fetchThreads = async () => {
            try {
                setIsLoading(true);
                await ShowMultipleThread(currentPage, itemsPerPage, workspaceId, signal);
            } catch (error) {
                if (error.name === "AbortError") {
                    console.log("Previous request aborted");
                } else {
                    console.error("Failed to fetch threads:", error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchThreads();

        return () => {
            controller.abort(); // Abort the request when `currentPage` or `workspaceId` changes
        };
    }, [currentPage, workspaceId, itemsPerPage]);


    const handlePageChange = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected + 1);
    };

    const handleDeleteThread = async (id: number) => {
        try {
            setLoadingThreads((prev) => ({ ...prev, [id]: true }));

            const res = await DeleteThread(id) as { message: string };
            setLoadingThreads((prev) => ({ ...prev, [id]: false }));
            triggerNotification({
                message: res.message,
                type: "info",
            });
            await ShowMultipleThread(currentPage, itemsPerPage, workspaceId);

        } catch (error) {
            setLoadingThreads((prev) => ({ ...prev, [id]: false }));
            triggerNotification({ message: error?.data?.message, type: "error" });
        }
    };

    const handleThreadHistory = (thread: TdataResponse) => {
        const SaveThreadInfo: selectedThread = {
            ws_name: thread.workspace.name,
            emoji: thread.workspace.emoji,
            thread_name: thread.chat.topic,
            thread_id: thread.id,
            ws_id: thread.workspace.id,
        }
        SaveSelectedThread(SaveThreadInfo);
        EmptyUploadedFiles();
        push(`/${RoutePaths.Workspaces}/${thread.workspace_id}/thread/${thread.id}`)
    }

    return (
        <div className={`font-inter ${styles.mainContainer}`}>
            {isLoading && (!ThreadsHistory?.data?.data || ThreadsHistory?.data?.data.length === 0) ? (
                <div className="flex justify-center items-center">
                    <Spinner small />
                </div>
            ) : (
                <>
                    {(ThreadsHistory?.data?.data && ThreadsHistory?.data?.data.length > 0) && (
                        <div className={styles.threadsHeading}>
                            <div className={classNames(styles.title, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}>
                                <FormattedMessage id="workspace.threads" />
                            </div>

                            <h5 className={classNames(styles.countThreads, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}>
                                ({ThreadsHistory.data.total} {ThreadsHistory.data.total === 1 ? <FormattedMessage id="workspace.thread" /> : <FormattedMessage id="workspace.threadss" />})
                            </h5>

                        </div>
                    )}
                    <div className="flex flex-col gap-[10px]">
                        {isLoading && ThreadsHistory?.data?.data?.length > 0 ? (
                            <div className="flex justify-center items-center h-auto">
                                <Spinner small />
                            </div>
                        ) : (
                            ThreadsHistory?.data?.data &&
                            ThreadsHistory?.data?.data?.map((thread: TdataResponse) => (

                                <div key={thread.id} className={classNames(styles.cardContainer, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                    [styles.hover]: hoveredThreadId === thread.id,

                                })}
                                    onMouseEnter={() => setHoveredThreadId(thread.id)}
                                    onMouseLeave={() => setHoveredThreadId(null)}
                                    onClick={() => {
                                        handleThreadHistory(thread);
                                    }}
                                >
                                    <div className={styles.headWrapper}>
                                        <div className={`${styles.nameContainer} flex-1 min-w-0 pr-4`}>
                                            <div className={classNames(styles.cardTitle, "truncate font-inter", {
                                                [styles.light]: theme === "light",
                                                [styles.dark]: theme === "dark",
                                            })}>{thread.chat.topic}</div>
                                        </div>
                                        <div className={styles.threadActions}>
                                            {hoveredThreadId === thread.id && (
                                                <>
                                                    {loadingThreads[thread.id] ? (
                                                        <Spinner extraSmall />  
                                                    ) : (
                                                        <div className={styles.delIcon} onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteThread(thread.id)
                                                        }}>
                                                            <DeleteIcon />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            <div className={classNames("font-inter", styles.date, {
                                                [styles.light]: theme === "light",
                                                [styles.dark]: theme === "dark",
                                            })}
                                            >
                                                {hoveredThreadId === thread.id
                                                    ? formatFullDate(thread.updated_at)
                                                    : getRelativeTime(thread.updated_at)}
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>

                            ))
                        )}
                    </div>
                    <div>
                        {ThreadsHistory?.data?.total > itemsPerPage &&
                            <div className="justify-center flex font-normal font-inter mb-[10px]">
                                <Pagination pageCount={lastPage}
                                    onPageChange={handlePageChange}
                                    forcePage={currentPageFromAPI - 1}
                                />
                            </div>
                        }
                    </div>
                </>
            )}
        </div>
    );
}
