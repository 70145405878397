import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

interface VideoFile {
    path: string;
    name: string;
}

interface VideoPreviewProps {
    videos: VideoFile[];
    loading?: boolean;
    handleVideoLoad?: () => void;
    onClose?: () => void;
}

const VideoPreviewOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999999; /* Extremely high z-index */
`;

const VideoContainer = styled.div`
    width: 80%;
    max-width: 1200px;
    max-height: 80vh;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 4px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    opacity: 0.75;
    font-size: 24px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    
    &:hover {
        opacity: 1;
    }
`;

const VideoTitle = styled.div`
    color: white;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
`;

const VideoPlayer = styled.video`
    width: 100%;
    max-height: 70vh;
    outline: none;
`;

const VideoPreview = ({ videos, handleVideoLoad, onClose }: VideoPreviewProps) => {

    useEffect(() => {
        const originalOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
            
        return () => {
            document.body.style.overflow = originalOverflow;
        };
    }, []);
        
    if (!videos || videos.length === 0) return null;
    
    const handleVideoLoadComplete = () => {
        if (handleVideoLoad) {
            handleVideoLoad();
        }
    };
    
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const video = videos && videos.length > 0 ? videos[0] : null;
    if (!video) return null;
    
    // Create the content that will be rendered in the portal
    const content = (
        <VideoPreviewOverlay className="video-preview-portal">
            <CloseButton onClick={handleClose}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </CloseButton>
            <VideoContainer>
                <VideoTitle>{video.name}</VideoTitle>
                <VideoPlayer 
                    src={video.path}
                    controls
                    autoPlay
                    muted={true}
                    onLoadedData={handleVideoLoadComplete}
                />
            </VideoContainer>
        </VideoPreviewOverlay>
    );
    
    // Use ReactDOM.createPortal to render directly to body
    return ReactDOM.createPortal(
        content,
        document.body
    );
};

export default VideoPreview;