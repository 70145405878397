import { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

import { AuthContainer } from "../components/AuthContainer";
import { LoadingPage } from "components";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import { IUser, IVerifyEmail, socialCallback } from "redux/actions";
import { RoutePaths } from "pages/routePaths";
import MixpanelAnalytics from "utils/mixpanel";

const sanitizeInput = (input: string): string => {
  if (!input) return input;
  return input.replace(/[<>"']/g, '');
};

export const SocialAuthVerificationPage = () => {
  const { query, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const [isLoading, setIsLoading] = useState(true);
  const onVerifyEmail = useCallback(() => {
    if (Object.keys(query).length > 1) {
      const sanitizedParams = {
        authuser: sanitizeInput(query.authuser),
        code: sanitizeInput(query.code),
        prompt: sanitizeInput(query.prompt),
        scope: sanitizeInput(query.scope)
      };

      socialCallback(sanitizedParams)
        .then((response) => {
          const res = response as IVerifyEmail;
          if (res.data) {
            const user = res.data.user as IUser;

            if (!user) {
              throw new Error("Invalid API response");
            }

            try {

              const userProperties = {
                name: user.name,
                email: user.email,
                createdAt: user.created_at || new Date().toISOString()
              };

              MixpanelAnalytics.identify(user.email, userProperties);
              // FullStory identification
              if (window.FullStory) {
                window.FullStory.identify(user.email, {
                  displayName: user.name,
                  email: user.email,
                  uid: user.id,
                  pricing_plan: user.activeSubscription ? user.activeSubscription.name : null,
                });
              }

            } catch (error) {
              console.error('Error in Mixpanel tracking:', error);
            }
          }
          push(`/${RoutePaths.Chat}`);
        })
        .catch((err: any) => {
          setIsLoading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    }
  }, [query, push, triggerNotification]);

  useEffectOnce(() => onVerifyEmail());

  return (
    <AuthContainer>
      {isLoading && <LoadingPage />}
    </AuthContainer>
  );
};