import styles from "./answerContainer.module.scss";
import messageStyles from "../message/message.module.scss";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";

import { WordHighlighter } from "views/layout/Sidebar/component/ChatSidebar/components/ChatItem/WordHighlighter";

import { useSelector } from "redux/hooks";

import { IMessage, IShareChatMessage } from "redux/actions";
import React, { RefObject, useEffect } from "react";
import { Tooltip } from "components";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyIcon } from "../icons";
import ChatEditIcon, { ThEditIcon } from "../icons/ChatEditIcon";
import { FormattedMessage } from "react-intl";
import { ThreadsCopyIcon } from "../icons/ThreadsAnsIcons";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";
import { RIPPLE_COLORS } from "utils/constants";
interface IProps {
    message?: IMessage;
    shareMessage?: IShareChatMessage;
    shareChat?: boolean;
    searchQuery?: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    isEditMode: boolean;
    textAreaRef: RefObject<HTMLTextAreaElement>;
    isShareChat: boolean;
    setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
    isHighlighted: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    isCopied: boolean;
    handleKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    threads?: boolean;
    setImage?: React.Dispatch<React.SetStateAction<string[]>>;
    setFile?: React.Dispatch<React.SetStateAction<string[]>>;
    assistantMessage?: boolean;
}

export const QuestionContainer = ({
    message,
    searchQuery,
    shareMessage,
    setMessage,
    isEditMode,
    textAreaRef,
    isShareChat,
    isHighlighted,
    handleKeyDown,
    threads,
    setImage,
    setFile,
    assistantMessage,
    isCopied,
    setIsCopied,
    shareChat,
    setIsEditMode,
}: IProps) => {

    const { theme } = useSelector((state) => state.authReducer);
    useEffect(() => {
        textAreaRef.current?.focus();

        if (textAreaRef.current) {
            const { value } = textAreaRef.current;
            textAreaRef.current.setSelectionRange(value.length, value.length);
        }
        return () => {
            setMessage && setMessage("");
        };
    }, [isEditMode === true]);

    useEffect(() => {
        const intervalID = setTimeout(() => {
            if (isCopied) setIsCopied(false);
        }, 500);

        return () => clearInterval(intervalID);
    }, [isCopied]);
    useEffect(() => {
        if (isEditMode === true) {
            if (message && message?.images && message?.images.length > 0) {
                const images = message?.images.map((img) => {
                    return img.path
                })
                setImage!(images);
            }
            else if (message?.files && message.files.length > 0) {
                const files = message.files.map((file) => file.path).filter((path): path is string => path !== null);
                setFile!(files);
            }
            else {
                setFile!([]);
                setImage!([]);
            }
        }
    }, [isEditMode === true])

  const themeColors = theme === 'dark' ? RIPPLE_COLORS.dark : RIPPLE_COLORS.light;

    return (
        <div style={{ width: isEditMode ? '100% ' : '', justifyContent: isEditMode ? 'space-between' : '' }}
            className={`${((message?.files?.length ?? 0) > 0 || (message?.images?.length ?? 0) > 0 || (shareMessage?.images?.length ?? 0) > 0 || (shareMessage?.files?.length ?? 0) > 0) ? `${styles.additionalClass}${threads ? ' ' + styles.additionalThread : ''}` : ''} ${shareMessage?.content && (shareMessage.images && shareMessage.images.length > 0 || shareMessage.files && shareMessage.files.length > 0) ? styles.sharedimage : ''} `}>
            <p
                className={classNames(messageStyles.message, {
                    [messageStyles.light]: theme === "light",
                    [messageStyles.dark]: theme === "dark",
                    [messageStyles.threads]: threads,
                    [messageStyles.threadsLight]: threads && theme === "light",
                    "font-inter": threads,
                })}
            >
                {isEditMode ? (
                    <div className="grid flex-1">
                        <TextareaAutosize
                            ref={textAreaRef}
                            style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                width: "100%",
                                background: "transparent",
                                flexGrow: 1,
                                border: "none",
                                outline: "none",
                                resize: "none",
                                boxSizing: "border-box",
                            }}
                            className={classNames(messageStyles.message, {
                                [messageStyles.light]: theme === "light",
                                [messageStyles.dark]: theme === "dark",
                                [messageStyles.styleTextArea]: true,
                                [messageStyles.threadsLight]: threads && theme === "light",
                                "font-inter": threads,
                            })}
                            autoFocus
                            defaultValue={
                                message ? message?.content : shareMessage?.content
                            }
                            onChange={(e) => {
                                e.preventDefault();
                                setMessage && setMessage(e.target.value);
                            }}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    </div>
                ) : (
                    <div className={classNames(messageStyles.mainMessage, {
                        [messageStyles.threads]: threads,
                    })}>

                        <div
                            className={classNames(messageStyles.userMessage, {
                                [messageStyles.light]: theme === "light",
                                [messageStyles.dark]: theme === "dark",
                                [messageStyles.sharchat]: isShareChat,
                                [messageStyles.messageContent]: isShareChat && !(shareMessage?.files),
                                [messageStyles.mainQuestion]: true,
                                [messageStyles.threads]: threads,
                                [messageStyles.threadsLight]: threads && theme === "light",
                            })}
                        >
                            <div className={styles.messageContent}>
                                {isHighlighted ? (
                                    <WordHighlighter
                                        searchQuery={searchQuery || ""}
                                        text={String(
                                            message
                                                ? message?.content
                                                : shareMessage?.content
                                        )}
                                        excerptLength={0}
                                    />
                                ) : (
                                    <>
                                        {message ? (
                                            <div>{message?.content}</div>
                                        ) : (
                                            shareMessage?.content
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        {threads && (
                            <>
                                {!isEditMode && (
                                    ((message?.content || ((message?.files?.length ?? 0) > 0 && message?.content)) || (((message?.images?.length ?? 0)) > 0 && message?.content) && !assistantMessage) && (
                                        <div className={` flex gap-[10px] ${classNames(styles.questionIconContainer, {
                                            [styles.threads]: threads
                                        })}`}>

                                            {!shareMessage?.content && (
                                                <Tooltip
                                                    regenrate={true}
                                                    tooltipAdjustement={true}
                                                    AdjustregenrateTooltip={true}
                                                    control={
                                                        <RippleIconButton
                                                        color={themeColors.default} 
                                                        noPaddingBorder={true}
                                                        className={classNames({
                                                            [styles.EditButton]: !threads,
                                                            [styles.light]: theme === "light",
                                                            [styles.dark]: theme === "dark",
                                                            "cursor-pointer": threads,
                                                        })} onClick={() => setIsEditMode(!isEditMode)} >
                                                            <div
                                                                className={`${styles.editIcon} ${" flex justify-center items-center w-full h-full "}`}
                                                            >
                                                                {threads ? <ThEditIcon /> : <ChatEditIcon theme={theme} />}
                                                            </div>
                                                        </RippleIconButton>
                                                    }
                                                    placement="top"
                                                    theme="light"
                                                >
                                                    <div>
                                                        <FormattedMessage id={"answer.tooltip.edit"} />
                                                    </div>
                                                </Tooltip>
                                            )}

                                            {!shareChat && (

                                                <Tooltip
                                                    regenrate={true}
                                                    tooltipAdjustement={true}
                                                    AdjustregenrateTooltip={true}
                                                    control={
                                                        <CopyToClipboard
                                                            text={String(message?.content)}
                                                            onCopy={() => setIsCopied(true)}
                                                        >
                                                              <RippleIconButton
                                                                color={themeColors.default} 
                                                                noPaddingBorder={true}
                                                                className={classNames({
                                                                [styles.EditButton]: !threads,
                                                                [styles.light]: theme === "light",
                                                                [styles.dark]: theme === "dark",
                                                                [styles.CopyIconContainer]: !threads,
                                                                "cursor-pointer": threads,
                                                                
                                                            })} 
                                                            >
                                                                <div className={`${styles.copyIcon} ${"cursor-pointer flex justify-center items-center w-full h-full"}`}
                                                                >
                                                                    {threads ? <ThreadsCopyIcon /> : <CopyIcon theme={theme} />}
                                                                </div>
                                                            </RippleIconButton>
                                                        </CopyToClipboard>
                                                    }
                                                    placement="top"
                                                    theme="light"
                                                >
                                                    <div>
                                                        <FormattedMessage
                                                            id={
                                                                isCopied
                                                                    ? "answer.tooltip.copied"
                                                                    : "answer.tooltip.copy"
                                                            }
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    ))}


                            </>
                        )}
                    </div>
                )}
            </p>
        </div>
    );
};
