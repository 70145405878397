import mixpanel from 'mixpanel-browser';

const environment = process.env.REACT_APP_ENVIRONMENT;

const MIXPANEL_PROD_TOKEN = process.env.REACT_APP_MIXPANEL_PRODTOKEN ;
const MIXPANEL_TEST_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN ;

const MIXPANEL_TOKEN = environment === 'production'
    ? MIXPANEL_PROD_TOKEN || ''
    : MIXPANEL_TEST_TOKEN || '';

if (MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN, {
        debug: environment !== 'production',
        track_pageview: false
    });
} else {
    console.warn('Warning: Mixpanel token is not defined. Analytics tracking is disabled.');
}

interface UserProperties {
    name?: string;
    email?: string;
    createdAt?: string;
    avatar?: string;
}

class MixpanelAnalytics {
    static identify(userId: string, userProperties: UserProperties = {}) {
        if (userId) {
            mixpanel.identify(userId);

            const userProfile = {
                $name: userProperties.name,
                $email: userProperties.email,
                $createdAt: userProperties.createdAt,
                $avatar: userProperties.avatar,
                ...userProperties
            };

            mixpanel.people.set(userProfile);
        }
    }

    static trackPageView(pageUrl: string) {
        mixpanel.track('page_view', {
            page_url: pageUrl
        });
    }

    static trackSignup(method = 'email') {
        mixpanel.track('signup', {
            signup_method: method
        });
    }

    static trackLogin(method = 'email') {
        mixpanel.track('login', {
            login_method: method
        });
    }

    static trackLogout() {
        mixpanel.track('logout');
    }

    static reset() {
        mixpanel.reset();
    }
}

export default MixpanelAnalytics;