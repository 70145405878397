
import { EThemeType } from "redux/reducers";
interface IProp {
    theme?: EThemeType | undefined;
}
export const StarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <g clip-path="url(#clip0_12246_121)">
                <path d="M8.99688 0.5C9.28438 0.5 9.54688 0.6625 9.67188 0.921875L11.8156 5.3375L16.6031 6.04375C16.8844 6.08437 17.1188 6.28125 17.2063 6.55312C17.2938 6.825 17.2219 7.11875 17.0219 7.31875L13.55 10.7625L14.3688 15.625C14.4156 15.9062 14.3 16.1906 14.0656 16.3594C13.8313 16.5281 13.525 16.5469 13.275 16.4125L8.99375 14.125L4.71875 16.4094C4.46563 16.5438 4.15938 16.525 3.92813 16.3562C3.69688 16.1875 3.57813 15.9031 3.625 15.6219L4.44375 10.7594L0.971879 7.31875C0.768754 7.11875 0.700004 6.82188 0.787504 6.55312C0.875004 6.28437 1.10938 6.0875 1.39063 6.04375L6.17813 5.3375L8.32188 0.921875C8.45 0.6625 8.70938 0.5 8.99688 0.5ZM8.99688 2.96875L7.35625 6.35C7.24688 6.57188 7.0375 6.72813 6.79063 6.76562L3.09375 7.30937L5.77813 9.96875C5.95 10.1406 6.03125 10.3844 5.99063 10.625L5.35625 14.3656L8.64375 12.6094C8.86563 12.4906 9.13125 12.4906 9.35 12.6094L12.6375 14.3656L12.0063 10.6281C11.9656 10.3875 12.0438 10.1438 12.2188 9.97188L14.9031 7.3125L11.2063 6.76562C10.9625 6.72813 10.75 6.575 10.6406 6.35L8.99688 2.96875Z" fill="#FFD700" />
            </g>
            <defs>
                <clipPath id="clip0_12246_121">
                    <rect width="18" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
export const LockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
            <g clip-path="url(#clip0_12246_124)">
                <path d="M2.8125 3.3125V4.25H5.9375V3.3125C5.9375 2.44922 5.23828 1.75 4.375 1.75C3.51172 1.75 2.8125 2.44922 2.8125 3.3125ZM1.5625 4.25V3.3125C1.5625 1.75977 2.82227 0.5 4.375 0.5C5.92773 0.5 7.1875 1.75977 7.1875 3.3125V4.25H7.5C8.18945 4.25 8.75 4.81055 8.75 5.5V9.25C8.75 9.93945 8.18945 10.5 7.5 10.5H1.25C0.560547 10.5 0 9.93945 0 9.25V5.5C0 4.81055 0.560547 4.25 1.25 4.25H1.5625Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_12246_124">
                    <rect width="8.75" height="10" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
export const PinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
        <g id="thumbtack-solid 1" clip-path="url(#clip0_1_3412)">
        <path id="Vector" d="M1.12506 1.375C1.12506 0.891016 1.51607 0.5 2.00006 0.5H9.00006C9.48404 0.5 9.87506 0.891016 9.87506 1.375C9.87506 1.85898 9.48404 2.25 9.00006 2.25H8.19342L8.50513 6.30234C9.50865 6.84648 10.3016 7.75703 10.679 8.8918L10.7063 8.97383C10.7965 9.2418 10.7501 9.53438 10.586 9.76133C10.4219 9.98828 10.1567 10.125 9.87506 10.125H1.12506C0.843416 10.125 0.580916 9.99102 0.414119 9.76133C0.247322 9.53164 0.203572 9.23906 0.293807 8.97383L0.321151 8.8918C0.698494 7.75703 1.49146 6.84648 2.49498 6.30234L2.8067 2.25H2.00006C1.51607 2.25 1.12506 1.85898 1.12506 1.375ZM4.62506 11H6.37506V13.625C6.37506 14.109 5.98404 14.5 5.50006 14.5C5.01607 14.5 4.62506 14.109 4.62506 13.625V11Z" fill="#94A3B8"/>
        </g>
        <defs>
        <clipPath id="clip0_1_3412">
        <rect width="10.5" height="14" fill="white" transform="translate(0.25 0.5)"/>
        </clipPath>
        </defs>
        </svg>
    )
}
export const ClosedPinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
        <path d="M1.31085 0.639485C1.02647 0.415266 0.613581 0.467219 0.389363 0.751594C0.165144 1.03597 0.217097 1.44886 0.501472 1.67308L16.689 14.3606C16.9733 14.5848 17.3862 14.5328 17.6105 14.2485C17.8347 13.9641 17.7827 13.5512 17.4983 13.327L13.4132 10.125C13.6812 10.1141 13.93 9.98011 14.0858 9.76136C14.2499 9.53441 14.2964 9.23909 14.2062 8.97386L14.1788 8.89183C13.8015 7.75706 13.0085 6.84652 12.005 6.30238L11.6933 2.25003H12.4999C12.9839 2.25003 13.3749 1.85902 13.3749 1.37503C13.3749 0.891047 12.9839 0.500032 12.4999 0.500032H5.49991C5.01592 0.500032 4.62491 0.891047 4.62491 1.37503C4.62491 1.85902 5.01592 2.25003 5.49991 2.25003H6.30655L6.13975 4.42386L1.31085 0.639485ZM9.13389 10.125L5.09249 6.94222C4.521 7.45902 4.0753 8.1262 3.821 8.89183L3.79366 8.97386C3.70343 9.24183 3.74991 9.53441 3.91397 9.76136C4.07803 9.98831 4.34327 10.125 4.62491 10.125H9.13389ZM8.12491 11V13.625C8.12491 14.109 8.51593 14.5 8.99991 14.5C9.48389 14.5 9.87491 14.109 9.87491 13.625V11H8.12491Z" fill="#94A3B8"/>
      </svg>
    )
}
export const ActivePinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
            <g clip-path="url(#clip0_12246_1256)">
                <path d="M1.37506 1.375C1.37506 0.891016 1.76607 0.5 2.25006 0.5H9.25006C9.73404 0.5 10.1251 0.891016 10.1251 1.375C10.1251 1.85898 9.73404 2.25 9.25006 2.25H8.44342L8.75513 6.30234C9.75865 6.84648 10.5516 7.75703 10.929 8.8918L10.9563 8.97383C11.0465 9.2418 11.0001 9.53438 10.836 9.76133C10.6719 9.98828 10.4067 10.125 10.1251 10.125H1.37506C1.09342 10.125 0.830916 9.99102 0.664119 9.76133C0.497322 9.53164 0.453572 9.23906 0.543807 8.97383L0.571151 8.8918C0.948494 7.75703 1.74146 6.84648 2.74498 6.30234L3.0567 2.25H2.25006C1.76607 2.25 1.37506 1.85898 1.37506 1.375ZM4.87506 11H6.62506V13.625C6.62506 14.109 6.23404 14.5 5.75006 14.5C5.26607 14.5 4.87506 14.109 4.87506 13.625V11Z" fill="#4338CA" />
            </g>
            <defs>
                <clipPath id="clip0_12246_1256">
                    <rect width="10.5" height="14" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const BoxIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <g id="box-archive-solid 1" clip-path="url(#clip0_1_3414)">
        <path id="Vector" d="M1.625 1.375H13.875C14.359 1.375 14.75 1.76602 14.75 2.25V3.125C14.75 3.60898 14.359 4 13.875 4H1.625C1.14102 4 0.75 3.60898 0.75 3.125V2.25C0.75 1.76602 1.14102 1.375 1.625 1.375ZM1.625 4.875H13.875V11.875C13.875 12.8402 13.0902 13.625 12.125 13.625H3.375C2.40977 13.625 1.625 12.8402 1.625 11.875V4.875ZM5.125 7.0625C5.125 7.30312 5.32188 7.5 5.5625 7.5H9.9375C10.1781 7.5 10.375 7.30312 10.375 7.0625C10.375 6.82188 10.1781 6.625 9.9375 6.625H5.5625C5.32188 6.625 5.125 6.82188 5.125 7.0625Z" fill="#94A3B8"/>
        </g>
        <defs>
        <clipPath id="clip0_1_3414">
        <rect width="14" height="14" fill="white" transform="translate(0.75 0.5)"/>
        </clipPath>
        </defs>
        </svg>
    )
}
export const DeleteIcon = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
  <g clip-path="url(#clip0_1_3416)">
    <path d="M4.44687 0.983984L4.25 1.375H1.625C1.14102 1.375 0.75 1.76602 0.75 2.25C0.75 2.73398 1.14102 3.125 1.625 3.125H12.125C12.609 3.125 13 2.73398 13 2.25C13 1.76602 12.609 1.375 12.125 1.375H9.5L9.30312 0.983984C9.15547 0.685938 8.85195 0.5 8.52109 0.5H5.22891C4.89805 0.5 4.59453 0.685938 4.44687 0.983984ZM12.125 4H1.625L2.20469 13.2695C2.24844 13.9613 2.82266 14.5 3.51445 14.5H10.2355C10.9273 14.5 11.5016 13.9613 11.5453 13.2695L12.125 4Z" fill="#94A3B8"/>
  </g>
  <defs>
    <clipPath id="clip0_1_3416">
      <rect width="12.25" height="14" fill="white" transform="translate(0.75 0.5)"/>
    </clipPath>
  </defs>
</svg>
)}

export const RightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
          <path d="M9.69141 5.94141C9.93555 5.69727 9.93555 5.30078 9.69141 5.05664L6.56641 1.93164C6.32227 1.6875 5.92578 1.6875 5.68164 1.93164C5.4375 2.17578 5.4375 2.57227 5.68164 2.81641L8.36523 5.5L5.68359 8.18359C5.43945 8.42773 5.43945 8.82422 5.68359 9.06836C5.92773 9.3125 6.32422 9.3125 6.56836 9.06836L9.69336 5.94336L9.69141 5.94141ZM2.81641 9.06641L5.94141 5.94141C6.18555 5.69727 6.18555 5.30078 5.94141 5.05664L2.81641 1.93164C2.57227 1.6875 2.17578 1.6875 1.93164 1.93164C1.6875 2.17578 1.6875 2.57227 1.93164 2.81641L4.61523 5.5L1.93359 8.18359C1.68945 8.42773 1.68945 8.82422 1.93359 9.06836C2.17773 9.3125 2.57422 9.3125 2.81836 9.06836L2.81641 9.06641Z" fill="#737373"/>
        </svg>
    )
};

export const LeftIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
          <path d="M1.30859 5.05859C1.06445 5.30273 1.06445 5.69922 1.30859 5.94336L4.43359 9.06836C4.67773 9.3125 5.07422 9.3125 5.31836 9.06836C5.5625 8.82422 5.5625 8.42773 5.31836 8.18359L2.63477 5.5L5.31641 2.81641C5.56055 2.57227 5.56055 2.17578 5.31641 1.93164C5.07227 1.6875 4.67578 1.6875 4.43164 1.93164L1.30664 5.05664L1.30859 5.05859ZM8.18359 1.93359L5.05859 5.05859C4.81445 5.30273 4.81445 5.69922 5.05859 5.94336L8.18359 9.06836C8.42773 9.3125 8.82422 9.3125 9.06836 9.06836C9.3125 8.82422 9.3125 8.42773 9.06836 8.18359L6.38477 5.5L9.06641 2.81641C9.31055 2.57227 9.31055 2.17578 9.06641 1.93164C8.82227 1.6875 8.42578 1.6875 8.18164 1.93164L8.18359 1.93359Z" fill="#737373"/>
        </svg>
    )
};

export const ShiftRightIcon = ({theme}: IProp) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
          <path d="M9.70615 7.79365C10.0968 8.18428 10.0968 8.81865 9.70615 9.20928L3.70615 15.2093C3.31553 15.5999 2.68115 15.5999 2.29053 15.2093C1.8999 14.8187 1.8999 14.1843 2.29053 13.7937L7.58428 8.4999L2.29365 3.20615C1.90303 2.81553 1.90303 2.18115 2.29365 1.79053C2.68428 1.3999 3.31865 1.3999 3.70928 1.79053L9.70928 7.79053L9.70615 7.79365Z" fill={theme==="light" ? "#94A3B8": "#fff"}/>
        </svg>
    )
};

export const ShiftLeftIcon = ({theme}: IProp) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
            <path d="M0.293847 9.20635C-0.0967756 8.81572 -0.0967756 8.18135 0.293847 7.79072L6.29385 1.79072C6.68447 1.4001 7.31885 1.4001 7.70947 1.79072C8.1001 2.18135 8.1001 2.81572 7.70947 3.20635L2.41572 8.5001L7.70635 13.7939C8.09697 14.1845 8.09697 14.8188 7.70635 15.2095C7.31572 15.6001 6.68135 15.6001 6.29072 15.2095L0.290722 9.20947L0.293847 9.20635Z" fill={theme==="light" ? "#94A3B8": "#fff"}/>
        </svg>
    );
};

export const ChatInfoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <g clip-path="url(#clip0_120_1875)">
                <path d="M15.1875 2.875C15.1875 2.56562 14.9344 2.3125 14.625 2.3125L3.375 2.3125C3.06563 2.3125 2.8125 2.56562 2.8125 2.875L2.8125 14.125C2.8125 14.4344 3.06563 14.6875 3.375 14.6875L14.625 14.6875C14.9344 14.6875 15.1875 14.4344 15.1875 14.125L15.1875 2.875ZM14.625 0.625C15.866 0.625 16.875 1.63398 16.875 2.875L16.875 14.125C16.875 15.366 15.866 16.375 14.625 16.375L3.375 16.375C2.13398 16.375 1.125 15.366 1.125 14.125L1.125 2.875C1.125 1.63398 2.13398 0.625 3.375 0.625L14.625 0.625ZM12.375 8.5C12.375 8.73555 12.2766 8.95703 12.1043 9.11875L8.1668 12.775C7.9207 13.0035 7.56211 13.0633 7.25625 12.9297C6.95039 12.7961 6.75 12.4902 6.75 12.1562L6.75 4.84375C6.75 4.50977 6.95039 4.20391 7.25625 4.07031C7.56211 3.93672 7.9207 3.99648 8.1668 4.225L12.1043 7.88125C12.2766 8.03945 12.375 8.26445 12.375 8.5Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_120_1875">
                    <rect width="15.75" height="18" fill="white" transform="matrix(0 1 -1 0 18 0.625)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const AllSourcesIcon = ({theme}: IProp) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M0.106498 2.00117C0.286966 1.61836 0.669779 1.375 1.09361 1.375H12.9061C13.3299 1.375 13.7127 1.61836 13.8932 2.00117C14.0737 2.38398 14.019 2.83516 13.751 3.16328L8.74986 9.27461V12.75C8.74986 13.0809 8.56392 13.3844 8.26587 13.532C7.96783 13.6797 7.61509 13.6496 7.34986 13.45L5.59986 12.1375C5.37837 11.9734 5.24986 11.7137 5.24986 11.4375V9.27461L0.245951 3.16055C-0.0192835 2.83516 -0.0767054 2.38125 0.106498 2.00117Z" fill={theme==="light" ? "#4338CA": "#527AE6"}/>
        </svg>
    )
}

export const CheckIcon =()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none">
<path d="M14.4562 0.293701C14.8469 0.684326 14.8469 1.3187 14.4562 1.70933L6.45624 9.70933C6.06562 10.1 5.43124 10.1 5.04062 9.70933L1.04062 5.70933C0.649994 5.3187 0.649994 4.68433 1.04062 4.2937C1.43124 3.90308 2.06562 3.90308 2.45624 4.2937L5.74999 7.58433L13.0437 0.293701C13.4344 -0.0969238 14.0687 -0.0969238 14.4594 0.293701H14.4562Z" fill="#4338CA"/>
</svg>
    )
}
