export const FileError = () =>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
  <g clip-path="url(#clip0_21_2319)">
    <path d="M0.625 2.75C0.625 1.2332 1.8582 0 3.375 0H10.25V5.5C10.25 6.26055 10.8645 6.875 11.625 6.875H17.125V8.53359C13.9496 9.43164 11.625 12.3492 11.625 15.8125C11.625 18.352 12.8754 20.5949 14.7918 21.9699C14.6543 21.9914 14.5168 22 14.375 22H3.375C1.8582 22 0.625 20.7668 0.625 19.25V2.75ZM17.125 5.5H11.625V0L17.125 5.5ZM19.1875 9.625C20.8285 9.625 22.4023 10.2769 23.5627 11.4373C24.7231 12.5977 25.375 14.1715 25.375 15.8125C25.375 17.4535 24.7231 19.0273 23.5627 20.1877C22.4023 21.3481 20.8285 22 19.1875 22C17.5465 22 15.9727 21.3481 14.8123 20.1877C13.6519 19.0273 13 17.4535 13 15.8125C13 14.1715 13.6519 12.5977 14.8123 11.4373C15.9727 10.2769 17.5465 9.625 19.1875 9.625ZM19.1875 19.9375C19.461 19.9375 19.7233 19.8288 19.9167 19.6355C20.1101 19.4421 20.2188 19.1798 20.2188 18.9062C20.2188 18.6327 20.1101 18.3704 19.9167 18.177C19.7233 17.9837 19.461 17.875 19.1875 17.875C18.914 17.875 18.6517 17.9837 18.4583 18.177C18.2649 18.3704 18.1562 18.6327 18.1562 18.9062C18.1562 19.1798 18.2649 19.4421 18.4583 19.6355C18.6517 19.8288 18.914 19.9375 19.1875 19.9375ZM19.1875 11.6875C18.8094 11.6875 18.5 11.9969 18.5 12.375V15.8125C18.5 16.1906 18.8094 16.5 19.1875 16.5C19.5656 16.5 19.875 16.1906 19.875 15.8125V12.375C19.875 11.9969 19.5656 11.6875 19.1875 11.6875Z" fill="#F87171"/>
  </g>
  <defs>
    <clipPath id="clip0_21_2319">
      <rect width="24.75" height="22" fill="white" transform="translate(0.625)"/>
    </clipPath>
  </defs>
</svg>
    )}