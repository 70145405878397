
export const ThreadsCopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 12 12" fill="none">
            <g id="copy-regular 1" clip-path="url(#clip0_927_2182)">
                <path id="Vector" d="M9 7.875H4.5C4.29375 7.875 4.125 7.70625 4.125 7.5V1.5C4.125 1.29375 4.29375 1.125 4.5 1.125H7.78359L9.375 2.71641V7.5C9.375 7.70625 9.20625 7.875 9 7.875ZM4.5 9H9C9.82734 9 10.5 8.32734 10.5 7.5V2.71641C10.5 2.41875 10.3805 2.13281 10.1695 1.92188L8.58047 0.330469C8.36953 0.119531 8.08359 0 7.78594 0H4.5C3.67266 0 3 0.672656 3 1.5V7.5C3 8.32734 3.67266 9 4.5 9ZM1.5 3C0.672656 3 0 3.67266 0 4.5V10.5C0 11.3273 0.672656 12 1.5 12H6C6.82734 12 7.5 11.3273 7.5 10.5V9.75H6.375V10.5C6.375 10.7063 6.20625 10.875 6 10.875H1.5C1.29375 10.875 1.125 10.7063 1.125 10.5V4.5C1.125 4.29375 1.29375 4.125 1.5 4.125H2.25V3H1.5Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_927_2182">
                    <rect width="11.5" height="13" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ThAmplifierIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 13 12" fill="none">
            <g id="Component 1" clip-path="url(#clip0_927_2190)">
                <path id="Vector" d="M11.375 6C11.375 4.70707 10.8614 3.46709 9.94715 2.55285C9.03291 1.63861 7.79293 1.125 6.5 1.125C5.20707 1.125 3.96709 1.63861 3.05285 2.55285C2.13861 3.46709 1.625 4.70707 1.625 6C1.625 7.29293 2.13861 8.53291 3.05285 9.44715C3.96709 10.3614 5.20707 10.875 6.5 10.875C7.79293 10.875 9.03291 10.3614 9.94715 9.44715C10.8614 8.53291 11.375 7.29293 11.375 6ZM0.5 6C0.5 4.4087 1.13214 2.88258 2.25736 1.75736C3.38258 0.632141 4.9087 0 6.5 0C8.0913 0 9.61742 0.632141 10.7426 1.75736C11.8679 2.88258 12.5 4.4087 12.5 6C12.5 7.5913 11.8679 9.11742 10.7426 10.2426C9.61742 11.3679 8.0913 12 6.5 12C4.9087 12 3.38258 11.3679 2.25736 10.2426C1.13214 9.11742 0.5 7.5913 0.5 6ZM4.91328 3.44766C5.09141 3.34922 5.30703 3.35156 5.48281 3.45938L8.85781 5.52188C9.02422 5.625 9.12734 5.80547 9.12734 6.00234C9.12734 6.19922 9.02422 6.37969 8.85781 6.48281L5.48281 8.54531C5.30938 8.65078 5.09141 8.65547 4.91328 8.55703C4.73516 8.45859 4.625 8.27109 4.625 8.06719V3.94219C4.625 3.73828 4.73516 3.55078 4.91328 3.45234V3.44766Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_927_2190">
                    <rect width="12" height="12" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
export const ThStopIcon = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 12 10" fill="none">
  <g clip-path="url(#clip0_238_6106)">
    <path d="M10.875 6C10.875 4.70707 10.3614 3.46709 9.44715 2.55285C8.53291 1.63861 7.29293 1.125 6 1.125C4.70707 1.125 3.46709 1.63861 2.55285 2.55285C1.63861 3.46709 1.125 4.70707 1.125 6C1.125 7.29293 1.63861 8.53291 2.55285 9.44715C3.46709 10.3614 4.70707 10.875 6 10.875C7.29293 10.875 8.53291 10.3614 9.44715 9.44715C10.3614 8.53291 10.875 7.29293 10.875 6ZM0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 7.5913 11.3679 9.11742 10.2426 10.2426C9.11742 11.3679 7.5913 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6ZM5.25 4.3125V7.6875C5.25 7.99922 4.99922 8.25 4.6875 8.25C4.37578 8.25 4.125 7.99922 4.125 7.6875V4.3125C4.125 4.00078 4.37578 3.75 4.6875 3.75C4.99922 3.75 5.25 4.00078 5.25 4.3125ZM7.875 4.3125V7.6875C7.875 7.99922 7.62422 8.25 7.3125 8.25C7.00078 8.25 6.75 7.99922 6.75 7.6875V4.3125C6.75 4.00078 7.00078 3.75 7.3125 3.75C7.62422 3.75 7.875 4.00078 7.875 4.3125Z" fill="#4338CA"/>
  </g>
  <defs>
    <clipPath id="clip0_238_6106">
      <rect width="12" height="12" fill="white"/>
    </clipPath>
  </defs>
</svg>
);
};
export const ThRegenerateIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 13 12" fill="none">
            <g id="Re-write" clip-path="url(#clip0_927_2188)">
                <path id="Vector" d="M0.5 5.25021C0.5 5.66506 0.835156 6.00021 1.25 6.00021C1.66484 6.00021 2 5.66506 2 5.25021C2 4.00802 3.00781 3.00021 4.25 3.00021H8V3.75021C8 4.05256 8.18281 4.32677 8.46406 4.44396C8.74531 4.56115 9.06641 4.49552 9.28203 4.28224L10.782 2.78224C11.075 2.48927 11.075 2.01349 10.782 1.72052L9.28203 0.220524C9.06641 0.00489908 8.74531 -0.0583822 8.46406 0.0588053C8.18281 0.175993 8 0.447868 8 0.750212V1.50021H4.25C2.17812 1.50021 0.5 3.17834 0.5 5.25021ZM12.5 6.75021C12.5 6.33537 12.1648 6.00021 11.75 6.00021C11.3352 6.00021 11 6.33537 11 6.75021C11 7.9924 9.99219 9.00021 8.75 9.00021H5V8.25021C5 7.94787 4.81719 7.67365 4.53594 7.55646C4.25469 7.43927 3.93359 7.5049 3.71797 7.71818L2.21797 9.21818C1.925 9.51115 1.925 9.98693 2.21797 10.2799L3.71797 11.7799C3.93359 11.9955 4.25469 12.0588 4.53594 11.9416C4.81719 11.8244 5 11.5526 5 11.2479V10.4979H8.75C10.8219 10.4979 12.5 8.81974 12.5 6.74787V6.75021Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_927_2188">
                    <rect width="12" height="12" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
