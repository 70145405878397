import { useEffect, useRef } from "react";
import { UrlUploadUI } from "./Component/UrlUploadUI";
import { useFileUpload } from "utils/uploadUtils";
export const UploadingUrl = () => {

  const isMounted = useRef(true);

  const { 
    onSubmit, 
    onConfirm, 
    onCancel, 
    messageId, 
    errorModal, 
    setErrorModal 
  } = useFileUpload();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>

   <UrlUploadUI
      onSubmit={onSubmit}
      errorModal={errorModal}
      setErrorModal={setErrorModal}
      messageId={messageId}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
    </>
  );
};

