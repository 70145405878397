import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of our context
interface MiniChatInfoContextType {
  showMiniChatInfo: boolean;
  setShowMiniChatInfo: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMiniChatInfo: () => void;
}

// Create the context with a default value
const MiniChatInfoContext = createContext<MiniChatInfoContextType | undefined>(undefined);

// Props type for the provider
interface MiniChatInfoProviderProps {
  children: ReactNode;
}

// Provider component that wraps your app
export const MiniChatInfoProvider: React.FC<MiniChatInfoProviderProps> = ({ children }) => {
  const [showMiniChatInfo, setShowMiniChatInfo] = useState<boolean>(false);

  // Value to be provided to consuming components
  const value: MiniChatInfoContextType = {
    showMiniChatInfo,
    setShowMiniChatInfo,
    toggleMiniChatInfo: () => setShowMiniChatInfo(prev => !prev)
  };

  return (
    <MiniChatInfoContext.Provider value={value}>
      {children}
    </MiniChatInfoContext.Provider>
  );
};

// Custom hook to use the context
export const useMiniChatInfo = (): MiniChatInfoContextType => {
  const context = useContext(MiniChatInfoContext);
  if (context === undefined) {
    throw new Error("useMiniChatInfo must be used within a MiniChatInfoProvider");
  }
  return context;
};