import { FormattedMessage } from "react-intl";
import styles from "../RichContext.module.scss";
import styled from "styled-components";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { AllowedFileTypes, DocAllowedTypes } from "utils/constants";
import { UploadIcon } from "../../icons/UploadIcon";
import { ErrorHandler } from "../../ErrorHandler/ErrorHandler";
import { useEffect } from "react";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";

// Style a wrapper for the ripple button
const RippleButtonWrapper = styled.div<{ disabled?: boolean }>`
    background-color: ${({ disabled }) => (disabled ? "#3730A3" : "#3730A3")};
  color: #fff;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  border-radius: 4px;
  padding: 10px 11px;
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  @media (max-width: 576px) {
    font-size: 11px;
    padding: 10px 16px;
  }
`;

const ErrorMessage = styled.div`
  color: #737373;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`;

interface FileUploadWrapperProps {
  fileLimit: number;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLElement>) => void;
  errorModal: { message: string; show: boolean };
  setErrorModal: (error: { message: string; show: boolean }) => void;
  messageId: string;
  onConfirm: () => void;
  onCancel: () => void;
  chatContent?: boolean;
}

export const FileUploadWrapper: React.FC<FileUploadWrapperProps> = ({
  fileLimit,
  handleFileSelect,
  handleDrop,
  errorModal,
  setErrorModal,
  messageId,
  onConfirm,
  onCancel,
  chatContent,
}) => {

  const { theme, userDetail } = useSelector((state) => state.authReducer);
  const acceptString = AllowedFileTypes.map((val) => ` .${val}`).toString();
  const { workSpaceFiles } = useSelector((state) => state.workSpaceReducer);
  const PlanName = userDetail?.user.activeSubscription?.name;

  const isFileLimitError = !chatContent && ((errorModal.message === "workSpace.upload.validation") ||
    (errorModal.message === "workSpace.upload.validation.Free"));
    
  // Determine if button should be disabled
  const isButtonDisabled = !chatContent && ((PlanName === "Free" && (workSpaceFiles?.length ?? 0) >= 1) || 
    (PlanName !== "Free" && (workSpaceFiles?.length ?? 0) >= 5));

    useEffect(() => {
      if (!isButtonDisabled && errorModal.show && !chatContent) {
        setErrorModal({ message: "", show: false });
      }
    }, [workSpaceFiles?.length, isButtonDisabled]);

  const handleButtonClick = () => {
    if (isButtonDisabled) return;

    if (!chatContent && errorModal.show && !isButtonDisabled) {
      setErrorModal({ message: "", show: false });
    }
    
    document.getElementById("fileUploadInput")?.click();
  };

  return (
    <>
      <div
        className={styles.fileWrapper}
        onDragOver={(event) => {
          // Clear error message when dragging over if button is not disabled
          if (!chatContent && errorModal.show && !isButtonDisabled) {
            setErrorModal({ message: "", show: false });
          }
          event.preventDefault();
        }}
        onDrop={handleDrop}
      >
        <UploadIcon />
        <div className={styles.chooseFileWrapper}>
        <RippleIconButton
        uploadfile={true}
              color={'rgba(255, 255, 255, 0.5)'}
              onClick={!isButtonDisabled ? handleButtonClick : undefined} 
          >
          <RippleButtonWrapper disabled={isButtonDisabled}>
            <span className={styles.btn}>
              <FormattedMessage id="workspace.context.btntext" />
            </span>
            </RippleButtonWrapper>
            <input
              id="fileUploadInput"
              type="file"
              multiple
              accept={
                chatContent
                  ? acceptString
                  : DocAllowedTypes.map((type) => `.${type}`).join(",")
              }
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
          </RippleIconButton>
        </div>
        
        {(isButtonDisabled || (isFileLimitError && errorModal.show) && !chatContent) ? (
          <ErrorMessage>
            {errorModal.message ? (
              <FormattedMessage id={errorModal.message} />
            ) : (
              <FormattedMessage id={PlanName === "Free" ? "workSpace.upload.validation.Free" : "workSpace.upload.validation"} />
            )}
          </ErrorMessage>
        ) : (
          <div
            className={classNames(styles.heading, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage
              id="workspace.context.statement"
              values={{ fileLimit }}
            />
          </div>
        )}
      </div>

      {(!isFileLimitError || !errorModal.show || chatContent) && (
        <ErrorHandler
          errorModal={{
            message: isFileLimitError ? "" : errorModal.message,
            show: isFileLimitError ? false : errorModal.show
          }}
          setErrorModal={setErrorModal}
          messageId={messageId}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </>
  );
};