export const CrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M12.5445 5.29454C12.9839 4.85509 12.9839 4.14142 12.5445 3.70197C12.105 3.26251 11.3914 3.26251 10.9519 3.70197L7.24995 7.40743L3.54448 3.70548C3.10503 3.26603 2.39136 3.26603 1.9519 3.70548C1.51245 4.14493 1.51245 4.85861 1.9519 5.29806L5.65737 9.00001L1.95542 12.7055C1.51597 13.1449 1.51597 13.8586 1.95542 14.2981C2.39487 14.7375 3.10854 14.7375 3.548 14.2981L7.24995 10.5926L10.9554 14.2945C11.3949 14.734 12.1085 14.734 12.548 14.2945C12.9875 13.8551 12.9875 13.1414 12.548 12.702L8.84253 9.00001L12.5445 5.29454Z"
        fill="#94A3B8"
      />
    </svg>
  );
};

export const FilePreviewCrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
    >
      <path
        d="M10.7062 5.20615C11.0968 4.81553 11.0968 4.18115 10.7062 3.79053C10.3155 3.3999 9.68115 3.3999 9.29053 3.79053L5.9999 7.08428L2.70615 3.79365C2.31553 3.40303 1.68115 3.40303 1.29053 3.79365C0.899902 4.18428 0.899902 4.81865 1.29053 5.20928L4.58428 8.4999L1.29365 11.7937C0.903027 12.1843 0.903027 12.8187 1.29365 13.2093C1.68428 13.5999 2.31865 13.5999 2.70928 13.2093L5.9999 9.91553L9.29365 13.2062C9.68428 13.5968 10.3187 13.5968 10.7093 13.2062C11.0999 12.8155 11.0999 12.1812 10.7093 11.7905L7.41553 8.4999L10.7062 5.20615Z"
        fill="#94A3B8"
      />
    </svg>
  );
};
