import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MixpanelAnalytics from "utils/mixpanel";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Add a small delay to ensure we only track the final destination
    const timeoutId = setTimeout(() => {
      MixpanelAnalytics.trackPageView(window.location.href);
    }, 200); 

    return () => clearTimeout(timeoutId);
  }, [location]);

  return null;
};