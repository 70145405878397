import { CloseIcon } from "pages/Workspaces/components/icons/CloseIcon";
import { UploadingFiles } from "pages/Workspaces/components/RichContext/UploadingFiles";
import { Notifications } from "pages/Workspaces/components/Notifications/Notifications";
import { UploadedFiles } from "pages/Workspaces/components/uploadedFiles/UploadedFiles";
import { UploadingUrl } from "pages/Workspaces/components/RichContext/UploadingUrl";
import { useState, Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";
import { useWindowSize } from "hooks/useWindowSize";
import { useEffectOnce } from "react-use";
import { IChatModel, setEmptyFile, setEmptyPreview } from "redux/actions";
import { useSelector } from "redux/hooks";
import { ChatContentUploadFile } from "../Component/ChatContentUploadFile";
import { FilesPreview } from "../Component/FilesPreview";
import { useFileUpload } from "hooks/useFileUpload";
import { ChatContentUploadURL } from "../Component/ChatContentUploadURL";

import { RIPPLE_COLORS } from "utils/constants";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";

interface Iprops {
  // setAddSource: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  chatContent?: boolean;
  setURL?: Dispatch<SetStateAction<string>>;
  message?: string;
  onSendMessage?: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[],
    ws_id?: string

) => void;
defaultOption?: string;
}

export const AddSource = ({ onClose, chatContent, setURL, message,  onSendMessage, defaultOption  }: Iprops) => {
  const { width } = useWindowSize();
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";
  const { workSpaceFiles, FilePreview, chatContentUploadingFiles } = useSelector((state) => state.workSpaceReducer);
  const { handleFileUpload } = useFileUpload(false);
  const [selectedOption, setSelectedOption] = useState((chatContentUploadingFiles.length === 0 && FilePreview.length === 0 && chatContent)? defaultOption : "files");

  useEffectOnce(() => {
    setEmptyFile();
    setEmptyPreview();
  });

  const maxSources = isFreePlan ? 1 : 5;
  const usedSources = workSpaceFiles?.length || 0;

  const handleSave = () => {
    const dataTransfer = new DataTransfer();
    FilePreview.forEach(({ file }) => {
      dataTransfer.items.add(file);
    });
      handleFileUpload(dataTransfer.files, true);
      onClose();
  };

  const themeColors = theme === 'dark' ? RIPPLE_COLORS.dark : RIPPLE_COLORS.light;

  return (
    <>
      <div className="flex flex-col gap-[14px] font-inter">
        <div className="flex justify-between pb-[2px]">
          <h3
            className={`${width <= 576 ? "text-[14px]" : "text-[16px]"} text-[#525252] dark:text-[#fff] leading-[20px] font-semibold `}
          >
            {!chatContent ? (
              <FormattedMessage id="workspace.addsources.head" />
            ) : (
              <FormattedMessage id="workspace.addThread.head" />
            )}
          </h3>
          <span
            className="cursor-pointer flex items-center justify-end text-[#94A3B8] dark:text-white"
            onClick={onClose}
          >
            <CloseIcon />
          </span>
        </div>
        <div
          className="flex justify-between"
          style={{ borderBottom: "0.4px solid #94A3B8 " }}
        >
          <div className={`${width <= 576 ? "gap-[6px]" : " gap-[24px]"} flex`}>
          <RippleIconButton
             uploadfile={true}
              color={themeColors.default}
              className={`leading-[17px] font-normal cursor-pointer pb-1 ${width <= 576 ? "text-[12px]" : "text-[14px]"} ${
                selectedOption === "files"
                  ? theme === "light"
                    ? "text-[#4338CA]"
                    : "text-[#527AE6]"
                  : theme === "light"
                    ? "text-[#737373]"
                    : "text-[rgba(255,255,255,0.9)]"
              }`}
              style={{
                borderBottom:
                  selectedOption === "files"
                    ? `2px solid ${theme === "light" ? "#4338CA" : "#527AE6"}`
                    : "none",
              }}
              onClick={() => setSelectedOption("files")}
            >
              <FormattedMessage id="workspace.addsources.files" />
            </RippleIconButton>
            <RippleIconButton
             uploadfile={true}
              color={themeColors.default}
              className={`leading-[17px] font-normal pb-1 ${((chatContentUploadingFiles.length > 0 || FilePreview.length > 0) && chatContent) ? "opacity-[50%] cursor-not-allowed" : "cursor-pointer"} ${width <= 576 ? "text-[12px]" : "text-[14px]"} ${
                selectedOption === "urls"
                  ?  theme === "light"
                    ? "text-[#4338CA]"
                    : "text-[#527AE6]"
                  : theme === "light"
                    ? "text-[#737373]"
                    : "text-[rgba(255,255,255,0.9)]"
              }`}
              style={{
                borderBottom:
                  selectedOption === "urls"
                    ? `2px solid ${theme === "light" ? "#4338CA" : "#527AE6"}`
                    : "none",
              }}
              onClick={() => {
              if(chatContent) {
                if((chatContentUploadingFiles.length === 0 && FilePreview.length === 0)) 
                setSelectedOption("urls")
              } else setSelectedOption("urls")
              }}
            >
              <FormattedMessage id="workspace.addsources.urls" />
            </RippleIconButton>
          </div>
          {!chatContent && (
            <div
              className={` ${width <= 576 ? "text-[10px]" : "text-[12px]"} text-[#525252] dark:text-[rgba(255,255,255,0.9)] leading-[15px] font-normal `}
            >
              <span className="underline">
                <FormattedMessage id="workspace.addsource.usage" />
              </span>
              &nbsp;-&nbsp;
              <FormattedMessage
                id="workspace.addsource.subhead"
                values={{ used: usedSources, max: maxSources }}
              />
            </div>
          )}
        </div>
        {selectedOption === "files" &&
          (chatContent ? <ChatContentUploadFile /> : <UploadingFiles />)}
        {selectedOption === "urls" && (chatContent ? <ChatContentUploadURL setURL={setURL} message={message} onSendMessage={ onSendMessage} onClose={onClose}/> : <UploadingUrl />)}
        <Notifications
          notificationIds={[
            "workspace.context.notification1",
            "workspace.context.notification2",
            "workspace.context.notification3",
            "workspace.context.notification4",
          ]}
          headingId="workspace.context.notificationhead"
        />
        {!chatContent ? (
          <UploadedFiles hideContainer={true} hideSubhead={true} />
        ) : (
          <FilesPreview handleSave={handleSave} />
        )}
      </div>
      <div className="flex justify-end pt-4"></div>
    </>
  );
};
