import React, { useRef, useEffect, useState } from "react";
import styles from "../../FilePreviewModal.module.scss";
import { useSelector } from "redux/hooks";

interface HTMLPreviewProps {
  content: string;
}

// Enhanced sanitization function with Inter font and better dark mode handling
const sanitizeHtml = (html: string, isDarkMode: boolean): string => {
  // Only add helpful styles without removing content
  let sanitized = html;
  
  // Add Inter font import and apply aggressive styling for dark mode
  const styleTag = `
    <style>
      /* Import Inter font */
      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
      
      /* Reset and ensure all content fits within container */
      html, body {
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        overflow-x: hidden !important;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
      }
      
      /* Main container for content */
      body {
        box-sizing: border-box !important;
        padding: 20px !important;
        ${isDarkMode ? `
          background-color: #1e1e1e !important;
          color: #ffffff !important;
        ` : `
          background-color: #ffffff !important;
          color: #000000 !important;
        `}
      }
      
      /* Apply Inter font to all elements */
      * {
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
        max-width: 100% !important;
        box-sizing: border-box !important;
        word-wrap: break-word !important;
      }
      
      /* Ensure ALL text elements have appropriate color in dark mode */
      ${isDarkMode ? `
        p, h1, h2, h3, h4, h5, h6, span, div, li, td, th, label, blockquote, cite, 
        figcaption, address, code, pre, q, small, strong, em, b, i, u, ul, ol {
          color: #ffffff !important;
        }
        
        /* Ensure links have high contrast in dark mode */
        a, a:link, a:active {
          color: #66b3ff !important;
        }
        a:visited {
          color: #d9b3ff !important;
        }
        a:hover {
          color: #99ccff !important;
          text-decoration: underline !important;
        }
        
        /* Force visibility for elements with text */
        [style*="visibility: hidden"], [style*="visibility:hidden"], 
        [style*="display: none"], [style*="display:none"] {
          visibility: visible !important;
          display: block !important;
        }
      ` : ''}
      
      /* Force tables to be responsive */
      table {
        width: 100% !important;
        display: block !important;
        overflow-x: auto !important;
        white-space: nowrap !important;
        ${isDarkMode ? `
          border-color: #555 !important;
          color: #ffffff !important;
        ` : ''}
      }
      
      /* Handle pre and code blocks */
      pre, code {
        white-space: pre-wrap !important;
        word-break: break-word !important;
        ${isDarkMode ? `
          background-color: #2a2a2a !important;
          color: #e6e6e6 !important;
        ` : ''}
      }
      
      /* Ensure images don't overflow */
      img {
        max-width: 100% !important;
        height: auto !important;
      }
      
      /* Adjust form elements for dark mode */
      ${isDarkMode ? `
        input, textarea, select, button {
          background-color: #333 !important;
          color: #fff !important;
          border-color: #555 !important;
        }
      ` : ''}
    </style>
  `;
  
  // Insert our style tag before the closing head tag, or create a head if none exists
  if (sanitized.includes('</head>')) {
    sanitized = sanitized.replace('</head>', `${styleTag}</head>`);
  } else {
    sanitized = `<head>${styleTag}</head>${sanitized}`;
  }
  
  return sanitized;
};

const HTMLPreview: React.FC<HTMLPreviewProps> = ({ content }) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  
  // Get the current theme from your state management
  const { theme } = useSelector((state) => state.authReducer) || { theme: 'light' };
  const isDarkMode = theme === 'dark';
  
  const sanitizedContent = sanitizeHtml(content, isDarkMode);
  
  // Set up error detection and loading status
  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        setIsLoading(false);
        
        try {
          // Check if we can access the content document
          const iframeDoc = iframeRef.current?.contentDocument;
          
          // If there's no body, something went wrong
          if (!iframeDoc || !iframeDoc.body || iframeDoc.body.innerHTML === '') {
            setError('Could not render HTML content properly');
          } else {
            setError(null);
            
            // Apply additional style modifications directly to the document
            try {
              const iframeStyle = iframeDoc.createElement('style');
              iframeStyle.textContent = isDarkMode ? `
                /* Additional dark mode specific styles for dynamic content */
                body > * {
                  max-width: 100% !important;
                  overflow-x: hidden !important;
                  font-family: 'Inter', sans-serif !important;
                }
                
                /* Force all text to be visible */
                * {
                  opacity: 1 !important;
                  visibility: visible !important;
                }
                
                /* Find any element with color similar to dark blue and force it to be lighter */
                [style*="color: #00"], [style*="color:#00"], [style*="color: rgb(0,0,"], [style*="color:rgb(0,0,"] {
                  color: #66b3ff !important;
                }
                
                /* Override any dark text on dark background */
                [style*="color: #0"], [style*="color:#0"],
                [style*="color: #1"], [style*="color:#1"],
                [style*="color: #2"], [style*="color:#2"],
                [style*="color: #3"], [style*="color:#3"] {
                  color: #ffffff !important;
                }
              ` : `
                body > * {
                  max-width: 100% !important;
                  overflow-x: hidden !important;
                  font-family: 'Inter', sans-serif !important;
                }
              `;
              iframeDoc.head.appendChild(iframeStyle);
              
              // Try to find and fix any hidden elements
              if (isDarkMode) {
                const allElements = iframeDoc.querySelectorAll('*');
                allElements.forEach(el => {
                  if (el instanceof HTMLElement) {
                    // Check for hidden elements via inline styles and fix them
                    if (el.style.display === 'none' || el.style.visibility === 'hidden') {
                      if (el.textContent && el.textContent.trim() !== '') {
                        el.style.display = 'block';
                        el.style.visibility = 'visible';
                      }
                    }
                    
                    // Check for dark text colors and fix
                    const computedStyle = window.getComputedStyle(el);
                    const color = computedStyle.color;
                    
                    // If text is too dark for dark mode, make it white
                    if (color && color.includes('rgb(0,') || color.includes('rgb(1,') || 
                        color.includes('rgb(2,') || color.includes('rgb(3,')) {
                      el.style.color = '#ffffff';
                    }
                  }
                });
              }
            } catch (styleErr) {
              console.warn('Could not add additional styles to iframe:', styleErr);
            }
          }
        } catch (err) {
          console.error('Error accessing iframe content:', err);
          setError('Error rendering HTML: possible security restriction');
        }
      };
      
      // Handle load errors
      iframeRef.current.onerror = () => {
        setIsLoading(false);
        setError('Failed to load HTML content');
      };
    }
    
    return () => {
      // Clean up to prevent memory leaks
      if (iframeRef.current) {
        iframeRef.current.onload = null;
        iframeRef.current.onerror = null;
      }
    };
  }, [content, theme, isDarkMode]); // Re-run when theme changes
  
  return (
    <div className={styles.htmlContainer} style={{ width: '100%', overflow: 'hidden' }}>
      {isLoading && (
        <div className={styles.loadingIndicator || 'loading-indicator'}>
          <span>Loading HTML preview...</span>
        </div>
      )}
      
      {error && (
        <div className={styles.errorMessage || 'error-message'}>
          <p>{error}</p>
        </div>
      )}
      
      <iframe 
        ref={iframeRef}
        srcDoc={sanitizedContent}
        sandbox="allow-same-origin allow-scripts allow-forms"
        className={styles.htmlIframe}
        title="HTML Preview"
        style={{ 
          width: '100%',
          height: 'calc(100vh - 100px)',
          border: 'none',
          backgroundColor: isDarkMode ? '#1e1e1e' : '#fff',
          overflow: 'hidden'
        }}
      />
    </div>
  );
};

export default HTMLPreview;