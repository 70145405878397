import { useCallback } from "react";
import { useEffectOnce } from "react-use";

import { AuthContainer } from "../components/AuthContainer";
import { LoadingPage } from "components";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import { verifyEmail, getCreditLimits, IUser, IVerifyEmail } from "redux/actions";
import { RoutePaths } from "pages/routePaths";
import { useSelector } from "redux/hooks";
import MixpanelAnalytics from "utils/mixpanel";

export const VerifyEmailPage = () => {
  const { params, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const {userDetail} = useSelector((state)=> state.authReducer);
  const lang = userDetail?.user?.language? userDetail?.user?.language: "en";

  const onVerifyEmail = useCallback(() => {
    const { token } = params;
    const shareToken = localStorage.getItem('shareChat-Token');
    const TargetURL = shareToken ? `/share-chat/${shareToken}` : `/${RoutePaths.Chat}`;
    verifyEmail({ token })
    .then((response) => {
      const res = response as IVerifyEmail;
      if (res.data && res.data.user) {
      const user = res.data.user as IUser;

      if (user) {
        try {
          const userProperties = {
            name: user.name,
            email: user.email,
            createdAt: user.created_at || new Date().toISOString()
          };
          
          MixpanelAnalytics.identify(user.email, userProperties);

           // FullStory identification
           if (window.FullStory) {
            window.FullStory.identify(user.email, {
              displayName: user.name,
              email: user.email,
              uid: user.id,
              pricing_plan: user.activeSubscription ? user.activeSubscription.name : null,
            });
          }
          
        } catch (error) {
          console.error('Error in Mixpanel tracking:', error);
        }
      } 
    }
        push(TargetURL);
        getCreditLimits().catch((err) => {
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
      })
      .catch((err: any) => {
        push(`/${lang}/${RoutePaths.Login}`);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  }, [params, push, triggerNotification]);

  useEffectOnce(() => onVerifyEmail());

  return (
    <AuthContainer>
      <LoadingPage />
    </AuthContainer>
  );
};