import { store } from "redux/store";
import { TYPES } from "redux/types";
import { ICRWorkSpace, IWorkspace, UploadFile, WorkspaceCR, WorkspaceUploadSrc, IUploadedFile, UpdateWs, IChatContentUploadFile, IFilePreview, IThreadHistoryResponse, UpdateThreadTitle, selectedThread } from "../chatInterface";
import { api } from "redux/api";
import { setChatModel } from "../chatModels";

export const AddWorkSpace = (AddWorkSpace: IWorkspace) => {
  store.dispatch({ type: TYPES.ADD_WORKSPACE, payload: AddWorkSpace });
}

export const setShowCreateWorkspace = (createWorkSpace: boolean) => {
  store.dispatch({ type: TYPES.CREATE_WORKSPACE, payload: createWorkSpace });
};

export const addFile = (file: UploadFile) => {
  store.dispatch({ type: TYPES.ADD_FILE, payload: file });
};

export const updateFileStatus = (id: string, status: UploadFile["status"]) => {
  store.dispatch({ type: TYPES.UPDATE_FILE_STATUS, payload: { id, status } });
};

export const AddUploadedId = (id: string, uploaded_id: number, updated_at?: string, created_at?: string) => {
  store.dispatch({ type: TYPES.UPLOADED_FILE_ID, payload: { id, uploaded_id, updated_at, created_at } });
};

export const updateErrorMessage = (
  id: string,
  status: UploadFile["status"],
  errorMessage: string
) => {
  store.dispatch({
    type: TYPES.UPDATE_FILE_ERR_MESSAGE,
    payload: { id, status, errorMessage },
  });
};

export const updateFileS3Link = (
  id: string,
  status: UploadFile["status"],
  S3Link: string
) => {
  store.dispatch({
    type: TYPES.UPDATE_FILE_S3_LINK,
    payload: { id, status, S3Link },
  });
};

export const removeFile = (id: string) => {
  store.dispatch({ type: TYPES.REMOVE_FILE, payload: id });
};

export const updateFileProgress = (id: string, progress: number) => {
  store.dispatch({ type: TYPES.UPDATE_FILE_PROGRESS, payload: { id, progress } });
};

export const updateFileSize = (id: string, fileSize: number) => {
  store.dispatch({ type: TYPES.UPDATE_FILE_SIZE, payload: { id, fileSize } });
}

export const wsUpdateUPloadedFiles = (addFiles: IUploadedFile) => {
  store.dispatch({ type: TYPES.UPDATE_UPLOADED_FILE, payload: addFiles })
}

export const RemoveWSFiles = () => {
  store.dispatch({ type: TYPES.REMOVE_WS_FILES, payload: [] });
}

export const AddUploadedFile = (file: IUploadedFile) => {
  store.dispatch({ type: TYPES.ADD_FILE_UPLOADED, payload: file });
}

export const delRemoveFile = (id: string) => {
  store.dispatch({ type: TYPES.DEL_REMOVE_FILE, payload: id });
}

export const setEmptyFile = () => {
  store.dispatch({ type: TYPES.EMPTY_FILE, payload: null })
}

export const setInProgressWS = (name: string, emoji: string, description: string, instructions: string) => {
  store.dispatch({ type: TYPES.WS_PROGESSVALUES, payload: { name, emoji, description, instructions } });
}

export const setCurrentConversation = (id: number) => {
  store.dispatch({ type: TYPES.CURRENT_CONVERSATION, payload: { id } });
}

export const setUpdatedName = (name: string, description: string, instructions: string, id: number) => {
  store.dispatch({ type: TYPES.WS_NAMEUPDATED, payload: { id, name, description, instructions } });
}

export const setWsUploadedFile = () => {
  store.dispatch({ type: TYPES.EMPTY_WS_UPLOADED_FILES, payload: [] });
}

export const setDelUploadedFile = (id: number) => {
  store.dispatch({ type: TYPES.WS_UPLOADED_FILES_REMOVE, payload: { id } });
}

export const AddChatContentFile = (AddWorkSpace: IChatContentUploadFile) => {
  store.dispatch({ type: TYPES.CHAT_CONTENT_UPLOADING_FILES, payload: AddWorkSpace });
}

export const AddNewChatContent = (AddWorkSpace: IChatContentUploadFile) => {
  store.dispatch({ type: TYPES.CHAT_CONTENT_NEW_UPLOADING_FILES, payload: AddWorkSpace });
}

export const UpdateFileContent = (file: File, status: UploadFile["status"], S3Link?: string) => {
  store.dispatch({ type: TYPES.CHAT_CONTENT_UPDATE_FILE_STATUS, payload: { file, status, S3Link } })
}

export const updateNewChatContent = (file: File, status: UploadFile["status"], S3Link?: string) => {
  store.dispatch({ type: TYPES.CHAT_CONTENT_UPDATE_NEW_FILE_STATUS, payload: { file, status, S3Link } })
}

export const setFilePreview = (AddWorkSpace: IFilePreview) => {
  store.dispatch({ type: TYPES.FILE_PREVIEW_UPLOADING_FILES, payload: AddWorkSpace });
};

export const DelPreviewFile = (file: File) => {
  store.dispatch({ type: TYPES.DEL_PREVIEW_UPLOADING_FILES, payload: { file } })
}

export const setEmptyPreview = () => {
  store.dispatch({ type: TYPES.EMPTY_PREVIEW_UPLOADING_FILES, payload: [] })
}

export const delChatContent = (id: string) => {
  store.dispatch({ type: TYPES.DEL_CHAT_UPLOAD_FILE, payload: id })
}

export const EmptyUploadedFiles = () => {
  store.dispatch({ type: TYPES.DEL_CHAT_UPLOAD_FILES, payload: [] });
}

export const setEmptyThreadsHistory = () => {
  store.dispatch({ type: TYPES.EMPTY_THREADS_HISTORY, payload: [] });
}

export const setMsgGeneration = (msgGeneration: boolean) => {
  store.dispatch({type: TYPES.MESSAGE_GENERATION, payload: msgGeneration});
}

export const setIsGenRes = (isGener: boolean) => {
  store.dispatch({type: TYPES.IS_GENERATE_RES, payload: isGener});
}

export const setControllerState = (controller: AbortController | null) => {
  store.dispatch({ type: TYPES.SET_CONTROLLER, payload: controller });
};

export const clearControllerState = () => {
  store.dispatch({ type: TYPES.CLEAR_CONTROLLER });
};

export const SaveSelectedThread = (selectThread: selectedThread) => {
  store.dispatch({ type: TYPES.THREAD_CHAT_HISTORY , payload: selectThread})
}

export const setGetThreadHistory =(history: boolean) => {
  store.dispatch({type: TYPES.GET_THREAD_HISTORY, payload: history})
}

export const getThreadTopicInfo = (load: boolean) => {
  store.dispatch({type: TYPES.GET_THREAD_TOPIC_INFO, payload: load});
}

export const NewChatThreadWS = (id: number) => {
  store.dispatch({type: TYPES.NEW_CHAT_THREAD_WS, payload: {id} });
}

export const EmptyThreadChat = (newThread: IWorkspace) => {
  store.dispatch({type: TYPES.EMPTY_NEW_CHAT_THREAD, payload: newThread});
} 

export const setIsModalOpen = (open: boolean) => {
  store.dispatch({type: TYPES.SET_NEW_CHAT_MODAL, payload: open})
}

export const EmptyContentFile = () => {
  store.dispatch({type: TYPES.DELETE_CHAT_CONTENT_BY_FILE})
}

export const EmptyCurrentChat = (newThread: IWorkspace) => {
  store.dispatch({type: TYPES.EMPTY_CURRENT_CHAT_THREAD, payload: newThread});
} 

export const setUploadURLModel = (model: boolean) => {
  store.dispatch({type: TYPES.OPEN_UPLOAD_URL_MODAL, payload: model});
}

export const WorkspaceCreation = (data: WorkspaceCR) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/workspace", data)
      .then((res) => {
        const response = res.data as ICRWorkSpace;
        AddWorkSpace(response.data)
        resolve(response.message);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const UploadSource = (data: WorkspaceUploadSrc, id?: string, options?: { signal?: AbortSignal }) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/workspace-sources", data, { signal: options?.signal })
      .then((res) => {
        resolve(res.data);
        if(id) {
          store.dispatch({
            type: TYPES.UPDATE_FILE_NAME,
            payload: { id, displayName: res.data.data.file.displayName, fileSize: res.data.data.file.size },
          })
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const ShowUploadSource = (workSpaceId: number) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/workspace-sources?workspace_id=${workSpaceId}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.WS_UPLOADED_FILES,
          payload: res.data.data
        });
   // Transform IUploadedFile[] → UploadFile[]
const workspaceUploadFiles: UploadFile[] = res.data.data.map((uploadedFile: IUploadedFile) => ({
  id: uploadedFile.id.toString(),
  file: new File([], uploadedFile.file.displayName, { type: uploadedFile.file.mime_type }),
  status: "uploaded",
  fileType: "document",
  S3Link: uploadedFile.file.path,
  errorMessage: "",
  progress: 100,
  fileSize: Number(uploadedFile.file.size) || 0,
  uploaded_id: uploadedFile.id,
  updated_at: uploadedFile.updated_at,
  created_at: uploadedFile.created_at,
  customName: uploadedFile.file.displayName,
}));
        store.dispatch({
          type: TYPES.WORKSPACE_UPLOADED_FILES,
          payload: workspaceUploadFiles,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const UpdateSource = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/workspace-sources/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.WS_UPLOADED_FILES,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const RefetchFile = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/workspace-sources/refetch/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export const DeleteUploadedFile = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/workspace-sources/${id}`)
      .then((res) => {
        resolve(res.data);
        // store.dispatch({
        //   type: TYPES.WS_UPLOADED_FILES_REMOVE,
        //   payload: {id}
        // });
        setDelUploadedFile(id);
        removeFile(id.toString());
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const ShowWorkspace = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/workspace")
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.WS_CREATED,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const UpdateWorkspace = (id: number, data: UpdateWs) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/workspace/${id}`, data)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.WS_Updated,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const ShowSingleThread = (threadId: number,workspace_id: number, page: number, perPage: number, options?: { signal?: AbortSignal }) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/workspace-threads/${threadId}`, {
        params: {
          workspace_id,
          page,
          perPage,
        },
        signal: options?.signal 
      })
      .then((res: any) => {
        resolve(res);
        const response: IThreadHistoryResponse = res.data;
        const { thread, messages } = response;

      const SaveThread = {
          ws_name: thread.workspace.name,
          emoji: "",
          thread_name: thread.topic,
          thread_id: thread.id,
          ws_id: thread.workspace.id, 
        }

        store.dispatch({
          type: TYPES.THREAD_CHAT_HISTORY,
          payload: SaveThread,
        });

        let messagesList = messages;

        messagesList.data = messagesList.data.map((message: any) => {
          return {
            ...message,
            chatType: thread.chat.chat_type,
          };
        });

        const updatedResponse = { thread, messages: messagesList.data };

        resolve(updatedResponse);
        store.dispatch({
          type: TYPES.GET_CHAT_HISTORY,
          payload: messagesList.data,
        });

        if (!page || page=== 1) {
          store.dispatch({
            type: TYPES.MESSAGES_PAGINATION,
            payload: {
              current_page: messagesList.current_page,
              last_page: messagesList.last_page,
            },
          });
        } else {
          store.dispatch({
            type: TYPES.UPDATE_MESSAGES_PAGINATION,
            payload: {
              current_page: messagesList.current_page,
            },
          });
        }

        setTimeout(() => {
          if (thread?.chat.model) {
            let Model = thread.chat.model;
            if (Model && typeof Model.type === "string") {
              Model.type = JSON.parse(Model.type);
            }
            if (Model && typeof Model.attributes === "string") {
              Model.attributes = JSON.parse(Model.attributes);
            }
            setChatModel(Model);
          }
        }, 300);

        resolve(updatedResponse);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const ShowMultipleThread = (page: number, perPage: number, workspace_id: string, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/workspace-threads", {
        params: {
          page,
          perPage,
          workspace_id,
        },
        signal,
      })
      .then((res) => {
        resolve(res.data.data);
        store.dispatch({
          type: TYPES.SAVE_THREADS_HISTORY,
          payload: res.data
        });
      })
      .catch((err) => {
        if (err.name === "AbortError") return;
        reject(err);
      });
  });
};


export const DeleteThread = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/workspace-threads/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_THREADS,
          payload: { id },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const UpdateThTitle = (id: number, data: UpdateThreadTitle) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/workspace-threads/${id}`, data)
      .then((res) => {
        resolve(res.data.message);
        store.dispatch({
          type: TYPES.TH_TITLE_Updated,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const GetNewChatThread = (threadId: number,workspace_id: number, page: number, perPage: number, options?: { signal?: AbortSignal }) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/workspace-threads/${threadId}`, {
        params: {
          workspace_id,
          page,
          perPage,
        },
        signal: options?.signal 
      })
      .then((res: any) => {
        resolve(res);
        const response: IThreadHistoryResponse = res.data;
        const { thread } = response;
        getThreadTopicInfo(false);
      const SaveThread = {
          ws_name: thread.workspace.name,
          emoji: "",
          thread_name: thread.topic,
          thread_id: thread.id,
          ws_id: thread.workspace.id, 
        }

        store.dispatch({
          type: TYPES.THREAD_CHAT_HISTORY,
          payload: SaveThread,
        });

        resolve(thread);
      })
      .catch((err) => {
        getThreadTopicInfo(false)
        reject(err);
      });
  });
}