import styles from "./ThreadsConversation.module.scss";
import { ChatInfoIcon } from "pages/Workspaces/components/icons/TreadsIcons";
import { useSelector } from "redux/hooks";
import { Loader } from "components/Loader";

interface IProp {
    setShowInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MiniTConversationHeader = ({ setShowInfo }: IProp) => {
    const { selectedThread, loadTopicInfo } = useSelector((state) => state.workSpaceReducer);
    return (
        <>
            <div className="font-inter flex justify-between mt-[14px] pr-[13px] pl-[14px] ">
                <div className="text-[#171717] dark:text-[#fff] text-[20px] font-normal leading-normal flex-1 mr-[25%] truncate">  {loadTopicInfo ? <div className="flex items-center mt-1"> <Loader /></div> : selectedThread.thread_name}</div>
                <div
                    className={styles.addSourcesContainer}
                    onClick={() => setShowInfo(true)}
                >
                    <span className="flex-shrink-0">
                        <ChatInfoIcon/>
                    </span>
                </div>
            </div>
        </>
    )
}