import React, { useState, useRef, useEffect } from "react";
import { useWindowSize } from "hooks/useWindowSize";
interface AnimatedCircleProps {
  name: React.ReactNode;
  children?: React.ReactNode;
  botSettings?: boolean;
}

const AnimatedCircle: React.FC<AnimatedCircleProps> = ({ name, children, botSettings }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [nameWidth, setNameWidth] = useState<number>(0);
  const nameRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  useEffect(() => {
    if (nameRef.current) {
      setNameWidth(nameRef.current.offsetWidth);
    }
  }, [name]);

  const shouldApplyBorder = (width <= 576 && botSettings) || (botSettings && !isHovered);
  return (
    <div
      className={`relative cursor-pointer h-9 overflow-hidden bg-[#EEF2FF] dark:bg-[#485474] rounded-full transition-width duration-500 ease-out`}
      style={{
        width: `${isHovered && width >= 576 ? nameWidth + 48 : 32}px`,
        border: shouldApplyBorder ? "1px solid #4338CA" : "none"
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="absolute top-[2px] left-[2px] w-8 h-8 flex flex-row items-center justify-center rounded-full">
        {children}
      </div>
      {
        width >= 576 && (
          <>
            <div
              ref={nameRef}
              className="absolute whitespace-nowrap invisible"
              aria-hidden="true"
            >
              {name}
            </div>
            <div
              className={`absolute top-1/2 left-10 transform -translate-y-1/2 transition-opacity whitespace-nowrap duration-500 ease-out ${isHovered ? "opacity-100" : "opacity-0"} ${botSettings ? "text-[#4338CA]" : "text-light dark:text-dark"}`}
            >
              {name}
            </div>
          </>
        )
      }
    </div >
  );
};

export default AnimatedCircle;
