import Select, { Props } from "react-select";
import { EThemeType } from "redux/reducers";
import styled from "styled-components";

export interface ISelectProps {
  $withBorder?: boolean;
  $error?: boolean;
  $background?: string;
  height?: number;
  variant?: EThemeType;
  account?: boolean;
  small?: boolean;
  inviteModel?: boolean;
  signup?: boolean; 
  workspace?:boolean;
}

const setBackgroundColor = (props: ISelectProps) => {
  if (props.$background) {
    return props.$background;
  }
  else if (props.variant === "dark") {
    return "#141517";
  } else if (props.variant === "light") {
    if (props.small) {
      return "rgba(255,255,255,1)";
    }
    return "rgba(42, 40, 49, 0.04)";
  } else {
    return "rgba(255, 255, 255, 0.03)";
  }
};

const setBorder = (theme: any, props: ISelectProps) => {
  const { $withBorder, $error, account,signup } = props;
  if ($error) {
    return `1px solid ${theme.dangerColor}`
  } else if ($withBorder) {
    return `1px solid ${theme.greyColor30}`
  } else if (account) {
    return 'none'
  } 
  else if(signup){
    return 'none';
  }
  else {
    return "1px solid rgba(255, 255, 255, 0.1)";
  }
};

export const CustomSelect = styled(Select)<ISelectProps & Props>`
  & > .react-select__control {
    height: ${({ height }) => (height ? height : 36)}px;
    box-shadow: ${({ small, account, variant }) =>
      small
        ? "-5px 67px 19px 0px rgba(92, 93, 122, 0.00), -3px 43px 17px 0px rgba(92, 93, 122, 0.01), -2px 24px 14px 0px rgba(92, 93, 122, 0.05), -1px 11px 11px 0px rgba(92, 93, 122, 0.09), 0px 3px 6px 0px rgba(92, 93, 122, 0.10)"
        : account && variant === "light"
          ? "0px 17px 5px 0px rgba(209, 209, 209, 0.00), 0px 11px 4px 0px rgba(209, 209, 209, 0.01), 0px 6px 4px 0px rgba(209, 209, 209, 0.05), 0px 3px 3px 0px rgba(209, 209, 209, 0.09), 0px 1px 2px 0px rgba(209, 209, 209, 0.10)"
          : "none"};
    border: ${({ theme, ...props }) => setBorder(theme, props)};
    background-color: ${(props) => {
      return setBackgroundColor(props);
    }};

    border-radius: ${({ workspace }) => (workspace ? "4px" : "10px")};
    font-size: 14px;
    line-height: 16px;
    min-height: 36px;

    padding: ${({ small }) => (small ? 0 : 0)}px;

    &:hover {
      border-color: ${({ theme, $error }) =>
        $error ? theme.dangerColor : "rgba(255, 255, 255, 0.1)"};
      background-color: ${(props) => setBackgroundColor(props)};
    }

    &.react-select__control--menu-is-open {
      border: 1px solid #5b71f5;
      box-shadow: none;
      background-color: ${(props) => setBackgroundColor(props)};
    }

    & .react-select__multi-value {
      background-color: rgba(255, 255, 255, 0);
    }

    & .react-select__value-container {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }

    // Media queries for different screen sizes

    ${(props) =>
      (props.variant === "light") === true &&
      !props.signup &&
      props.account === true &&
      `
     @media (min-width: 577px) {
      background-color: #fff;
    }
      `}
  }

  & .react-select__single-value {
    div {
      color: ${({ account, theme, variant, signup, workspace }) =>
        workspace && variant === "dark"
          ? "#fff"
          : signup && variant === "light"
            ? "#A09FA2"
            : signup && variant === "dark"
              ? "#A09FA2"
              : account && variant === "light"
                ? theme.textColorLight
                : account
                  ? "#fff"
                  : ""} !important;
    }
  }

  ${({ workspace, variant }) =>
    workspace &&
    `
    & .react-select__placeholder {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: ${variant === "light" ? "#525252" : "rgba(255, 255, 255, 0.7)"} !important;
    }
  `}

  @media (max-width: 576px) {
    ${({ workspace }) =>
      workspace &&
      `
      & > .react-select__control {
        font-size: 12px;
      }
      
      & .react-select__single-value,
      & .react-select__placeholder {
        font-size: 12px;
      }
    `}
  }
`;