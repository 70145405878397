import classNames from "classnames";
import styles from "./ChatNav.module.scss";
import { Link } from "react-router-dom";
import CutsomButton from "components/Button";

import { RoutePaths } from "pages/routePaths";
import styled from "styled-components";
import { useWindowSize } from "hooks/useWindowSize";
import { Credits } from "./components/Credits";

import { useSelector } from "redux/hooks";

import useRouter from "hooks/useRouter";
import { FormattedMessage } from "react-intl";
import { SetStateAction, Dispatch, useState } from "react";
import { Setting } from "./icons/setting";
import { clearhistoryMessages, startNewChat, IChat, ICRWorkSpace, IWorkspace, setUpdatedName, UpdateThTitle, UpdateWorkspace, SaveSelectedThread } from "redux/actions";
import { ChatModel } from "./components/ChatModel";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { EditIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import { RemoveIcon, TickIcon } from "views/layout/Sidebar/component/ChatSidebar/components/icons";
import { Spinner } from "components";
import { useAppNotification } from "hooks/services/AppNotification";
import { Loader } from "components/Loader";
import { SaveThreadInfo } from "utils/constants";
interface IProps {
  isMainScreenOpen?: boolean;
  toggleChatModelsModel?: () => void;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}

const Button = styled(CutsomButton)`
  display: none;
  padding : 5px 40px ;
  @media screen and (min-width: 769px) {
    display: inline-flex;
  }
`;

const WorkspaceName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: max-content;
  width: fit-content;
  flex-shrink: 1;
`;

const WorkspaceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 37%;
  min-width: 0;

  @media (max-width: 860px) {
   max-width: 28%;
  }
`;

export const ChatNav = ({
  isMainScreenOpen,
  toggleChatModelsModel,
  setChatSetting,
  chatItem,
}: IProps) => {

  const { isOpen } = useToggleSidebar();
  const { push, includeRoute, pathname } = useRouter();
  const { width } = useWindowSize();
  const { theme, userDetail } = useSelector((state) => state.authReducer);
  const { newMessages } = useSelector((state) => state.chatReducer);
  const isShare = window.location.pathname.includes("share-chat");
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";
  const Id = pathname.split("/")[2];
  const { triggerNotification } = useAppNotification();
  const { currentConversation, selectedThread, loadTopicInfo } = useSelector((state) => state.workSpaceReducer);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const pathParts = pathname.split("/").filter(Boolean);
  // Parse path to determine which component to render
  const isWorkspacePath = pathParts[0] === "workspaces";
  const hasThreadId = pathParts.includes("thread") && pathParts.length > pathParts.indexOf("thread") + 1;

  // Decide which content to render
  const renderThreadsContent = isWorkspacePath && hasThreadId;

  const [workspaceName, setWorkspaceName] = useState<string>(renderThreadsContent ? selectedThread.thread_name : currentConversation?.name ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const workSpace = pathname.includes("/thread");

  const handleEditClick = () => {
    if(renderThreadsContent || newMessages?.length > 0){
      setWorkspaceName(selectedThread.thread_name);
      setIsEditing(true);
    }
    else if (currentConversation) {
      setWorkspaceName(currentConversation.name);
      setIsEditing(true);
    }
  };

  const handleInputChange = (event: any) => {
    setWorkspaceName(event.target.value);
  };

  const handleEdit = () => {
    setIsLoading(true);  // Set loading state to true
    if(renderThreadsContent || newMessages?.length > 0) {
      UpdateThTitle(selectedThread.thread_id, {topic : workspaceName})
      .then((response: any) => {
        setIsLoading(false);
        setIsEditing(false);
        triggerNotification({ message: response.message, type: "info" });
      })
      .catch((err: any) => {
        setIsLoading(false);
        const errorMessage = err?.data?.message || "An unexpected error occurred.";
        triggerNotification({ message: errorMessage, type: "error" });
      })

    }
    else if (currentConversation) {
      UpdateWorkspace(currentConversation.id, { name: workspaceName })  // API call with the updated name
        .then((response) => {
          const res = response as ICRWorkSpace;
          const updatedData = res.data as IWorkspace
          setUpdatedName(updatedData.name, updatedData.description, updatedData.instructions, updatedData.id);
          setIsLoading(false);
          setIsEditing(false);
          triggerNotification({ message: res.message, type: "info" });
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err); // Log the error for debugging
          const errorMessage = err?.data?.message || "An unexpected error occurred.";
          triggerNotification({ message: errorMessage, type: "error" });
        })
    }

  }

  const handleRedirectBack = () => {
    if(renderThreadsContent || (newMessages.length > 0 && workSpace)) {
      SaveSelectedThread(SaveThreadInfo);
      startNewChat();
      clearhistoryMessages();
      push(`/${RoutePaths.Workspaces}/${renderThreadsContent? selectedThread.ws_id : Id}`)
    }
    else push(`/${RoutePaths.Workspaces}`)
    setIsEditing(false);
  }
  
  return (
    <>
      {isShare && (
        <div className={classNames(styles.LogoContainer, "flex items-center")}>
          <Link
            to="/"
            className={styles.headerLogo}
            data-testid="share-header-logo"
          >
            <img src={`/logo-${theme}.svg`} alt="deftGPT logo" />
          </Link>
          {width > 1020 && (
            <div
              className={classNames(styles.multipleaiModel, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              - Chat with multiple AI models
            </div>
          )}
        </div>
      )}

      {(includeRoute(RoutePaths.Workspaces) || (workSpace)) && (
        <div className={classNames(styles.WorkSpace, styles.LogoContainer, "flex items-center gap-2", {
          [styles.sidebarClose]: !isOpen,
        })}>
          <span className={classNames(styles.workSpaceName, {
           "hover:underline underline-offset-[3px]": Id,
          // "hover:border-b-2 border-current pb-[2px]": Id, 
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
            [styles.cursor]: Id,
          })} onClick={handleRedirectBack}
            data-testid="sidebar-chat-newchat-workspace">{renderThreadsContent?
              selectedThread.ws_name : (workSpace && newMessages.length > 0) && currentConversation?.name
              ? currentConversation.name
              : <FormattedMessage id="sidebar.chat.newChat.workspace" />} </span>
          {(Id && width > 576) && (
            <>
              {/* <span className="text-[#171717] dark:text-[#fff]">/</span> */}
              {isEditing ? (
                <>
                  <span className="text-[#171717] dark:text-[#fff]">/</span>
                  <span
                    className={classNames(
                      styles.inputWithIcons,
                      "flex items-center flex-1 gap-4"
                    )}
                  >
                    <input
                      className={classNames(
                        "flex-grow min-w-0",
                        styles.inputField,
                        {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                        }
                      )}
                      type="text"
                      value={workspaceName}
                      onChange={handleInputChange}
                      autoFocus
                    />
                    <span className={`flex items-center ${width <860? "gap-2" : "gap-4"}`}>
                      <div
                        data-testid="confirm-edit-btn"
                        className="cursor-pointer flex flex-row items-center justify-center"
                        onClick={handleEdit}
                      >
                        {isLoading ? (
                          <Spinner extraSmall />
                        ) : (
                          <TickIcon EWorkSpace={true} width={20} height={20} />
                        )}
                      </div>
                      <div
                        data-testid="cancel-edit-btn"
                        className="cursor-pointer flex flex-row items-center justify-center"
                        onClick={() => setIsEditing(false)}
                      >
                        <RemoveIcon EWorkSpace={true} width={20} height={20} />
                      </div>
                    </span>
                  </span>
                </>
              ) : (
                <>
                  <WorkspaceWrapper>
                    {(renderThreadsContent || (newMessages?.length > 0))? (
                      <>
                        {loadTopicInfo ? (
                          <div className="flex items-center"> <Loader /> </div>
                        ) : (
                          <>
                            {selectedThread.thread_name !== "" && <span className="text-[#171717] dark:text-[#fff]">/</span>}
                            <WorkspaceName className={classNames(styles.WorkSpace, {
                              [styles.light]: theme === "light",
                              [styles.dark]: theme === "dark",
                            })}>{selectedThread.thread_name}</WorkspaceName>
                            {selectedThread.thread_name !== "" &&
                              <span className="flex items-center cursor-pointer" onClick={handleEditClick}>
                                <EditIcon EditWorkSpace={true} />
                              </span>
                            }
                          </>
                        )}</>) : !currentConversation?.name ? ( // Show loader if name is not available
                          <div className="flex item-center"><Loader /></div>
                        ) : (
                      <>
                        {currentConversation?.name && <span className="text-[#171717] dark:text-[#fff]">/</span>}
                        <WorkspaceName className={classNames(styles.WorkSpace, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                        })}>{currentConversation?.name}</WorkspaceName>
                       {currentConversation?.name && <span className="flex items-center cursor-pointer" onClick={handleEditClick}>
                          <EditIcon EditWorkSpace={true} />
                        </span>
                        }
                      </>
                    )}
                  </WorkspaceWrapper>
                </>
              )}
            </>
          )}
        </div>
      )}

      {(userDetail && userDetail.token && !(includeRoute(RoutePaths.Workspaces) || workSpace)) &&
        <ChatModel
          toggleChatModelsModel={toggleChatModelsModel}
          chatItem={chatItem}
          isMainScreenOpen={isMainScreenOpen} />
      }

      {isShare ? (
        <div className={styles.shareChatbtn}>
          <Button
            data-testid="share-chat-try-btn"
            className={styles.shareChatTryBtn}
            onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
          >
            <FormattedMessage id="share.chat.header" />
          </Button>
        </div>
      ) : (
        <>
          <div className={styles.creditsContainer}>
            {/* {!(includeRoute(RoutePaths.Workspaces) || (workSpace)) && ( */}
              <div
                className={styles.settingIcon}
                onClick={() => setChatSetting!(true)}
                data-testid="chat-setting-icon"
              >
                <Setting />
              </div>
            {/* // )} */}
            <div>
              <Credits />
            </div>
          </div>
        </>
      )}
    </>
  );
};