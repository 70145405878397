import { useIntl } from "react-intl";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";
import { getFileSize, validateFileType } from "utils/functions";
import validUrl from "valid-url";
import { ChangeEvent, useState, Dispatch, SetStateAction } from "react";
import {
  ChatType,
  IChatModel,
  setChatModel,
} from "redux/actions";
import { UrlUploadUI } from "pages/Workspaces/components/RichContext/Component/UrlUploadUI";

import {  getImageSize, getVideoDuration } from "utils/functions";
import { documentMaxCountReached, videoMaxCountReached, audioMaxCountReached } from "utils/chat";
import { audioURL, imgURL, videoURL, DocAllowedTypes, AllowedFileTypes } from "utils/constants";

interface IProp {
    setURL: Dispatch<SetStateAction<string>> | undefined;
    message: string | undefined;
    onSendMessage?: (
        question: string,
        chatModels?: IChatModel,
        regenerate?: boolean,
        images?: string[],
        filePath?: string[],
        ws_id?: string
    ) => void;
    onClose:() => void;
}

export const ChatContentUploadURL = ({ setURL, message,  onSendMessage, onClose} : IProp) => {
    const AllowedFileSize = 100;
    const FreePlanAllowedFileSize = 10;
    const StandardPlanAllowedFileSize = 50;

    const { isModalOpen, newChatThread } = useSelector((state) => state.workSpaceReducer);
    const chatModel = useSelector((state) => state.authReducer.gptModel);
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const { gptModel, userDetail } = useSelector((state) => state.authReducer);
    const { newMessages, messages } = useSelector((state) => state.chatReducer);
    const { formatMessage } = useIntl();
    const currentPlan = useSelector(
        (state) => state.planSubscriptionReducer.activePlan
    );

    const planName = userDetail?.user.activeSubscription.name.toLowerCase();

    const getConditionalAllowedFileTypes = () => {
      return gptModel?.name.includes("Claude") ? AllowedFileTypes : [...AllowedFileTypes, "jpg"];
  };
    const { push, pathname } = useRouter();
    const [messageId, setMessageId] = useState<string>("");
    const [errorModal, setErrorModal] = useState<{
      message: string;
      show: boolean;
    }>({
      message: "",
      show: false,
    });
    const [changeModel, setChangeModel] = useState<boolean>(false);
    const newChat = pathname.includes("thread");
    const workspaceId = pathname.split("/")[2];
  
    const setValidationError = (messageKey: string) => {
      setErrorModal({
        message: formatMessage({ id: messageKey }),
        show: true,
      });
    };

    const onSubmit = async (inputUrl: string, resetForm: () => void) => {

      const urlExtension = inputUrl?.split('.').pop()?.toLowerCase() || '';

      if (DocAllowedTypes.includes(urlExtension)) {
          if (documentMaxCountReached(currentPlan, userDetail)) {
              setMessageId!("documentChat.plan.max_count");
              resetForm();
              return;
          }
      }
      if (videoURL.includes(urlExtension)) {
          if (videoMaxCountReached(currentPlan, userDetail)) {
              setMessageId("videoChat.plan.max_count");
              resetForm();
              return;
          }
      }

      if (audioURL.includes(urlExtension)) {
          if (audioMaxCountReached(currentPlan, userDetail)) {
              setMessageId!("audioChat.plan.max_count");
              resetForm();
              return;
          }
      }
      if (((newMessages.length > 0 &&
          newMessages[0]?.chatType === "audio"
          // && chatHistory
        ) || (messages[0]?.chatType === 'audio' 
          // && chatId

        )) && (DocAllowedTypes.includes(urlExtension) || imgURL.includes(urlExtension) || videoURL.includes(urlExtension))) {
          setValidationError("upload.file.validation.audio");
          resetForm();
          return false;
      }
      else if (((newMessages.length > 0 &&
          newMessages[0]?.chatType === "video"
          // && chatHistory
        ) || (messages[0]?.chatType === 'video' 
            // && chatId

          )) && (DocAllowedTypes.includes(urlExtension) || imgURL.includes(urlExtension) || audioURL.includes(urlExtension))) {
          setValidationError("upload.file.validation.video");
          resetForm();
          return false;
      } else if (((newMessages.length > 0 &&
          newMessages[0]?.chatType === "image_chat"
          // && chatHistory
        ) || (messages[0]?.chatType === 'image_chat' 
          // && chatId
        )) && (DocAllowedTypes.includes(urlExtension) || videoURL.includes(urlExtension) || audioURL.includes(urlExtension))) {
          setValidationError("upload.file.validation.docURL");
          resetForm();
          return false;
      }
      else if (((newMessages.length > 0 &&
          newMessages[0]?.chatType === "document"
          // && chatHistory
          ) || (messages[0]?.chatType === 'document' 
            // && chatId
          )) && (imgURL.includes(urlExtension) || videoURL.includes(urlExtension) || audioURL.includes(urlExtension))) {
          setValidationError("upload.file.validation.imgURL");
          resetForm();
          return false;
      }
      else if (await validateURL(inputUrl)) {
          const urlExtension = inputUrl?.split('.').pop()?.toLowerCase() || '';

          if (imgURL.includes(urlExtension)) {
            if(isModalOpen) {
                setTimeout(() => {  
                  onSendMessage?.(message ? message : "", chatModel, false, inputUrl ? [inputUrl] : [], [], newChatThread.id !== 0 ? newChatThread.id.toString() : workspaceId);
                  onClose();
                    setTimeout(() => {
                     push(`/${RoutePaths.Workspaces}/${newChatThread.id!==0? newChatThread.id : workspaceId}/thread`);
                   },6)
                   },5);
            }
            else {
              onSendMessage?.(message ? message : "", chatModel, false, inputUrl ? [inputUrl] : [], [])
              onClose();
              }
              if(!newChat) push(`/${RoutePaths.Workspaces}/${workspaceId}/thread`);
          }
          else {
            if(isModalOpen) {
                setTimeout(() => {
                    onSendMessage?.(message ? message : "", chatModel, false, [], inputUrl ? [inputUrl] : [], newChatThread.id !== 0 ? newChatThread.id.toString() : workspaceId);
                    onClose();
                    setTimeout(() => {
                     push(`/${RoutePaths.Workspaces}/${newChatThread.id!==0? newChatThread.id : workspaceId}/thread`);
                   },6)
                },5);
            }
            else {
              onSendMessage?.(message ? message : "", chatModel, false, [], inputUrl ? [inputUrl] : []);
              onClose();
            }
              if(!newChat) push(`/${RoutePaths.Workspaces}/${workspaceId}/thread`);
          }
      }
  };

    const validateURL = async (inputUrl: string): Promise<boolean> => {
      if (validUrl.isHttpsUri(inputUrl ? inputUrl : '')) {
          const conditionalAllowedFileTypes = getConditionalAllowedFileTypes();
          const urlExtension = inputUrl?.split('.').pop()?.toLowerCase() || '';

          // New validation for multiple URLs or text after a comma
          const commaIndex = inputUrl?.indexOf(',') ?? -1; // Default to -1 if url is undefined
          if (commaIndex !== -1) {
              const textAfterComma = inputUrl?.slice(commaIndex + 1).trim();
              if (textAfterComma) {
                  setValidationError("upload.url.validation");
                  // handleStateUpdate();
                  return false;
              }
          }
          if ((validateFileType({ url: inputUrl, types: conditionalAllowedFileTypes }))) {

              if (imgURL.includes(urlExtension)) {
                  const imageSize = await getImageSize(inputUrl ? inputUrl : '');
                  const allowedImgSize = gptModel?.name.includes("GPT-4") ? 15 : 5;
                  const maxSizeInBytes = gptModel?.name.includes("GPT-4") ? 15 * 1024 * 1024 : 5 * 1024 * 1024; // 5 MB

                  if (imageSize > maxSizeInBytes) {
                      setErrorModal({
                          message: formatMessage(
                              { id: 'ImgChat.file.fileSizeImage' },
                              { size: allowedImgSize }
                          ),
                          show: true,
                      });
                      // handleStateUpdate();
                      return false;
                  }
                  else {
                      return true;
                  }
              }
              else {
                  const FileSize = await getFileSize(inputUrl ? inputUrl : '');

                  if (planName === 'free' && FileSize > FreePlanAllowedFileSize) {
                      setMessageId!(`documentChat.file.${planName}.plan.fileSize`);
                      // handleStateUpdate();
                      return false;
                  }
                  else if (planName === 'standard' && FileSize > StandardPlanAllowedFileSize) {
                      setMessageId!(`documentChat.file.${planName}.plan.fileSize`);
                      // handleStateUpdate();
                      return false;
                  }
                  else if (FileSize > AllowedFileSize) {
                      // setMessageId!("documentChat.file.fileSize");
                      setValidationError("documentChat.file.fileSize");
                      // handleStateUpdate();
                      return false;
                  }

                  if (videoURL.includes(urlExtension) || audioURL.includes(urlExtension)) {
                      try {
                          const videoDuration = await getVideoDuration(inputUrl ?? ""); // You'll need a function to get the duration
                          const maxDurationInSeconds = videoURL.includes(urlExtension) ?  30 * 60 :  60 * 60;

                          if (videoDuration > maxDurationInSeconds) {
                              setValidationError(videoURL.includes(urlExtension) ? 'validate.video.duration' : 'validate.audio.duration');
                              // handleStateUpdate();
                              return false;
                          } else {
                              return true;
                          }
                      } catch (error) {
                          console.error("Error fetching video duration", error);
                          // handleStateUpdate();
                          return true;
                      }
                  }
                  else {
                      return true;
                  }
              }
          } else {
              setValidationError("documentChat.url.fileFormat");
              // handleStateUpdate();
              return false;
          }
      } else {
          setValidationError("documentChat.url.validation");
          // handleStateUpdate();
          return false;
      }
  };

    const getFileURL = (event: ChangeEvent<HTMLInputElement>) => {

        setURL?.(event.target.value);
        if (!gptModel?.type?.includes(ChatType.document)) {
            setChangeModel(true);
            const model = localStorage.getItem("documentChatGptModel");
            let selectedModel = model ? JSON.parse(model) : chatModels[0];
            setChangeModel(true);
            if (model) {
              localStorage.setItem("GptModel", model);
            } else {
              localStorage.setItem(
                "documentChatGptModel",
                JSON.stringify(chatModels[0])
              );
              localStorage.setItem("GptModel", JSON.stringify(chatModels[0]));
            }
            setChangeModel?.(true);
            setChatModel(selectedModel);
          }

        const urlExtension = event.target.value?.split('.').pop()?.toLowerCase() || '';
        const modelTypeMapping = {
            [ChatType.video]: 'videoChatGptModel',
            [ChatType.audio]: 'audioChatGptModel',
            [ChatType.image_chat]: 'imageChatGptModel',
        };
    
        const supportedModels = {
            video: chatModels.filter(model => model.type.includes(ChatType.video)).map(model => model.name),
            audio: chatModels.filter(model => model.type.includes(ChatType.audio)).map(model => model.name),
            image_chat: chatModels.filter(model => model.type.includes(ChatType.image_chat)).map(model => model.name),
        };
    
        const urlMappings = {
            video: videoURL,
            audio: audioURL,
            image_chat: imgURL,
        };
    
        const handleModelChange = (modelKey: keyof typeof modelTypeMapping, defaultIndex: number) => {
            const storageKey = modelTypeMapping[modelKey];
            const storedModel = localStorage.getItem(storageKey);
            const parsedModel = storedModel ? JSON.parse(storedModel) : chatModels[defaultIndex];
    
            if (storedModel) {
                localStorage.setItem('GptModel', storedModel);
            } else {
                localStorage.setItem(storageKey, JSON.stringify(parsedModel));
                localStorage.setItem('GptModel', JSON.stringify(parsedModel));
            }
    
            setChatModel(parsedModel);
            setChangeModel?.(false);
        };
    
        Object.entries(urlMappings).forEach(([type, urlArray]) => {
            const typeKey = type as keyof typeof supportedModels;
    
            if (urlArray.includes(urlExtension)) {
                if (changeModel) {
                    handleModelChange(typeKey, typeKey === 'video' ? 0 : typeKey === 'audio' ? 0 : 1);
                }
    
                const isModelSupported = supportedModels[typeKey].includes(gptModel?.name ?? '');
                if (!isModelSupported) {
                    handleModelChange(typeKey, typeKey === 'video' ? 0 : typeKey === 'audio' ? 0 : 1);
                }
            }
        });
    }; 

  const onConfirm = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);

  const onCancel = () => setMessageId("");

  return (
   <UrlUploadUI
      onSubmit={onSubmit}
      errorModal={errorModal}
      setErrorModal={setErrorModal}
      messageId={messageId}
      onConfirm={onConfirm}
      onCancel={onCancel}
      getFileURL={getFileURL}
      chatContent={true}
    />
  );
};
