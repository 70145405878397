// FilePreviewModal/components/previewTypes/CodePreview.tsx
import React from "react";
import styles from "../../FilePreviewModal.module.scss";

interface CodePreviewProps {
  content: string;
  language: string;
}

const highlightCode = (content: string, language: string): string => {
  // For production use, consider a library like highlight.js or prism.js
  if (language === "json") {
    return content
      .replace(/"([^"]+)":/g, '<span class="json-key">"$1":</span>')
      .replace(/: "([^"]+)"/g, ': <span class="json-string">"$1"</span>');
  }
  return content;
};

const CodePreview: React.FC<CodePreviewProps> = ({ content, language }) => (
  <div className={styles.codeContainer}>
    <pre className={styles.codeContent}>
      {language === "json" ? (
        <code dangerouslySetInnerHTML={{ __html: highlightCode(content, language) }} />
      ) : (
        <code>{content}</code>
      )}
    </pre>
  </div>
);

export default CodePreview;