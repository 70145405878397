import { useRef } from "react";
import { IFile } from "redux/actions";
import {
  PDFIcon,
  TextIcon,
  PPTXIcon,
  PPTIcon,
  CSVIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  EMLIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
import { FILE_COLORS, renderAudioName, renderVideoName, textExtensions } from "utils/constants";

// Define the file icons mapping
const FILE_ICONS = {
  pdf: PDFIcon,
  "text/plain": TextIcon,
  presentation: PPTXIcon,
  "ms-powerpoint": PPTIcon,
  csv: CSVIcon,
  sheet: XLSXIcon,
  "ms-excel": XLSIcon,
  "application/x-subrip": SRTIcon,
  "message/rfc822": EMLIcon,
};

// Define the ThreadsFileDisplay component props interface
interface ThreadsFileDisplayProps {
  files: IFile | IFile[];
}

export const ThreadsFileDisplay = ({ files }: ThreadsFileDisplayProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Convert single file to array if needed
  const fileArray = Array.isArray(files) ? files : [files];

  // Helper to extract file extension from path
  const getFileExtension = (filePath: string | null) => {
    if (!filePath) return "";
    const fileName = filePath.split("/").pop() || "";
    return fileName.split(".").pop()?.toLowerCase() || "";
  };

  // Helper to get file name from path
//   const getFileName = (filePath: string | null) => {
//     if (!filePath) return "";
//     return filePath.split("/").pop() || "";
//   };

  // Determine the appropriate file icon based on file extension
  const getFileIcon = (filePath: string | null | undefined) => {
    if (!filePath) return <DocIcon />;
    
    const fileExt = getFileExtension(filePath);
    // const fileName = getFileName(filePath);
    const mimeType = getMimeTypeFromExtension(fileExt);
    
    for (const key in FILE_ICONS) {
      if (mimeType.includes(key)) {
        const IconComponent = FILE_ICONS[key as keyof typeof FILE_ICONS];
        return <IconComponent />;
      }
    }
    
    if (fileExt === "srt") {
      return <SRTIcon />;
    }

    if (textExtensions.includes(fileExt)) {
      return (
        <div >
          <span className="text-[8px] font-medium text-[#A09FA2]">
            {fileExt}
          </span>
        </div>
      );
    }
    
    return <DocIcon />;
  };

  // Get a simple MIME type based on file extension
  const getMimeTypeFromExtension = (ext: string) => {
    const mimeTypes: {[key: string]: string} = {
      pdf: "application/pdf",
      txt: "text/plain",
      pptx: "presentation",
      ppt: "ms-powerpoint",
      csv: "csv",
      xlsx: "sheet",
      xls: "ms-excel",
      srt: "application/x-subrip",
      eml: "message/rfc822",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      mp4: "video/mp4",
      mp3: "audio/mp3",
      wav: "audio/wav"
    };
    
    return mimeTypes[ext] || "application/octet-stream";
  };

  // Determine if a file is an image, video, or audio based on extension
//   const isImage = (filePath: string | null) => {
//     if (!filePath) return false;
//     const ext = getFileExtension(filePath);
//     return ["jpg", "jpeg", "png", "gif", "webp", "bmp"].includes(ext);
//   };
  
  const isVideo = (filePath: string | null) => {
    if (!filePath) return false;
    const ext = getFileExtension(filePath);
    return ["mp4", "mpeg", "mpg", "webm", "3gpp", "mov", "avi", "wmv"].includes(ext);
  };
  
  const isAudio = (filePath: string | null) => {
    if (!filePath) return false;
    const ext = getFileExtension(filePath);
    return ["mp3", "wav", "ogg", "flac", "m4a"].includes(ext);
  };

  // Get background color based on file type
  const getBackgroundColor = (filePath: string | null) => {
    if (!filePath) return "#4285F4";
    
    const fileExt = getFileExtension(filePath);
    const mimeType = getMimeTypeFromExtension(fileExt);
    
    for (const key in FILE_COLORS) {
      if (mimeType.includes(key)) {
        return FILE_COLORS[key as keyof typeof FILE_COLORS];
      }
    }
    
    if (fileExt === "srt") {
      return "#FFB300";
    }
    
    if (textExtensions.includes(fileExt)) {
      return "rgb(217, 217, 217)";
    }

    return "#4285F4";
  };

  if (!fileArray || fileArray.length === 0) return null;

  return (
    <div className="relative w-full overflow-y-hidden pb-[7px]">
      <div className="relative w-full flex items-center">

        <div
          ref={containerRef}
         
        >
          {fileArray.map((file, index) => {
            const bgColor = isVideo(file.path) || isAudio(file.path)
              ? "#D9D9D9"
              : getBackgroundColor(file.path);
              
            return (
              <div
                key={index}
                className="relative w-32 h-24 rounded-[10px] flex-shrink-0"
                style={{
                  backgroundColor: bgColor,
                }}
              >
                <div className="w-full h-full flex items-center justify-center rounded-lg">
                  {
                //   isImage(file.path) && previews[index] ? (
                //     <img
                //       className="rounded-lg"
                //       src={previews[index] as string}
                //       width="100%"
                //       height="100%"
                //       alt={file.name || "File preview"}
                //     />
                //   ) :
                   isVideo(file.path) ? (
                    renderVideoName(getMimeTypeFromExtension(getFileExtension(file.path)))
                  ) : isAudio(file.path) ? (
                    renderAudioName(getMimeTypeFromExtension(getFileExtension(file.path)), file.name)
                  ) : (
                    getFileIcon(file.path)
                  )}
                </div>

                <div className="absolute left-1/2 -translate-x-1/2 -bottom-2">
                  <div className="px-[8px] py-[4px] bg-white dark:bg-black rounded-full text-[10px] font-normal leading-normal text-[#525252] dark:text-[#fff]">
                    {getFileExtension(file.path).toUpperCase() || "UNKNOWN"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ThreadsFileDisplay;