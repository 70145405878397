import { useState, useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, FieldProps, Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Theme } from "theme";

import { AuthContainer } from "../components/AuthContainer";
import { PageItem } from "./components/PageItem";
import {
  LabeledInput,
  Separator,
  LoadingButton,
  DropDown,
  DropDownRow,
} from "components";
import { FieldItem, Form } from "../components/FormComponents";
import { GoogleAuthBtn } from "../components/GoogleAuthBtn";
import { RoutePaths } from "../../routePaths";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { signup } from "redux/actions";

import { languages } from "utils/languages";
import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";
import MixpanelAnalytics from "utils/mixpanel";

const Title = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: normal;
  padding: 0 47px 0px 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
   width: 100%;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : themeMode === "light" ? "#333333" : "#333333"};
           @media screen and (max-width: 375px) {
    font-size: 22px;
    line-height:normal;
}
  
     @media screen and (min-width: 769px) {
    display: none;
  }
`;

const Heading = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
`;

const Row = styled.div`
  width: 100%;
  padding: 0 6%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 30px;
  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
          @media screen and (max-width: 576px) {
  flex-direction: column;
    padding: 0 47px 68px 48px;
}
`;

const LeftCol = styled.div`
  width: 550px;
  padding: 7% 0;
  @media screen and (max-width: 769px) {
    width: 100%;
    padding: 7% 0 3% 0;
    display: none;
  }
`;

const RightCol = styled.div`
  width: 550px;
  padding: 7% 0;
  @media screen and (max-width: 769px) {
    width: 100%;
    padding: 0 0 10% 0;
  }
`;

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 33px;
  @media screen and (max-width: 769px) {
    flex-direction: column;
    gap: 0;
  }
`;

const TextContainer = styled.div
  `
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
 color:#666;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
          @media screen and (max-width: 576px) {
    font-size: 14px;
    line-height:normal;
`;

const LinksBottomContainer = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
   color: ${({ themeMode, theme }) => {
    return themeMode === "dark" ? theme.white : "#666";
  }};
           @media screen and (max-width: 576px) {
    text-align: start;
}
`;

const WarningLink = styled(Link) <
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
 color: ${({ themeMode, theme }) => {
    return themeMode === "dark" ? theme.white : "#111";
  }};
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const LinkText = styled(Link)`
  color: #527AE6;
`;

const LinkTextContainer = styled.div<{
  themeMode?: EThemeType;
} & { theme: Theme }>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  padding: 0 47px 0px 48px;
  margin-bottom:32px;
 color: ${({ themeMode, theme }) => {
    return themeMode === "dark" ? theme.white : "#333";
  }};
  width: 100%;
     @media screen and (max-width: 375px) {
    font-size: 14px;
}
   @media screen and (min-width: 769px) {
    display: none;
  }
`;

const LinkTextOne = styled(Link) <{ themeMode?: EThemeType }>`
  color: ${({ themeMode }) => {
    return themeMode === "dark" ? "#527AE6" : "#111";
  }};
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;


const LinkTextContain = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  display:flex;
 text-align: center;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : "#333"};
  width: 100%;
    justify-content: center;
`;

const LoginPageValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .min(3, "update.firstName.minLength")
    .required("signup.firstName.empty.error"),
  surname: yup
    .string()
    .min(3, "update.surName.minLength")
    .required("signup.surName.empty.error"),
  email: yup
    .string()
    .trim()
    .email("signup.email.error")
    .required("signup.email.empty.error"),
});

export const SignupPage = () => {
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const themeMode = useSelector((state) => state.authReducer.theme);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";


  const [language, setLanguage] = useState<string | undefined>(
    languages[0].value
  );

  useEffect(() => {
    setLanguage(userDetail?.user.language || languages[0].value);
  }, [userDetail]);
  return (
    <AuthContainer>
      <Title themeMode={themeMode}>
        <FormattedMessage id="signup.page.title" />
      </Title>
      <LinkTextContainer themeMode={themeMode}>
        <FormattedMessage id="signup.form.haveAccount" />
        &nbsp;&nbsp;
        <LinkTextOne themeMode={themeMode} data-testid='signup-form-link' to={`/${lang}/${RoutePaths.Login}`}>
          <FormattedMessage id="signup.form.link" />
        </LinkTextOne>
      </LinkTextContainer>
      <Row>
        <LeftCol>
          <Heading themeMode={themeMode}>
            <FormattedMessage id="signup.page.heading" />
          </Heading>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading1" />
          </PageItem>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading2" />
          </PageItem>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading3" />
          </PageItem>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading4" />
          </PageItem>
        </LeftCol>
        <RightCol>
          <Formik
            initialValues={{
              first_name: "",
              surname: "",
              email: "",
            }}
            validationSchema={LoginPageValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (language) {
                ReactGA.event({
                  action: "Authentication_EmailSignup_Initiated",
                  category: "WebsiteEmailSignupScreenSignupBtn_category",
                  label: "WebsiteEmailSignupScreenSignupBtn_label",
                  value: 2,
                });
                MixpanelAnalytics.trackSignup('email');
                signup({ ...values, language })
                  .then(() => {
                    MixpanelAnalytics.identify(values.email, {
                      name: `${values.first_name} ${values.surname}`,
                      email: values.email,
                      createdAt: new Date().toISOString()
                    });
                    push(`/${lang}/${RoutePaths.EmailSent}`);
                    setSubmitting(false);
                  })
                  .catch((err: any) => {
                    triggerNotification({
                      message: err?.data?.message,
                      type: "error",
                    });
                    setSubmitting(false);
                  });
              }
            }}
            validateOnBlur
            validateOnChange
          >
            {({ isSubmitting }) => (
              <Form data-testid='signup-form'>
                <FieldsRow>
                  <FieldItem bottom="15">
                    <Field name="first_name">
                      {({ field, meta }: FieldProps<string>) => (
                        <LabeledInput
                          {...field}
                          labelAdditionLength={0}
                          placeholder={formatMessage({
                            id: "signup.firstName.placeholder",
                          })}
                          type="text"
                          error={!!meta.error && meta.touched}
                          message={
                            meta.touched &&
                            meta.error &&
                            formatMessage({ id: meta.error })
                          }
                          variant={themeMode}
                          data-testid='signup-firstName'
                          loginField={true}
                        />
                      )}
                    </Field>
                  </FieldItem>

                  <FieldItem bottom="15">
                    <Field name="surname">
                      {({ field, meta }: FieldProps<string>) => (
                        <LabeledInput
                          {...field}
                          labelAdditionLength={0}
                          placeholder={formatMessage({
                            id: "signup.surName.placeholder",
                          })}
                          type="text"
                          error={!!meta.error && meta.touched}
                          message={
                            meta.touched &&
                            meta.error &&
                            formatMessage({ id: meta.error })
                          }
                          variant={themeMode}
                          data-testid='signup-surName'
                          loginField={true}
                        />
                      )}
                    </Field>
                  </FieldItem>
                </FieldsRow>
                <FieldItem bottom="15">
                  <Field name="email">
                    {({ field, meta }: FieldProps<string>) => (
                      <LabeledInput
                        {...field}
                        labelAdditionLength={0}
                        placeholder={formatMessage({
                          id: "signup.email.placeholder",
                        })}
                        type="text"
                        error={!!meta.error && meta.touched}
                        message={
                          meta.touched &&
                          meta.error &&
                          formatMessage({ id: meta.error })
                        }
                        variant={themeMode}
                        data-testid='signup-email'
                        inputSpace={true}
                        loginField={true}
                      />
                    )}
                  </Field>
                </FieldItem>
                <FieldItem bottom="25">
                  <DropDown
                    $background={themeMode === "light" ? "#F1F3F7" : "#383E4F"}
                    variant={themeMode}
                    height={40}
                    options={languages}
                    value={language}
                    onChange={(item: DropDownRow.IDataItem) =>
                      setLanguage(item.value)
                    }
                    data-testid='sign-up-lang-dropdown'
                    signup={true}
                  />
                </FieldItem>
                <LinksBottomContainer themeMode={themeMode}>
                  <FormattedMessage id="signup.form.agree" />
                  {" "}
                  <WarningLink themeMode={themeMode} data-testid='signup-terms' to={`/${lang}/terms`}>
                    <FormattedMessage id="signup.form.termsOfService.link" />
                  </WarningLink>
                  {" "}
                  <FormattedMessage id="signup.form.and" />
                  &nbsp;
                  <WarningLink themeMode={themeMode} data-testid='signup-privacy' to={`/${lang}/privacy`}>
                    <FormattedMessage id="signup.form.privacyPolicy.link" />
                  </WarningLink>
                </LinksBottomContainer>
                <Separator height="32px" />
                <LoadingButton
                  full
                  type="submit"
                  isloading={isSubmitting}
                  data-testid='signup-btn'
                  login={true}
                >
                  <FormattedMessage id="signup.page.btn" />
                </LoadingButton>
              </Form>
            )}
          </Formik>
          <Separator height="32px" />

          <div className="flex items-center justify-center gap-4">
            <div className="h-[2px] flex-1" style={{ backgroundColor: "rgba(102, 102, 102, 0.25)" }}></div>

            {/* Center Text using TextContainer */}
            <TextContainer>
              <FormattedMessage id="login.form.seperator.text" />
            </TextContainer>

            {/* Right Divider Line */}
            <div className="h-[2px] flex-1" style={{ backgroundColor: "rgba(102, 102, 102, 0.25)" }}></div>
          </div>
          <Separator height="32px" />
          <GoogleAuthBtn themeMode={themeMode} dataTestID={'google-auth-signup-btn'} type="signup">
            <FormattedMessage id="signup.socialAuth.google.btn.text" />
          </GoogleAuthBtn>
          <Separator height="32px" />
          <LinkTextContain themeMode={themeMode}>
            <FormattedMessage id="signup.form.haveAccount" />
            &nbsp;&nbsp;
            <LinkText data-testid='signup-form-link' to={`/${lang}/${RoutePaths.Login}`}>
              <FormattedMessage id="signup.form.link" />
            </LinkText>
          </LinkTextContain>
        </RightCol>
      </Row>
    </AuthContainer>
  );
};
