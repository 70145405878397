import React, { 
    ReactNode
  } from 'react';
  import styled from "styled-components";
  import { Spinner } from "components";
  import { RippleIconButton } from "./RippleEffects";
  
  // Previous code unchanged...
  
  // Define styled component outside of the component function
  export const ButtonContent = styled.div`
    background-color: #4338CA;
    color: #fff;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    border-radius: 4px;
    padding: 6px 20px;
    font-family: "Inter";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 80px; /* Ensure minimum width */
    min-height: 32px; /* Ensure minimum height */
    position: relative; /* For absolute positioning of spinner */
    
    @media (max-width: 576px) {
      font-size: 11px;
      padding: 5px 16px;
    }
  `;
  
  // Styled container for the spinner that overlays the content
  const SpinnerContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4338CA; /* Same as button background */
  `;
  
  // Content container that fades out during loading
  const ContentContainer = styled.div<{ isLoading?: boolean }>`
    opacity: ${props => props.isLoading ? 0 : 1};
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Set a fixed height to prevent layout shift */
    min-height: 20px;
  `;
  
  // Define interface for RippleDurationButton props
  interface RippleDurationButtonProps {
    children: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    isloading?: boolean;
    className?: string;
    style?: React.CSSProperties;
    [key: string]: any; // For other props
  }
  
  export const RippleDurationButton: React.FC<RippleDurationButtonProps> = ({ 
    children, 
    onClick, 
    isloading,
    className = '',
    style,
    ...props 
  }) => {
    return (
      <RippleIconButton
        color="rgba(255, 255, 255, 0.4)"
        onClick={onClick}
        className={`p-0 overflow-hidden ${className}`}
        style={{ borderRadius: "4px", ...style }}
        disabled={isloading}
        uploadfile={true}
        {...props}
      >
        <ButtonContent>
          {/* Content container that fades out but maintains space */}
          <ContentContainer isLoading={isloading}>
            {children}
          </ContentContainer>
          
          {/* Spinner that appears on top when loading */}
          {isloading && (
            <SpinnerContainer>
              <Spinner extraSmall isWhite />
            </SpinnerContainer>
          )}
        </ButtonContent>
      </RippleIconButton>
    );
  };
  