import React, {
  memo,
  Fragment,
  useState,
  useEffect,
  useRef,
} from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./answerContainer.module.scss";

import {
  CopyIcon,
  ShareIcon,
} from "../icons";
import { AssistanceIcon } from "../userName";

import { Tooltip } from "components";
import { Message } from "../message";
import { DocQuestion } from "../docQuestion";

import {
  IChat,
  IChatModel,
  IMessage,
  IShareChatMessage,
  setCurrentMessageInd,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import { RegenerateWithModel } from "../regeneratewithModel";
import messageStyles from "../message/message.module.scss";
import { responseLoadingMessage, RIPPLE_COLORS } from "utils/constants";
import { Loader } from "components/Loader/Loader";
import { AudioController } from "./AudioController";
import { ThreadsCopyIcon } from "../icons/ThreadsAnsIcons";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";

interface IProps {
  isAllChunksReceived?: boolean;
  message?: IMessage;
  isGenerating?: boolean;
  onSendMessage?: (question: string) => void;
  onComplete?: () => void;
  onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
  share?: boolean;
  toggleShareChat?: () => void;
  shareMessage?: IShareChatMessage;
  shareChat?: boolean;
  handleCheckboxChange?: (messageId: any) => void;
  chatItem?: IChat;
  searchQuery?: string;
  setHighlighted?: React.Dispatch<React.SetStateAction<boolean>>;
  highlighted?: boolean;
  setContainerHeight?: React.Dispatch<React.SetStateAction<number>>;
  messageIndex?: number;
  userMessage?: boolean;
  threads?: boolean;
}

export const BlockType = {
  text: "text",
  code: "code",
} as const;

export type EBlockType = (typeof BlockType)[keyof typeof BlockType];

export interface IMessageBlock {
  blockType: EBlockType;
  language?: string;
  message?: string;
}

export const Answer = memo(
  ({
    isAllChunksReceived,
    message,
    isGenerating,
    onSendMessage,
    onComplete,
    onRegenerate,
    share,
    toggleShareChat,
    shareMessage,
    shareChat,
    handleCheckboxChange,
    chatItem,
    searchQuery,
    highlighted,
    setContainerHeight,
    messageIndex,
    threads,
  }: IProps) => {
    const { theme, gptModel } = useSelector((state) => state.authReducer);
    const { PrivateChat, regenerateModel, currentMessageInd
    } = useSelector((state) => state.chatReducer);

    const { isGeneRep } = useSelector((state) => state.workSpaceReducer);
    // Store the content that should be displayed
    const [displayContent, setDisplayContent] = useState("");
    const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(!message?.content ? currentMessageInd : 0);
    const [showResponseLoadingPrompt, setShowResponseLoadingPrompt] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const isHighlighted = searchQuery !== "";
    const containerRef = useRef<HTMLDivElement>(null);
    // const copyButtonRef = useRef<HTMLDivElement>(null);

    // BUT ONLY IF generation is still active
    useEffect(() => {
      if ((isGenerating || isGeneRep) && message && message.isNew === true) {
        setDisplayContent(String(message?.content || ""));
      }

    }, [message?.content, isGenerating, isGeneRep]);

    useEffect(() => {
      const updateContainerHeight = () => {
        if (containerRef.current) {
          const height = containerRef.current.getBoundingClientRect().height;
          setContainerHeight && setContainerHeight(height);
        }
      };
      updateContainerHeight();
      window.addEventListener("resize", updateContainerHeight);

      return () => {
        window.removeEventListener("resize", updateContainerHeight);
      };
    }, [message]);

    useEffect(() => {
      const intervalID = setTimeout(() => {
        if (isCopied) setIsCopied(false);
      }, 500);

      return () => clearInterval(intervalID);
    }, [isCopied]);

    const content = String(message?.content);
    const [typingFinished, setTypingFinished] = useState(false);

    const showAssistanAction = message?.isNew
      ? content.length > 0 && !isGenerating && !message?.files
      : content.length > 0;

    useEffect(() => {
      if (isAllChunksReceived) {
        // setShouldFreeze(false);
        setTypingFinished(true);
      }
    }, [isAllChunksReceived]);

    useEffect(() => {
      if (typingFinished) {
        onComplete?.();
      }
    }, [typingFinished]);

    useEffect(() => {
      if ((!isGenerating && !isGeneRep) && (content as string).length > 100) {
        setTypingFinished(true);
      }

    }, [isGenerating, content, isGeneRep]);

    useEffect(() => {
      let interval: NodeJS.Timeout | null = null;
      if (message?.isNew && message.content === "" && ((regenerateModel && regenerateModel.modelName !== '' && regenerateModel.loader === true) || (gptModel && gptModel.attributes?.loader === true))) {
        setTimeout(() => setShowResponseLoadingPrompt(true), 100)

        interval = setInterval(() => {
          setCurrentMessageIndex((prevIndex) => {
            if (prevIndex < responseLoadingMessage.length - 1) {
              setCurrentMessageInd(prevIndex + 1);
              return prevIndex + 1;
            } else {
              return prevIndex;
            }
          });
        }, 8000);
      }
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [message, content]);

    const handleCopy = (e: any) => {
      e.preventDefault();

      const selection = window.getSelection();
      let messageText = selection ? selection.toString() : "";
      message?.related_questions?.forEach((question) => {
        messageText = messageText.replace(question, "");
      });
      messageText = messageText.replace(/\s{2,}/g, " ");

      e.clipboardData.setData("text/plain", messageText);
      setIsCopied(true);
        // Show ripple success feedback
      // if (copyButtonRef.current) {
      //    showSuccessFeedback(copyButtonRef.current);
      // }
    };

    // Create a message object with our frozen content
    const messageToDisplay = message ? {
      ...message,
      content: displayContent
    } : undefined;

    const themeColors = theme === 'dark' ? RIPPLE_COLORS.dark : RIPPLE_COLORS.light;

    return (
      <Fragment>
        <div className={classNames(styles.container, {
                [styles.threads]: threads,
              })} ref={containerRef}>
          <div className={styles.leftContainer}>
            <div
              className={classNames({
                [styles.assistanceIconAnimation]: (message?.isNew &&
                  message?.adminAccountdel !== true &&
                  ((!content) || (content && isGenerating))),
              })}
            >
              <AssistanceIcon />
            </div>
            <div
              className={classNames(styles.messageContainer, {
                [styles.threads]: threads,
              })}
              onDoubleClick={(e) => {
                e.stopPropagation();
              }}
              onCopy={handleCopy}
            >

              {(message?.isNew && !content && showResponseLoadingPrompt && (regenerateModel.modelName !== '' ? (regenerateModel?.loader === true) : (gptModel?.attributes?.loader === true))) && (
                <div
                  className={classNames(messageStyles.message, {
                    [messageStyles.light]: theme === "light",
                    [messageStyles.dark]: theme === "dark",
                    [messageStyles.threads]: threads,
                    [messageStyles.threadsLight]: threads && theme === "light",
                    "font-inter": threads,
                  })}
                  style={{ lineHeight: '24px' }}
                >
                  <FormattedMessage id={`response.loading.message.${responseLoadingMessage[currentMessageIndex]}`} />
                  <span className="inline-block ml-2">
                    <Loader mini />
                  </span>
                </div>
              )}
              <span
                className={classNames(messageStyles.answerStyle, {
                  [messageStyles.light]: theme === "light" && message?.content,
                  [messageStyles.dark]: theme === "dark" && message?.content,
                  [messageStyles.threadsLight]: theme === "light" && message?.content,
                  [messageStyles.threadsDark]: theme === "dark" && message?.content,
                })}>
                <Message
                  // message={message}
                  message={messageToDisplay && messageToDisplay.content.length > 0 ? messageToDisplay : message}
                  // message={{
                  //   ...message,
                  //   content: displayContent
                  // } as IMessage}
                  shareMessage={shareMessage}
                  searchQuery={searchQuery}
                  isHighlighted={isHighlighted}
                  highlighted={highlighted}
                  threads={threads}
                />
              </span>
            </div>
          </div>
        </div>
        {!shareChat && showAssistanAction && (
          <div className={classNames(styles.actionContainer, {
            [styles.threads]: threads,
          })}>
            {/* Create an array of components and sort them based on threads prop */}
            {(() => {
              // Define all action components
              const actionComponents = [];
              
              // AUDIO BUTTON
              const audioButton = (
                <AudioController 
                  key="audio"
                  message={message?.content ?? ''} 
                  messageId={message?.id ?? 1} 
                  threads={threads} 
                />
              );
              
              // COPY BUTTON
              const copyButton = (
                <Tooltip
                  key="copy"
                  regenrate={true}
                  tooltipAdjustement={true}
                  control={
                    <CopyToClipboard
                      text={content}
                      onCopy={() => setIsCopied(true)}
                    >
                    <RippleIconButton
                        color={themeColors.default}
                        noPaddingBorder={true}
                        className={classNames(
                          { [styles.iconContainer]: !threads },
                          {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                          }
                        )}
                      >
                        {threads ? <ThreadsCopyIcon /> : <CopyIcon theme={theme} />}
                      </RippleIconButton>
                    </CopyToClipboard>
                  }
                  placement="top"
                  theme="light"
                >
                  <div>
                    <FormattedMessage
                      id={
                        isCopied ? "answer.tooltip.copied" : "answer.tooltip.copy"
                      }
                    />
                  </div>
                </Tooltip>
              );
              
              // SHARE BUTTON - Only included when not in threads mode
              const shareButton = (!share && PrivateChat === false && !threads) ? (
                <Tooltip
                  key="share"
                  regenrate={true}
                  tooltipAdjustement={true}
                  control={
                    <RippleIconButton
                    noPaddingBorder={true}
                    color={themeColors.default}
                      className={classNames(styles.icon, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                        [styles.iconContainer]: !threads,
                      })}
                      onClick={() => {
                        toggleShareChat!();
                        handleCheckboxChange!(message?.id);
                      }}
                      data-testid="share-chat-btn"
                    >
                      <ShareIcon theme={theme} />
                    </RippleIconButton>
                  }
                  placement="top"
                  theme="light"
                >
                  <div>
                    <FormattedMessage id="share.tooltip.chat" />
                  </div>
                </Tooltip>
              ) : null;
              
              // REGENERATE BUTTON
              const regenerateButton = (
                <RegenerateWithModel
                  key="regenerate"
                  message={message}
                  chatItem={chatItem}
                  messageIndex={messageIndex}
                  onRegenerate={onRegenerate}
                  threads={threads}
                />
              );
              
              // Order components based on threads prop
              if (threads) {
                // Order for threads: Copy, Regenerate, Audio
                actionComponents.push(copyButton, regenerateButton, audioButton);
              } else {
                // Original order: Audio, Copy, Share, Regenerate
                actionComponents.push(audioButton, copyButton);
                if (shareButton) actionComponents.push(shareButton);
                actionComponents.push(regenerateButton);
              }
              
              return actionComponents;
            })()}
          </div>
        )}
        {!shareChat &&
          !isGenerating &&
          (message?.related_questions as string[])?.length > 0 && (
            <div className={classNames(styles.questionContainer, {
              [styles.threads]: threads,
            })}>
              {message?.related_questions?.map((question, index) => (
                <DocQuestion
                  key={`${message.id}-${index}`}
                  question={question}
                  onClick={() => onSendMessage?.(question)}
                  delay={500 * index}
                />
              ))}
            </div>
          )}
      </Fragment>
    );
  }
);
