import { Preview } from "redux/actions";

export const getFilePreview = async (fileUrl: string, fileType: string): Promise<{ preview: Preview, lines: number | null }> => {
  let preview: Preview = null;
  let lines: number | null = null;
  
  switch (fileType) {
    case "pdf":
      preview = { type: "pdf", url: fileUrl };
      break;
      
    case "txt":
    case "srt":
    case "log":
      try {
        const response = await fetch(fileUrl);
        const text = await response.text();
        lines = text.split("\n").length;
        preview = { type: "text", content: text, url: fileUrl };
      } catch (err) {
        preview = { type: "download", fileType, url: fileUrl };
      }
      break;
      
    case "csv":
      try {
        const response = await fetch(fileUrl);
        const text = await response.text();
        
        const rows = text.split("\n").map(row => 
          row.split(",").map(cell => cell.trim())
        );
        lines = text.split("\n").length;
        preview = { type: "csv", rows, url: fileUrl };
      } catch (err) {
        preview = { type: "download", fileType, url: fileUrl };
      }
      break;
      
    case "json":
      try {
        const response = await fetch(fileUrl);
        const text = await response.text();
        // Format JSON with indentation for better readability
        const formattedJson = JSON.stringify(JSON.parse(text), null, 2);
        lines = text.split("\n").length;
        preview = { type: "json", content: formattedJson, url: fileUrl };
      } catch (err) {
        preview = { type: "download", fileType, url: fileUrl };
      }
      break;
      
    case "xml":
      try {
        const response = await fetch(fileUrl);
        const text = await response.text();
        lines = text.split("\n").length;
        preview = { type: "xml", content: text, url: fileUrl };
      } catch (err) {
        preview = { type: "download", fileType, url: fileUrl };
      }
      break;
      
    case "md":
    case "mdx":
      try {
        const response = await fetch(fileUrl);
        const text = await response.text();
        lines = text.split("\n").length;
        preview = { type: "markdown", content: text, url: fileUrl };
      } catch (err) {
        preview = { type: "download", fileType, url: fileUrl };
      }
      break;
      
    case "htm":
    case "html":
      try {
        const response = await fetch(fileUrl);
        const text = await response.text();
        lines = text.split("\n").length;
        preview = { type: "html", content: text, url: fileUrl };
      } catch (err) {
        preview = { type: "download", fileType, url: fileUrl };
      }
      break;
      
    case "docx":
    case "pptx":
    case "ppt":
    case "xlsx":
    case "xls":
    case "eml":
    case "epub":
    case "msg":
    default:
      preview = { 
        type: "download", 
        fileType,
        url: fileUrl
      };
      break;
  }
  
  return { preview, lines };
};