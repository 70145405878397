import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import styles from "./FilePreviewModal.module.scss";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import AnimatedModal from "components/AnimateModal/AnimateModal";
import FilePreviewHeader from "./Components/FilePreviewHeader";
import PreviewContent from "./Components/PreviewContent";
import { Preview, PreviewableFile } from "redux/actions";
import { getFileName, getFileType } from "utils/fileUtils";
import { getFilePreview } from "utils/previewGenerators";
import { externalDomains } from "utils/constants";

interface FilePreviewModalProps {
  file: PreviewableFile | null;
  onClose: () => void;
  getsize?: boolean;
}

// Function to check if file has an extension
const hasFileExtension = (fileName: string): boolean => {
  // Check if the filename contains a dot (.) followed by characters
  return /\.[^/.]+$/.test(fileName);
};

// Function to check if the URL is an external website
const isExternalWebsite = (url: string): boolean => {
  if (!url) return false;

  try {
    // Extract last part of the URL after the last '/'
    const lastSegment = url.split('/').pop() || '';

    // Extract the file extension (last part after the last '.')
    const fileExtension = lastSegment.split('.').pop() || '';

    // Check if the extracted extension is in the external domains list
    return externalDomains.includes(fileExtension.toLowerCase());
  } catch (e) {
    console.error("Invalid URL:", url, e);
    return false;
  }
};

const FilePreviewModalContent: React.FC<FilePreviewModalProps> = ({ file, onClose, getsize }) => {
  const [preview, setPreview] = useState<Preview>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [lineCount, setLineCount] = useState<number | null>(null);
  
  const { theme } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (!file) return;
    
    const generatePreview = async () => {
      setLoading(true);
      
      try {
        const fileType = getFileType(file);
        const fileUrl = file.S3Link;
        
        if (!fileUrl) {
          setError("File URL not available");
          setLoading(false);
          return;
        }
        
        const { preview, lines } = await getFilePreview(fileUrl, fileType);
        setPreview(preview);
        setLineCount(lines);
      } catch (err) {
        console.error("Error generating preview:", err);
        setError("Failed to generate preview");
      } finally {
        setLoading(false);
      }
    };
    
    generatePreview();
  }, [file]);

  const fileName = file ? getFileName(file) : "";
  const fileUrl = file?.S3Link || "";
  const fileSize = file && 'fileSize' in file ? file.fileSize : undefined;

  return (
    <AnimatedModal filePreview={true} isOpen={true} onClose={onClose}>
      <div className={classNames(styles.fullscreenModalContainer, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}>
        <FilePreviewHeader 
          fileName={fileName} 
          fileSize={fileSize} 
          lineCount={lineCount} 
          onClose={onClose} 
          theme={theme}
          getsize={getsize}
        />
        
        <PreviewContent 
          loading={loading}
          error={error}
          preview={preview}
          fileUrl={fileUrl}
          theme={theme}
        />
      </div>
    </AnimatedModal>
  );
};

// Main component that determines whether to show modal or open in new tab
const FilePreviewModal: React.FC<FilePreviewModalProps> = ({ file, onClose, getsize }) => {

const handleOpenInNewTab = useCallback(() => {
  if (!file || !file.S3Link) return;
  
  const fileName = getFileName(file);
  
  if (!hasFileExtension(fileName) ||  isExternalWebsite(file.S3Link)) {
    window.open(file.S3Link, '_blank');
    onClose();
  }
}, [file, onClose]);

useEffect(() => {
  if (!file) return;
  
  handleOpenInNewTab();

  // Only handle overflow if we're showing the modal
  if (hasFileExtension(getFileName(file)) && !isExternalWebsite(file.S3Link ?? "")) {
  const originalOverflow = document.body.style.overflow;
  document.body.style.overflow = 'hidden';
  
  // Re-enable when component unmounts
  return () => {
    document.body.style.overflow = originalOverflow;
  };
};
}, [file, onClose]);

  // If file has no extension, don't render the modal at all
  if (file && (!hasFileExtension(getFileName(file)) ||  isExternalWebsite(file.S3Link?? ""))) {
    return null;
  }

  // Render the modal content in a portal at the document body level
  return ReactDOM.createPortal(
    <FilePreviewModalContent file={file} onClose={onClose} getsize={getsize} />,
    document.body
  );
};

export default FilePreviewModal;
