import Button from "components/Button";
import styled from "styled-components";
import classNames from "classnames";
import { CancelIcon } from "../icons";
import chatItemStyle from '../ChatItem/ChatItem.module.scss';
import styles from "../../ChatSidebar.module.scss";

import { FormattedMessage } from "react-intl";
import { useSelector } from "redux/hooks";

interface IProp {
    setChatDelConfirm: React.Dispatch<React.SetStateAction<Record<number | string, boolean>>>
    loading: boolean;
    handleSelectAllChats: (isChecked: boolean, folder_id: number | string) => void;
    selectAllChat: Record<string | number, boolean>;
    handleCancel: (cancel: boolean, folder_id: number | string) => void;
    folder_id: number | string;
    selectedChatIds: Record<string | number, number[]>
}

const DeleteBtn = styled(Button)`
flex-shrink: 0;
border-radius: 5px;
padding: 5px 10px;
font-size: 11px;

`;

export const SelectChat = ({ setChatDelConfirm, loading, handleSelectAllChats, selectAllChat, handleCancel, folder_id, selectedChatIds }: IProp) => {

    const { PrivateChat } = useSelector((state) => state.chatReducer);

    const selectedCount = selectedChatIds[folder_id]?.length;
    const deleteText =
        selectAllChat[folder_id]
            ? <FormattedMessage id="modal.deleteMember.btn.deleteAll" />
            : selectedCount === 1
                ? <FormattedMessage id="modal.deleteMember.btn.delete1" values={{ count: 1 }} />
                : <FormattedMessage id="modal.deleteMember.btn.deletecount" values={{ count: selectedCount }} />;

    return (
        <>
            <div className="flex justify-end right-6 gap-3">
                <DeleteBtn
                    variant="delete"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setChatDelConfirm((prev) => ({ ...prev, [folder_id]: true }))

                    }}
                    isloading={loading}
                    data-testid='submit-btn'
                >
                    <div> {deleteText} </div>
                </DeleteBtn>

                <div data-testid='cancel-edit-btn' className="cursor-pointer flex items-center " onClick={() => { handleCancel(false, folder_id) }}>
                    <CancelIcon />
                </div>
            </div>

            <div className={`flex pl-[6px] items-center py-1 ${classNames({
                [styles.isbackdrop]: PrivateChat,
            })}`}>
                <div className={chatItemStyle.check}>
                    <input type="checkbox"
                        checked={selectAllChat[folder_id]}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className={chatItemStyle.visibleCheckbox}
                        onChange={(e) => handleSelectAllChats(e.target.checked, folder_id)}
                    />
                </div>
                <span className="text-[12px] font-medium opacity-90	text-white leading-[28px] pl-2 w-[56%]"><FormattedMessage id="sidebar.chat.select.all" /></span>
            </div>
        </>
    )
}