import {
   memo,
  useMemo 
} from "react";
import styles from "./ThreadsConversation.module.scss";
import { ChatHistory } from "pages/ChatPage/pages/chatHistory";
import { WorkspaceSearchField } from "../WorkspaceConversation.tsx/InfoComponent/WorkspaceSearchField";
import { useSelector } from "redux/hooks";
import { UploadedFilePreview } from "../WorkspaceConversation.tsx/Threads/Components/UploadedFilePreview";
import useRouter from "hooks/useRouter";
import { ScrollToBottomButton } from "pages/ChatPage/components/chatFooter/components/ScrollToBottomButton";
import { useThreadsHistoryContext } from "hooks/ThreadHistoryContext";
import { loadThreadHistory } from "utils/chat";

interface IProp {
    isAllowUploadFile: boolean;
}

export const ThreadsHistory = memo(({ isAllowUploadFile }:IProp) => {
       const {
        chatContainerRef,
        isAnswerComplete,
        isAllChunksReceived,
        isGenerating,
        IGLoading,
        openHistory,
        share,
        messageHeight,
        selectedMessages,
        chatItem,
        setMessageHeight,
        setSelectedMessages,
        setSelectedChatId,
        onSendMessage,
        onEnter,
        onRegenerate,
        setIsAnswerComplete,
        setIsGenerating,
        getMessagesLoading,
        showScrollToBottom,
        scrollToBottom
    } = useThreadsHistoryContext();
  
    const { isModalOpen } = useSelector((state) => state.workSpaceReducer);
    const { newMessages, messages, messagesPagination, isLoadMoreMessages  } = useSelector((state) => state.chatReducer );
    const { pathname } = useRouter();
    const chatId = pathname.split("/")[4];
    const ws_Id = pathname.split("/")[2];
        // Memoize messages to avoid unnecessary re-renders
        const currentMessages = useMemo(() => {
          return chatId ? messages : newMessages;
      }, [chatId, messages, newMessages]);

      const handleMessageScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (messagesPagination.page < messagesPagination.lastPage) {
            const { scrollTop } = event?.currentTarget;
            const scrollPositionFromTop = scrollTop;
            const loadThreshold = 1;

            if (scrollPositionFromTop <= loadThreshold && !isLoadMoreMessages) {
              loadThreadHistory(chatId, Number(ws_Id), messagesPagination, chatContainerRef);
            }
        }
    };

    return (
        <>
           <div className={styles.chathistory} ref={chatContainerRef} onScroll={handleMessageScroll}> 
            <ChatHistory
                isAllChunksReceived={isAllChunksReceived}
                isloading={getMessagesLoading}
                messages={ currentMessages
                }
                isGenerating={isGenerating}
                setIsAnswerComplete={setIsAnswerComplete}
                setIsGenerating={setIsGenerating}
                onSendMessage={onSendMessage}
                IGLoading={IGLoading}
                onRegenerate={onRegenerate}
                selectedMessages={selectedMessages}
                setSelectedMessages={setSelectedMessages}
                share={share}
                setSelectedChatId={setSelectedChatId}
                messageHeight={messageHeight}
                setMessageHeight={setMessageHeight}
                chatItem={chatItem}
                threads={true}
            />
            </div>
            <div className={styles.footer}>
            {!getMessagesLoading && showScrollToBottom && (
        <ScrollToBottomButton
          dataTestId="scroll-to-bottom"
          onClick={()=>
            scrollToBottom?.({ behavior: "smooth" })
          }
        />
      )}
               {!isModalOpen && <UploadedFilePreview /> }
                <WorkspaceSearchField
                    chatPage={true}
                    threads={true}
                    openHistory={openHistory}
                    isAnswerComplete={isAnswerComplete}
                    onEnter={onEnter}
                    isAllowUploadFile={isAllowUploadFile}
                />
            </div>
        </>
    );
});