import styles from "../../workSpaceConversation.module.scss";
import { useRef, useState, useEffect } from "react";
import { CrossIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import { FileError } from "pages/Workspaces/components/icons/FileError";
import {
  ShiftLeftIcon,
  ShiftRightIcon,
} from "pages/Workspaces/components/icons/TreadsIcons";
import { useSelector } from "redux/hooks";
import {
  PDFIcon,
  TextIcon,
  PPTXIcon,
  PPTIcon,
  CSVIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  EMLIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
import { Spinner } from "components";
import { FILE_COLORS, renderAudioName, renderVideoName, textExtensions } from "utils/constants";
import classNames from "classnames";
import { delChatContent, DeleteS3Link, IChatContentUploadFile } from "redux/actions";
import { AudioPreview } from "pages/ChatPage/components/searchField/AudioPreview";
import VideoPreview from "pages/ChatPage/components/searchField/VideoPreview";
import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import React from "react";
import FilePreviewModal from "pages/Workspaces/components/FilePreviewModal/FilePreviewModal";
import { adaptUploadFile, debounce } from "utils/functions";
// import { isImageFile } from "utils/fileProcessing";

const FILE_ICONS: { [key: string]: React.ComponentType } = {
  pdf: PDFIcon,
  "text/plain": TextIcon,
  presentation: PPTXIcon,
  "ms-powerpoint": PPTIcon,
  csv: CSVIcon,
  sheet: XLSXIcon,
  "ms-excel": XLSIcon,
  "application/x-subrip": SRTIcon,
  "message/rfc822": EMLIcon,
};

export const UploadedFilePreview = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [previews, setPreviews] = useState<(string | null)[]>([]);

  const { chatContentUploadingFiles } = useSelector( (state) => state.workSpaceReducer);
  const { theme } = useSelector((state) => state.authReducer);
  const [previewFile, setPreviewFile] = useState<IChatContentUploadFile | null>(null);
  const [audioPreview, setAudioPreview] = useState<{ path: string; name: string } | null>(null);

  // Video preview states
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<{ path: string; name: string } | null>(null);

  useEffect(() => {
    if (chatContentUploadingFiles) {
      const filePreviews = chatContentUploadingFiles.map((uploadFile) => {
        const file = uploadFile.file;
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          return new Promise<string | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
          });
        }
        return Promise.resolve(null);
      });

      Promise.all(filePreviews).then(setPreviews);
    }
  }, [chatContentUploadingFiles]);

    // Setup z-index for PhotoView-Portal
    useEffect(() => {
      let observer: MutationObserver | null = null;
      
      const adjustPhotoViewPortalZIndex = () => {
        const portals = document.getElementsByClassName('PhotoView-Portal');
        Array.from(portals).forEach(portal => {
          if (portal instanceof HTMLElement && portal.style.zIndex !== '9000000') {
            portal.style.zIndex = '9000000';
            portal.style.position = 'absolute';
          }
        });
      };
      
      const debouncedAdjustPhotoViewPortalZIndex = debounce(adjustPhotoViewPortalZIndex, 100);
      
      observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' && 
              Array.from(mutation.addedNodes).some(node => 
                node instanceof HTMLElement && 
                (node.classList.contains('PhotoView-Portal') || 
                 node.querySelector('.PhotoView-Portal')))) {
            debouncedAdjustPhotoViewPortalZIndex();
            break; // Only need to adjust once per batch of mutations
          }
        }
      });
      
      adjustPhotoViewPortalZIndex();
      observer.observe(document.body, { childList: true, subtree: true });
      
      return () => {
        if (observer) {
          observer.disconnect();
          observer = null;
        }
      };
    }, []);

  const updateArrows = () => {
    if (!containerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(Math.ceil(scrollLeft + clientWidth) < scrollWidth - 1);
  };

  useEffect(() => {
    updateArrows();
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", updateArrows);
    }
    window.addEventListener("resize", updateArrows);
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateArrows);
      }
      window.removeEventListener("resize", updateArrows);
    };
  }, [chatContentUploadingFiles]);

  const scroll = (direction: "left" | "right") => {
    if (containerRef.current) {
      const scrollAmount = 150;
      containerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const getFileIcon = (fileType: string, fileName: string) => {
    for (const key in FILE_ICONS) {
      if (fileType.includes(key)) {
        const IconComponent = FILE_ICONS[key as keyof typeof FILE_ICONS];
        return <IconComponent />;
      }
    }
    if (fileType === "" && fileName.endsWith(".srt")) {
      return <SRTIcon />;
    }

    const fileExt = fileName.split(".").pop()?.toLowerCase(); // Extract file extension

    if (fileExt && textExtensions.includes(fileExt)) {
      return (
        <div className={classNames(styles.loader, {
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
        })}>
          <span className="text-[8px] font-medium text-[#A09FA2]">
            {fileExt}
          </span>
        </div>
      );
    }
    
    return <DocIcon />;
  };

  const getBackgroundColor = (fileType: string, fileName: string) => {
    for (const key in FILE_COLORS) {
      if (fileType.includes(key)) {
        return FILE_COLORS[key as keyof typeof FILE_COLORS];
      }
    }
    if (fileType === "" && fileName.endsWith(".srt")) {
      return "#FFB300";
    }
    const fileExt = fileName.split(".").pop()?.toLowerCase(); // Extract file extension

    if (fileExt && textExtensions.includes(fileExt)) {
    return "rgb(217, 217, 217)";
    }

    return "#4285F4";
  };

  const handleDeleteFile = ( e: React.MouseEvent, id: string, S3Link: string ) => {
    e.preventDefault();
    e.stopPropagation();

    DeleteS3Link(S3Link);
    delChatContent(id);
  };

  const handlePreviewFiles = (file: IChatContentUploadFile, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  
    if (!file) return;
  
    if (file.fileType && !audioPreview && file.file.type.includes("audio")) {
      setAudioPreview({
        path: file.S3Link || '',
        name: file.file.name
      });
    } else if (file.fileType && !showVideoPreview && file.file.type.includes("video")) {
    setSelectedVideo({
      path: file.S3Link || '',
      name: file.file.name
    });
    setShowVideoPreview(true);
    setIsVideoLoading(true);
    } else if (!previewFile) {
    const previewFileWithType = {
      ...file,
      fileType: "document" as const // Explicitly set fileType to "document"
    };
      
    setPreviewFile(previewFileWithType);
  }
  };
  
  // Add function to close preview
  const closePreview = () => {
    setPreviewFile(null);
  };
  
  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };
  
  const closeVideoPreview = () => {
    setShowVideoPreview(false);
    setSelectedVideo(null);
  };
  
  const closeAudioPreview = () => {
  setAudioPreview(null);
  };

  if (!chatContentUploadingFiles || chatContentUploadingFiles.length === 0)
    return;
  
  return (
    <>
      {/* Render the FilePreviewModal when a file is selected */}
      {previewFile && (
        <FilePreviewModal 
          file={adaptUploadFile(previewFile)} 
          onClose={closePreview} 
        />
      )}

      {showVideoPreview && selectedVideo && (
        <VideoPreview 
          videos={[selectedVideo]} 
          loading={isVideoLoading}
          handleVideoLoad={handleVideoLoad}
          onClose={closeVideoPreview}
        />
      )}

      {/* Audio preview modal */}
      {audioPreview && (
        <AudioPreview 
          audio={audioPreview}
          onClose={closeAudioPreview}
        />
      )}
       {/* Wrap entire file list with PhotoProvider for image previews */}
    <PhotoProvider maskOpacity={0.91}>
    <div className="relative w-full px-4">
      <div className="relative w-full flex items-center">
        {/* Left Scroll Button */}
        {showLeftArrow && (
          <button
            className="absolute -left-2 top-1/2 -translate-y-1/2 z-10 dark:bg-black bg-white shadow-[0px_1px_4px_0px_rgba(0,0,0,0.15)] rounded-[13.5px] w-[26px] py-[5px] px-[9px] flex items-center justify-center"
            onClick={() => scroll("left")}
          >
            <ShiftLeftIcon theme={theme}/>
          </button>
        )}

        <div
          ref={containerRef}
          className="bg-[#E2E8F0] dark:bg-[#333435] p-5 rounded-lg flex items-start gap-6 overflow-x-hidden w-full"
          onScroll={updateArrows}
        >
          {chatContentUploadingFiles.map((file, index) => {
            // const IconComponent = getFileIcon(file.file.type, file.file.name);
            const isImage = file.fileType=== "image";
            const bgColor =
              file.status === "error"
                ? "#FEE2E2"
                : file.status === "uploading" ||
                    file.file.type.startsWith("video/") ||
                    file.file.type.startsWith("audio/") ||
                    file.file.type.startsWith("image/")
                  ? "#D9D9D9"
                  : getBackgroundColor(file.file.type, file.file.name);
                  const fileItem = (
            // return (
              <div
                key={index}
                className={`relative w-32 h-24 rounded-[10px] flex-shrink-0 `}
                style={{
                  border: file.status === "error" ? "0.6px solid #F87171" : "",
                  backgroundColor: bgColor,
                }}
                onClick={(e) => {
                  if(file.status === "uploaded" && !isImage) { handlePreviewFiles(file, e)}
               }}
              >
                {file.status === "uploading" ? (
                  <div className="flex justify-center items-center h-full">
                    <Spinner extraSmall />
                  </div>
                ) : (
                  <>
                    <div className="w-full h-full flex items-center justify-center rounded-lg">
                      {file.file.type.startsWith("image/") &&
                      previews[index] ? (
                        <img
                          className="rounded-lg"
                          src={previews[index] as string}
                          width="100%"
                          height="100%"
                          alt="Uploaded Preview"
                        />
                      ) : file.file.type.startsWith("video/") ? (
                        renderVideoName(file.file.type)
                      ) : file.file.type.startsWith("audio/") ? (
                        renderAudioName(file.file.type, file.file.name)
                      ) : (
                        getFileIcon(file.file.type, file.file.name)
                      )}
                    </div>
                    <div
                      className={`absolute -top-2 -left-2 bg-white dark:bg-black rounded-full p-[6px] w-[26px] flex justify-center items-center shadow ${styles.clossIcon} cursor-pointer`}
                      onClick={(e) => handleDeleteFile(e, file.id, file.S3Link?? "")}
                    >
                      <CrossIcon theme={theme}/>
                    </div>

                    {file.status === "error" && (
                      <div className="flex items-center justify-center flex-col text-center gap-[6px] h-[100%]">
                        <FileError />
                        <span className="text-[10px] font-normal text-center text-[#F87171]">
                          {file.file.name.split(".").pop()?.toUpperCase() ||
                            "UNKNOWN"}
                        </span>
                      </div>
                    )}

                    {file.status !== "error" && (
                      <div className="absolute left-1/2 -translate-x-1/2 -bottom-2">
                        <div className="px-[8px] py-[4px] bg-white dark:bg-black rounded-full text-[10px] font-normal leading-normal text-[#525252] dark:text-[#fff]">
                          {file.file.name.split(".").pop()?.toUpperCase() ||
                            "UNKNOWN"}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
                  )

                  if (isImage && file.status === "uploaded") {
                    return (
                      <PhotoView key={file.id} src={file.S3Link}>
                        {fileItem}
                      </PhotoView>
                    );
                  }
        
                  return (
                    <React.Fragment key={file.id}>
                      {fileItem}
                    </React.Fragment>
                  );
            // );
          })}
        </div>

        {/* Right Scroll Button */}
        {showRightArrow && (
          <button
            className="absolute -right-2 top-1/2 -translate-y-1/2 z-10 bg-white dark:bg-black shadow-[0px_1px_4px_0px_rgba(0,0,0,0.15)] rounded-[13.5px] w-[26px] py-[5px] px-[9px] flex items-center justify-center"
            onClick={() => scroll("right")}
          >
            <ShiftRightIcon theme={theme}/>
          </button>
        )}
      </div>
    </div>
    </PhotoProvider>
    </>
  );
};
