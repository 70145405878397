import { IUser } from "../auth";
import { IChatModel } from "../chatModels";

export interface ISendMessageBody {
  message: string;
  newChatBoolean: boolean;
  chatId: number | null;
  model: string;
  chatType?: EChatType;
  messageViewType?: EMessageViewType;
  file_path?: string[];
  regenerate?: boolean;
  images?: string[];
  isPrivate?: boolean;
  folder_id?: number | null;
}

export interface IGenerateRelatedQuestionsBody {
  chatId: number | null;
  messageViewType?: EMessageViewType;
}

export interface IRemoveGeneratedQuestions {
  messageId: number;
  messageViewType?: EMessageViewType;
}

export interface IGetAllChat {
  chats: IChat[];
}

export interface IGetChatHistory {
  messages: IMessage[];
}

export type EMessageViewType =
  (typeof MessageViewType)[keyof typeof MessageViewType];

export const MessageViewType = {
  new: "new",
  history: "history",
} as const;

export type EChatType = (typeof ChatType)[keyof typeof ChatType];

export const ChatType = {
  text: "text",
  document: "document",
  image: "image",
  image_chat: "image_chat",
  video: "video",
  audio: "audio",
} as const;

export type EMessageType = (typeof MessageType)[keyof typeof MessageType];

export const MessageType = {
  user: "user",
  assistant: "assistant",
} as const;

export interface IFile {
  id: number | null;
  model_type?: string;
  model_id: number | null;
  name: string;
  path: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IUpscaledImage {
  id?: number;
  image_id: number;
  upscale_image_id: number;
  created_at?: string;
  updated_at?: string;
}

export interface IImage {
  id: number;
  model_type: string;
  model_id: number;
  name: string | null;
  path: string;
  size: string;
  created_at: string;
  updated_at: string;
  upscaled_images?: IUpscaledImage[];
}

export interface ISharImgeChat {
  path?: string;
}

export interface IChat {
  id: number;
  chat_type: EChatType;
  topic: string;
  is_topic_updated?: number | null;
  document_index_name?: string;
  user_id: number;
  created_at?: string;
  updated_at?: string;
  files?: IFile[];
  last_assistant_message?: IMessage;
  searched_messages?: IMessage[];
  model?: IChatModel;
  messages?: IMessage[];
  folder?: Folder;
}

export interface IMessage {
  id?: number;
  content?: string;
  images: IImage[];
  type?: EMessageType;
  chat_id?: number;
  created_at?: string;
  updated_at?: string;
  isNew?: boolean;
  related_questions?: string[];
  files?: IFile[];
  regenerate_model_id?: number | null;
  audio_src_link?: string | null;
  model_id?: number | null;
  adminAccountdel?: boolean;
  chatType?: EChatType;
  folder_id?: number | null;
  thread_id?: number | null;
}

export interface IShareChatMessage {
  content?: string;
  type?: EMessageType;
  images: ISharImgeChat[];
  files?: IFile[];
  // 0: ImageContainer;
}

export interface IStopGeneratingMessageBody {
  chatId?: number;
  messageId?: number;
}

export interface ISaveFilePathBody {
  path: string;
  name: string;
}

export interface IChatHistoryResponse {
  chat: IChat;
  // messages: IMessage[];
  messages: {
    current_page: number;
    last_page: number;
    data: IMessage[];
  };
}

export interface IPrompt {
  id: number;
  title: string;
  prompt: string;
  created_at: string;
  updated_at: string;
}

export interface ISavePrompt {
  title: string;
  prompt: string;
}

export interface IGetChat {
  token: string;
}

export interface IShareChat {
  id: number;
  chat_id: number;
  model_id: number;
  chat_type: string;
  token: string;
  messages: IShareChatMessage[];
  created_at: string;
  updated_at: string;
}

export interface IChatSetting {
  real_time_results: boolean;
  related_questions: boolean;
  send_message_with_enter: boolean;
  customized_response?: boolean;
}

export interface ChatSetting {
  key: string;
  real_time_results: boolean;
  related_questions: boolean;
  send_message_with_enter: boolean;
  customized_response?: boolean;
}

export interface RememberBot {
  key: string;
  customized_response: boolean;
}

export interface IRollBotSetting {
  language: string;
  length: string;
  output_format: string;
  style_of_writing: string;
  tone: string;
}

export interface IBot {
  key: string;
  output_format?: string;
  tone?: string;
  style_of_writing?: string;
  length?: string;
  language?: string;
}

export interface subOptions {
  label: string;
  value: string;
}
export interface BotSettings {
  writingStyles: subOptions[];
  languages: subOptions[];
  outputFormats: subOptions[];
  responseLengths: subOptions[];
  tones: subOptions[];
}

export interface SettingsBase {
  chat_settings: IChatSetting;
  bot_settings: IRollBotSetting;
  bot_settings_allowed: BotSettings;
}

export interface subOption {
  label: string;
  value: string;
}

export interface DropdownOption {
  key: string;
  label: string;
  value: string;
  options: subOption[];
}

export interface pagination {
  page: number;
  lastPage: number;
}

export interface TextToSpeech {
  model: string;
  voice: string;
  text: string;
}

export interface Regenerate {
  modelName: string;
  loader: boolean;
}

export interface FolderCR {
  user_id: number;
  name: string;
}

export interface Folder {
  created_at: string;
  id: number;
  name: string;
  updated_at: string;
  user_id: number;
}

export interface FolderCRRes {
  data: Folder;
  message: string;
  status: string;
}

export interface getFolder {
  chats: IChat[];
  chats_count: number;
  created_at: string;
  id: number;
  name: string;
  updated_at: string;
  user_id: number;
  pagination: pagination;
}

export interface IMessageData {
  current_page: number;
  last_page: number;
  data: IChat[];
}

export interface UploadFile {
  id: string;
  file: File;
  status: "validating" | "uploading" | "uploaded" | "error";
  fileType: "document" | "image" | "video" | "audio";
  S3Link?: string;
  errorMessage: string;
  progress: number;
  fileSize: number;
  uploaded_id?: number;
  updated_at?: string;
  created_at?: string;
  customName?:string;
}
export interface WorkspaceCR {
  name: string;
  emoji: string;
}
export interface WorkspaceUploadSrc1 {
  source_type: string;
  file_path: File;
  workspace_id: number;
}
export interface IUploadFile {
  path: string;
  size: number | null;
}
export interface WorkspaceUploadSrc {
  source_type: string;
  file_path: string[];
  workspace_id: number;
  // file_size: number | null;
}
export interface IWorkspace {
  id: number;
  created_at: string;
  description: string;
  instructions: string;
  name: string;
  settings: string;
  team_id: number;
  updated_at: string;
  emoji: string;
}
export interface ICRWorkSpace {
  data: IWorkspace;
  message: string;
}

export interface IWSFile {
  created_at: string;
  id: number;
  displayName: string;
  fileName: string;
  updatedAt:string;
  mime_type: string;
  path: string;
  source_type: string;
  user_id: number;
  size: string | null;
}
export interface IWSUser {
  email: string;
  id: number;
  name: string;
}
export interface IWorkspaceSr {
  description: null | string;
  id: number;
  name: string;
}
export interface IUploadedFile {
  created_at: string;
  file: IWSFile;
  id: number;
  updated_at: string;
  user: IWSUser;
  workspace: IWorkspaceSr;
  workspace_id: number;
}
export interface UploadedFileRes {
  data: IUploadedFile;
  message: string;
}
export interface UpdateWs {
  description?: string;
  instructions?: string;
  name?: string;
}
export interface InprogressWs {
  description?: string;
  instructions?: string;
  name?: string;
  emoji?:string;
}

export type Preview = 
  | { type: "pdf"; url: string }
  | { type: "text"; content: string; url: string }
  | { type: "csv"; rows: string[][]; url: string }
  | { type: "html"; content: string; url: string }
  | { type: "json"; content: string; url: string }
  | { type: "xml"; content: string; url: string }
  | { type: "markdown"; content: string; url: string }
  | { type: "download"; fileType: string; url: string }
  | { type: "officeViewer"; embedUrl: string; url: string }
  | null;

  export interface PreviewableFile {
    // Essential fields for preview functionality
    file: File | { name: string; type?: string; size?: number };
    S3Link?: string;
    fileSize?: number;
  }
export interface TSendMessageBody {
  message: string;
  newChatBoolean: boolean;
  thread_id?: number | null;
  model: string;
  chatType?: EChatType;
  messageViewType?: EMessageViewType;
  file_path?: string[];
  regenerate?: boolean;
  images?: string[];
  workspace_id?:number;
}

export interface IChatContentUploadFile {
    id: string;
    file: File;
    status: "validating" | "uploading" | "uploaded" | "error";
    fileType: "document" | "image" | "video" | "audio";
    S3Link?: string;
    fileSize?: number;

}
export interface IFilePreview {
  file: File;
  errorMessage: string;
}

export interface TChatHistoryResponse {
  data: {
    current_page: number;
    last_page: number;
    total: number;
    data: TdataResponse[];
  };
}
export interface TdataResponse {
  id: number;
  workspace_id: number;
  user_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  workspace: IWorkspace;
  chat: IChat;
  model: IChatModel;
  created_by: IUser;
}

export interface ThreadChat {
  chat_type: EChatType;
  model: IChatModel;
  files: IWSFile;
}

export interface IThread {
  archived_at : null | string;
chat_id : number;
created_at: string;
id: number;
is_favourite: number;
topic : string;
updated_at: string;
user : {id: number, name:string}
workspace : {id: number, name: string}
workspace_id: number;
// model?: IChatModel;
// chat_type?: EChatType;
chat: ThreadChat;
}
export interface IThreadHistoryResponse {
  thread: IThread;
  messages: {
    current_page: number;
    last_page: number;
    data: IMessage[];
  };
}
export interface SelectedRollSubOptions {
  [key: string]: string;
}export interface UpdateThreadTitle {
  topic: string;
}

export interface selectedThread {
  ws_name: string;
  emoji: string;
  thread_name: string;
  thread_id: number;
  ws_id: number;
}

export interface SelectedSubOptions {
  outputFormats: string;
  tones: string;
  writingStyles: string;
  responseLengths: string;
  language: string;
  [key: string]: string;
}