export const CodeBlockCopyIcon = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path d="M3.98094 16.6289V17.3408C3.98094 18.6045 5.00538 19.6289 6.26908 19.6289H16.7119C17.9756 19.6289 19 18.6045 19 17.3408V6.91702C19 5.65332 17.9756 4.62888 16.7119 4.62888H16.0191M1 14.3408V3.91704C1 2.65331 2.02444 1.62891 3.28814 1.62891H13.731C14.9947 1.62891 16.0191 2.65331 16.0191 3.91704V14.3408C16.0191 15.6045 14.9947 16.6289 13.731 16.6289H3.28814C2.02444 16.6289 1 15.6045 1 14.3408Z" stroke="#527AE6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    );}
    export const ThCodeBlockCopyIcon = () => {
        return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
<g id="copy-regular 1" clip-path="url(#clip0_954_2138)">
<path id="Vector" d="M7.75 7.0625H4C3.82812 7.0625 3.6875 6.92188 3.6875 6.75V1.75C3.6875 1.57812 3.82812 1.4375 4 1.4375H6.73633L8.0625 2.76367V6.75C8.0625 6.92188 7.92188 7.0625 7.75 7.0625ZM4 8H7.75C8.43945 8 9 7.43945 9 6.75V2.76367C9 2.51562 8.90039 2.27734 8.72461 2.10156L7.40039 0.775391C7.22461 0.599609 6.98633 0.5 6.73828 0.5H4C3.31055 0.5 2.75 1.06055 2.75 1.75V6.75C2.75 7.43945 3.31055 8 4 8ZM1.5 3C0.810547 3 0.25 3.56055 0.25 4.25V9.25C0.25 9.93945 0.810547 10.5 1.5 10.5H5.25C5.93945 10.5 6.5 9.93945 6.5 9.25V8.625H5.5625V9.25C5.5625 9.42188 5.42188 9.5625 5.25 9.5625H1.5C1.32812 9.5625 1.1875 9.42188 1.1875 9.25V4.25C1.1875 4.07812 1.32812 3.9375 1.5 3.9375H2.125V3H1.5Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_954_2138">
<rect width="8.75" height="10" fill="white" transform="translate(0.25 0.5)"/>
</clipPath>
</defs>
</svg>
 );}