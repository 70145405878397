import { useSelector } from "redux/hooks";
import { DragEvent } from "react";

import {
  classifyFiles,
  validateMaxFileCount,
  validateWSFile,
} from "utils/fileService";
import { addFile, IUploadedFile, UploadFile } from "redux/actions";
import { useState } from "react";
import { DocAllowedTypes, generateUniqueId } from "utils/constants";
import { documentMaxCountReached } from "utils/chat";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useWSFileUploader } from "utils/workSpaceS3";
import { FileUploadWrapper } from "./Component/FileUploadWrapper";

export const UploadingFiles = () => {
  const { fileUPToS3 } = useWSFileUploader();
  const { push } = useRouter();
  const { workSpaceFiles, wsUploadedFiles } = useSelector((state) => state.workSpaceReducer);
  const { userDetail } = useSelector((state) => state.authReducer);
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );

  const [messageId, setMessageId] = useState<string>("");
  const [errorModal, setErrorModal] = useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });

  const PlanName = userDetail?.user.activeSubscription?.name ;

  const fileLimit =
    userDetail?.user.activeSubscription?.name === "Free"
      ? 10
      : userDetail?.user.activeSubscription?.name === "Standard"
        ? 50
        : 100;

  const setValidationError = (messageKey: string) => {
    setErrorModal({ message: messageKey, show: true });
  };

  const checkForFreeLimit = (files: File[], messageId: string) => {
    const isFreeUser = userDetail?.user.activeSubscription?.name === "Free";
    const totalFilesCount =
      (workSpaceFiles ? workSpaceFiles.length : 0) + files.length;
    if (isFreeUser && (files.length > 1 || totalFilesCount > 1)) {
      setMessageId?.(messageId);
      return false;
    }
  };

  const handleFileUploadProcess = async(files: FileList | null) => {
    if (!files?.length) return;

    const newFilesArray: File[] = Array.from(files);
    const { docFiles } = classifyFiles(newFilesArray);

    const validDocFiles = newFilesArray.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return DocAllowedTypes.includes(fileExtension || "");
    });

    if (validDocFiles.length === 0) {
      setValidationError("documentChat.file.fileFormatImage");
      return;
    }

    const uniqueFileIds = new Set([
      ...(workSpaceFiles
        ?.filter((file: UploadFile) => file.status !== "error")
        .map((file: UploadFile) => file.uploaded_id) || []),
      ...(wsUploadedFiles?.map((file: IUploadedFile) => file.id) || []),
    ]);
    
    const TotalFiles = uniqueFileIds.size + newFilesArray.length;

    if((TotalFiles > 5 && PlanName!=="Free") || (PlanName==="Free" && TotalFiles > 1)){
      if(PlanName!=="Free") setValidationError("workSpace.upload.validation");
      else setValidationError( "workSpace.upload.validation.Free");
     return;
   }

   if (
     docFiles.length > 0 &&
     !validateMaxFileCount(docFiles, 5, workSpaceFiles)
   ) {
     setValidationError("workSpace.upload.validation");
     return;
   }

    if (documentMaxCountReached(currentPlan, userDetail)) {
      setMessageId?.("documentChat.plan.max_count");
      return;
    }
    const isFree = checkForFreeLimit(
      newFilesArray,
      "documentChat.plan.max_count"
    );
    if (isFree === false) return;

    await uploadFilesToS3(newFilesArray);

  }

  const handleDrop = async (event: DragEvent<HTMLElement> | undefined) => {
    event?.preventDefault();
    const files = event?.dataTransfer?.files ?? null;
    handleFileUploadProcess(files);
   
  };

const handleUploadFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (!event.target.files?.length) return;
  handleFileUploadProcess(event.target.files);
  event.target.value = "";
};

  const uploadFilesToS3 = async (files: File[]) => {
    const uploadedFiles: UploadFile[] = [];

    for (const file of files) {
      const updatedFile: UploadFile = {
        id: generateUniqueId(),
        file,
        status: "validating",
        fileType: "document",
        errorMessage: "",
        progress: 0,
        fileSize: file.size / (1024 * 1024),
      };

      uploadedFiles.unshift(updatedFile);
      addFile(updatedFile);
    }

    const validFiles = validateWSFile(uploadedFiles, setErrorModal, userDetail);

    if (validFiles) {
      await fileUPToS3({ fileArray: uploadedFiles });
    }
  };

  return (
    <>
     <FileUploadWrapper
      fileLimit={fileLimit}
      handleFileSelect={handleUploadFileSelect}
      handleDrop={handleDrop}
      errorModal={errorModal}
      setErrorModal={setErrorModal}
      messageId={messageId}
      onConfirm={() => push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)}
      onCancel={() => setMessageId("")}
    />
    </>
  );
};
