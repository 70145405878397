import styles from "./infoChat.module.scss";
import classNames from "classnames";
import { SuccessfulUploadingIcon } from "pages/Workspaces/components/icons/SuccessfulUploadingIcon";
import { useSelector } from "redux/hooks";
import { ChatInfoIcon } from "pages/Workspaces/components/icons/TreadsIcons";
import { useMiniChatInfo } from "hooks/services/ThreadServices/useMiniChatInfo";

interface IProp {
  loading: boolean;
}

export const MiniChatInfo = ({loading}: IProp) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { setShowMiniChatInfo } = useMiniChatInfo();
  const { currentConversation, wsUploadedFiles } = useSelector((state) => state.workSpaceReducer);

  const fileCount = loading? 0 : wsUploadedFiles.length;
  const displayCount = fileCount > 3 ? "3+" : fileCount.toString();
  return (
    <>
      <div
        className={classNames("max-w-[86px] text-center", styles.miniRightSide, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <div
          className={classNames("font-inter", styles.KnowledgeContainer, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <div
            className="flex-shrink-0 rotate-[-180deg]"
            onClick={() => setShowMiniChatInfo(false)}
          >
            <ChatInfoIcon />
          </div>
          <div className="h-[0.6px] bg-[#94A3B8]"></div>
          <div
            className="flex py-[14px] px-[12px] rounded-xl bg-transparent justify-center"
            style={{ border: "0.8px solid rgba(148, 163, 184, 0.50)" }}
          > {currentConversation && currentConversation?.emoji} </div>
          <div
            className="p-[8px] roundedbg-[#fff] shadow-[0px 1px 2px 0px rgba(0, 0, 0, 0.10)]"
            style={{ border: "0.2px solid rgba(148, 163, 184, 0.50)" }}
          >
            <div className="flex-shrink-0 w-[38px] h-[38px] rounded bg-[#E2E8F0] flex items-center justify-center">
              <SuccessfulUploadingIcon />
            </div>
            {fileCount > 0 && (
            <div className="absolute top-[9.4rem] right-[0.2rem] w-8 h-8 bg-[#C7D2FE] rounded-full flex items-center justify-center"  style={{ border: "2px solid #fff" }}>
              <span className="text-[#3730A3] font-normal text-[10px] font-inter">
              {displayCount}
              </span>
            </div>
            )}
        </div>
          </div>
         
      </div>
    </>
  );
};
