import { EThemeType } from "redux/reducers";
interface IProps {
  theme?: EThemeType;
}

const ChatEditIcon = ({ theme }: IProps) => {
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width='12'
      height='11'
      viewBox="0 0 12 11"
      fill="none"
    >
      <path
        d="M6.8999 10.1257H10.9292"
        stroke={theme === "dark" ? '#fff' : 'currentColor'}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36216 0.876375C6.79308 0.361361 7.56772 0.285842 8.09342 0.708005C8.12249 0.730908 9.05636 1.45638 9.05636 1.45638C9.63387 1.8055 9.81332 2.54769 9.45632 3.11408C9.43737 3.14441 4.15763 9.74859 4.15763 9.74859C3.98198 9.96772 3.71534 10.0971 3.43038 10.1002L1.40846 10.1256L0.952895 8.19736C0.889078 7.92624 0.952895 7.6415 1.12855 7.42237L6.36216 0.876375Z"
        stroke={theme === "dark" ? '#fff' : 'currentColor'}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3833 2.10107L8.41239 4.4273"
        stroke={theme === "dark" ? '#fff' : 'currentColor'}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}

export default ChatEditIcon

export const ThEditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 12" fill="none">
      <g id="pen-to-square-regular" clip-path="url(#clip0_1050_1906)">
        <path id="Vector" d="M10.3359 1.38052L10.6195 1.66411C10.8398 1.88442 10.8398 2.24067 10.6195 2.45864L9.9375 3.14302L8.85703 2.06255L9.53906 1.38052C9.75938 1.16021 10.1156 1.16021 10.3336 1.38052H10.3359ZM4.91719 6.00474L8.0625 2.85708L9.14297 3.93755L5.99531 7.08286C5.92734 7.15083 5.84297 7.20005 5.75156 7.22583L4.38047 7.61724L4.77188 6.24614C4.79766 6.15474 4.84688 6.07036 4.91484 6.00239L4.91719 6.00474ZM8.74453 0.585986L4.12031 5.20786C3.91641 5.41177 3.76875 5.66255 3.69141 5.93677L3.02109 8.28052C2.96484 8.47739 3.01875 8.68833 3.16406 8.83364C3.30938 8.97896 3.52031 9.03286 3.71719 8.97661L6.06094 8.3063C6.3375 8.22661 6.58828 8.07896 6.78984 7.87739L11.4141 3.25552C12.0727 2.59692 12.0727 1.52817 11.4141 0.86958L11.1305 0.585986C10.4719 -0.0726074 9.40313 -0.0726074 8.74453 0.585986ZM2.0625 1.50005C0.923438 1.50005 0 2.42349 0 3.56255V9.93755C0 11.0766 0.923438 12 2.0625 12H8.4375C9.57656 12 10.5 11.0766 10.5 9.93755V7.31255C10.5 7.00083 10.2492 6.75005 9.9375 6.75005C9.62578 6.75005 9.375 7.00083 9.375 7.31255V9.93755C9.375 10.4555 8.95547 10.875 8.4375 10.875H2.0625C1.54453 10.875 1.125 10.4555 1.125 9.93755V3.56255C1.125 3.04458 1.54453 2.62505 2.0625 2.62505H4.6875C4.99922 2.62505 5.25 2.37427 5.25 2.06255C5.25 1.75083 4.99922 1.50005 4.6875 1.50005H2.0625Z" fill="#94A3B8" />
      </g>
      <defs>
        <clipPath id="clip0_1050_1906">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}