import { EThemeType } from "redux/reducers";

interface IProp {
  theme?: EThemeType;
}

export const CopyIcon = ({theme}: IProp) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M2.39562 9.26373V9.65858C2.39562 10.3595 2.96384 10.9277 3.66477 10.9277H9.45704C10.158 10.9277 10.7262 10.3595 10.7262 9.65858V3.87687C10.7262 3.17594 10.158 2.60772 9.45704 2.60772H9.07278M0.742188 7.99457V2.21289C0.742188 1.51193 1.31041 0.943734 2.01134 0.943734H7.80363C8.50456 0.943734 9.07278 1.51193 9.07278 2.21289V7.99457C9.07278 8.69551 8.50456 9.26373 7.80363 9.26373H2.01134C1.31041 9.26373 0.742188 8.69551 0.742188 7.99457Z"
      stroke={theme==="dark"? "#fff":"currentColor"}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
};

export const ThCopyIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
<g id="floppy-disk-regular-2" clip-path="url(#clip0_1050_1908)">
<path id="Vector" d="M1.125 2.25V9.75C1.125 9.95625 1.29375 10.125 1.5 10.125H9C9.20625 10.125 9.375 9.95625 9.375 9.75V3.99609C9.375 3.89766 9.33516 3.80156 9.26484 3.73125L10.0594 2.93672C10.3406 3.21797 10.4977 3.6 10.4977 3.99844L10.5 9.75C10.5 10.5773 9.82734 11.25 9 11.25H1.5C0.672656 11.25 0 10.5773 0 9.75V2.25C0 1.42266 0.672656 0.75 1.5 0.75H7.25391C7.65234 0.75 8.03437 0.907031 8.31562 1.18828L10.0617 2.93437L9.26719 3.72891L7.51875 1.98516C7.51172 1.97812 7.50703 1.97344 7.5 1.96641V4.3125C7.5 4.62422 7.24922 4.875 6.9375 4.875H2.4375C2.12578 4.875 1.875 4.62422 1.875 4.3125V1.875H1.5C1.29375 1.875 1.125 2.04375 1.125 2.25ZM3 1.875V3.75H6.375V1.875H3ZM3.75 7.5C3.75 7.10218 3.90804 6.72064 4.18934 6.43934C4.47064 6.15804 4.85218 6 5.25 6C5.64782 6 6.02936 6.15804 6.31066 6.43934C6.59196 6.72064 6.75 7.10218 6.75 7.5C6.75 7.89782 6.59196 8.27936 6.31066 8.56066C6.02936 8.84196 5.64782 9 5.25 9C4.85218 9 4.47064 8.84196 4.18934 8.56066C3.90804 8.27936 3.75 7.89782 3.75 7.5Z" fill="#94A3B8"/>
</g>
<defs>
<clipPath id="clip0_1050_1908">
<rect width="10.5" height="12" fill="white"/>
</clipPath>
</defs>
</svg>
  );}